import { Button } from './shadcn/ui/button'
import { Navbar } from './Navbar'

interface ErrorDisplayProps {
  message?: string
  hideHelpMessage?: boolean
  hideReloadButton?: boolean
}

export const ErrorDisplay = ({
  message = 'Whoops, something went wrong!',
  hideHelpMessage,
  hideReloadButton,
}: ErrorDisplayProps) => {
  return (
    <>
      <Navbar />
      <div className="container flex h-screen flex-col items-center justify-center gap-y-4 py-10">
        <h1 className="text-5xl font-bold">Error</h1>
        <p>{message}</p>
        {!hideHelpMessage && (
          <p>
            If the error persists, please{' '}
            <a
              href="https://docs.pydantic.dev/logfire/help/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              contact us
            </a>
            .
          </p>
        )}
        {!hideReloadButton && (
          <Button
            onClick={() => {
              window.location.reload()
            }}
          >
            Reload
          </Button>
        )}
      </div>
    </>
  )
}

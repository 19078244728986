import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import { getListChannelsQueryKey, queryClient, useUpdateChannel } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { usePageTitle } from '@/hooks/usePageTitle'
import { Dialog, DialogContent } from '@/components/shadcn/ui/dialog'
import { ChannelForm } from '@/app/alerts/channels/components/ChannelForm'
import { Form } from '@/components/shadcn/ui/form'
import { ChannelRead } from '@/packages/api/__generated__/model'
import { useToast } from '@/components/shadcn/ui/use-toast'

import { channelFormSchema, ChannelFormSchema } from './channel-zod-schema'

interface EditChannelProps {
  channel: ChannelRead
  onDismiss: () => void
  onSaved: () => void
}

export const EditChannel = ({ channel, onDismiss }: EditChannelProps) => {
  usePageTitle('Edit Notification Channel')
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS.CHANNELS)
  const { toast } = useToast()

  const mutation = useUpdateChannel()
  const mutationErrorDetail = mutation.error?.response?.data.detail
  const mutationErrorMessage = Array.isArray(mutationErrorDetail) ? mutationErrorDetail[0].msg : mutationErrorDetail

  const form = useForm<ChannelFormSchema>({
    resolver: zodResolver(channelFormSchema),
    reValidateMode: 'onBlur',
    defaultValues: channel,
  })

  const onSubmit = () => {
    const values = form.getValues()
    mutation
      .mutateAsync({
        channelId: channel.id,
        organization: organizationName,
        project: projectName,
        data: {
          config: {
            type: values.config.type,
            url: values.config.url,
            format: values.config.format,
          },
          label: values.label,
        },
      })
      .then((data) => {
        const keys = getListChannelsQueryKey(organizationName, projectName)
        queryClient.invalidateQueries({ queryKey: keys }).then(() => {
          form.reset()
          onDismiss()
          toast({
            title: 'Channel updated',
            description: `Channel '${data.data.label}' has been updated`,
            variant: 'default',
          })
        })
      })
      .catch(() => {
        toast({ title: 'Failed to update channel', variant: 'destructive' })
      })
  }

  return (
    <Dialog
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          form.reset()
          onDismiss()
        }
      }}
    >
      <DialogContent>
        <section>
          <p className="text-2xl font-bold">Edit Channel</p>
        </section>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-4 text-on-surface-variant">
            <ChannelForm
              form={form}
              channel={channel}
              onSubmit={onSubmit}
              isSubmitting={mutation.isPending}
              mutationErrorMessage={mutationErrorMessage}
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

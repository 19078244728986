import { LoaderFunctionArgs, RouteObject } from 'react-router-dom'
import { z } from 'zod'
import { AxiosError, AxiosResponse } from 'axios'

import { REDIRECT_KEY, stateSchema } from '@/packages/router/routes'
import { getBaseUrl, githubUserInfo } from '@/packages/api'
import { GithubUserInfo200, HTTPExceptionError } from '@/packages/api/__generated__/model'
import { to } from '@/utils/await-to-js'

type RouteLoader = RouteObject['loader']

export const githubLoginLoader: RouteLoader = async ({ request }) => {
  // If the user is coming from a redirect of a protected route, the redirect saves original
  // path as a location state (https://reactrouter.com/en/main/start/concepts) so the route that it was
  // redirected to can access it and then saves sends it as a search param to this route
  // once the user is authenticated it goes back to the intended route they were trying to access
  // Check <ProtectedRoute /> -> <LoginPage /> -> here
  const redirectTo = new URL(request.url).searchParams.get(REDIRECT_KEY)

  // Check https://auth0.com/docs/secure/attack-protection/state-parameters
  // TLDR; we create a state object with a random key:
  // [randomKey] = {whatever you want to save}
  // The response of the auth flow should have the same randomKey if it's the one we sent
  const nonceKey = crypto.randomUUID().substring(7)
  if (redirectTo) {
    sessionStorage.setItem(nonceKey, JSON.stringify({ redirectTo }))
  } else {
    // Even if we don't have anything to add to the object we should save it as a way to know if
    // the request is the one we sent by checking the key
    sessionStorage.setItem(nonceKey, JSON.stringify({}))
  }
  const baseUrl = getBaseUrl()
  const response = await fetch(`${baseUrl}/auth/github-auth-url?state=${nonceKey}`)
  const githubAuthUrl = await response.json()

  location.replace(githubAuthUrl.url)
  return null
}

interface GitHubCodeLoaderSuccess {
  kind: 'success'
  userInfo: GithubUserInfo200
  state: z.infer<typeof stateSchema>
}

interface GitHubCodeLoaderError {
  kind: 'error'
  message: string
}

type GitHubCodeLoaderReturn = GitHubCodeLoaderSuccess | GitHubCodeLoaderError

export const githubCodeLoader = async ({ request }: LoaderFunctionArgs): Promise<GitHubCodeLoaderReturn> => {
  const url = new URL(request.url)
  const code = url.searchParams.get('code')

  if (!code) {
    return { kind: 'error', message: 'Missing "code" parameter in the URL.' }
  }

  const [response, error] = await to<AxiosResponse<GithubUserInfo200>, AxiosError<HTTPExceptionError>>(
    githubUserInfo({ code }),
  )
  if (error !== null) {
    return {
      kind: 'error' as const,
      message: error.response?.data.detail ?? 'User info request failed',
    }
  }

  const { data: userInfo } = response

  const stateParam = url.searchParams.get('state')

  if (!stateParam) {
    return { kind: 'error', message: 'Missing state parameter in the URL from GitHub callback' }
  }

  const stateInSession = sessionStorage.getItem(stateParam)

  if (!stateInSession) {
    return { kind: 'error', message: 'Missing state in session storage.' }
  }

  const state = JSON.parse(stateInSession)
  sessionStorage.removeItem(stateParam)
  return { kind: 'success', userInfo, state }
}

export type GitHubCodeLoaderResponse = Awaited<ReturnType<typeof githubCodeLoader>>

import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { useNavigate } from 'react-router-dom'

import { usePageTitle } from '@/hooks/usePageTitle'
import { NewWriteTokenForm } from '@/components/NewWriteTokenForm'
import { ROUTES } from '@/packages/router/routes'

export const ProjectSettingsWriteTokensNew = () => {
  usePageTitle('New Write Token')

  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS.WRITE_TOKENS.NEW)
  const navigate = useNavigate()

  return (
    <>
      <NewWriteTokenForm
        onDone={() =>
          navigate(ROUTES.ORGANIZATION.PROJECT.SETTINGS.WRITE_TOKENS.buildPath({ organizationName, projectName }))
        }
        organizationName={organizationName}
        projectName={projectName}
      />
    </>
  )
}

import { SectionElement } from '@uiw/react-json-view'

/** This is a container around each `<keyname /><colon /><value /><copied />`
 * etc. row within an array/object-like value */
export const RenderRow = (props: SectionElement<'div'>) => {
  const { children, style, ...otherProps } = props
  return (
    <div style={{ ...style, ...{ display: 'flex', alignItems: 'center' } }} {...otherProps}>
      {children}
      <div className="mt-auto">,</div>
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SettingsType } from '@/store/settings/settingsType'

export const initialState: SettingsType = {
  pinnedAttributes: [],
  useTimeFilterCte: true,
  useLimitCte: true,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setPinnedAttributes: (state, action: PayloadAction<string[]>) => {
      state.pinnedAttributes = action.payload
    },
    addPinnedAttribute: (state, action: PayloadAction<string>) => {
      if (!state.pinnedAttributes.includes(action.payload)) {
        state.pinnedAttributes.push(action.payload)
      }
    },
    removePinnedAttribute: (state, action: PayloadAction<string>) => {
      state.pinnedAttributes = state.pinnedAttributes.filter((item) => item !== action.payload)
    },
    setUseTimeFilterCte: (state, action: PayloadAction<boolean>) => {
      state.useTimeFilterCte = action.payload
    },
    setUseLimitCte: (state, action: PayloadAction<boolean>) => {
      state.useLimitCte = action.payload
    },
  },
})

// Actions
// Unpacking and repacking is a workaround for a bug in PyCharm that causes
// it to mis-resolve the actions as the reducer functions.
const { setPinnedAttributes, addPinnedAttribute, removePinnedAttribute, setUseTimeFilterCte, setUseLimitCte } =
  settingsSlice.actions

export const settingsActions = {
  setPinnedAttributes,
  addPinnedAttribute,
  removePinnedAttribute,
  setUseTimeFilterCte,
  setUseLimitCte,
}

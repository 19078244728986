import { Avatar, AvatarFallback, AvatarImage } from '@/components/shadcn/ui/avatar'
import { cn } from '@/packages/style'

interface UserAvatarProps {
  avatar?: string | undefined
  displayName?: string | null
  username?: string | null
  className?: string
}

export const UserAvatar = ({ avatar, displayName, username, className }: UserAvatarProps) => {
  const name = displayName ?? username
  const avatarFallback =
    name
      ?.split(' ')
      .slice(0, 2)
      .map((w) => w[0])
      .join('') ?? '-'

  return (
    <Avatar className={cn('h-20 w-20 rounded-full', className)}>
      <AvatarImage src={avatar} alt={`${name} profile picture`} />
      <AvatarFallback className={cn('bg-inverse-primary text-sm uppercase', className)}>
        {avatarFallback}
      </AvatarFallback>
    </Avatar>
  )
}

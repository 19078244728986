import { useCallback, useMemo } from 'react'

import { useToast } from '@/components/shadcn/ui/use-toast'
import { useToken } from '@/packages/auth/useToken'

import { TokenInfo } from '../auth/useToken'

interface RequestArgs {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  params?: Record<string, any>
  body?: string | FormData
  headers?: Record<string, string>
}

export interface ProjectDetails {
  id: string
  project_name: string
  organization_id: string
  organization_name: string
  project_url: string
}

export interface FileDetails {
  id: string
  name: string
  size: number
  python?: string
  createdAt: string
  updatedAt: string
}

interface QueryBuilderSuccess {
  status: 'success'
  where_clause: string
}

interface QueryBuilderError {
  status: 'error'
  error: string
}

interface QueryBuilderResponse {
  result: QueryBuilderSuccess | QueryBuilderError
}

export interface AutoComplete {
  span_names: string[]
  service_names: string[]
  tags: string[]
  args_str_keys: string[]
  args_bool_keys: string[]
  args_int_keys: string[]
  args_float_keys: string[]
  args_decimal_keys: string[]
  args_json_keys: string[]
  args_null_keys: string[]
}

export interface OpenAiMessage {
  role: 'system' | 'user' | 'assistant'
  content: string
}
export interface AIMessage {
  tag: 'message'
  message: OpenAiMessage
}
export interface AIFailure {
  tag: 'failure'
  reason: string
}
export interface AiChatMessage {
  uiRequest: OpenAiMessage
  uiResponse: AIMessage | AIFailure | null
  created_at: string
  responded_at: string | null
}
export type AiChatSource = Record<string, any>
export interface AiChat {
  id: string
  label: string | null
  created_at: string
  source: AiChatSource
  messages: AiChatMessage[]
}

interface ApiClient {
  hasToken: boolean
  tokenInfo: TokenInfo
  getProject: (organization: string, project: string) => Promise<ProjectDetails>
  queryLogs: (q: string) => Promise<any>
  aiQuery: (prompt: string) => Promise<QueryBuilderResponse>
  aiExploreQuery: (chat: string | AiChatSource, prompt: string, code: string) => Promise<AiChat>
  getAutocomplete: () => Promise<AutoComplete>
  savedQueries: {
    list: () => Promise<FileDetails[]>
    get: (fileId: string) => Promise<FileDetails>
    create: (name: string, python: string) => Promise<{ created: string }>
    update: (fileId: string, name: string, python: string) => Promise<{ updated: string }>
    delete: (fileId: string) => Promise<void>
  }
}

export const getBaseUrl = (): string => {
  return `${window.location.protocol}//${window.appConfig.apiHost}`
}

export const useApiClient = (projectName: string | undefined): ApiClient => {
  const { toast } = useToast()
  const tokenInfo = useToken()

  const setError = useCallback(
    (description: string) => {
      toast({ title: 'Request Error', description, className: 'bg-red-600' })
    },
    [toast],
  )

  const projectApiClient = useMemo(() => {
    if (!projectName) {
      return getErrorHandlerApiClient('projectName is required but not specified')
    }
    if (!tokenInfo) {
      return getErrorHandlerApiClient('token is required but was not present')
    }
    const baseUrl = getBaseUrl()
    return getProjectApiClient(baseUrl, tokenInfo.token, setError)
  }, [projectName, tokenInfo, setError])

  const { getProject, queryLogs, aiQuery, savedQueries, getAutocomplete, aiExploreQuery } = projectApiClient

  return {
    hasToken: tokenInfo !== null,
    tokenInfo,
    getProject,
    queryLogs,
    aiQuery,
    aiExploreQuery,
    savedQueries,
    getAutocomplete,
  }
}

interface ProjectApiClient {
  getProject: (organization: string, project: string) => Promise<ProjectDetails>
  queryLogs: (q: string) => Promise<any>
  getAutocomplete: () => Promise<AutoComplete>
  aiQuery: (prompt: string) => Promise<QueryBuilderResponse>
  aiExploreQuery: (chat: string | AiChatSource, prompt: string, code: string) => Promise<AiChat>
  savedQueries: {
    list: () => Promise<FileDetails[]>
    get: (fileId: string) => Promise<FileDetails>
    create: (name: string, python: string) => Promise<{ created: string }>
    update: (fileId: string, name: string, python: string) => Promise<{ updated: string }>
    delete: (fileId: string) => Promise<void>
  }
}

const getErrorHandlerApiClient = (errorMsg: string): ProjectApiClient => {
  const handleRequest = (..._args: any[]): Promise<any> => {
    throw new Error(errorMsg)
  }
  return {
    getProject: handleRequest,
    queryLogs: handleRequest,
    aiQuery: handleRequest,
    aiExploreQuery: handleRequest,
    getAutocomplete: handleRequest,
    savedQueries: {
      list: handleRequest,
      get: handleRequest,
      create: handleRequest,
      update: handleRequest,
      delete: handleRequest,
    },
  }
}

export const getProjectApiClient = (
  baseUrl: string,
  token: string,
  handleErrorDescription?: (description: string) => void,
): ProjectApiClient => {
  const projectRequest = async (path: string, args?: RequestArgs): Promise<any> => {
    return request({ path: `${baseUrl}${path}`, token, args, handleErrorDescription })
  }

  return {
    getProject: (organization: string, project: string): Promise<ProjectDetails> =>
      projectRequest(`/dash/projects/${organization}/${project}`),
    queryLogs: (q: string): Promise<any> => projectRequest(`/dash/query`, { params: { q } }),
    getAutocomplete: (): Promise<AutoComplete> => projectRequest(`/dash/autocomplete`),
    aiQuery: (prompt: string): Promise<QueryBuilderResponse> => projectRequest(`/dash/ai`, { params: { prompt } }),
    aiExploreQuery: (chat: string | AiChatSource, prompt: string, code: string): Promise<AiChat> =>
      projectRequest(`/dash/ai/explore-help`, {
        method: 'POST',
        body: JSON.stringify({ chat, prompt, code }),
        headers: { 'Content-Type': 'application/json' },
      }),
    savedQueries: {
      list: (): Promise<any> => projectRequest(`/saved_queries`),
      get: (fileId: string): Promise<FileDetails> => projectRequest(`/saved_queries/${fileId}`),
      delete: (fileId: string): Promise<void> => projectRequest(`/saved_queries/${fileId}`, { method: 'DELETE' }),
      create: (name: string, python: string): Promise<{ created: string }> => {
        return projectRequest(`/saved_queries`, {
          method: 'POST',
          body: JSON.stringify({ name, python }),
          headers: { 'Content-Type': 'application/json' },
        })
      },
      update: (fileId: string, name: string, python: string): Promise<any> => {
        return projectRequest(`/saved_queries/${fileId}`, {
          method: 'PUT',
          body: JSON.stringify({ name, python }),
          headers: { 'Content-Type': 'application/json' },
        })
      },
    },
  }
}

interface RequestOptions {
  path: string
  token: string
  args?: RequestArgs
  handleErrorDescription?: (description: string) => void
}

const request = async ({ path, token, args, handleErrorDescription }: RequestOptions): Promise<any> => {
  let url = path
  const { params } = args ?? {}
  if (params) {
    const searchParams = new URLSearchParams()
    for (const [key, value] of Object.entries(params)) {
      searchParams.append(key, value)
    }
    url += '?' + searchParams.toString()
  }
  const init: RequestInit = {}
  init.headers = args?.headers ?? {}

  if (token) {
    init.headers['Authorization'] = `Bearer ${token}`
  }

  if (args?.body) {
    init.body = args.body
  }

  if (args?.method) {
    init.method = args.method
  }

  let response
  try {
    response = await fetch(url, init)
  } catch (e) {
    handleErrorDescription?.('fetch failed')
    throw e
  }
  const { status } = response
  if (!response.ok) {
    let detail: null | string = null
    const content = await response.text()
    try {
      const jsonData = JSON.parse(content)
      console.warn(`${path} -> ${status} JSON:`, jsonData)
      if (typeof jsonData.detail === 'string') {
        detail = jsonData.detail
      }
    } catch (e) {
      console.warn(`${path} -> ${status} JSON:`, detail)
      detail = content
    }
    const msg = `${detail ?? response.statusText} (${status})`
    const e = new Error(msg)
    handleErrorDescription?.(msg)
    throw e
  } else {
    const data = await response.json()
    console.debug(`${path} -> ${status} JSON:`, data)
    return data
  }
}

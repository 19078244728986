import JsonView, { SectionElement, SymbolsElement, TypesElement } from '@uiw/react-json-view'

import { RenderCopied } from './RenderCopied'
import { RenderRow } from './RenderRow'
import { RenderString } from './RenderString'

export const JsonViewJsonOverrides = ({ includeFilter }: { includeFilter?: boolean }) => {
  return (
    <>
      <JsonView.BraceLeft render={(props: SymbolsElement<'span'>) => <span {...props} />} />
      <JsonView.BraceRight render={(props: SymbolsElement<'span'>) => <span {...props} />} />
      <JsonView.BracketsLeft render={(props: SymbolsElement<'span'>) => <span {...props} />} />
      <JsonView.BracketsRight render={(props: SymbolsElement<'span'>) => <span {...props} />} />
      <JsonView.Colon
        render={(props) => {
          const { children, ...otherProps } = props
          return <span {...otherProps}>{children}&nbsp;</span>
        }}
      />
      <JsonView.Copied
        render={(props, { value }) => <RenderCopied {...props} value={value} includeFilter={includeFilter} />}
      />
      <JsonView.CountInfo render={(props: SectionElement<'span'>) => <span {...props} />} />
      <JsonView.False render={(props: TypesElement<'span'>) => <span {...props}>false</span>} />
      <JsonView.Float render={(props: TypesElement<'span'>) => <span {...props} />} />
      <JsonView.Int render={(props: TypesElement<'span'>) => <span {...props} />} />
      <JsonView.KeyName render={(props: SectionElement) => <span {...props} />} />
      <JsonView.Null render={(props: TypesElement<'span'>) => <span {...props}>null</span>} />
      <JsonView.Quote render={(props) => <span {...props} />} />
      <JsonView.Row render={(props: SectionElement<'div'>) => <RenderRow {...props} />} />
      <JsonView.String render={(props: TypesElement<'span'>) => <RenderString {...props} />} />
      <JsonView.True render={(props: TypesElement<'span'>) => <span {...props}>true</span>} />
      <JsonView.ValueQuote render={(props: SymbolsElement<'span'>) => <span {...props} />} />
    </>
  )
}

import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import { TokenElement, AuthManager, User } from '@/context/AuthManager'
import { useAccountGet } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { useLoadingLogo } from '@/components/LoadingLogo'

interface AuthState {
  login: (props: { userToken: string; user: User; expiration: string }) => void
  // syncProjects: () => void
  logout: () => void
  isAuthenticated: boolean
  isReady: boolean
  activeProject: TokenElement | undefined
  user: User | null
  token?: string
}

const initialState: AuthState = {
  login: () => {
    console.error('login not implemented')
  },
  logout: () => {
    console.error('logout not implemented')
  },
  isAuthenticated: false,
  isReady: false,
  activeProject: undefined,
  user: null,
}

const AuthContext = createContext<AuthState>(initialState)

const authManager = AuthManager.getInstance()

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isReady, setIsReady] = useState(false)
  const { organizationName, projectName } = useParams()
  const [user, setUser] = useState(authManager.getUser())
  const navigate = useNavigate()
  const logFireToken = authManager.getLogfireUserToken()
  const { data: account, isLoading } = useAccountGet({ query: { enabled: isReady && Boolean(logFireToken?.token) } })

  useLoadingLogo(isLoading)

  const projectToken = authManager.getToken(organizationName!, projectName!)?.token
  const token = projectToken && projectToken.length > 0 ? projectToken : logFireToken?.token

  const { hash } = useLocation()

  const login = useCallback((props: { userToken: string; user: User; expiration: string }) => {
    const logfireTokenResult = authManager.setLogfireUserToken({
      token: props.userToken,
      expiration: props.expiration,
    })

    try {
      const userResult = authManager.setUser(props.user)

      if (logfireTokenResult instanceof Error || userResult instanceof Error) {
        console.error('Error setting token', logfireTokenResult, userResult)
        return
      }
    } catch (e) {
      console.error('Error setting token', e)
      return
    }

    setUser(props.user)
  }, [])

  const logout = useCallback(() => {
    const { location } = window
    const previousPath = location.pathname + location.search
    authManager.cleanAll()
    setUser(null)
    navigate(ROUTES.LOGIN.path, { state: { from: previousPath } })
    setIsReady(true)
  }, [navigate])

  useEffect(() => {
    // setIsReady(false)

    const hashAsQueryParams = new URLSearchParams(hash.slice(1))
    const hashToken = hashAsQueryParams.get('token')

    if (hashToken) {
      hashAsQueryParams.delete('token')
      const newHash = hashAsQueryParams.toString()

      if (projectName && organizationName && hashToken) {
        authManager.addProject({
          token: hashToken,
          organizationName,
          projectName,
        })

        // Clear the hash
        if (newHash === '') {
          history.replaceState('', document.title, window.location.pathname + window.location.search)
        } else {
          window.location.hash = newHash
        }
      } else {
        console.debug('AuthContext:: Organization and project name not found in the URL for the token')
        logout()
        return
      }
    } else if (logFireToken && authManager.isLogfireUserTokenExpired(logFireToken)) {
      logout()
      console.debug('AuthContext:: Token expired')
      return
    }

    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const projectToken = authManager.getToken(organizationName!, projectName!)?.token
        const usableToken = hashToken ?? (projectToken && projectToken.length > 0 ? projectToken : logFireToken?.token)

        if (usableToken) {
          config.headers.Authorization = `Bearer ${usableToken}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response?.status === 401) {
          logout()
        }
        return Promise.reject(error)
      },
    )

    // TODO: ... this should really be reworked
    // setTimeout(() => {
    setIsReady(true)
    // }, 10)

    return () => {
      axios.interceptors.request.eject(requestInterceptor)
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [hash, logFireToken, logout, organizationName, projectName])

  useEffect(() => {
    if (account?.data) {
      const user: User = {
        defaultOrganizationName: account.data.default_organization?.organization_name,
        email: account.data.email,
        githubUsername: account.data.github_username,
        id: account.data.id,
        name: account.data.name,
      }
      setUser(user)
      authManager.setUser(user)
    }
  }, [account])

  const contextValue = {
    login,
    logout,
    isAuthenticated: Boolean(token),
    isReady: isReady && (token ? account?.data !== undefined : true),
    activeProject: authManager.getToken(organizationName!, projectName!),
    user,
    token,
  }

  return <AuthContext.Provider value={contextValue}>{isReady ? children : null}</AuthContext.Provider>
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === null) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return context
}

export { AuthProvider, useAuth }

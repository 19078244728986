import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { boolean, useTypedParams } from 'react-router-typesafe-routes/dom'
import { Blocker, Link, useBlocker, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { ArrowLeft } from 'lucide-react'
import { Suspense } from 'react'

import {
  getGetAlertQueryKey,
  getListAlertsQueryKey,
  queryClient,
  useGetAlertSuspense,
  useUpdateAlert,
} from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { usePageTitle } from '@/hooks/usePageTitle'
import { AlertForm } from '@/app/alerts/components/AlertForm'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { AlertWithLastRun } from '@/packages/api/__generated__/model'
import { Dialog, DialogClose, DialogContent } from '@/components/shadcn/ui/dialog'
import { Button } from '@/components/shadcn/ui/button'
import { getSimpleDurationIsoDuration, parseIsoDuration, simpleDurationSchema } from '@/packages/time/simpleDuration'

import { updateAlertFormSchema } from '../zod-schemas'
import { FIVE_MINUTE_DURATION } from '../utils'

type UpdateAlertFormSchema = z.infer<typeof updateAlertFormSchema>

const DEFAULT_DURATION = FIVE_MINUTE_DURATION
const notificationChannelWebhooksToString = (notificationChannels: AlertWithLastRun['notification_channels']) => {
  return notificationChannels
    ?.map((channel) => channel.url.trim())
    .filter(boolean)
    .join(', ')
}

interface UnsavedChangesBlockerProps {
  blocker: Blocker
}

const UnsavedChangesBlocker = ({ blocker }: UnsavedChangesBlockerProps) => {
  return (
    <Dialog
      open={blocker.state === 'blocked'}
      onOpenChange={(open) => {
        if (!open) {
          blocker.reset?.()
        }
      }}
    >
      <DialogContent>
        <section>
          <p className="text-2xl font-bold">Unsaved changes</p>
          <p className="mt-2 text-on-surface">You have unsaved changes. If you leave the page they will be lost.</p>
        </section>
        <section className="flex items-center gap-x-2">
          <DialogClose asChild>
            <Button onClick={() => blocker.reset?.()} variant="secondary">
              Stay
            </Button>
          </DialogClose>
          <Button onClick={() => blocker.proceed?.()} variant="destructive">
            Leave
          </Button>
        </section>
      </DialogContent>
    </Dialog>
  )
}

const EditAlertInternal = () => {
  usePageTitle('Edit Alert')
  const { organizationName, projectName, alertId } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS.EDIT)
  const navigate = useNavigate()

  const { toast } = useToast()
  const { data: alertData } = useGetAlertSuspense(organizationName, projectName, alertId)
  const alert = alertData.data

  const mutation = useUpdateAlert({
    mutation: {
      onSuccess: async (newAlertStateResponse) => {
        const newAlertState = newAlertStateResponse.data
        toast({ title: 'Alert updated', variant: 'default' })
        form.reset({}, { keepValues: true })
        const key = getGetAlertQueryKey(organizationName, projectName, alertId)
        await queryClient.invalidateQueries({ queryKey: key })
        queryClient.invalidateQueries({ queryKey: getListAlertsQueryKey(organizationName, projectName) }).then(() => {
          form.reset({
            active: newAlertState.active,
            name: newAlertState.name,
            query: newAlertState.query,
            timeWindow: parseIsoDuration(newAlertState.time_window) ?? DEFAULT_DURATION,
            notifyWhen: newAlertState.notify_when,
            webhookURLs: notificationChannelWebhooksToString(newAlertState.notification_channels),
          })
        })

        // isFormDirty is not updated yet, so we need to wait for the next tick to avoid wrongfully triggering the blocker
        setTimeout(() => {
          navigate(
            ROUTES.ORGANIZATION.PROJECT.ALERTS.HISTORY.buildPath({
              organizationName,
              projectName,
              alertId,
            }),
            { replace: true },
          )
        }, 0)
      },
      onError: () => {
        toast({ title: 'Failed to update alert', variant: 'destructive' })
      },
    },
  })

  const mutationErrorDetail = mutation.error?.response?.data.detail
  const mutationErrorMessage = Array.isArray(mutationErrorDetail) ? mutationErrorDetail[0].msg : mutationErrorDetail

  const form = useForm<UpdateAlertFormSchema>({
    resolver: zodResolver(updateAlertFormSchema),
    defaultValues: {
      name: alert?.name ?? '',
      query: alert?.query ?? '',
      channels: alert?.channels?.map((channel) => channel.id) ?? [],
      timeWindow: parseIsoDuration(alert?.time_window) ?? DEFAULT_DURATION,
      notifyWhen: alert?.notify_when ?? 'has_matches',
      webhookURLs: notificationChannelWebhooksToString(alert?.notification_channels ?? []) ?? '',
      active: alert?.active ?? true,
    },
  })

  const isFormDirty = form.formState.isDirty

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return isFormDirty && currentLocation.pathname !== nextLocation.pathname && mutation.isIdle
  })

  const onSubmit = (formData: UpdateAlertFormSchema) => {
    const timeWindow = simpleDurationSchema.parse(formData.timeWindow) // purposely error if this is not valid

    const notificationChannels = (formData.webhookURLs ?? '')
      .replace(/\s/g, '')
      .split(',')
      .map((url) => ({ type: 'webhook' as const, format: 'auto' as const, url }))
    mutation.mutate({
      organization: organizationName,
      project: projectName,
      alertId,
      data: {
        name: formData.name,
        time_window: getSimpleDurationIsoDuration(timeWindow),
        active: formData.active,
        notification_channels: notificationChannels,
        channel_ids: formData.channels,
        notify_when: formData.notifyWhen,
        query: formData.query,
      },
    })
  }

  return (
    <>
      <div>
        <Link
          to={ROUTES.ORGANIZATION.PROJECT.ALERTS.HISTORY.buildPath({ organizationName, projectName, alertId })}
          className="text-sm text-on-surface-variant"
        >
          <ArrowLeft className="-mt-1 mr-1 inline-block w-4" />
          Back to alerts history
        </Link>
      </div>
      <h2 className="mb-12 text-xl text-on-surface">Editing configuration for {alert?.name}</h2>
      {alert ? (
        <AlertForm
          form={form}
          alert={alert}
          onSubmit={onSubmit}
          isSubmitting={mutation.isPending}
          mutationErrorMessage={mutationErrorMessage}
        />
      ) : null}
      <UnsavedChangesBlocker blocker={blocker} />
    </>
  )
}

export const EditAlert = () => {
  return (
    <Suspense>
      <EditAlertInternal />
    </Suspense>
  )
}

export const themeTokens = {
  light: {
    'design-primary': '#000000',
    'on-primary': '#F7F7F7',
    'primary-container': '#20262E',
    'on-primary-container': '#9BA1AA',
    'design-secondary': '#3A3C40',
    'on-secondary': '#D0D0D5',
    'secondary-container': '#737478',
    'on-secondary-container': '#FFFEFF',
    tertiary: '#000000',
    'on-tertiary': '#A59484',
    'tertiary-container': '#2F2418',
    'on-tertiary-container': '#AF9D8D',
    link: '#3679D9',
    'link-variant': '#EBF2FB',
    highlight: '#FCD34D',
    'states-error': '#EA4335',
    'states-on-error': '#FFEDE9',
    'states-error-container': '#FFDAD4',
    'states-error-container-alpha': '#FFDAD480',
    'states-on-error-container': '#410001',
    'states-error-variant': '#BA1B1B',
    'states-on-error-variant': '#FFFAF9',
    'states-warning': '#EFB77A',
    'states-on-warning': '#613A0D',
    'states-warning-container': '#FFDCB8',
    'states-on-warning-container': '#683C00',
    'states-warning-container-alpha': '#FFDCB880',
    'states-notice': '#A5D490',
    'states-on-notice': '#222222',
    'states-notice-container-alpha': '#CAE6BC99',
    'states-on-notice-container': '#00814C',
    'states-notice-container': '#E5F6EB',
    'states-info': '#9EC1FB',
    'states-on-info': '#063175',
    'states-info-variant': '#5881C6',
    'states-debug': '#E3E3E3',
    'states-on-debug': '#636262',
    surface: '#FFFFFF',
    'on-surface': '#1B1B1B',
    'on-surface-variant': '#5F6368',
    'inverse-surface': '#303030',
    'inverse-on-surface': '#F1F1F1',
    'surface-dim': '#DADADA',
    'surface-bright': '#FFFFFF',
    'surface-container-lowest': '#FFFFFF',
    'surface-container-low': '#F9F9F9',
    'surface-container': '#F5F5F5',
    'surface-container-high': '#E8E8E8',
    'surface-container-highest': '#E2E2E2',
    outline: '#DADCE0',
    'outline-variant': '#DDE0E4',
    'inverse-primary': '#9CCBFB',
  },
  dark: {
    'design-primary': '#2B2B2B',
    'on-primary': '#B0B0B0',
    'primary-container': '#6A7079',
    'on-primary-container': '#F1F6FF',
    'design-secondary': '#DFDFE3',
    'on-secondary': '#414346',
    'secondary-container': '#737478',
    'on-secondary-container': '#FFFDFF',
    tertiary: '#F0DCCA',
    'on-tertiary': '#4D4033',
    'tertiary-container': '#7C6D5E',
    'on-tertiary-container': '#FFF4EC',
    link: '#2582E2',
    'link-variant': '#081B35',
    highlight: '#CA8A04',
    'states-error': '#EA4335',
    'states-on-error': '#FFEDE9',
    'states-error-container': '#442A26',
    'states-error-container-alpha': '#442A2680',
    'states-on-error-container': '#FF8978',
    'states-error-variant': '#FF8978',
    'states-on-error-variant': '#2C1F12',
    'states-warning': '#C36D43',
    'states-on-warning': '#F9DBB9',
    'states-warning-container': '#58432C',
    'states-on-warning-container': '#FFDCB8',
    'states-warning-container-alpha': '#58432C80',
    'states-notice': '#85E89D',
    'states-on-notice': '#262626',
    'states-notice-container-alpha': '#294E3185',
    'states-on-notice-container': '#63D196',
    'states-notice-container': '#152C1F',
    'states-info': '#355E77',
    'states-on-info': '#AAC3EC',
    'states-info-variant': '#3F86B4',
    'states-debug': '#262626',
    'states-on-debug': '#94A3B8',
    surface: '#111111',
    'on-surface': '#C9C9C9',
    'on-surface-variant': '#737373',
    'inverse-surface': '#E3E3E3',
    'inverse-on-surface': '#303030',
    'surface-dim': '#131313',
    'surface-bright': '#393939',
    'surface-container-lowest': '#171717',
    'surface-container-low': '#222222',
    'surface-container': '#2A2A2A',
    'surface-container-high': '#313131',
    'surface-container-highest': '#3A3A3A',
    outline: '#484848',
    'outline-variant': '#606060',
    'inverse-primary': '#31628D',
  },
}

import { sql, SQLDialect } from '@codemirror/lang-sql'
import { Completion } from '@codemirror/autocomplete'

import { AutoComplete } from '@/packages/api/__generated__/model'

export const getRecordSchema = (extraSqlAutoComplete: Completion[]) => {
  return {
    records: [
      {
        label: 'project_id',
        type: 'UUID NOT NULL',
        detail: 'Unique ID for the project',
      },
      {
        label: 'start_timestamp',
        type: 'TIMESTAMPTZ NOT NULL',
        detail: 'Time the record was opened',
      },
      { label: 'trace_id', type: 'TEXT NOT NULL', detail: 'ID of the trace for this record' },
      {
        label: 'span_id',
        type: 'TEXT NOT NULL',
        detail: 'ID of the span for this record',
      },
      { label: 'parent_span_id', type: 'TEXT', detail: 'ID of the parent of the span for this record' },
      { label: 'end_timestamp', type: 'TIMESTAMPTZ NOT NULL', detail: 'Time the record was closed' },
      { label: 'kind', type: 'span_kind NOT NULL', detail: "Kind of record: 'log', 'pending_span', or 'span'" },
      {
        label: 'level',
        type: 'SMALLINT',
        detail: 'Numeric level: trace=1, debug=5, info=9, notice=10, warn=13, error=17, fatal=21',
      },
      { label: 'span_name', type: 'TEXT NOT NULL', detail: 'Name of the span' },
      { label: 'message', type: 'TEXT NOT NULL', detail: 'The formatted span message' },
      { label: 'attributes', type: 'JSONB NOT NULL', detail: 'Attributes associated with the record', boost: 2 },
      { label: 'tags', type: 'TEXT[]', detail: 'Array of tags for the record' },
      { label: 'otel_events', type: 'JSONB', detail: 'Array of events for the record' },
      {
        label: 'is_exception',
        type: 'BOOLEAN',
        detail: 'Whether the record was produced while handling a raised exception',
      },
      {
        label: 'otel_status_code',
        type: 'status_code NOT NULL',
        detail: "The OTel status_code for the span: 'UNSET', 'OK', or 'ERROR'",
      },
      { label: 'otel_status_message', type: 'TEXT', detail: 'The OTel otel_status_message for the span' },
      { label: 'otel_scope_name', type: 'TEXT NOT NULL', detail: 'The name for the OTel scope span' },
      { label: 'otel_scope_version', type: 'TEXT', detail: 'The version for the OTel scope span' },
      { label: 'otel_scope_attributes', type: 'JSONB NOT NULL', detail: 'The attributes for the OTel scope span' },
      { label: 'service_name', type: 'TEXT NOT NULL', detail: 'The service_name for the OTel resource span' },
      {
        label: 'service_version',
        type: 'TEXT',
        detail: 'The service_version for the OTel resource span',
      },
      {
        label: 'otel_resource_attributes',
        type: 'JSONB NOT NULL',
        detail: 'The attributes for the OTel resource span',
      },
      {
        label: 'attributes_json_schema',
        type: 'TEXT NOT NULL',
        detail: 'The JSON schema for the attributes',
      },
      ...extraSqlAutoComplete,
    ],
    metrics: [
      { label: 'project_id', type: 'UUID NOT NULL', detail: 'Unique ID for the project' },
      { label: 'recorded_timestamp', type: 'TIMESTAMPTZ NOT NULL', detail: 'Timestamp when the value was sampled' },
      { label: 'metric_name', type: 'TEXT NOT NULL', detail: 'Name of the metric' },
      { label: 'metric_type', type: 'TEXT NOT NULL', detail: 'Type of the metric' },
      { label: 'unit', type: 'TEXT NOT NULL', detail: 'Unit for the metric' },
      {
        label: 'start_timestamp',
        type: 'TIMESTAMPTZ',
        detail: 'The start of the time window over which the metric is aggregated',
      },
      { label: 'aggregation_temporality', type: 'aggregation_temporality', detail: '' },
      { label: 'is_monotonic', type: 'BOOLEAN', detail: '' },
      { label: 'metric_description', type: 'TEXT', detail: 'Description of the metric' },
      { label: 'scalar_value', type: 'FLOAT8', detail: '' },
      { label: 'histogram_min', type: 'FLOAT8', detail: '' },
      { label: 'histogram_max', type: 'FLOAT8', detail: '' },
      { label: 'histogram_count', type: 'INTEGER', detail: '' },
      { label: 'histogram_sum', type: 'FLOAT8', detail: '' },
      { label: 'exp_histogram_scale', type: 'INTEGER', detail: '' },
      { label: 'exp_histogram_zero_count', type: 'INTEGER', detail: '' },
      { label: 'exp_histogram_zero_threshold', type: 'FLOAT8', detail: '' },
      { label: 'exp_histogram_positive_bucket_counts', type: 'INTEGER[]', detail: '' },
      { label: 'exp_histogram_positive_bucket_counts_offset', type: 'INTEGER', detail: '' },
      { label: 'exp_histogram_negative_bucket_counts', type: 'INTEGER[]', detail: '' },
      { label: 'exp_histogram_negative_bucket_counts_offset', type: 'INTEGER', detail: '' },
      { label: 'histogram_bucket_counts', type: 'INTEGER[]', detail: '' },
      { label: 'histogram_explicit_bounds', type: 'FLOAT8[]', detail: '' },
      { label: 'attributes', type: 'JSONB NOT NULL', detail: 'Attributes associated with the metric', boost: 2 },
      { label: 'tags', type: 'TEXT[] NOT NULL', detail: 'Array of tags for the metric' },
      { label: 'otel_scope_name', type: 'TEXT NOT NULL', detail: 'The name for the OTel scope span' },
      { label: 'otel_scope_version', type: 'TEXT', detail: 'The version for the OTel scope span' },
      { label: 'otel_scope_attributes', type: 'JSONB NOT NULL', detail: 'The attributes for the OTel scope span' },
      { label: 'service_name', type: 'TEXT', detail: 'The service_name for the OTel resource span' },
      { label: 'service_version', type: 'TEXT', detail: 'The service_version for the OTel resource span' },
      {
        label: 'otel_resource_attributes',
        type: 'JSONB NOT NULL',
        detail: 'The attributes for the OTel resource span',
      },
    ],
  }
}

// TODO(Samuel) use auto-completes to add extra columns
export const pgSql = (extraSqlAutoComplete: Completion[], limitedKeywords?: boolean, defaultTable = 'records') => {
  return sql({
    schema: getRecordSchema(extraSqlAutoComplete),
    dialect: SQLDialect.define({
      // based very roughly on https://github.com/simonw/datasette/blob/fd083e37ec53e7e625111168d324a572344a3b19/datasette/static/cm-editor-6.0.1.js#L6C16-L17C3
      keywords: limitedKeywords ? 'and between cast count or date interval' : undefined,
      types:
        'BOOLEAN FLOAT8 FLOAT8[] INTEGER INTEGER[] INTERVAL JSONB NULL REAL SMALLINT TEXT TEXT[] TIME TIMETZ TIMESTAMP TIMESTAMPTZ UUID',
      builtin: 'current_date lower upper trim ltrim rtrim length concat regexp_like today',
      operatorChars: '*+-%<>!=&|/~',
      identifierQuotes: '"',
      // specialVar: "@:?$",
    }),
    defaultTable,
  })
}

export const buildSqlAutocompleteColumns = (autocomplete: AutoComplete): Completion[] => {
  const autocompleteColumns: Completion[] = []
  if (autocomplete.service_names.length > 0) {
    autocompleteColumns.push({
      label: 'service_name',
      type: 'text',
      detail: 'Service name',
      boost: 1,
    })
  }

  autocomplete.attribute_keys.forEach((key) => {
    const label = `attributes->>'${key}'`
    autocompleteColumns.push({ label, type: 'enum', boost: 1 })
  })

  return autocompleteColumns
}

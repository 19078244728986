import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { useNavigate } from 'react-router-dom'

import { getListChannelsQueryKey, queryClient, useCreateChannel } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { usePageTitle } from '@/hooks/usePageTitle'
import { Dialog, DialogContent } from '@/components/shadcn/ui/dialog'
import { ChannelForm } from '@/app/alerts/channels/components/ChannelForm'
import { Form } from '@/components/shadcn/ui/form'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { ChannelRead } from '@/packages/api/__generated__/model'

import { channelFormSchema, ChannelFormSchema } from './channel-zod-schema'

interface NewChannelProps {
  onSaved?: (channel: ChannelRead) => void
  onDismissed?: () => void
}

export const NewChannel = ({ onDismissed, onSaved }: NewChannelProps) => {
  usePageTitle('New Channel')
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS)
  const navigate = useNavigate()
  const { toast } = useToast()

  const mutation = useCreateChannel()
  const mutationErrorDetail = mutation.error?.response?.data.detail
  const mutationErrorMessage = Array.isArray(mutationErrorDetail) ? mutationErrorDetail[0].msg : mutationErrorDetail

  const form = useForm<ChannelFormSchema>({
    resolver: zodResolver(channelFormSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      label: '',
      config: {
        type: 'webhook',
        url: '',
        format: 'auto',
      },
    },
  })

  const onSubmit = () => {
    const values = form.getValues()
    mutation
      .mutateAsync({
        organization: organizationName,
        project: projectName,
        data: {
          config: {
            type: values.config.type,
            url: values.config.url,
            format: values.config.format,
          },
          label: values.label,
        },
      })
      .then((data) => {
        const keys = getListChannelsQueryKey(organizationName, projectName)
        queryClient.invalidateQueries({ queryKey: keys }).then(() => {
          form.reset()
          if (data.data.id === undefined) return
          if (onSaved) {
            onSaved(data.data)
          } else {
            navigate(
              ROUTES.ORGANIZATION.PROJECT.ALERTS.CHANNELS.buildPath({
                organizationName,
                projectName,
              }),
              {
                replace: true,
              },
            )
          }
        })
        toast({
          title: 'Channel created',
          description: `Channel '${data.data.label}' has been created`,
          variant: 'default',
        })
      })
      .catch(() => {
        toast({ title: 'Failed to create channel. Please try again', variant: 'destructive' })
      })
  }

  return (
    <Dialog
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          form.reset()
          if (onDismissed) {
            onDismissed()
          } else {
            navigate(
              ROUTES.ORGANIZATION.PROJECT.ALERTS.CHANNELS.buildPath({
                organizationName,
                projectName,
              }),
            )
          }
        }
      }}
    >
      <DialogContent>
        <section>
          <p className="text-2xl font-bold">New Channel</p>
        </section>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-4 text-on-surface-variant">
            <ChannelForm
              form={form}
              onSubmit={onSubmit}
              isSubmitting={mutation.isPending}
              mutationErrorMessage={mutationErrorMessage}
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

import { InfoIcon } from 'lucide-react'

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/shadcn/ui/dialog'
import { cn } from '@/packages/style'

import { Button, buttonVariants } from '../shadcn/ui/button'

export const BetaModal = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          size="sm"
          className="flex items-center gap-x-1 rounded-sm bg-black px-3 py-1 text-white transition-colors dark:bg-white dark:text-black"
        >
          <InfoIcon className="h-4 w-4" />
          Beta
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl">We&apos;re in Beta</DialogTitle>
        </DialogHeader>
        <p>
          Pydantic Logfire is in beta, it could break or behaving in unexpected ways. Please create an issue if you
          find bugs, get frustrated, or have questions.
        </p>
        <a
          className={cn(buttonVariants({ variant: 'default' }))}
          href="https://github.com/pydantic/logfire/issues/new/choose"
          target="_blank"
          rel="noreferrer noopener"
        >
          Create an issue
        </a>
        <p className="text-sm">Thank you for trying Logfire, your feedback and usage are so helpful!</p>
      </DialogContent>
    </Dialog>
  )
}

import { DateRangePickerValue } from '@/store/monitoring'
import { getSimpleDurationRangeStart, getSimpleDurationMs, SimpleDuration } from '@/packages/time/simpleDuration'

export const getDefaultDateRangePickerValue = (value: SimpleDuration): DateRangePickerValue => {
  const to = new Date()
  const from = getSimpleDurationRangeStart(to, value)

  return {
    preset: value,
    range: {
      from: from.toISOString(),
      to: to.toISOString(),
    },
  }
}

export const adjustResolutions = (duration: SimpleDuration, resolution: SimpleDuration): SimpleDuration => {
  // if the number of resolution periods is more than 2000 items in the duration, we need to reduce the increase the duration
  // to avoid performance issues
  const durationInMilliseconds = getSimpleDurationMs(duration)
  const resolutionInMilliseconds = getSimpleDurationMs(resolution)
  const resolutionPeriods = durationInMilliseconds / resolutionInMilliseconds

  if (resolution === '1m' && resolutionPeriods > 2000) {
    return '15m'
  } else if (resolution === '15m' && resolutionPeriods > 2000) {
    return '30m'
  }

  return resolution
}

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { Link, useNavigate } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'

import { useCreateAlert, getListAlertsQueryKey, queryClient } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { usePageTitle } from '@/hooks/usePageTitle'
import { AlertForm } from '@/app/alerts/components/AlertForm'
import { getSimpleDurationIsoDuration, simpleDurationSchema } from '@/packages/time/simpleDuration'

import { AlertFormSchema, AlertUpdateFormSchema, updateAlertFormSchema } from '../zod-schemas'
import { FIVE_MINUTE_DURATION } from '../utils'

export const CreateAlert = () => {
  usePageTitle('Create Alert')
  const navigate = useNavigate()
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS)

  const form = useForm<AlertUpdateFormSchema>({
    resolver: zodResolver(updateAlertFormSchema),
    defaultValues: {
      name: '',
      query: '',
      timeWindow: FIVE_MINUTE_DURATION,
      notifyWhen: 'has_matches',
      channels: [],
      webhookURLs: '',
      active: true,
    },
  })

  const mutation = useCreateAlert({
    mutation: {
      onSuccess: (data) => {
        const keys = getListAlertsQueryKey(organizationName, projectName)
        queryClient.invalidateQueries({ queryKey: keys }).then(() => {
          form.reset()
          if (data.data.id === undefined) return
          navigate(
            ROUTES.ORGANIZATION.PROJECT.ALERTS.HISTORY.buildPath({
              organizationName,
              projectName,
              alertId: data.data.id,
            }),
          )
        })
      },
    },
  })

  const mutationErrorDetail = mutation.error?.response?.data.detail
  const mutationErrorMessage = Array.isArray(mutationErrorDetail) ? mutationErrorDetail[0].msg : mutationErrorDetail

  const onSubmit = (formData: AlertFormSchema) => {
    const timeWindow = simpleDurationSchema.parse(formData.timeWindow) // purposely error if this is not valid

    const notificationChannels = (formData.webhookURLs ?? '')
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => x !== '') // ignore empty strings
      .map((url) => ({ type: 'webhook' as const, format: 'auto' as const, url }))

    mutation.mutate({
      data: {
        name: formData.name,
        query: formData.query,
        time_window: getSimpleDurationIsoDuration(timeWindow),
        channel_ids: formData.channels,
        notification_channels: notificationChannels,
        notify_when: formData.notifyWhen,
      },
      organization: organizationName,
      project: projectName,
    })
  }
  return (
    <>
      <div>
        <Link
          to={ROUTES.ORGANIZATION.PROJECT.ALERTS.buildPath({ organizationName, projectName })}
          className="text-sm text-on-surface-variant"
        >
          <ArrowLeft className="-mt-1 mr-1 inline-block w-4" />
          Back to Alerts
        </Link>
      </div>
      <h2 className="mb-12 text-xl text-on-surface">Create Alert</h2>
      <AlertForm
        form={form}
        onSubmit={onSubmit}
        isSubmitting={mutation.isPending}
        mutationErrorMessage={mutationErrorMessage}
      />
    </>
  )
}

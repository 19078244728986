import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { z } from 'zod'
import { Link } from 'react-router-dom'
import { ArrowLeft, ChevronUp, CircleXIcon, CopyIcon, ExternalLinkIcon, SirenIcon } from 'lucide-react'
import React, { useState, ReactNode, useMemo, useEffect } from 'react'
import CodeMirror from '@uiw/react-codemirror'
import { ParentSize } from '@visx/responsive'
import { SelectValue } from '@radix-ui/react-select'
import { format } from 'date-fns'

import { ROUTES } from '@/packages/router/routes'
import { useGetAlert } from '@/packages/api'
import { Button, buttonVariants } from '@/components/shadcn/ui/button'
import { AlertRunRead } from '@/packages/api/__generated__/model'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/shadcn/ui/tabs'
import { cn } from '@/packages/style'
import { TimestampWithAge } from '@/components/TimestampWithAge'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { GlideDataGrid } from '@/components/GlideDataGrid'
import { AlertPreview } from '@/app/alerts/components/AlertPreview'
import { ListItem, List } from '@/components/List'
import { AlertMatchesState } from '@/app/alerts/components/AlertMatchesState'
import { pgSql, useCodemirrorTheme } from '@/packages/codeMirror'
import { CodeDiff } from '@/components/CodeDiff/CodeDiff'
import { AlertTimeline } from '@/app/alerts/components/timeline/AlertTimeline'
import { Select, SelectContent, SelectItem, SelectTrigger } from '@/components/shadcn/ui/select'
import { useThrottle } from '@/hooks/useThrottle'
import { timeFilterOptions, useAlertRunsHistoryData } from '@/app/alerts/components/useAlertRunsHistoryData'
import { alertResultSchema } from '@/app/alerts/components/parse'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { AssignNotificationChannelToAlert } from '@/app/alerts/components/AssignNotificationChannelToAlert'
import { Switch } from '@/components/shadcn/ui/switch'
import { Label } from '@/components/shadcn/ui/label'

interface ParsedAlertRun {
  id: string
  createdAt: string
  data: z.infer<typeof alertResultSchema>
}

export const AlertDetails = () => {
  const pageLimit = 30
  const codeMirrorTheme = useCodemirrorTheme()
  const { organizationName, projectName, alertId } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS.HISTORY)
  const [timeFilter, setTimeFilter] = useState<keyof typeof timeFilterOptions>('Last 24 hours')
  const [selectedTab, setSelectedTab] = useState<'history' | 'query'>('history')
  const [offset, setOffset] = useState(0)
  const [timeRange, setTimeRange] = useState<{ startTimestamp: string; endTimestamp: string } | null>(null)
  const timeRangeThrottled = useThrottle(timeRange, 1000)
  const [timeFilterDates, setTimeFilterDates] = useState<{ start: Date; end: Date }>(timeFilterOptions[timeFilter]())
  const [notificationChannels, setNotificationChannels] = useState<string[]>()
  const [filterMatches, setFilterMatches] = useState(true)

  const { data: alertData } = useGetAlert(organizationName, projectName, alertId, {
    query: {
      refetchInterval: 60_000,
      refetchIntervalInBackground: true,
      staleTime: 0,
    },
  })

  const { alertHistory, alertTimeLineData, isLoading, isError, hasMoreData } = useAlertRunsHistoryData({
    pageLimit,
    offset,
    timeRangeThrottled,
    timeFilter,
    timeFilterDates,
    setTimeFilterDates,
    filterMatches,
  })

  useEffect(() => {
    if (alertData?.data?.channels && !notificationChannels) {
      setNotificationChannels(alertData.data.channels.map((c) => c.id))
    }
  }, [alertData, notificationChannels])

  const alert = alertData?.data

  if (!alert) {
    return <LoadingPlaceholder />
  }

  const lastMatches = alert.result_length
  const query = alert.query

  return (
    <>
      <div>
        <Link
          to={ROUTES.ORGANIZATION.PROJECT.ALERTS.buildPath({ organizationName, projectName })}
          className="text-sm text-on-surface-variant"
        >
          <ArrowLeft className="-mt-1 mr-1 inline-block w-4" />
          Back to alerts list
        </Link>
      </div>
      <div className="my-6 flex justify-between">
        <AlertPreview
          alert={{
            ...alert,
            last_run: alert.last_run,
            result_length: lastMatches,
          }}
        />
        <div>
          <AssignNotificationChannelToAlert channelIds={notificationChannels ?? []} alertId={alertId} />
          <Link
            to={ROUTES.ORGANIZATION.PROJECT.ALERTS.EDIT.buildPath({ organizationName, projectName, alertId })}
            className={cn('ml-2', buttonVariants({ size: 'sm', variant: 'alternative' }))}
          >
            Edit Alert
          </Link>
        </div>
      </div>
      <section className="w-full rounded-lg border border-outline-variant px-4 pt-4">
        <div>
          <Tabs
            className="m-0 p-0"
            value={selectedTab}
            onValueChange={(value) => {
              setSelectedTab(value as 'history' | 'query')
            }}
          >
            <div className="flex items-center justify-between">
              <TabsList className="my-2 mb-4 h-6 p-0 pt-0">
                <TabsTrigger value="history" className="h-6 text-xs">
                  History
                </TabsTrigger>
                <TabsTrigger value="query" className="h-6 text-xs">
                  Query
                </TabsTrigger>
              </TabsList>
              <Select
                value={timeFilter}
                onValueChange={(value) => {
                  setTimeFilter(value as keyof typeof timeFilterOptions)
                  setOffset(0)
                  setTimeFilterDates(timeFilterOptions[value as keyof typeof timeFilterOptions]())
                }}
              >
                <SelectTrigger className="h-6 w-36 bg-surface-bright text-xs placeholder:text-on-surface">
                  <SelectValue placeholder="Group by: None" className="text-xs" />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(timeFilterOptions).map((option) => (
                    <SelectItem key={option} value={option} className="text-xs">
                      {option}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <TabsContent className="m-0 flex w-full flex-col gap-y-5" value="history">
              <div className="h-24">
                <ParentSize>
                  {({ width, height }) => (
                    <AlertTimeline
                      alertsRun={alertTimeLineData}
                      startTimestamp={timeFilterDates.start}
                      endTimestamp={timeFilterDates.end}
                      width={width}
                      height={height}
                      onRangeChange={(range) => {
                        setTimeRange(range)
                        setOffset(0)
                      }}
                    />
                  )}
                </ParentSize>
              </div>
            </TabsContent>
            <TabsContent className="m-0 flex flex-col gap-y-5" value="query">
              <CodeMirror
                className="mb-4 mt-0 h-20 overflow-y-auto rounded border border-outline bg-surface-container-low font-ibm-plex-mono text-xs [&_.cm-gutters]:rounded-l"
                theme={codeMirrorTheme}
                value={query}
                editable={false}
                maxHeight="150px"
              />
            </TabsContent>
          </Tabs>
        </div>
      </section>
      <div className="mb-2 mt-8 flex justify-between text-sm text-on-surface-variant">
        Runs History
        <div className="flex items-center gap-2">
          <span>
            {timeRange
              ? `from ${format(new Date(timeRange.startTimestamp), 'yyyy-MM-dd HH:mm:ss')} to ${format(
                  new Date(timeRange.endTimestamp),
                  'yyyy-MM-dd HH:mm:ss',
                )}`
              : ''}
          </span>
          <div className="flex items-center space-x-2">
            <Switch
              className="h-4"
              id="filter-matches"
              checked={!filterMatches}
              onCheckedChange={() => {
                setOffset(0)
                setFilterMatches((prev) => !prev)
              }}
            />
            <Label htmlFor="filter-matches" className="text-xs">
              Include runs without matches
            </Label>
          </div>
        </div>
      </div>
      <section className="overflow-y-auto">
        {!isLoading && !isError && alertHistory && alertHistory.length > 0 && (
          <List
            remotePagination
            onPreviousPage={() => setOffset(offset - pageLimit)}
            onNextPage={() => setOffset(offset + pageLimit)}
            hasPreviousPage={offset > 0}
            previousPageTitle="Newer runs"
            nextPageTitle="Older runs"
            hasNextPage={hasMoreData}
          >
            {(alertHistory?.length ?? 0) > 0 &&
              alertHistory?.map((run, i) => (
                <AlertTableRow key={run.id} run={run} previousRun={alertHistory[i + 1]} />
              ))}
          </List>
        )}
        {isLoading && <LoadingPlaceholder />}
        {isError && <ErrorPlaceholder />}
        {!isLoading && alertHistory?.length === 0 && !timeRange && offset === 0 && <WaitingPlaceholder />}
        {alertHistory?.length === 0 && (timeRange ?? offset !== 0) && <NoRunPlaceholder />}
      </section>
    </>
  )
}

const parseAlertRun = (run: AlertRunRead): ParsedAlertRun | undefined => {
  const parsedResult = alertResultSchema.safeParse(run.result)
  if (!parsedResult.success) {
    console.error(parsedResult.error.errors)
    return undefined
  } else {
    return {
      id: run.id,
      createdAt: run.created_at,
      data: parsedResult.data,
    }
  }
}

const AlertTablePlaceholder = ({ children }: { children: ReactNode }) => {
  return (
    <section className="flex grow flex-col items-center justify-center gap-y-1 rounded-lg bg-surface px-3 py-10">
      {children}
    </section>
  )
}

const WaitingPlaceholder = () => {
  return (
    <AlertTablePlaceholder>
      <LoadingSpinner className="opacity-50" />
      <p className="text-foreground text-opacity-50">Waiting for the first run of this alert...</p>
    </AlertTablePlaceholder>
  )
}
const LoadingPlaceholder = () => {
  return (
    <AlertTablePlaceholder>
      <div className="text-foreground text-opacity-50">
        <LoadingSpinner />
      </div>
      <p className="text-foreground text-opacity-50">Loading alert run history...</p>
    </AlertTablePlaceholder>
  )
}

const ErrorPlaceholder = () => {
  return (
    <AlertTablePlaceholder>
      <div className="text-states-error opacity-80">
        <CircleXIcon />
      </div>
      <p className="text-states-error opacity-80">Failed to load alert run history</p>
    </AlertTablePlaceholder>
  )
}

const NoRunPlaceholder = () => {
  return (
    <AlertTablePlaceholder>
      <div className="text-foreground opacity-80">
        <SirenIcon className="h-12 w-12" />
      </div>
      <p className="text-center text-foreground opacity-80">
        No runs found for the selected time range. <br /> Try changing the time range or waiting for the next run.
      </p>
    </AlertTablePlaceholder>
  )
}

const AlertTableRow = ({ run, previousRun }: { run: AlertRunRead; previousRun: AlertRunRead | undefined }) => {
  const parsedRun = useMemo(() => parseAlertRun(run), [run])
  const parsedPreviousRun = useMemo(() => previousRun && parseAlertRun(previousRun), [previousRun])
  // TODO(DavidM): Add some visual indication if the run can't be parsed, rather than pretending there are no errors

  const nMatches = parsedRun?.data.data?.length ?? 0
  const nErrors = parsedRun?.data.errors?.length ?? 0

  const parsedMatches =
    parsedRun?.data.data && parsedRun?.data.columns
      ? { data: parsedRun.data.data, columns: parsedRun.data.columns }
      : undefined
  const parsedErrors = parsedRun?.data.errors
  const parsedTraceId = parsedRun?.data.trace_id

  const [isCollapsed, setIsCollapsed] = useState(true)
  const [selectedTab, setSelectedTab] = useState<'results' | 'errors' | 'query'>(
    nMatches > 0 ? 'results' : nErrors > 0 ? 'errors' : 'query',
  )
  const codeMirrorTheme = useCodemirrorTheme()

  const extensions = [pgSql([])]

  const query = parsedRun?.data.query ?? 'Failed to parse query'
  const previousRunQuery = parsedPreviousRun?.data.query
  const queryChanged = previousRunQuery !== undefined && previousRunQuery !== query

  return (
    <React.Fragment key={run.id}>
      <ListItem
        className="cursor-pointer bg-surface-container-lowest pr-1"
        size="sm"
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
      >
        <section className="flex items-center justify-between">
          <span className="flex gap-2">
            <TimestampWithAge
              className="text-sm tabular-nums text-on-surface"
              timestamp={run.created_at}
              formatDistanceOptions={{ addSuffix: true, includeSeconds: true }}
              labelFormatOverride={`yyyy-MM-dd 'at' HH:mm:ss`}
            />
            <AlertMatchesState
              numberOfMatches={nMatches}
              numberOfErrors={nErrors}
              configChanged={queryChanged}
              latestRun={false}
            />
          </span>
          <Button variant="link" size="sm">
            <span className="text-sm text-on-surface">{isCollapsed ? 'Expand Results' : 'Collapse Results'}</span>
            <ChevronUp className={cn('ml-2 h-4 w-4', isCollapsed ? 'rotate-180 transform' : 'rotate-0 transform')} />
          </Button>
        </section>
        {!isCollapsed && (
          <section className="cursor-default bg-surface-container-lowest p-0 hover:bg-surface-container-lowest">
            <Tabs
              className="m-0 p-0"
              value={selectedTab}
              onValueChange={(value) => {
                setSelectedTab(value as 'results' | 'errors' | 'query')
              }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <TabsList className="m-4 h-6 p-0 pt-0">
                {parsedMatches && (
                  <TabsTrigger value="results" className="h-6 text-xs">
                    Results
                  </TabsTrigger>
                )}
                {parsedErrors && (
                  <TabsTrigger value="errors" className="h-6 text-xs">
                    Errors
                  </TabsTrigger>
                )}
                <TabsTrigger value="query" className="h-6 text-xs">
                  Query
                </TabsTrigger>
              </TabsList>
              {parsedMatches && (
                <TabsContent className="m-0 flex flex-col gap-y-5" value="results">
                  <GlideDataGrid data={parsedMatches} />
                </TabsContent>
              )}
              {parsedErrors && (
                <TabsContent className="m-0 flex flex-col gap-y-5" value="errors">
                  <section className="pb-4 pl-4">
                    {parsedErrors.map((error, i) => (
                      <p key={i} className="text-states-error">
                        <code>- {error.message}</code>
                      </p>
                    ))}
                    {parsedTraceId && <SupportMessage traceId={parsedTraceId} />}
                  </section>
                </TabsContent>
              )}
              <TabsContent className="m-0 flex flex-col gap-y-5" value="query">
                {queryChanged ? (
                  <CodeDiff
                    oldCode={previousRunQuery}
                    newCode={query}
                    language="sql"
                    className="m-4 mt-0 rounded border border-outline bg-surface-container-low font-ibm-plex-mono text-xs [&_.cm-gutters]:rounded-l"
                  />
                ) : (
                  <CodeMirror
                    className="m-4 mt-0 rounded border border-outline bg-surface-container-low font-ibm-plex-mono text-xs [&_.cm-gutters]:rounded-l"
                    theme={codeMirrorTheme}
                    extensions={extensions}
                    placeholder="SELECT * FROM records WHERE is_exception"
                    value={query}
                    editable={false}
                    maxHeight="150px"
                  />
                )}
              </TabsContent>
            </Tabs>
          </section>
        )}
      </ListItem>
    </React.Fragment>
  )
}

const SupportMessage = ({ traceId }: { traceId: string }) => {
  const { toast } = useToast()
  return (
    <div className="flex items-center pt-2 text-xs text-on-surface-variant">
      <div>
        Need help?{' '}
        <Link
          to="https://docs.pydantic.dev/logfire/help/"
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact us
        </Link>{' '}
        and include this trace ID:
      </div>
      <Button
        variant="ghost"
        className="ml-1 h-6 px-1 py-1"
        onClick={() => {
          navigator.clipboard.writeText(traceId)
          toast({
            title: 'Trace ID copied to clipboard',
            duration: 3000,
            action: (
              <Button
                onClick={() => {
                  window.open('https://docs.pydantic.dev/logfire/help/', '_blank')
                }}
              >
                <div className="flex items-center gap-x-2">
                  <span>Support</span>
                  <ExternalLinkIcon className="h-4 w-4 text-on-surface-variant" />
                </div>
              </Button>
            ),
          })
        }}
      >
        <CopyIcon className="h-3 w-3" />
      </Button>
      {traceId}
    </div>
  )
}

import { useContext, createContext, useEffect, useState } from 'react'

import { useMatchMediaQuery } from '@/packages/utils/useMatchMediaQuery'

/**
 * `ThemeChoiceLightDarkSystem` should not be used anywhere _except_ in components related to _setting_ the theme
 * Use `ThemeMode` anywhere you actually want to style components based on the active theme.
 * I have used this absurdly verbose name in an attempt to make it easier to identify misuse.
 */
export enum ThemeChoiceLightDarkSystem {
  light = 'light',
  dark = 'dark',
  system = 'system',
}

/**
 * The Theme enum purposely does NOT use string values, to try to prevent confusion with
 * the ThemeChoiceLightDarkSystem. In particular, the strings 'dark' or 'light' should never match
 * a value of ThemeMode.
 */
export enum Theme {
  light,
  dark,
}

const VALID_USER_THEME_CHOICES = new Set<string | null>([
  ThemeChoiceLightDarkSystem.light,
  ThemeChoiceLightDarkSystem.dark,
  ThemeChoiceLightDarkSystem.system,
])

interface ThemeContextState {
  theme: Theme
  // The following should only be used by components used to control the theme choice:
  themeChoiceLightDarkSystem: ThemeChoiceLightDarkSystem
  setThemeChoiceLightDarkSystem: (choice: ThemeChoiceLightDarkSystem) => void
}

const initialState: ThemeContextState = {
  theme: Theme.light,
  themeChoiceLightDarkSystem: ThemeChoiceLightDarkSystem.system,
  setThemeChoiceLightDarkSystem: () => undefined,
}
const ThemeContext = createContext<ThemeContextState>(initialState)

interface ThemeProviderProps {
  children: React.ReactNode
  defaultThemeChoice?: ThemeChoiceLightDarkSystem
  storageKey?: string
}

export function ThemeProvider({
  children,
  defaultThemeChoice = ThemeChoiceLightDarkSystem.system,
  storageKey = 'vite-ui-theme',
  ...props
}: ThemeProviderProps) {
  const [themeChoice, setThemeChoice] = useState(() => {
    const storedChoice = localStorage.getItem(storageKey)
    if (VALID_USER_THEME_CHOICES.has(storedChoice)) return storedChoice as ThemeChoiceLightDarkSystem
    return defaultThemeChoice
  })

  const systemPrefersDark = useMatchMediaQuery('(prefers-color-scheme: dark)')
  let theme: Theme
  if (themeChoice === ThemeChoiceLightDarkSystem.dark) {
    theme = Theme.dark
  } else if (themeChoice === ThemeChoiceLightDarkSystem.light) {
    theme = Theme.light
  } else {
    theme = systemPrefersDark ? Theme.dark : Theme.light
  }

  useEffect(() => {
    const root = window.document.documentElement
    root.classList.remove('light', 'dark')
    root.classList.add(theme === Theme.light ? 'light' : 'dark')
  }, [theme])

  const value: ThemeContextState = {
    theme,
    themeChoiceLightDarkSystem: themeChoice,
    setThemeChoiceLightDarkSystem: (choice: ThemeChoiceLightDarkSystem) => {
      localStorage.setItem(storageKey, choice)
      setThemeChoice(choice)
    },
  }

  return (
    <ThemeContext.Provider {...props} value={value}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = (): ThemeContextState => {
  return useContext(ThemeContext)
}

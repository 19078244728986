import { CircleHelpIcon, ExternalLinkIcon } from 'lucide-react'
import { Link, useParams } from 'react-router-dom'

import { ROUTES } from '@/packages/router/routes'

import { Popover, PopoverContent, PopoverTrigger } from './shadcn/ui/popover'
import { Button } from './shadcn/ui/button'
import { Separator } from './shadcn/ui/separator'

export const Feedback = () => {
  const { organizationName, projectName } = useParams()
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size="sm" className="flex items-center gap-x-1">
          <CircleHelpIcon className="h-4 w-4" />
          <span>Feedback / Help</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="p-0">
        {organizationName && projectName && (
          <>
            <ul className="flex flex-col rounded-sm bg-surface-container-lowest text-sm">
              <li className="rounded-tl-sm rounded-tr-sm transition-colors hover:bg-surface-container-low">
                <Link
                  to={ROUTES.ORGANIZATION.PROJECT.GETTING_STARTED.buildPath({ organizationName, projectName })}
                  className="flex items-center justify-between px-3 py-3"
                >
                  Getting started
                </Link>
              </li>
            </ul>
            <Separator />
          </>
        )}
        <ul className="flex flex-col rounded-sm bg-surface-container-lowest text-sm">
          <li className="rounded-tl-sm rounded-tr-sm transition-colors hover:bg-surface-container-low">
            <a
              href="https://docs.pydantic.dev/logfire/"
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center justify-between px-3 py-3"
            >
              <span>Documentation</span>
              <ExternalLinkIcon className="h-4 w-4" />
            </a>
          </li>
          <li className="transition-colors hover:bg-surface-container-low">
            <a
              href="https://docs.pydantic.dev/logfire/help/"
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center justify-between px-3 py-3"
            >
              <span>Support</span>
              <ExternalLinkIcon className="h-4 w-4" />
            </a>
          </li>
          <li className="rounded-bl-sm rounded-br-sm transition-colors hover:bg-surface-container-low">
            <a
              href="https://github.com/pydantic/logfire/issues/new/choose"
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center justify-between px-3 py-3"
            >
              <span>Report an issue</span>
              <ExternalLinkIcon className="h-4 w-4" />
            </a>
          </li>
        </ul>
      </PopoverContent>
    </Popover>
  )
}

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/ui/tooltip'

interface AlertMatchesStateProps {
  numberOfMatches: number
  numberOfErrors: number
  configChanged?: boolean
  latestRun?: boolean
}

export const AlertMatchesState = ({
  numberOfMatches,
  numberOfErrors,
  configChanged,
  latestRun,
}: AlertMatchesStateProps) => {
  return (
    <>
      {numberOfMatches === 0 && numberOfErrors === 0 && (
        <Tooltip>
          <TooltipTrigger className="cursor-default">
            <p className="rounded-full bg-states-notice-container px-2 py-0.5 text-xs text-states-on-notice-container">
              No matches
            </p>
          </TooltipTrigger>
          <TooltipContent>
            {!latestRun ? `Number of matches during alert run` : `Number of matches during the most recent alert run`}
          </TooltipContent>
        </Tooltip>
      )}
      {numberOfMatches > 0 && (
        <Tooltip>
          <TooltipTrigger className="cursor-default">
            <p className="rounded-full bg-states-warning-container px-2 py-0.5 text-xs text-states-on-warning-container">
              {numberOfMatches} {numberOfMatches === 1 ? 'match' : 'matches'}
            </p>
          </TooltipTrigger>
          <TooltipContent>
            {!latestRun ? `Number of matches during alert run` : `Number of matches during the most recent alert run`}
          </TooltipContent>
        </Tooltip>
      )}
      {numberOfErrors > 0 && (
        <Tooltip>
          <TooltipTrigger className="cursor-default">
            <p className="rounded-full bg-states-error-container px-2 py-0.5 text-xs text-states-on-error-container">
              {numberOfErrors} {numberOfErrors === 1 ? 'error' : 'errors'}
            </p>
          </TooltipTrigger>
          <TooltipContent>
            {!latestRun ? `Number of errors during alert run` : `Number of errors during the most recent alert run`}
          </TooltipContent>
        </Tooltip>
      )}
      {configChanged && (
        <p className="rounded-full bg-[#FBC4FF] px-2 py-0.5 text-xs text-design-primary">Query changed</p>
      )}
    </>
  )
}

import { PropsWithChildren } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '@/context/auth'
import { ROUTES } from '@/packages/router/routes'

interface ProtectedRouteProps {
  redirectPath?: string
}

const ProtectedRoute = ({ children, redirectPath = ROUTES.LOGIN.path }: PropsWithChildren<ProtectedRouteProps>) => {
  const location = useLocation()

  const from = location.pathname + location.search

  const { isAuthenticated, isReady } = useAuth()

  if (!isReady) {
    return null
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} state={{ from }} replace />
  }

  return children ?? <Outlet />
}

export default ProtectedRoute

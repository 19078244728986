import { Duration, sub } from 'date-fns'
import { z } from 'zod'

type TimeUnit = 's' | 'm' | 'h' | 'd' // seconds, minutes, hours, days
export type SimpleDuration = `${number}${TimeUnit}`

export const simpleDurationSchema = z.preprocess(
  (input) => {
    if (typeof input === 'string') {
      // Remove underscores and the 'last' prefix if it exists
      return input.replace(/_/g, '').replace(/^last/, '')
    }
    return input
  },
  z
    .string()
    .regex(/^[1-9]\d*[smhd]$/, "must be a positive integer followed by 's', 'm', 'h', or 'd'.")
    .transform((val): SimpleDuration => val as SimpleDuration), // just for type-hinting; this is already validated
)

export const getSimpleDurationLabel = (duration: SimpleDuration, includeLast = false): string => {
  if (includeLast) return `Last ${getSimpleDurationSqlDuration(duration)}`
  return getSimpleDurationSqlDuration(duration)
}

export const getSimpleDurationMs = (duration: SimpleDuration): number => {
  const number = parseInt(duration.slice(0, -1))
  if (isNaN(number)) throw new Error(`Invalid simple duration: ${duration}`)

  const unit = duration.slice(-1)
  const unitMs = {
    s: 1000,
    m: 1000 * 60,
    h: 1000 * 60 * 60,
    d: 1000 * 60 * 60 * 24,
  }[unit]
  if (unitMs === undefined) throw new Error(`Invalid simple duration: ${duration}`)
  return number * unitMs
}

export const getSimpleDurationMinutes = (duration: SimpleDuration): number => {
  const ms = getSimpleDurationMs(duration)
  if (ms === undefined) throw new Error(`Invalid simple duration: ${duration}`)
  return Math.round(ms / 60_000)
}

export const getSimpleDurationDuration = (duration: SimpleDuration): Duration => {
  const number = parseInt(duration.slice(0, -1))
  if (isNaN(number)) throw new Error(`Invalid simple duration: ${duration}`)

  const unit = duration.slice(-1)
  const durationUnit: keyof Duration | undefined = (
    {
      s: 'seconds',
      m: 'minutes',
      h: 'hours',
      d: 'days',
    } as const
  )[unit]
  if (!durationUnit) throw new Error(`Invalid simple duration: ${duration}`)

  return { [durationUnit]: number }
}

export const getSimpleDurationIsoDuration = (duration: SimpleDuration): string => {
  const number = parseInt(duration.slice(0, -1))
  if (isNaN(number)) throw new Error(`Invalid simple duration: ${duration}`)

  const unit = duration.slice(-1)
  if (unit === 's') {
    return `PT${number}S`
  } else if (unit === 'm') {
    return `PT${number}M`
  } else if (unit === 'h') {
    return `PT${number}H`
  } else if (unit === 'd') {
    return `P${number}D`
  } else {
    throw new Error(`Invalid simple duration: ${duration}`)
  }
}

// Returns the duration in the format "1 second", "2 minutes", "3 hours", "4 days"
// This is generally valid for use in SQL queries as `INTERVAL '1 second'` etc.
export const getSimpleDurationSqlDuration = (duration: SimpleDuration): string => {
  const number = parseInt(duration.slice(0, -1))
  if (isNaN(number)) throw new Error(`Invalid simple duration: ${duration}`)

  const unit = duration.slice(-1)
  let unitLabel = {
    s: 'second',
    m: 'minute',
    h: 'hour',
    d: 'day',
  }[unit]
  if (!unitLabel) throw new Error(`Invalid simple duration: ${duration}`)

  if (number !== 1) {
    unitLabel += 's'
  }
  return `${number} ${unitLabel}`
}

export const getSimpleDurationRangeStart = (to: Date, duration: SimpleDuration) => {
  // TODO: Should this operate on numbers instead of Dates?
  return sub(to, getSimpleDurationDuration(duration))
}

export const parseIsoDuration = (isoDuration: string): SimpleDuration | undefined => {
  if (isoDuration.startsWith('PT')) {
    // PT1M -> 1m
    return simpleDurationSchema.safeParse(isoDuration.slice(2).toLowerCase()).data
  } else {
    // P1D -> 1d
    return simpleDurationSchema.safeParse(isoDuration.slice(1).toLowerCase()).data
  }
}

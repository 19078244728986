import { useEffect, useRef, useState } from 'react'

/**
 * Custom hook for throttling the update frequency of a value.
 * The value update will only happen after a specified interval has elapsed since the last update.
 *
 * @param value The value to be throttled.
 * @param interval The minimum time interval (in milliseconds) between updates. Defaults to 500ms.
 * @returns The throttled value.
 */
export function useThrottle<T>(value: T, interval = 500): T {
  const [throttledValue, setThrottledValue] = useState<T>(value)
  const lastExecuted = useRef<number>(Date.now())

  useEffect(() => {
    // Check if the current time has passed the interval since the last execution.
    const timeSinceLastExecution = Date.now() - lastExecuted.current
    if (timeSinceLastExecution >= interval) {
      // Update last executed time and the throttled value.
      lastExecuted.current = Date.now()
      setThrottledValue(value)
    } else {
      // Otherwise, set a timer to update the value after the remaining time in the interval.
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now()
        setThrottledValue(value)
      }, interval - timeSinceLastExecution)

      // Clear the timeout if the component unmounts or values change.
      return () => clearTimeout(timerId)
    }
  }, [value, interval]) // Depend on value and interval to re-run the effect.

  return throttledValue
}

import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { useNavigate } from 'react-router-dom'

import { DeleteProject } from '@/components/DeleteProject'
import { ROUTES } from '@/packages/router/routes'
import { Button } from '@/components/shadcn/ui/button'
import { ProjectInfoForm } from '@/app/project/settings/components/ProjectInfoForm'

export const ProjectSettingsGeneral = () => {
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS)

  const navigate = useNavigate()

  const goToOrgPage = () => navigate(ROUTES.ORGANIZATION.buildPath({ organizationName }))

  return (
    <section className="space-y-4">
      <section className="rounded-lg border p-4">
        <article>
          <ProjectInfoForm />
        </article>
      </section>
      <section className="rounded-lg border p-4">
        <p className="text-lg font-semibold">Danger Zone</p>
        <section className="mt-4 rounded-lg border border-red-500">
          <article className="flex items-center justify-between p-4">
            <section>
              <p className="font-semibold">Delete project</p>
              <p className="text-sm text-foreground/90">This removes the project from the organization permanently</p>
            </section>
            <DeleteProject projectName={projectName} organizationName={organizationName} afterDelete={goToOrgPage}>
              <Button variant="destructive">Delete</Button>
            </DeleteProject>
          </article>
        </section>
      </section>
    </section>
  )
}

import { MonitoringBlockConfig } from '@/store/monitoring'
import { ExploreQueryResponse } from '@/packages/api/__generated__/model'
import { GlideDataGrid } from '@/components/GlideDataGrid'
import { JsonObjectView } from '@/components/ObjectView/JsonObjectView'

export type TableData = ExploreQueryResponse
interface TableRenderProps {
  config: MonitoringBlockConfig
  disableAutoCollapsedJson?: boolean
  data?: TableData
}

export const getIdForColumn = (index: number): string => {
  return `col-${index}`
}

export const TableRender = ({ data, config }: TableRenderProps) => {
  const hasRunQuery = data !== undefined

  if (hasRunQuery && data.data.length === 0) {
    return (
      <div className="w-full p-2">
        <p className="text-sm">0 rows returned.</p>
        <p className="text-sm">No data found for the query.</p>
      </div>
    )
  }

  if (hasRunQuery && config?.type === 'json') {
    const rows = data.data.map((row) => Object.fromEntries(row.map((value, index) => [getIdForColumn(index), value])))
    return (
      <section className="w-full overflow-y-auto">
        {rows.map((row, index) => {
          return (
            <div key={index} className="mb-1 rounded bg-muted p-1">
              <JsonObjectView value={row} collapsed />
            </div>
          )
        })}
      </section>
    )
  }

  if (hasRunQuery && data.data.length > 0) {
    // We need this key to reset the columns (they're initialized with useState inside of this component so they don't get reset after initial render)
    // and prevent stale preview of data
    return <GlideDataGrid key={data.duration} data={data} />
  }

  return null
}

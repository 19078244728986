import { usePageTitle } from '@/hooks/usePageTitle'
import { AlertDetails } from '@/app/alerts/components/AlertDetails'

export const AlertHistoryPage = () => {
  usePageTitle('Alert History')
  return (
    <section className="h-full flex-1 overflow-hidden">
      <section className="flex h-full flex-1 flex-col">
        <section className="overflow-y-auto">
          <AlertDetails />
        </section>
      </section>
    </section>
  )
}

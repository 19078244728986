import { formatDistance } from 'date-fns'
import { PlayCircle } from 'lucide-react'
import { useMemo } from 'react'

import { AlertWithLastRun } from '@/packages/api/__generated__/model'
import { AlertMatchesState } from '@/app/alerts/components/AlertMatchesState'
import { AlertActiveState } from '@/app/alerts/components/AlertActiveState'
import { AgeWithTimestamp } from '@/components/TimestampWithAge'
import { alertResultSchema } from '@/app/alerts/components/parse'

interface AlertPreviewProps {
  alert: Partial<AlertWithLastRun>
}

export const AlertPreview = ({ alert }: AlertPreviewProps) => {
  const { name, last_run: lastRun, has_matches: hasMatches, result } = alert
  const parsedResult = useMemo(() => {
    const parsed = alertResultSchema.safeParse(result)
    return parsed.data
  }, [result])
  const nMatches = parsedResult?.data?.length ?? 0
  const nErrors = parsedResult?.errors?.length ?? 0

  const distance = lastRun
    ? formatDistance(new Date(lastRun), new Date(), {
        addSuffix: true,
      })
    : null

  return (
    <section className="flex flex-col gap-2">
      <section className="flex">
        <section className="flex items-center gap-x-2">
          <p className="font-semibold capitalize">{name}</p>
          {hasMatches != null && (
            <AlertMatchesState latestRun={true} numberOfMatches={nMatches} numberOfErrors={nErrors} />
          )}
        </section>
      </section>
      <section className="flex space-x-4 pl-1 text-sm text-on-surface-variant">
        <AlertActiveState isActive={!!alert.active} />
        <div className="flex items-center justify-center gap-1">
          <PlayCircle className="h-4 w-4" />
          {distance ? <p>Last run {distance}</p> : <p>{`Hasn't run yet`}</p>}
        </div>
      </section>
      <section className="flex space-x-4 pl-1 text-sm text-on-surface-variant">
        {alert.created_at && !alert.updated_at && (
          <div>
            Created{' '}
            <AgeWithTimestamp
              hideDate
              timestamp={alert.created_at}
              formatDistanceOptions={{ addSuffix: true }}
              labelFormatOverride={`yyyy-MM-dd 'at' HH:mm`}
            />
            {alert.created_by_name && <span> by {alert.created_by_name}</span>}
          </div>
        )}
        {alert.updated_at && (
          <div>
            Updated{' '}
            <AgeWithTimestamp
              hideDate
              timestamp={alert.updated_at}
              formatDistanceOptions={{ addSuffix: true }}
              labelFormatOverride={`yyyy-MM-dd 'at' HH:mm`}
            />
            {alert.updated_by_name && <span> by {alert.updated_by_name}</span>}
          </div>
        )}
      </section>
    </section>
  )
}

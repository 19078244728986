import { useCallback, useEffect } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { Link } from 'lucide-react'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import { DarkModeToggle } from '@/components/DarkModeToggle'
import { useToastCopyText } from '@/hooks/clipboard'
import { useToken } from '@/packages/auth/useToken'
import { cn } from '@/packages/style'
import { BetaModal } from '@/components/BetaModal/BetaModal'
import { ROUTES } from '@/packages/router/routes'
import { useProjectInfo } from '@/packages/api'
import { ResponseError } from '@/utils/response-error'
import { UserSheet } from '@/components/UserSheet'
import { initializeRecordDetailsLoader } from '@/packages/logV3/recordDetailsService'
import { useQueryEngine } from '@/hooks/useQueryEngine'
import { LogfireIcon } from '@/components/LogfireIcon'
import { Footer } from '@/components/Footer'

import { Breadcrumbs } from '../Breadcrumbs'
import { ButtonToolTip } from '../ButtonToolTip'
import { Feedback } from '../Feedback'
import { useLoadingLogo } from '../LoadingLogo'

const linkClasses = (isActive: boolean) =>
  cn(
    'pl-3 pr-4 pt-3 pb-2 opacity-80  flex items-center gap-x-1.5  border-slate-300 dark:border-slate-400',
    isActive && 'opacity-100 border-b-2 border-slate-600 dark:border-slate-200',
  )

export const ProjectLayout = () => {
  const { projectName, organizationName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT)
  const [queryEngine] = useQueryEngine()

  useEffect(() => {
    initializeRecordDetailsLoader(organizationName, projectName, queryEngine)
  }, [organizationName, projectName, queryEngine])

  const projectInfoQuery = useProjectInfo(organizationName, projectName)

  useLoadingLogo(projectInfoQuery.isLoading)

  const responseStatus = projectInfoQuery.failureReason?.response?.status

  if (projectInfoQuery.isLoading && projectInfoQuery.failureCount < 2) {
    return null
  }

  if (responseStatus === 404) {
    throw new ResponseError({ status: 404, statusText: 'Not Found' })
  }

  const navLinksJSX = (
    <section className="relative bottom-[2px] flex gap-x-3 lg:items-center lg:justify-center">
      <NavLink
        to={ROUTES.ORGANIZATION.PROJECT.buildPath({
          organizationName,
          projectName,
        })}
        end={true}
      >
        {({ isActive }) => <div className={linkClasses(isActive)}>Live</div>}
      </NavLink>
      <NavLink
        to={ROUTES.ORGANIZATION.PROJECT.DASHBOARDS.buildPath({
          organizationName,
          projectName,
        })}
      >
        {({ isActive }) => <div className={linkClasses(isActive)}>Dashboards</div>}
      </NavLink>
      <NavLink to={ROUTES.ORGANIZATION.PROJECT.ALERTS.buildPath({ organizationName, projectName })}>
        {({ isActive }) => <div className={linkClasses(isActive)}>Alerts</div>}
      </NavLink>
      <NavLink
        to={ROUTES.ORGANIZATION.PROJECT.EXPLORE.buildPath({
          organizationName,
          projectName,
        })}
      >
        {({ isActive }) => <div className={linkClasses(isActive)}>Explore</div>}
      </NavLink>
      <NavLink
        to={ROUTES.ORGANIZATION.PROJECT.SETTINGS.buildPath({
          organizationName,
          projectName,
        })}
      >
        {({ isActive }) => <div className={linkClasses(isActive)}>Settings</div>}
      </NavLink>
    </section>
  )

  return (
    <>
      <nav className="sticky top-0 z-40 border-b border-outline bg-surface px-7 text-sm font-[450]">
        <div className="flex justify-between gap-3 py-1 lg:py-0">
          <section className="flex items-center gap-x-1">
            <div className="flex items-center gap-x-2">
              <div className="relative bottom-[8px] flex flex-col items-center justify-center">
                <div className="relative z-0 h-4 w-[1px] border-l-[0.5px] border-outline bg-on-surface/20" />
                <LogfireIcon className="z-10 h-5 w-5 select-none text-logfire dark:text-logfire" />
              </div>
              <Breadcrumbs />
            </div>
          </section>
          <section className="hidden h-9 lg:block">{navLinksJSX}</section>
          <section className="flex items-center justify-end gap-x-4">
            <Feedback />
            <BetaModal />
            <div className="flex h-10 gap-x-2">
              <DarkModeToggle />
              <ProjectLink />
            </div>
            <UserSheet />
          </section>
        </div>
        <section className="lg:hidden">{navLinksJSX}</section>
      </nav>
      <div role="main" className="flex h-full grow flex-col items-stretch overflow-hidden px-7">
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

const ProjectLink = () => {
  const { toastCopyText } = useToastCopyText()
  const token = useToken()
  const { organizationName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT)

  const isAnon = organizationName === 'anonymous'

  const onClick = useCallback(() => {
    const url = `${window.location.href}` + (isAnon ? `#token=${token?.token}` : '')

    toastCopyText(url, {
      variant: 'default',
      title: 'Copied page link to clipboard',
      duration: 3000,
    })
  }, [toastCopyText, token, isAnon])

  return (
    <ButtonToolTip
      tooltip="Copy link to current page"
      variant="ghost"
      size="icon"
      onClick={onClick}
      className="p-2"
      aria-label="copy page link"
    >
      <Link />
    </ButtonToolTip>
  )
}

import { useMemo } from 'react'

function couldBeJsonCheck(str: string) {
  return /^\s*[{[](.*)[}\]]\s*$/.test(str)
}

export function handleJson(value: unknown) {
  if (typeof value === 'object' && value !== null) {
    return { json: true, value }
  } else if (typeof value === 'string' && couldBeJsonCheck(value)) {
    try {
      const parsedValue = JSON.parse(value)
      return { json: true, value: parsedValue }
    } catch (e) {
      return { json: false, value }
    }
  }
  return { json: false, value }
}

export const useJsonHandling = (value: unknown) => {
  return useMemo(() => handleJson(value), [value])
}

import { AppState } from '@/store'

export const settingsSelector = {
  selectPinnedAttributes: (state: AppState) => {
    return state.settings.pinnedAttributes
  },
  selectUseTimeFilterCte: (state: AppState) => {
    return state.settings.useTimeFilterCte
  },
  selectUseLimitCte: (state: AppState) => {
    return state.settings.useLimitCte
  },
}

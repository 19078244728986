/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Logfire
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import * as axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type { ChannelCreate, ChannelRead, ChannelUpdate, HTTPValidationError } from '.././model'

/**
 * Create a notification channel.
 * @summary Create Channel
 */
export const createChannel = (
  organization: string,
  project: string,
  channelCreate: ChannelCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChannelRead>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/channels/`,
    channelCreate,
    options,
  )
}

export const getCreateChannelMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createChannel>>,
    TError,
    { organization: string; project: string; data: ChannelCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof createChannel>>,
  TError,
  { organization: string; project: string; data: ChannelCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createChannel>>,
    { organization: string; project: string; data: ChannelCreate }
  > = (props) => {
    const { organization, project, data } = props ?? {}

    return createChannel(organization, project, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateChannelMutationResult = NonNullable<Awaited<ReturnType<typeof createChannel>>>
export type CreateChannelMutationBody = ChannelCreate
export type CreateChannelMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Create Channel
 */
export const useCreateChannel = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createChannel>>,
    TError,
    { organization: string; project: string; data: ChannelCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof createChannel>>,
  TError,
  { organization: string; project: string; data: ChannelCreate },
  TContext
> => {
  const mutationOptions = getCreateChannelMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * List all notification channels for the organization the project is contained in.
 * @summary List Channels
 */
export const listChannels = (
  organization: string,
  project: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChannelRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/channels/`, options)
}

export const getListChannelsQueryKey = (organization: string, project: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/channels/`] as const
}

export const getListChannelsQueryOptions = <
  TData = Awaited<ReturnType<typeof listChannels>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listChannels>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getListChannelsQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listChannels>>> = ({ signal }) =>
    listChannels(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listChannels>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListChannelsQueryResult = NonNullable<Awaited<ReturnType<typeof listChannels>>>
export type ListChannelsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary List Channels
 */
export const useListChannels = <
  TData = Awaited<ReturnType<typeof listChannels>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listChannels>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListChannelsQueryOptions(organization, project, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getListChannelsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof listChannels>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listChannels>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getListChannelsQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listChannels>>> = ({ signal }) =>
    listChannels(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof listChannels>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListChannelsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listChannels>>>
export type ListChannelsSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary List Channels
 */
export const useListChannelsSuspense = <
  TData = Awaited<ReturnType<typeof listChannels>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listChannels>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListChannelsSuspenseQueryOptions(organization, project, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a notification channel.
 * @summary Update Channel
 */
export const updateChannel = (
  organization: string,
  project: string,
  channelId: string,
  channelUpdate: ChannelUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChannelRead>> => {
  return axios.default.put(
    `/dash/organizations/${organization}/projects/${project}/channels/${channelId}`,
    channelUpdate,
    options,
  )
}

export const getUpdateChannelMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateChannel>>,
    TError,
    { organization: string; project: string; channelId: string; data: ChannelUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateChannel>>,
  TError,
  { organization: string; project: string; channelId: string; data: ChannelUpdate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateChannel>>,
    { organization: string; project: string; channelId: string; data: ChannelUpdate }
  > = (props) => {
    const { organization, project, channelId, data } = props ?? {}

    return updateChannel(organization, project, channelId, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateChannelMutationResult = NonNullable<Awaited<ReturnType<typeof updateChannel>>>
export type UpdateChannelMutationBody = ChannelUpdate
export type UpdateChannelMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Update Channel
 */
export const useUpdateChannel = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateChannel>>,
    TError,
    { organization: string; project: string; channelId: string; data: ChannelUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof updateChannel>>,
  TError,
  { organization: string; project: string; channelId: string; data: ChannelUpdate },
  TContext
> => {
  const mutationOptions = getUpdateChannelMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Delete a notification channel.
 * @summary Delete Channel
 */
export const deleteChannel = (
  organization: string,
  project: string,
  channelId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.default.delete(`/dash/organizations/${organization}/projects/${project}/channels/${channelId}`, options)
}

export const getDeleteChannelMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteChannel>>,
    TError,
    { organization: string; project: string; channelId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteChannel>>,
  TError,
  { organization: string; project: string; channelId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteChannel>>,
    { organization: string; project: string; channelId: string }
  > = (props) => {
    const { organization, project, channelId } = props ?? {}

    return deleteChannel(organization, project, channelId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteChannelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteChannel>>>

export type DeleteChannelMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Delete Channel
 */
export const useDeleteChannel = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteChannel>>,
    TError,
    { organization: string; project: string; channelId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteChannel>>,
  TError,
  { organization: string; project: string; channelId: string },
  TContext
> => {
  const mutationOptions = getDeleteChannelMutationOptions(options)

  return useMutation(mutationOptions)
}

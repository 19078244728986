import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useState } from 'react'
import { CopyIcon } from 'lucide-react'

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/shadcn/ui/form'
import { Input } from '@/components/shadcn/ui/input'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { getProjectsGetWriteTokensQueryKey, queryClient, useProjectsCreateWriteToken } from '@/packages/api'
import { Button } from '@/components/shadcn/ui/button'
import { LoadingButton } from '@/components/Button'
import { Alert } from '@/components/shadcn/ui/alert'
import { TipsCard } from '@/components/TipsContainer'

const createWriteTokenFormSchema = z.object({
  description: z.string().min(1, { message: 'Description is required' }),
})

interface NewWriteTokenFormProps {
  organizationName: string
  projectName: string
  onSuccess?: () => void
  onDone: () => void
}

export const NewWriteTokenForm = ({ organizationName, projectName, onDone, onSuccess }: NewWriteTokenFormProps) => {
  const { toast } = useToast()
  const [writeToken, setWriteToken] = useState<string | null>(null)
  const form = useForm<z.infer<typeof createWriteTokenFormSchema>>({
    resolver: zodResolver(createWriteTokenFormSchema),
    defaultValues: {
      description: '',
    },
  })
  const mutation = useProjectsCreateWriteToken({
    mutation: {
      onSuccess: (data) => {
        const token = data.data
        form.reset()
        setWriteToken(token)
        onSuccess?.()
      },
      onError: (error) => {
        const errorMessage =
          typeof error.response?.data.detail === 'string'
            ? error.response.data.detail
            : 'An error occurred. Please try again.'
        toast({
          title: 'Error',
          description: errorMessage,
          variant: 'destructive',
        })
      },
    },
  })

  const onSubmit = form.handleSubmit((values) => {
    mutation.mutate(
      { organization: organizationName, project: projectName, data: values },
      {
        onSuccess: () => {
          const writeTokensKey = getProjectsGetWriteTokensQueryKey(organizationName, projectName)
          queryClient.invalidateQueries({ queryKey: writeTokensKey })
        },
      },
    )
  })

  const hasError = mutation.isError

  return (
    <div className="flex flex-col gap-y-5">
      {writeToken ? (
        <>
          <p className="rounded border border-states-warning bg-states-warning-container px-3 py-2 text-sm text-states-on-warning">
            <div className="mb-1 flex flex-wrap items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-1 h-4 w-4"
              >
                <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                <path d="M12 9v4" />
                <path d="M12 17h.01" />
              </svg>
              <span className="font-bold">Warning</span>
            </div>
            <span>This token will not be shown again</span>
          </p>
          <TipsCard
            className="mb-4"
            description="We recommend setting this token as the LOGFIRE_TOKEN environment variable."
            learnMoreLink="https://docs.pydantic.dev/logfire/guides/advanced/creating_write_tokens/?h=write"
          />
          <section className="space-y-4">
            <span>Copy and safely store this token:</span>
            <section className="flex items-center gap-x-2 rounded-lg border border-outline">
              <Input
                readOnly
                className="overflow-x-scroll border-none focus:border-none focus-visible:ring-0"
                value={writeToken}
              />
              <Button
                size="icon"
                variant="ghost"
                onClick={() => {
                  navigator.clipboard.writeText(writeToken)
                  toast({
                    title: 'Token copied to clipboard',
                    duration: 3000,
                  })
                }}
              >
                <CopyIcon className="h-4 w-4" />
              </Button>
            </section>
            <Button onClick={onDone}>Done</Button>
          </section>
        </>
      ) : (
        <>
          <Form {...form}>
            <form onSubmit={onSubmit} className="space-y-5">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Input placeholder="Token description" {...field} />
                      </FormControl>
                      <FormMessage className="pt-0.5" />
                      <FormDescription>Add a description to identify your token.</FormDescription>
                    </FormItem>
                  )
                }}
              />
              <LoadingButton isLoading={mutation.isPending} type="submit">
                Create token
              </LoadingButton>
            </form>
            {hasError && (
              <Alert variant="destructive" className="mb-4">
                There was an error creating the token. Please try again.
              </Alert>
            )}
          </Form>
        </>
      )}
    </div>
  )
}

import { useCallback, useState } from 'react'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/shadcn/ui/dialog'
import { Button } from '@/components/shadcn/ui/button'
import { ChannelRead } from '@/packages/api/__generated__/model'
import { getListChannelsQueryKey, queryClient, useDeleteChannel } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { useToast } from '@/components/shadcn/ui/use-toast'

interface DeleteChannelModalProps {
  channel: ChannelRead
}

export const DeleteChannelModal = ({ channel }: DeleteChannelModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS.CHANNELS)
  const { toast } = useToast()

  const { mutate, isPending } = useDeleteChannel({
    mutation: {
      onSuccess: async () => {
        setIsOpen(false)
        toast({
          title: 'Channel Deleted',
          description: 'The channel has been successfully deleted',
        })

        const channelsKey = getListChannelsQueryKey(organizationName, projectName)
        await queryClient.cancelQueries({ queryKey: channelsKey })

        const previousItems = queryClient.getQueryData(channelsKey)

        queryClient.setQueryData(channelsKey, (old: { data: ChannelRead[] }) =>
          old.data.filter((item) => item.id !== channel.id),
        )

        return { previousItems }
      },
      onSettled: () => {
        const channelsKey = getListChannelsQueryKey(organizationName, projectName)
        queryClient.invalidateQueries({ queryKey: channelsKey })
      },
      onError: () => {
        toast({
          title: 'Delete Channel Failed',
          description: 'An error occurred while deleting the channel',
          variant: 'destructive',
        })
      },
    },
  })

  const onRemoveMember = useCallback(() => {
    mutate({
      channelId: channel.id,
      organization: organizationName,
      project: projectName,
    })
  }, [channel.id, mutate, organizationName, projectName])

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        <Button variant="destructive" size="sm">
          Delete
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Channel</DialogTitle>
        </DialogHeader>
        <p>
          Are you sure you want to delete the <strong>{channel.label}</strong> channel?
        </p>
        <p>This action cannot be undone and will affect all alerts using this channel.</p>
        <DialogFooter>
          <Button variant="ghost" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button variant="destructive" disabled={isPending} onClick={onRemoveMember}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { CopyIcon, SquareArrowOutUpRight } from 'lucide-react'
import { Link } from 'react-router-dom'

import { ROUTES } from '@/packages/router/routes'
import { buttonVariants } from '@/components/Button'
import { Input } from '@/components/shadcn/ui/input'
import { Button } from '@/components/shadcn/ui/button'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { TipsCard } from '@/components/TipsContainer'
import { cn } from '@/packages/style'

const ENVIRONMENT_VARIABLE = 'LOGFIRE_TOKEN'

export const ProductionInstructions = () => {
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT)

  const { toast } = useToast()

  return (
    <>
      <TipsCard description="In production, we recommend you provide your write token to the Logfire SDK via environment variables." />
      <section className="flex flex-col gap-y-2 p-2">
        <h4 className="text-lg font-semibold">1. Install SDK</h4>
        <p className="pl-4">If you haven't installed the Logfire SDK, do this first. </p>
        <p className="pl-4">
          <a
            href="https://docs.pydantic.dev/logfire/guides/first_steps/#install"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Install SDK
            <SquareArrowOutUpRight className="ml-1 inline-block h-3 w-3" />
          </a>
        </p>
        <h4 className="mt-4 text-lg font-semibold">2. Generate Write Token</h4>
        <div className="pl-4">
          <Link
            to={ROUTES.ORGANIZATION.PROJECT.SETTINGS.WRITE_TOKENS.buildPath({ organizationName, projectName })}
            className={cn(buttonVariants({ variant: 'default' }), 'my-2')}
          >
            Go to Write Tokens
          </Link>
        </div>
        <h4 className="mt-4 text-lg font-semibold">3. Configure environment</h4>
        <p className="pl-4">
          <ul className="mb-2 list-inside list-[square] space-y-2">
            <li>
              Set the <code className="bg-surface-container p-1">{ENVIRONMENT_VARIABLE}</code> environment variable in
              your application's execution environment to a valid write token
            </li>
            <li>The Logfire SDK uses that token to send data to your project</li>
          </ul>
          <section className="flex grow items-center gap-x-2 rounded-lg border border-outline">
            <Input
              readOnly
              className="border-none focus:border-none focus-visible:ring-0"
              value={`${ENVIRONMENT_VARIABLE}=<your-write-token>`}
            />
            <Button
              size="icon"
              variant="ghost"
              onClick={() => {
                navigator.clipboard.writeText(`${ENVIRONMENT_VARIABLE}=your-write-token`)
                toast({
                  title: 'Token copied to clipboard',
                  duration: 3000,
                })
              }}
            >
              <CopyIcon className="h-4 w-4" />
            </Button>
          </section>
        </p>
        <h4 className="mt-4 text-lg font-semibold">4. Send data to Logfire</h4>
        <p className="pl-4">
          Once your environment variable is set, you should be able to see your app data in live view.
        </p>
        <div className="pl-4">
          <Link
            className={cn(buttonVariants({ variant: 'default' }), 'my-2')}
            to={ROUTES.ORGANIZATION.PROJECT.buildPath({ organizationName, projectName })}
          >
            Go to Live View
          </Link>
        </div>
      </section>
    </>
  )
}

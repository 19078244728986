import { ExternalLinkIcon } from 'lucide-react'
import { ReactNode } from 'react'

import { cn } from '@/packages/style'

interface TipsCardProps {
  description: string | ReactNode
  learnMoreLink?: string
  className?: string
}

export const TipsCard = ({ className, description, learnMoreLink }: TipsCardProps) => {
  return (
    <div className={cn('flex items-center gap-2 bg-surface-container p-3', className)}>
      <svg
        viewBox="0 0 192 192"
        fill="fill-logfire"
        xmlns="http://www.w3.org/2000/svg"
        className="h-8 w-8 shrink-0 text-logfire"
      >
        <path
          d="M156 28H36C31.6 28 28 31.6 28 36V132H44V148H60V164H156C160.4 164 164 160.4 164 156V36C164 31.6 160.4 28 156 28ZM71.08 117.44L64.92 122.56L42.8 96L64.92 69.44L71.08 74.56L53.2 96L71.08 117.44ZM87.92 124H79.16L104.04 68H112.8L87.92 124ZM127.08 122.56L120.92 117.44L138.8 96L120.92 74.56L127.08 69.44L149.2 96L127.08 122.56Z"
          fill="currentColor"
        />
      </svg>
      <p className="inline-block text-sm text-on-surface">
        {description}
        {learnMoreLink && (
          <a
            target="_blank"
            className="ml-1.5 gap-x-1 underline transition-opacity hover:opacity-50"
            href={learnMoreLink}
            rel="noreferrer noopener"
          >
            Learn more
            <ExternalLinkIcon className="mb-0.5 ml-1 inline-block h-3 w-3" />
          </a>
        )}
      </p>
    </div>
  )
}

import { z } from 'zod'

export const alertResultSchema = z.object({
  query: z.string().optional(),
  trace_id: z.string().optional(),
  data: z.array(z.array(z.any())).optional(),
  columns: z
    .array(
      z.object({
        name: z.string(),
        type: z.object({ is_array: z.boolean(), type_name: z.string() }).nullable(),
      }),
    )
    .optional(),
  duration: z.number().optional(),
  errors: z.array(z.object({ message: z.string() })).optional(),
})

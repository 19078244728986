/**
 ** If you throw errors from a loader, the error boundary will catch it and show the error page. https://reactrouter.com/en/main/route/loader#throwing-in-loaders
 ** The error thrown seems to be intercepted by React Router and be added some extra properties.
 ** By creating a custom error that adheres to the structure expected by this util function https://github.com/remix-run/react-router/blob/main/packages/router/utils.ts#L1600-L1612
 ** we can throw this error in a component and make the error boundary catch it and show the same error page as if it was thrown from a loader.

 For an example check:
 - organization-page.tsx
 - RootBoundary.tsx
 **/

export class ResponseError extends Error {
  status: number
  statusText: string
  internal: boolean
  data: Record<string, unknown>

  constructor({ status, statusText }: { status: number; statusText: string }) {
    super(statusText)
    this.status = status
    this.statusText = statusText
    this.internal = true
    this.data = {}
  }
}

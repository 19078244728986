/**
 * This file contains the redux store, hooks and related types.
 * The idea is to create new slices in their own files, but keep the combined store here.
 */
import { useDispatch } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  PersistConfig,
  createMigrate,
  PersistedState,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { LogsState, ScrollViewSettings, logsSlice } from '@/store/logsSlice'
import { settingsSlice } from '@/store/settings'
import { monitoringSlice } from '@/store/monitoring'

const logsPersistConfig: PersistConfig<LogsState> = {
  key: 'logs',
  version: 3,
  storage,
  whitelist: ['settings'],
  migrate: createMigrate(
    {
      2: (state: PersistedState) => {
        const logsState = state as PersistedState & { settings?: ScrollViewSettings }
        const settingsState = logsState.settings
        return {
          ...logsState,
          settings: {
            ...settingsState,
            recordDetailsPanelGroupDirection: 'horizontal',
          },
        }
      },
      3: (state: PersistedState) => {
        const logsState = state as PersistedState & { settings?: ScrollViewSettings }
        const settingsState = logsState.settings
        return {
          ...logsState,
          settings: {
            ...settingsState,
            timelinePosition: 'top',
          },
        }
      },
    },
    { debug: true },
  ),
}

const rootReducer = combineReducers({
  logs: persistReducer(logsPersistConfig, logsSlice.reducer),
  settings: settingsSlice.reducer,
  monitoring: monitoringSlice.reducer,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['settings'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch<AppDispatch>

import { useEffect, useState } from 'react'
import axios from 'axios'

import { useToast } from '@/components/shadcn/ui/use-toast'
import { ToastAction } from '@/components/shadcn/ui/toast'

export const AppUpdatePopup = () => {
  const { toast } = useToast()
  const [latestVersion, setLatestVersion] = useState<string | null>(null)

  const reloadPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        const appVersion = response.headers['x-api-version']

        if (latestVersion && appVersion !== latestVersion) {
          toast({
            id: 'app-update-required',
            duration: 24 * 60 * 60 * 1000,
            title: 'App Update Required',
            description: (
              <>
                A new version of the Logfire Web UI has been released. Please refresh the page to use the latest
                version.
              </>
            ),
            action: (
              <ToastAction altText="Reload" className="text-xs" onClick={reloadPage}>
                Reload Page
              </ToastAction>
            ),
          })
        }

        setLatestVersion(appVersion)

        return response
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    return () => {
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [toast, latestVersion])

  return null
}

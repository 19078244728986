import ReactSyntaxHighlighter from 'react-syntax-highlighter'
import { useMemo } from 'react'
import { CopyIcon } from 'lucide-react'

import { Theme, useTheme } from '@/packages/theme/useTheme'
import { useToastCopyText } from '@/hooks/clipboard'
import { darkTheme, lightTheme } from '@/app/explore-v3/components/syntax-highlight-theme'

export const getSqlHighlightTheme = (theme: Theme): Record<string, React.CSSProperties> => {
  // @ts-ignore
  return theme === Theme.dark
    ? { ...darkTheme, 'hljs-keyword': { color: '#E2A4E7' }, 'hljs-string': { color: '#D3FA63' } }
    : {
        ...lightTheme,
        'hljs-keyword': { color: '#d73a49' },
        'hljs-string': { color: '#032f62' },
        'hljs-operator': { color: '#005cc5' },
      }
}

interface HighlighterProps {
  language: string
  text: string
}

export const Highlighter = ({ language, text }: HighlighterProps) => {
  const { theme } = useTheme()
  const { codeTheme, codeTagProps } = useMemo(() => {
    const codeTheme = getSqlHighlightTheme(theme)
    const codeTagProps = { style: { color: theme === Theme.dark ? '#E0E2E7' : '#22863a' } }
    return { codeTheme, codeTagProps }
  }, [theme])
  const { toastCopyText } = useToastCopyText()
  return (
    <div className="relative">
      <ReactSyntaxHighlighter
        customStyle={{ background: 'transparent', padding: 0, fontSize: 12 }}
        codeTagProps={codeTagProps}
        style={codeTheme}
        language={language}
      >
        {text}
      </ReactSyntaxHighlighter>
      <button
        onClick={() => toastCopyText(text, { title: 'Code copied!' })}
        className="absolute right-0 top-0 flex items-start justify-end transition-opacity hover:opacity-60"
      >
        <CopyIcon className="h-4 w-4" />
      </button>
    </div>
  )
}

import { useEffect } from 'react'

const LOADING_LOGO_COUNT = { count: 0 }

const hideLoadingLogo = () => {
  document.getElementById('global-loading')?.style.setProperty('visibility', 'hidden')
}

const showLoadingLogo = () => {
  document.getElementById('global-loading')?.style.setProperty('visibility', 'visible')
}

export const useLoadingLogo = (isLoading = true) => {
  useEffect(() => {
    if (isLoading) {
      LOADING_LOGO_COUNT.count += 1
      const timeout = setTimeout(() => {
        if (LOADING_LOGO_COUNT.count > 0) {
          showLoadingLogo()
        }
      }, 100)
      return () => {
        LOADING_LOGO_COUNT.count -= 1
        clearTimeout(timeout)
        if (LOADING_LOGO_COUNT.count === 0) {
          hideLoadingLogo()
        }
      }
    } else if (LOADING_LOGO_COUNT.count === 0) {
      // Hide if the count is currently zero
      hideLoadingLogo()
    }
  }, [isLoading])
}

export const LoadingLogo = () => {
  useLoadingLogo()

  return null
}

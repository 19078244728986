import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import { useAuth } from '@/context/auth'
import { AuthManager } from '@/context/AuthManager'

import { ROUTES } from '../router/routes'

export type TokenInfo = {
  token: string
  type: 'auth' | 'anon'
} | null

export const useToken = (): TokenInfo => {
  const authContext = useAuth()
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT)
  const isAnon = organizationName === 'anonymous'

  const authToken = authContext?.token

  const [token, setToken] = useState<TokenInfo>(
    authToken ? { token: authToken, type: isAnon ? 'anon' : 'auth' } : null,
  )

  const { hash } = useLocation()

  useEffect(() => {
    const hashAsQueryParams = new URLSearchParams(hash.slice(1))
    const hashToken = hashAsQueryParams.get('token')

    if (hashToken) {
      hashAsQueryParams.delete('token')
      const newHash = hashAsQueryParams.toString()
      // Clear the hash
      if (newHash === '') {
        history.replaceState('', document.title, window.location.pathname + window.location.search)
      } else {
        window.location.hash = newHash
      }

      if (projectName) {
        AuthManager.getInstance().addProject({
          token: hashToken,
          organizationName,
          projectName,
        })
      }

      return setToken({ token: hashToken, type: 'anon' })
    }
  }, [hash, projectName, isAnon, organizationName])

  if (!token) return null
  return token
}

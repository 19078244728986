import { useSelector } from 'react-redux'
import { ReactNode, MouseEventHandler } from 'react'

import { useAppDispatch } from '@/store'
import { settingsActions, settingsSelector } from '@/store/settings'
import { Checkbox } from '@/components/shadcn/ui/checkbox'
import { Button } from '@/components/shadcn/ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/shadcn/ui/select'
import { Label } from '@/components/shadcn/ui/label'
import { useQueryEngine } from '@/hooks/useQueryEngine'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/shadcn/ui/popover'
import { Divider } from '@/components/Panels/CustomPanelComponents'
import { DisplayOptions } from '@/components/Logs/DisplayOptions'

export const AdvancedSettingsPopover = ({
  isOpen,
  setIsOpen,
  children,
  allowLeftClick,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children: ReactNode
  allowLeftClick?: boolean
}) => {
  const handler: MouseEventHandler = (e) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }
  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => {
        // Close the advanced settings popover whenever the background gets clicked etc.
        // Ignore open=True so that we can require a contextMenu event to open this menu.
        if (!open) setIsOpen(false)
      }}
    >
      <PopoverTrigger asChild>
        <div onContextMenu={handler} onClick={allowLeftClick ? handler : undefined}>
          {children}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <AdvancedSettingsPanel />
      </PopoverContent>
    </Popover>
  )
}

const AdvancedSettingsPanel = () => {
  const dispatch = useAppDispatch()
  const useTimeFilterCte = useSelector(settingsSelector.selectUseTimeFilterCte)
  const useLimitCte = useSelector(settingsSelector.selectUseLimitCte)
  const [queryEngine, setQueryEngine] = useQueryEngine()

  const reset = () => {
    dispatch(settingsActions.setUseTimeFilterCte(true))
    dispatch(settingsActions.setUseLimitCte(true))
    setQueryEngine('ts')
  }

  return (
    <div className="flex flex-col space-y-3">
      <label className="font-semibold">Advanced Settings</label>
      <div className="space-y-2 pb-2">
        <section className="flex flex-col gap-y-2">
          <label className="text-sm font-semibold">All</label>
          <div className="-mt-3 flex items-center gap-x-2">
            <Label className="text-xs">Query Engine:</Label>
            <div className="grow">
              <Select value={queryEngine} onValueChange={(value) => setQueryEngine(value as 'ts' | 'ff')}>
                <SelectTrigger className="h-9 py-1">
                  <SelectValue className="p-1" placeholder="Select..." />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="ts">TS</SelectItem>
                  <SelectItem value="ff">FF</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </section>
        <section className="flex flex-col gap-y-2">
          <label className="text-sm font-semibold">Explore</label>
          <div className="flex items-center gap-x-2">
            <Checkbox
              id="useLimitCte"
              checked={useTimeFilterCte}
              onCheckedChange={() => dispatch(settingsActions.setUseTimeFilterCte(!useTimeFilterCte))}
            />
            <label
              htmlFor="useLimitCte"
              className="text-xs font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Use CTE to enforce time bounds
            </label>
          </div>
          <div className="flex items-center gap-x-2">
            <Checkbox
              id="useLimitCte"
              checked={useLimitCte}
              onCheckedChange={() => dispatch(settingsActions.setUseLimitCte(!useLimitCte))}
            />
            <label
              htmlFor="useLimitCte"
              className="text-xs font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Use CTE to enforce row count limit
            </label>
          </div>
        </section>
      </div>
      <Button disabled={useLimitCte && useTimeFilterCte && queryEngine === 'ts'} onClick={() => reset()}>
        Reset to defaults
      </Button>
      <Divider />
      <DisplayOptions />
    </div>
  )
}

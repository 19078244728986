import { useEffect, useState } from 'react'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { add } from 'date-fns'

import { ROUTES } from '@/packages/router/routes'
import { useGetAlertHistory, useGetAlertTimeline } from '@/packages/api'
import { AlertRunRead, AlertTimelineRecord } from '@/packages/api/__generated__/model'

export const timeFilterOptions = {
  'Last 1 hour': () => {
    const now = new Date()
    const start = add(now, { hours: -1 })
    return { start, end: now }
  },
  'Last 3 hours': () => {
    const now = new Date()
    const start = add(now, { hours: -3 })
    return { start, end: now }
  },
  'Last 12 hours': () => {
    const now = new Date()
    const start = add(now, { hours: -12 })
    return { start, end: now }
  },
  'Last 24 hours': () => {
    const now = new Date()
    const start = add(now, { hours: -24 })
    return { start, end: now }
  },
  'Last 7 days': () => {
    const now = new Date()
    const start = add(now, { days: -7 })
    return { start, end: now }
  },
  'Last 14 days': () => {
    const now = new Date()
    const start = add(now, { days: -30 })
    return { start, end: now }
  },
} as const

interface useAlertRunsHistoryDataProps {
  offset: number
  pageLimit: number
  setTimeFilterDates: (dates: { start: Date; end: Date }) => void
  timeFilter: keyof typeof timeFilterOptions
  timeFilterDates: { start: Date; end: Date }
  timeRangeThrottled: { startTimestamp: string; endTimestamp: string } | null
  filterMatches: boolean
}

export const useAlertRunsHistoryData = (props: useAlertRunsHistoryDataProps) => {
  const { pageLimit, offset, timeFilter, timeFilterDates, timeRangeThrottled, setTimeFilterDates, filterMatches } =
    props
  const { organizationName, projectName, alertId } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS.HISTORY)
  const [alertHistoryData, setAlertHistoryData] = useState<AlertRunRead[] | []>([])
  const [alertTimeLineData, setAlertTimeLineData] = useState<AlertTimelineRecord[] | []>([])

  // We are adding one here to check if we have more data to show
  const pageLimitToCheckIfMoreData = pageLimit + 1

  const {
    data: latestAlertHistoryData,
    isError,
    isLoading,
  } = useGetAlertHistory(organizationName, projectName, alertId, {
    end_timestamp: timeRangeThrottled ? timeRangeThrottled.endTimestamp : timeFilterDates.end.toISOString(),
    start_timestamp: timeRangeThrottled ? timeRangeThrottled.startTimestamp : timeFilterDates.start.toISOString(),
    limit: pageLimitToCheckIfMoreData,
    offset,
    filter_matches: filterMatches,
  })

  const { data: latestAlertTimeLineData } = useGetAlertTimeline(organizationName, projectName, alertId, {
    end_timestamp: timeFilterDates.end.toISOString(),
    start_timestamp: timeFilterDates.start.toISOString(),
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (offset === 0) {
        setTimeFilterDates(timeFilterOptions[timeFilter]())
      }
    }, 5_000)
    return () => clearTimeout(interval)
  }, [offset, timeFilter, setTimeFilterDates])

  useEffect(() => {
    if (latestAlertHistoryData?.data) {
      setAlertHistoryData(latestAlertHistoryData.data)
    }
    if (latestAlertTimeLineData) {
      setAlertTimeLineData(latestAlertTimeLineData.data)
    }
  }, [latestAlertHistoryData, latestAlertTimeLineData])

  return {
    // Since we are fetching one element more to check if there's more data, we need to slice it to the actual page limit
    alertHistory: alertHistoryData.slice(0, pageLimit),
    alertTimeLineData,
    isError,
    hasMoreData: alertHistoryData.length === pageLimitToCheckIfMoreData,
    // if we are on the first page, and we have data, we don't want to show loading
    isLoading: offset === 0 && alertHistoryData.length > 0 ? false : isLoading,
  }
}

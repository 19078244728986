import { useMemo, useState } from 'react'
import { SectionElement, useJsonPath } from '@uiw/react-json-view'
import { Copy, Filter } from 'lucide-react'

import { useToast } from '@/components/shadcn/ui/use-toast'

/** This is the component displayed on hover and used to copy the value / filter value */

export const RenderCopied = (props: SectionElement<'svg'> & { value: any; includeFilter?: boolean }) => {
  const jsonPath = useJsonPath()
  const [copiedValue, setCopiedValue] = useState(false)
  const [copiedFilter, setCopiedFilter] = useState(false)

  const { value, includeFilter, fill: _fill, onClick: _onClick, style, ...otherProps } = props
  const filterText = useMemo(() => convertToPostgresFilter('attributes', jsonPath, value), [jsonPath, value])
  const { toast } = useToast()

  const onClickCopyValue = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    let copyText = ''
    if (typeof value === 'number' && value === Infinity) {
      copyText = 'Infinity'
    } else if (typeof value === 'number' && isNaN(value)) {
      copyText = 'NaN'
    } else if (typeof value === 'bigint') {
      copyText = value + 'n'
    } else if (typeof value === 'string') {
      copyText = value
    } else if (value instanceof Date) {
      copyText = value.toLocaleString()
    } else {
      copyText = JSON.stringify(value, null, 2)
    }
    setCopiedValue(true)
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        toast({
          title: 'Copied value',
          description: <code>{truncateCopyText(copyText)}</code>,
        })
        const timer = setTimeout(() => {
          setCopiedValue(false)
          clearTimeout(timer)
        }, 3000)
      })
      .catch((error) => {
        console.error(error)
        toast({
          variant: 'destructive',
          title: 'Failed to copy value',
          description: `See console for more details`,
        })
      })
  }

  const onClickCopyFilter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()

    setCopiedFilter(true)
    navigator.clipboard
      .writeText(filterText)
      .then(() => {
        toast({
          title: 'Copied query filter',
          description: <code>{truncateCopyText(filterText)}</code>,
        })
        const timer = setTimeout(() => {
          setCopiedFilter(false)
          clearTimeout(timer)
        }, 3000)
      })
      .catch((error) => {
        console.error(error)
        toast({
          variant: 'destructive',
          title: 'Failed to copy filter',
          description: `See console for more details`,
        })
      })
  }

  const copyFilterColor = copiedFilter
    ? 'var(--w-rjv-copied-success-color, #28a745)'
    : 'var(--w-rjv-copied-color, currentColor)'
  const copyValueColor = copiedValue
    ? 'var(--w-rjv-copied-success-color, #28a745)'
    : 'var(--w-rjv-copied-color, currentColor)'

  return (
    <div className="centered absolute right-2">
      <div className="-mt-0.5 flex gap-x-0.5">
        <div onClick={onClickCopyValue} className="cursor-pointer rounded bg-surface-container-high p-1">
          <Copy style={{ ...style, marginLeft: 0 }} {...otherProps} className="ml-0" color={copyValueColor} />
        </div>
        {includeFilter && (
          <div onClick={onClickCopyFilter} className="cursor-pointer rounded bg-surface-container-high p-1">
            <Filter style={{ ...style, marginLeft: 0 }} {...otherProps} color={copyFilterColor} />
          </div>
        )}
      </div>
    </div>
  )
}

const convertToPostgresFilter = (columnName: string, jsonPath: (string | number)[], value: any): string => {
  let filter = columnName

  // Iterate over the path to construct the query part
  jsonPath.forEach((segment, index) => {
    const operator = index === jsonPath.length - 1 ? '->>' : '->'
    const formattedSegment = typeof segment === 'number' ? segment : `'${segment}'`
    filter += `${operator}${formattedSegment}`
  })

  let formattedValue: string
  if (typeof value === 'string') {
    // Escape single quotes by replacing each with two single quotes
    const escapedValue = value.replace(/'/g, "''")
    formattedValue = `'${escapedValue}'`
    filter = `${filter} = ${formattedValue}`
  } else if (typeof value === 'number') {
    formattedValue = value.toString()
    filter = `(${filter})::NUMERIC = ${formattedValue}`
  } else if (typeof value === 'boolean') {
    formattedValue = value.toString()
    filter = `(${filter})::BOOLEAN = ${formattedValue}`
  } else if (value == null) {
    filter = `${filter} IS NULL`
  } else {
    // For objects, arrays, etc., convert the value to a JSON string and escape single quotes
    const escapedValue = JSON.stringify(value).replace(/'/g, "''")
    filter = `${filter} @> '${escapedValue}'::JSONB`
  }

  return filter
}

const truncateCopyText = (value: string, maxLength = 100): string => {
  if (value.length <= maxLength) {
    return value
  } else {
    return value.slice(0, maxLength) + '...'
  }
}

import { Link, LinkProps } from 'react-router-dom'
import { ExternalLinkIcon } from 'lucide-react'

import { cn } from '@/packages/style'

type UserSheetLinkProps = { external?: boolean } & LinkProps & React.RefAttributes<HTMLAnchorElement>
export const UserSheetLink = ({ external, className, children, ...props }: UserSheetLinkProps) => {
  return (
    <Link target="_blank" className={cn('text-foreground', className)} {...props}>
      <div className="flex items-center justify-between px-4 py-3 hover:bg-surface-container-highest">
        <span className="text-sm text-foreground">{children}</span>
        {external && <ExternalLinkIcon className="h-4 w-4 text-on-surface-variant" />}
      </div>
    </Link>
  )
}

import { Trash2Icon } from 'lucide-react'
import { SelectValue } from '@radix-ui/react-select'
import { useMemo, useState } from 'react'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/shadcn/ui/table'
import {
  useAccountGet,
  useOrganizationGet,
  useProjectGetMembers,
  useProjectRemoveMember,
  useProjectUpdateMember,
} from '@/packages/api'
import { Select, SelectContent, SelectItem, SelectTrigger } from '@/components/shadcn/ui/select'
import { ProjectMemberUserRead, type ProjectMemberUserReadRole } from '@/packages/api/__generated__/model'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/ui/tooltip'
import { Button } from '@/components/shadcn/ui/button'
import { DialogContent, Dialog, DialogFooter, DialogHeader, DialogTitle } from '@/components/shadcn/ui/dialog'
import { ROUTES } from '@/packages/router/routes'
import { UserAvatar } from '@/components/UserAvatar'

export const ProjectSettingsMembers = () => {
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS)

  const [selectedRemoveMember, setSelectedRemoveMember] = useState<ProjectMemberUserRead | null>(null)
  const removeMemberMutation = useProjectRemoveMember()
  const updateMemberRoleMutation = useProjectUpdateMember()
  const membersQuery = useProjectGetMembers(organizationName, projectName)
  const members = membersQuery.data?.data
  const orderedMembers = members?.sort((a, b) => a.name.localeCompare(b.name))
  const { toast } = useToast()

  const { data: account } = useAccountGet()
  const personalOrg = account?.data?.name ?? ''
  const organizationQuery = useOrganizationGet(personalOrg)
  const organizationData = organizationQuery?.data?.data

  const onUserRoleChange = (userId: string, role: ProjectMemberUserReadRole) => {
    updateMemberRoleMutation.mutate(
      {
        organization: organizationName,
        project: projectName,
        userId,
        data: {
          role,
        },
      },
      {
        onSuccess: () => {
          toast({
            title: 'Role updated successfully',
            description: `User role updated successfully`,
          })
        },
        onError: () => {
          toast({
            title: 'Failed to update role',
            description: `Failed to update user role, please try again later or contact organization admin.`,
            variant: 'destructive',
          })
        },
      },
    )
  }

  const onRemoveMember = () => {
    if (selectedRemoveMember) {
      removeMemberMutation.mutate(
        {
          organization: organizationName,
          project: projectName,
          userId: selectedRemoveMember.id,
        },
        {
          onSuccess: () => {
            toast({
              title: 'Member removed successfully',
            })
            membersQuery.refetch()
            setSelectedRemoveMember(null)
          },
          onError: () => {
            toast({
              variant: 'destructive',
              title: 'Failed to remove member',
              description: `Failed to remove user, please try again later or contact organization admin.`,
            })
          },
        },
      )
    }
  }

  const numberOfAdmins = useMemo(
    () => orderedMembers?.filter((member) => member.role === 'admin').length,
    [orderedMembers],
  )

  return (
    <article>
      <section className="px-0">
        <Table>
          <TableHeader>
            <TableRow className="uppercase hover:bg-inherit">
              <TableHead className="pl-2">Account</TableHead>
              <TableHead>Permission</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orderedMembers?.map((member) => (
              <TableRow key={member.id} className="group">
                <TableCell className="flex items-center gap-x-3">
                  <UserAvatar
                    displayName={organizationData?.organization_display_name}
                    avatar={organizationData?.avatar ?? undefined}
                    username={organizationData?.organization_name}
                    className="h-9 w-9"
                  />
                  <section>
                    <p>
                      {organizationData?.organization_display_name ?? organizationData?.organization_name}
                      <span className="ml-1 text-xs font-medium text-states-info-variant">
                        /{organizationData?.organization_name}
                      </span>
                    </p>
                    <p className="text-xs text-foreground text-opacity-70">{member.email}</p>
                  </section>
                </TableCell>
                <TableCell>
                  <Tooltip>
                    {numberOfAdmins === 1 && member.role === 'admin' && (
                      <TooltipContent>You cannot change the role of the only admin of your project</TooltipContent>
                    )}
                    <TooltipTrigger>
                      <Select
                        disabled={numberOfAdmins === 1 && member.role === 'admin'}
                        onValueChange={(value) => {
                          onUserRoleChange(member.id, value as ProjectMemberUserReadRole)
                        }}
                        defaultValue={member.role}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="User Role" />
                        </SelectTrigger>
                        <SelectContent>
                          {/* Note: the values below _must_ be cases of OrganizationMemberUpdateRole */}
                          <SelectItem value="admin">Admin</SelectItem>
                          <SelectItem value="member">Member</SelectItem>
                        </SelectContent>
                      </Select>
                    </TooltipTrigger>
                  </Tooltip>
                </TableCell>
                <TableCell className="">
                  {numberOfAdmins === 1 && member.role === 'admin' ? null : (
                    <Button
                      variant="ghost"
                      className="invisible group-hover:visible"
                      onClick={() => {
                        setSelectedRemoveMember(member)
                      }}
                    >
                      <Trash2Icon className="h-4 w-4 stroke-destructive" />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>

      <Dialog open={Boolean(selectedRemoveMember)} onOpenChange={() => setSelectedRemoveMember(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Remove Member</DialogTitle>
          </DialogHeader>
          <p>
            Are you sure you want to remove <strong>{selectedRemoveMember?.name}</strong>?
          </p>
          <DialogFooter>
            <Button variant="ghost" onClick={() => setSelectedRemoveMember(null)}>
              Cancel
            </Button>
            <Button variant="destructive" disabled={removeMemberMutation.isPending} onClick={onRemoveMember}>
              Remove
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </article>
  )
}

interface AlertActiveStateProps {
  isActive: boolean
}

export const AlertActiveState = ({ isActive }: AlertActiveStateProps) => {
  return (
    <div className="flex items-center gap-x-2">
      {isActive ? (
        <>
          <div className="h-2 w-2 rounded-full bg-states-notice ring-4 ring-states-notice-container-alpha" />
          Active
        </>
      ) : (
        <>
          <div className="h-2 w-2 rounded-full bg-states-warning ring-4 ring-states-warning-container" />
          Inactive
        </>
      )}
    </div>
  )
}

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Logfire
 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import * as axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type { PlatformConfig } from '.././model'

/**
 * @summary Get Platform Config
 */
export const getPlatformConfig = (options?: AxiosRequestConfig): Promise<AxiosResponse<PlatformConfig>> => {
  return axios.default.get(`/dash/platform-config/`, options)
}

export const getGetPlatformConfigQueryKey = () => {
  return [`/dash/platform-config/`] as const
}

export const getGetPlatformConfigQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformConfig>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlatformConfig>>, TError, TData>>
  axios?: AxiosRequestConfig
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPlatformConfigQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatformConfig>>> = ({ signal }) =>
    getPlatformConfig({ signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlatformConfig>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPlatformConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatformConfig>>>
export type GetPlatformConfigQueryError = AxiosError<unknown>

/**
 * @summary Get Platform Config
 */
export const useGetPlatformConfig = <
  TData = Awaited<ReturnType<typeof getPlatformConfig>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlatformConfig>>, TError, TData>>
  axios?: AxiosRequestConfig
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformConfigQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetPlatformConfigSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformConfig>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPlatformConfig>>, TError, TData>>
  axios?: AxiosRequestConfig
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPlatformConfigQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatformConfig>>> = ({ signal }) =>
    getPlatformConfig({ signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPlatformConfig>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPlatformConfigSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatformConfig>>>
export type GetPlatformConfigSuspenseQueryError = AxiosError<unknown>

/**
 * @summary Get Platform Config
 */
export const useGetPlatformConfigSuspense = <
  TData = Awaited<ReturnType<typeof getPlatformConfig>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPlatformConfig>>, TError, TData>>
  axios?: AxiosRequestConfig
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformConfigSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

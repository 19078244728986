import { TypesElement } from '@uiw/react-json-view'

import { cn } from '@/packages/style'

/** This is the display of a string-like value */
export const RenderString = (props: TypesElement<'span'>) => {
  const { children, className, ...otherProps } = props

  const escapedValue = String(children).replaceAll(/"/g, '\\"')

  return (
    <span {...otherProps} className={cn(className, 'whitespace-pre-wrap')}>
      &quot;{escapedValue}&quot;
    </span>
  )
}

import { useMemo } from 'react'
import { Diff, Hunk, parseDiff, tokenize } from 'react-diff-view'
// @ts-ignore
// eslint-disable-next-line import/order
import { diffLines, formatLines } from 'unidiff'

import './code-diff.css'

import * as refractor from 'refractor'

import { cn } from '@/packages/style'

interface CodeDiffProps {
  oldCode: string
  newCode: string
  language: string
  className?: string
}

export const CodeDiff = ({ oldCode, newCode, language, className }: CodeDiffProps) => {
  const { type, hunks } = useMemo(() => {
    const diffText = formatLines(diffLines(oldCode, newCode), {
      context: 100,
    })
    const [diff] = parseDiff(diffText, { nearbySequences: 'zip' })
    return diff
  }, [oldCode, newCode])

  const tokens = useMemo(() => {
    if (!hunks) {
      return undefined
    }

    try {
      return tokenize(hunks, {
        refractor,
        language,
        highlight: true,
        oldSource: oldCode,
      })
    } catch (ex) {
      return undefined
    }
  }, [hunks, language, oldCode])

  return (
    <section className={cn('font-mono text-xs', className)}>
      <Diff
        viewType="split"
        diffType={type}
        hunks={hunks || []}
        tokens={tokens}
        codeClassName="text-xs text-[#22863a] leading-[1.4] font-thin"
        lineClassName="text-xs bg-surface-container-lowest"
        gutterType="default"
      >
        {(hunks) => hunks.map((hunk) => <Hunk key={hunk.content} hunk={hunk} />)}
      </Diff>
    </section>
  )
}

import { useLocation } from 'react-router-dom'

import useLocalStorage from '@/hooks/useLocalStorage'

type QueryEngine = 'ts' | 'ff'
const VALID_QUERY_ENGINES = new Set(['ts', 'ff'])

const FF_PATH_NAMES = new Set(['/meta/meta/explore', '/e2e-test/test-e2e-project'])

export const useQueryEngine = () => {
  const { pathname } = useLocation()

  // For now, default to 'ff' for /meta/meta/explore; 'ts' for all other pages
  const defaultValue: QueryEngine = FF_PATH_NAMES.has(pathname) ? 'ff' : 'ts'

  const key = `logfire:queryEngine:${pathname}`
  const { value, set } = useLocalStorage(key, defaultValue, (x): x is QueryEngine => VALID_QUERY_ENGINES.has(x))

  return [value, set] as const
}

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { AppState, useAppDispatch } from '@/store'
import { logsActions } from '@/store/logsSlice'
import { Label } from '@/components/shadcn/ui/label'
import { Switch } from '@/components/shadcn/ui/switch'

export const DisplayOptions: React.FC = () => {
  const showTags = useSelector((state: AppState) => state.logs.settings.showTags)
  const showSpansOnly = useSelector((state: AppState) => state.logs.settings.showSpansOnly)
  const expandToMatches = useSelector((state: AppState) => state.logs.settings.expandToMatches)
  const flatView = useSelector((state: AppState) => state.logs.settings.flatView)

  const dispatch = useAppDispatch()

  const toggleShowTags = useCallback(() => {
    dispatch(logsActions.setSettings({ showTags: !showTags }))
  }, [dispatch, showTags])
  const toggleShowSpansOnly = useCallback(() => {
    dispatch(logsActions.setSettings({ showSpansOnly: !showSpansOnly }))
  }, [dispatch, showSpansOnly])
  const toggleExpandToMatches = useCallback(() => {
    dispatch(logsActions.setSettings({ expandToMatches: !expandToMatches }))
  }, [dispatch, expandToMatches])

  const toggleFlatView = useCallback(() => {
    dispatch(logsActions.setSettings({ flatView: !flatView }))
  }, [dispatch, flatView])

  return (
    <>
      <div>Display options:</div>
      <div className="flex items-center gap-2">
        <Switch id="show-tags" checked={showTags} onCheckedChange={toggleShowTags} />
        <Label htmlFor="show-tags" className="font-normal">
          Show tags
        </Label>
      </div>
      <div className="flex items-center gap-2">
        <Switch id="show-query-matches-only" checked={showSpansOnly} onCheckedChange={toggleShowSpansOnly} />
        <Label htmlFor="show-query-matches-only" className="font-normal">
          Only display query-matching spans
        </Label>
      </div>
      <div className="flex items-center gap-2">
        <Switch id="expand-to-matches" checked={expandToMatches} onCheckedChange={toggleExpandToMatches} />
        <Label htmlFor="expand-to-matches" className="font-normal">
          Expand to display query-matching spans
        </Label>
      </div>
      <div className="flex items-center gap-2">
        <Switch id="flat-view" checked={!flatView} onCheckedChange={toggleFlatView} />
        <Label htmlFor="flat-view" className="font-normal">
          Show full trace in search results
        </Label>
      </div>
    </>
  )
}

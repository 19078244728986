import { useCallback, useEffect, useSyncExternalStore } from 'react'

interface UseLocalStorageReturn<T> {
  value: T
  set: (newValue: T) => void
  remove: () => void
}

function dispatchStorageEvent(key: string, newValue: string | null | undefined) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }))
}

const setLocalStorageItem = (key: string, value: any) => {
  const stringifiedValue = JSON.stringify(value)
  window.localStorage.setItem(key, stringifiedValue)
  dispatchStorageEvent(key, stringifiedValue)
}

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key)
  dispatchStorageEvent(key, null)
}

const getLocalStorageItem = (key: string): string | null => {
  return window.localStorage.getItem(key)
}

const useLocalStorageSubscribe = (callback: (ev: StorageEvent) => void) => {
  window.addEventListener('storage', callback)
  return () => window.removeEventListener('storage', callback)
}

const getLocalStorageServerSnapshot = () => {
  throw Error('useLocalStorage is a client-only hook')
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  typeGuard?: (x: any) => x is T,
): UseLocalStorageReturn<T> {
  const getSnapshot = () => getLocalStorageItem(key)

  const store = useSyncExternalStore(useLocalStorageSubscribe, getSnapshot, getLocalStorageServerSnapshot)
  let parsedStore: T | undefined
  if (store !== null) {
    try {
      parsedStore = JSON.parse(store)
    } catch {
      console.error('Failed to JSON parse localStorage', { key, store })
    }
  }
  // Use the initialValue if parsing fails or if a typeguard is present and doesn't return true
  const value = parsedStore === undefined || (typeGuard && !typeGuard(parsedStore)) ? initialValue : parsedStore

  const set = useCallback(
    (v: T | ((prevValue: T) => T)) => {
      try {
        const nextState = typeof v === 'function' ? (v as (prevValue: T) => T)(value) : v

        if (nextState === undefined || nextState === null) {
          removeLocalStorageItem(key)
        } else {
          setLocalStorageItem(key, nextState)
        }
      } catch (e) {
        console.warn(e)
      }
    },
    [key, value],
  )

  const remove = useCallback(() => removeLocalStorageItem(key), [key])

  useEffect(() => {
    if (getLocalStorageItem(key) === null && initialValue !== undefined) {
      setLocalStorageItem(key, initialValue)
    }
  }, [key, initialValue])

  return { value, set, remove }
}

export default useLocalStorage

import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { BellOffIcon, ChevronUp, CircleXIcon, Search } from 'lucide-react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Select, SelectValue } from '@radix-ui/react-select'
import _ from 'lodash'
import { Tabs } from '@radix-ui/react-tabs'

import { cn } from '@/packages/style'
import { buttonVariants } from '@/components/shadcn/ui/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/shadcn/ui/collapsible'
import { useListAlerts } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { Input } from '@/components/shadcn/ui/input'
import List, { ListItem } from '@/components/List'
import { AlertWithLastRun } from '@/packages/api/__generated__/model'
import { SelectContent, SelectItem, SelectTrigger } from '@/components/shadcn/ui/select'
import { TabsList, TabsTrigger } from '@/components/shadcn/ui/tabs'
import { LoadingSpinner } from '@/components/LoadingSpinner'

import { AlertPreview } from './AlertPreview'

const minuteInMilliseconds = 60000

const LoadingAlertsJSX = (
  <section className="flex flex-col items-center justify-center gap-y-1 rounded-lg border border-outline bg-surface-container-low px-3 py-10">
    <div className="text-foreground text-opacity-50">
      <LoadingSpinner />
    </div>
    <p className="text-foreground text-opacity-50">Loading alerts...</p>
  </section>
)

const NoAlerts = ({ hasFilter }: { hasFilter: boolean }) => (
  <section className="mt-6 flex flex-col items-center justify-center gap-y-1 rounded-lg border border-outline bg-surface-container-low px-3 py-10">
    <BellOffIcon className="opacity-50" />
    <p className="text-foreground text-opacity-50">
      {hasFilter
        ? 'No alerts found. Try a different search.'
        : "You don't have any alerts. Create one to view it in this list."}
    </p>
  </section>
)

const AlertsError = ({ message }: { message?: string }) => {
  return (
    <section className="flex flex-col items-center justify-center gap-y-1 rounded-lg border border-outline bg-surface-container-low px-3 py-10">
      <div className="text-states-error opacity-80">
        <CircleXIcon />
      </div>
      <p className="text-foreground text-opacity-50">
        {message ?? 'An error occurred while retrieving alerts for this project.'}
      </p>
    </section>
  )
}

const groupByOptions = [
  {
    key: 'None',
    groupFn: () => 'All',
  },
  {
    key: 'Is Active',
    groupFn: (alert: AlertWithLastRun) => (alert.active ? 'Active' : 'Inactive'),
  },
  {
    key: 'Has Matches',
    groupFn: (alert: AlertWithLastRun) => ((alert.result_length ?? 0) > 0 ? 'Matches' : 'No Matches'),
  },
]

export const AlertsList = () => {
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS)
  const [collapsedGroups, setCollapsedGroups] = useState<string[]>([])
  const [search, setSearch] = useState('')
  const [groupByKey, setGroupByKey] = useState('None')

  const {
    data: alertsQueryData,
    error,
    isLoading,
  } = useListAlerts(organizationName, projectName, {
    query: { refetchInterval: minuteInMilliseconds, refetchIntervalInBackground: true },
  })
  const alerts = alertsQueryData?.data ?? []

  const alertsFiltered = alerts?.filter((alert) => alert.name.toLowerCase().includes(search.toLowerCase())) ?? []

  const groupFn = groupByOptions.find((option) => option.key === groupByKey)?.groupFn ?? groupByOptions[0].groupFn
  const alertGroups = groupBy(alertsFiltered, groupFn, (key) => key + ' Alerts')

  return (
    <>
      <section className="mb-8 mt-12 flex items-center justify-between border-outline">
        <section className="flex space-x-2">
          <Tabs className="m-0 p-0" value="alerts">
            <TabsList className="h-8">
              <TabsTrigger asChild value="alerts" className="h-6 text-xs">
                <Link to={ROUTES.ORGANIZATION.PROJECT.ALERTS.buildPath({ organizationName, projectName })}>
                  Alerts
                </Link>
              </TabsTrigger>
              <TabsTrigger value="channels" className="h-6 text-xs">
                <Link to={ROUTES.ORGANIZATION.PROJECT.ALERTS.CHANNELS.buildPath({ organizationName, projectName })}>
                  Channels
                </Link>
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <section className="w-[296px]">
            <Input
              className="h-8 bg-surface-bright placeholder:text-on-surface"
              disabled={!alerts || alerts.length === 0}
              placeholder="Find an alert"
              startIcon={Search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </section>
          <Select value={groupByKey} onValueChange={(value) => setGroupByKey(value)}>
            <SelectTrigger className="h-8 w-56 bg-surface-bright text-xs placeholder:text-on-surface">
              <SelectValue placeholder="Group by: None" className="text-xs">
                <span className="">Group by: {groupByOptions.find((option) => option.key === groupByKey)?.key}</span>
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {groupByOptions.map((option) => (
                <SelectItem key={option.key} value={option.key}>
                  {option.key}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </section>
        <section>
          <Link
            to={ROUTES.ORGANIZATION.PROJECT.ALERTS.NEW.buildPath({ organizationName, projectName })}
            className={cn(buttonVariants({ size: 'sm', variant: 'alternative' }))}
          >
            New Alert
          </Link>
        </section>
      </section>

      {alertsFiltered.length === 0 && isLoading ? (
        LoadingAlertsJSX
      ) : error ? (
        <AlertsError message={error.message} />
      ) : alertsFiltered.length === 0 ? (
        <NoAlerts hasFilter={search.length > 0} />
      ) : (
        <section className="mt-6 flex flex-col gap-y-10">
          {alertGroups
            .filter((group) => group.alerts.length > 0)
            .map((group) => {
              return (
                <section key={group.title}>
                  <Collapsible
                    defaultOpen={true}
                    onOpenChange={(isOpen) => {
                      if (isOpen) {
                        setCollapsedGroups(collapsedGroups.filter((g) => g !== group.title))
                      } else {
                        setCollapsedGroups([...collapsedGroups, group.title])
                      }
                    }}
                  >
                    <CollapsibleTrigger
                      className={cn('w-full', collapsedGroups.includes(group.title) && 'border-b border-outline')}
                    >
                      <div className={cn('mb-4 flex items-center space-x-2 text-on-surface-variant')}>
                        <ChevronUp
                          className={cn(
                            'h-4 w-4',
                            collapsedGroups.includes(group.title) ? 'rotate-90 transform' : 'rotate-180 transform',
                          )}
                        />
                        <span className="text-lg text-on-surface">{group.title}</span>
                        <span>{group.alerts.length}</span>
                      </div>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <List>
                        {group.alerts.map((alert) => (
                          <ListItem
                            linkTo={ROUTES.ORGANIZATION.PROJECT.ALERTS.HISTORY.buildPath({
                              organizationName,
                              projectName,
                              alertId: alert.id,
                            })}
                            key={alert.id}
                            className="flex flex-col space-y-3"
                          >
                            <AlertPreview alert={alert} />
                          </ListItem>
                        ))}
                      </List>
                    </CollapsibleContent>
                  </Collapsible>
                </section>
              )
            })}
        </section>
      )}
    </>
  )
}

type GroupByKeyFunction<T> = (item: T) => string

type TitleFunction = (key: string) => string

interface Group<T> {
  title: string
  alerts: T[]
}

function groupBy<T>(array: T[], keyFunction: GroupByKeyFunction<T>, titleFunction?: TitleFunction): Group<T>[] {
  const grouped = _.groupBy(array, keyFunction)
  return Object.keys(grouped).map((key) => ({
    title: titleFunction ? titleFunction(key) : key,
    alerts: grouped[key],
  }))
}

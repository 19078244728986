import { useLocation, useParams } from 'react-router-dom'
import { useEffect } from 'react'

export const usePageTitle = (title: string) => {
  const { projectName, organizationName } = useParams()
  const location = useLocation()

  useEffect(() => {
    const setTile = (newTitle: string) => {
      const titleParts = [newTitle]

      if (projectName && organizationName) {
        titleParts.push(`${organizationName}/${projectName}`)
      } else if (organizationName) {
        titleParts.push(organizationName)
      }
      titleParts.push('Pydantic Logfire')

      document.title = titleParts.filter((p) => p.length > 0).join(' · ')
    }
    setTile(title)

    // Cleanup function to reset the title when the component unmounts
    return () => {
      setTile('')
    }
  }, [title, projectName, organizationName, location.pathname])
}

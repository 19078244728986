import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useState } from 'react'
import { CopyIcon } from 'lucide-react'

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/shadcn/ui/form'
import { Input } from '@/components/shadcn/ui/input'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { getProjectsGetReadTokensQueryKey, queryClient, useProjectsCreateReadToken } from '@/packages/api'
import { Button } from '@/components/shadcn/ui/button'
import { LoadingButton } from '@/components/Button'
import { Alert } from '@/components/shadcn/ui/alert'

const createReadTokenFormSchema = z.object({
  description: z.string().min(1, { message: 'Description is required' }),
})

interface NewReadTokenFormProps {
  organizationName: string
  projectName: string
  onSuccess?: () => void
  onDone: () => void
}

export const NewReadTokenForm = ({ organizationName, projectName, onDone, onSuccess }: NewReadTokenFormProps) => {
  const { toast } = useToast()
  const [readToken, setReadToken] = useState<string | null>(null)
  const form = useForm<z.infer<typeof createReadTokenFormSchema>>({
    resolver: zodResolver(createReadTokenFormSchema),
    defaultValues: {
      description: '',
    },
  })
  const mutation = useProjectsCreateReadToken({
    mutation: {
      onSuccess: (data) => {
        const token = data.data
        form.reset()
        setReadToken(token)
        onSuccess?.()
      },
      onError: (error) => {
        const errorMessage =
          typeof error.response?.data.detail === 'string'
            ? error.response.data.detail
            : 'An error occurred. Please try again.'
        toast({
          title: 'Error',
          description: errorMessage,
          variant: 'destructive',
        })
      },
    },
  })

  const onSubmit = form.handleSubmit((values) => {
    mutation.mutate(
      { organization: organizationName, project: projectName, data: values },
      {
        onSuccess: () => {
          const readTokensKey = getProjectsGetReadTokensQueryKey(organizationName, projectName)
          queryClient.invalidateQueries({ queryKey: readTokensKey })
        },
      },
    )
  })

  const hasError = mutation.isError

  return (
    <div className="flex flex-col gap-y-5">
      {readToken ? (
        <>
          <p className="text-sm text-on-surface">
            <span className="font-bold">Copy this token now, as it will not be shown again.</span>
            {/* TODO: Add docs link here, see the NewWriteTokenForm for format of link/etc. */}
          </p>

          <section className="space-y-4">
            <section className="flex items-center gap-x-2 rounded-lg border border-outline">
              <Input
                readOnly
                className="overflow-x-scroll border-none focus:border-none focus-visible:ring-0"
                value={readToken}
              />
              <Button
                size="icon"
                variant="ghost"
                onClick={() => {
                  navigator.clipboard.writeText(readToken)
                  toast({
                    title: 'Token copied to clipboard',
                    duration: 3000,
                  })
                }}
              >
                <CopyIcon className="h-4 w-4" />
              </Button>
            </section>
            <Button onClick={onDone}>Done</Button>
          </section>
        </>
      ) : (
        <>
          <Form {...form}>
            <form onSubmit={onSubmit} className="space-y-5">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Input placeholder="Token description" {...field} />
                      </FormControl>
                      <FormMessage className="pt-0.5" />
                      <FormDescription>Add a description to better identify your token.</FormDescription>
                    </FormItem>
                  )
                }}
              />
              <LoadingButton isLoading={mutation.isPending} type="submit">
                Create token
              </LoadingButton>
            </form>
            {hasError && (
              <Alert variant="destructive" className="mb-4">
                There is an error while creating the token. Please try again.
              </Alert>
            )}
          </Form>
        </>
      )}
    </div>
  )
}

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Logfire
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import * as axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type {
  DashboardQueryParams,
  ExploreQueryResponse,
  HTTPExceptionError,
  HTTPValidationError,
  ProjectCreate,
  ProjectDashboardQueryParams,
  ProjectInfo,
  ProjectInvitationCreate,
  ProjectInvitationRead,
  ProjectListRead,
  ProjectMemberCreate,
  ProjectMemberUpdate,
  ProjectMemberUserRead,
  ProjectRead,
  ProjectRecordsCount,
  ProjectUpdate,
  ReadTokenCreate,
  ReadTokenRead,
  ReadTokenUpdate,
  WriteTokenCreate,
  WriteTokenRead,
  WriteTokenUpdate,
} from '.././model'

/**
 * @summary Projects List By Org
 */
export const projectsListByOrg = (
  organization: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectListRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects`, options)
}

export const getProjectsListByOrgQueryKey = (organization: string) => {
  return [`/dash/organizations/${organization}/projects`] as const
}

export const getProjectsListByOrgQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsListByOrg>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsListByOrg>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsListByOrgQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsListByOrg>>> = ({ signal }) =>
    projectsListByOrg(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsListByOrg>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectsListByOrgQueryResult = NonNullable<Awaited<ReturnType<typeof projectsListByOrg>>>
export type ProjectsListByOrgQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects List By Org
 */
export const useProjectsListByOrg = <
  TData = Awaited<ReturnType<typeof projectsListByOrg>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsListByOrg>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsListByOrgQueryOptions(organization, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getProjectsListByOrgSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsListByOrg>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsListByOrg>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsListByOrgQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsListByOrg>>> = ({ signal }) =>
    projectsListByOrg(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof projectsListByOrg>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectsListByOrgSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof projectsListByOrg>>>
export type ProjectsListByOrgSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects List By Org
 */
export const useProjectsListByOrgSuspense = <
  TData = Awaited<ReturnType<typeof projectsListByOrg>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsListByOrg>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsListByOrgSuspenseQueryOptions(organization, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Projects Create
 */
export const projectsCreate = (
  organization: string,
  projectCreate: ProjectCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectRead>> => {
  return axios.default.post(`/dash/organizations/${organization}/projects`, projectCreate, options)
}

export const getProjectsCreateMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsCreate>>,
    TError,
    { organization: string; data: ProjectCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsCreate>>,
  TError,
  { organization: string; data: ProjectCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsCreate>>,
    { organization: string; data: ProjectCreate }
  > = (props) => {
    const { organization, data } = props ?? {}

    return projectsCreate(organization, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof projectsCreate>>>
export type ProjectsCreateMutationBody = ProjectCreate
export type ProjectsCreateMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Create
 */
export const useProjectsCreate = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsCreate>>,
    TError,
    { organization: string; data: ProjectCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsCreate>>,
  TError,
  { organization: string; data: ProjectCreate },
  TContext
> => {
  const mutationOptions = getProjectsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Project Info
 */
export const projectInfo = (
  organization: string,
  project: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectInfo>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}`, options)
}

export const getProjectInfoQueryKey = (organization: string, project: string) => {
  return [`/dash/organizations/${organization}/projects/${project}`] as const
}

export const getProjectInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof projectInfo>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectInfo>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectInfoQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectInfo>>> = ({ signal }) =>
    projectInfo(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectInfoQueryResult = NonNullable<Awaited<ReturnType<typeof projectInfo>>>
export type ProjectInfoQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Project Info
 */
export const useProjectInfo = <
  TData = Awaited<ReturnType<typeof projectInfo>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectInfo>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectInfoQueryOptions(organization, project, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getProjectInfoSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof projectInfo>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectInfo>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectInfoQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectInfo>>> = ({ signal }) =>
    projectInfo(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof projectInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectInfoSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof projectInfo>>>
export type ProjectInfoSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Project Info
 */
export const useProjectInfoSuspense = <
  TData = Awaited<ReturnType<typeof projectInfo>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectInfo>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectInfoSuspenseQueryOptions(organization, project, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Projects Update
 */
export const projectsUpdate = (
  organization: string,
  project: string,
  projectUpdate: ProjectUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectRead>> => {
  return axios.default.put(`/dash/organizations/${organization}/projects/${project}`, projectUpdate, options)
}

export const getProjectsUpdateMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsUpdate>>,
    TError,
    { organization: string; project: string; data: ProjectUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsUpdate>>,
  TError,
  { organization: string; project: string; data: ProjectUpdate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsUpdate>>,
    { organization: string; project: string; data: ProjectUpdate }
  > = (props) => {
    const { organization, project, data } = props ?? {}

    return projectsUpdate(organization, project, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof projectsUpdate>>>
export type ProjectsUpdateMutationBody = ProjectUpdate
export type ProjectsUpdateMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Update
 */
export const useProjectsUpdate = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsUpdate>>,
    TError,
    { organization: string; project: string; data: ProjectUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsUpdate>>,
  TError,
  { organization: string; project: string; data: ProjectUpdate },
  TContext
> => {
  const mutationOptions = getProjectsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Projects Delete
 */
export const projectsDelete = (
  organization: string,
  project: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.default.delete(`/dash/organizations/${organization}/projects/${project}`, options)
}

export const getProjectsDeleteMutationOptions = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsDelete>>,
    TError,
    { organization: string; project: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsDelete>>,
  TError,
  { organization: string; project: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsDelete>>,
    { organization: string; project: string }
  > = (props) => {
    const { organization, project } = props ?? {}

    return projectsDelete(organization, project, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof projectsDelete>>>

export type ProjectsDeleteMutationError = AxiosError<HTTPExceptionError | HTTPValidationError>

/**
 * @summary Projects Delete
 */
export const useProjectsDelete = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsDelete>>,
    TError,
    { organization: string; project: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsDelete>>,
  TError,
  { organization: string; project: string },
  TContext
> => {
  const mutationOptions = getProjectsDeleteMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Project Records Count
 */
export const projectRecordsCount = (
  organization: string,
  project: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectRecordsCount>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/records-count`, options)
}

export const getProjectRecordsCountQueryKey = (organization: string, project: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/records-count`] as const
}

export const getProjectRecordsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof projectRecordsCount>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectRecordsCount>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectRecordsCountQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectRecordsCount>>> = ({ signal }) =>
    projectRecordsCount(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectRecordsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectRecordsCountQueryResult = NonNullable<Awaited<ReturnType<typeof projectRecordsCount>>>
export type ProjectRecordsCountQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Project Records Count
 */
export const useProjectRecordsCount = <
  TData = Awaited<ReturnType<typeof projectRecordsCount>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectRecordsCount>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectRecordsCountQueryOptions(organization, project, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getProjectRecordsCountSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof projectRecordsCount>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectRecordsCount>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectRecordsCountQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectRecordsCount>>> = ({ signal }) =>
    projectRecordsCount(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof projectRecordsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectRecordsCountSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof projectRecordsCount>>>
export type ProjectRecordsCountSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Project Records Count
 */
export const useProjectRecordsCountSuspense = <
  TData = Awaited<ReturnType<typeof projectRecordsCount>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectRecordsCount>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectRecordsCountSuspenseQueryOptions(organization, project, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Project Dashboard Query
 */
export const projectDashboardQuery = (
  organization: string,
  project: string,
  dashboardQueryParams: DashboardQueryParams,
  params?: ProjectDashboardQueryParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExploreQueryResponse>> => {
  return axios.default.post(`/dash/organizations/${organization}/projects/${project}/query`, dashboardQueryParams, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getProjectDashboardQueryMutationOptions = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectDashboardQuery>>,
    TError,
    { organization: string; project: string; data: DashboardQueryParams; params?: ProjectDashboardQueryParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectDashboardQuery>>,
  TError,
  { organization: string; project: string; data: DashboardQueryParams; params?: ProjectDashboardQueryParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectDashboardQuery>>,
    { organization: string; project: string; data: DashboardQueryParams; params?: ProjectDashboardQueryParams }
  > = (props) => {
    const { organization, project, data, params } = props ?? {}

    return projectDashboardQuery(organization, project, data, params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectDashboardQueryMutationResult = NonNullable<Awaited<ReturnType<typeof projectDashboardQuery>>>
export type ProjectDashboardQueryMutationBody = DashboardQueryParams
export type ProjectDashboardQueryMutationError = AxiosError<HTTPExceptionError | HTTPValidationError>

/**
 * @summary Project Dashboard Query
 */
export const useProjectDashboardQuery = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectDashboardQuery>>,
    TError,
    { organization: string; project: string; data: DashboardQueryParams; params?: ProjectDashboardQueryParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectDashboardQuery>>,
  TError,
  { organization: string; project: string; data: DashboardQueryParams; params?: ProjectDashboardQueryParams },
  TContext
> => {
  const mutationOptions = getProjectDashboardQueryMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Project Get Members
 */
export const projectGetMembers = (
  organization: string,
  project: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectMemberUserRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/members`, options)
}

export const getProjectGetMembersQueryKey = (organization: string, project: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/members`] as const
}

export const getProjectGetMembersQueryOptions = <
  TData = Awaited<ReturnType<typeof projectGetMembers>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectGetMembers>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectGetMembersQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectGetMembers>>> = ({ signal }) =>
    projectGetMembers(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectGetMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectGetMembersQueryResult = NonNullable<Awaited<ReturnType<typeof projectGetMembers>>>
export type ProjectGetMembersQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Project Get Members
 */
export const useProjectGetMembers = <
  TData = Awaited<ReturnType<typeof projectGetMembers>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectGetMembers>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectGetMembersQueryOptions(organization, project, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getProjectGetMembersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof projectGetMembers>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectGetMembers>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectGetMembersQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectGetMembers>>> = ({ signal }) =>
    projectGetMembers(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof projectGetMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectGetMembersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof projectGetMembers>>>
export type ProjectGetMembersSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Project Get Members
 */
export const useProjectGetMembersSuspense = <
  TData = Awaited<ReturnType<typeof projectGetMembers>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectGetMembers>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectGetMembersSuspenseQueryOptions(organization, project, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Project Add Member
 */
export const projectAddMember = (
  organization: string,
  project: string,
  projectMemberCreate: ProjectMemberCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/members`,
    projectMemberCreate,
    options,
  )
}

export const getProjectAddMemberMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectAddMember>>,
    TError,
    { organization: string; project: string; data: ProjectMemberCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectAddMember>>,
  TError,
  { organization: string; project: string; data: ProjectMemberCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectAddMember>>,
    { organization: string; project: string; data: ProjectMemberCreate }
  > = (props) => {
    const { organization, project, data } = props ?? {}

    return projectAddMember(organization, project, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectAddMemberMutationResult = NonNullable<Awaited<ReturnType<typeof projectAddMember>>>
export type ProjectAddMemberMutationBody = ProjectMemberCreate
export type ProjectAddMemberMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Project Add Member
 */
export const useProjectAddMember = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectAddMember>>,
    TError,
    { organization: string; project: string; data: ProjectMemberCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectAddMember>>,
  TError,
  { organization: string; project: string; data: ProjectMemberCreate },
  TContext
> => {
  const mutationOptions = getProjectAddMemberMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Project Update Member
 */
export const projectUpdateMember = (
  organization: string,
  project: string,
  userId: string,
  projectMemberUpdate: ProjectMemberUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.put(
    `/dash/organizations/${organization}/projects/${project}/members/${userId}`,
    projectMemberUpdate,
    options,
  )
}

export const getProjectUpdateMemberMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectUpdateMember>>,
    TError,
    { organization: string; project: string; userId: string; data: ProjectMemberUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectUpdateMember>>,
  TError,
  { organization: string; project: string; userId: string; data: ProjectMemberUpdate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectUpdateMember>>,
    { organization: string; project: string; userId: string; data: ProjectMemberUpdate }
  > = (props) => {
    const { organization, project, userId, data } = props ?? {}

    return projectUpdateMember(organization, project, userId, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectUpdateMemberMutationResult = NonNullable<Awaited<ReturnType<typeof projectUpdateMember>>>
export type ProjectUpdateMemberMutationBody = ProjectMemberUpdate
export type ProjectUpdateMemberMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Project Update Member
 */
export const useProjectUpdateMember = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectUpdateMember>>,
    TError,
    { organization: string; project: string; userId: string; data: ProjectMemberUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectUpdateMember>>,
  TError,
  { organization: string; project: string; userId: string; data: ProjectMemberUpdate },
  TContext
> => {
  const mutationOptions = getProjectUpdateMemberMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Project Remove Member
 */
export const projectRemoveMember = (
  organization: string,
  project: string,
  userId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.default.delete(`/dash/organizations/${organization}/projects/${project}/members/${userId}`, options)
}

export const getProjectRemoveMemberMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectRemoveMember>>,
    TError,
    { organization: string; project: string; userId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectRemoveMember>>,
  TError,
  { organization: string; project: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectRemoveMember>>,
    { organization: string; project: string; userId: string }
  > = (props) => {
    const { organization, project, userId } = props ?? {}

    return projectRemoveMember(organization, project, userId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectRemoveMemberMutationResult = NonNullable<Awaited<ReturnType<typeof projectRemoveMember>>>

export type ProjectRemoveMemberMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Project Remove Member
 */
export const useProjectRemoveMember = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectRemoveMember>>,
    TError,
    { organization: string; project: string; userId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectRemoveMember>>,
  TError,
  { organization: string; project: string; userId: string },
  TContext
> => {
  const mutationOptions = getProjectRemoveMemberMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * This function creates a write token for a project. The write token allows you to add data to your project.
 * @summary Projects Create Write Token
 */
export const projectsCreateWriteToken = (
  organization: string,
  project: string,
  writeTokenCreate: WriteTokenCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<string>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/write-tokens`,
    writeTokenCreate,
    options,
  )
}

export const getProjectsCreateWriteTokenMutationOptions = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsCreateWriteToken>>,
    TError,
    { organization: string; project: string; data: WriteTokenCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsCreateWriteToken>>,
  TError,
  { organization: string; project: string; data: WriteTokenCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsCreateWriteToken>>,
    { organization: string; project: string; data: WriteTokenCreate }
  > = (props) => {
    const { organization, project, data } = props ?? {}

    return projectsCreateWriteToken(organization, project, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsCreateWriteTokenMutationResult = NonNullable<Awaited<ReturnType<typeof projectsCreateWriteToken>>>
export type ProjectsCreateWriteTokenMutationBody = WriteTokenCreate
export type ProjectsCreateWriteTokenMutationError = AxiosError<HTTPExceptionError | HTTPValidationError>

/**
 * @summary Projects Create Write Token
 */
export const useProjectsCreateWriteToken = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsCreateWriteToken>>,
    TError,
    { organization: string; project: string; data: WriteTokenCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsCreateWriteToken>>,
  TError,
  { organization: string; project: string; data: WriteTokenCreate },
  TContext
> => {
  const mutationOptions = getProjectsCreateWriteTokenMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Projects Get Write Tokens
 */
export const projectsGetWriteTokens = (
  organization: string,
  project: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<WriteTokenRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/write-tokens`, options)
}

export const getProjectsGetWriteTokensQueryKey = (organization: string, project: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/write-tokens`] as const
}

export const getProjectsGetWriteTokensQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetWriteTokens>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetWriteTokens>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetWriteTokensQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetWriteTokens>>> = ({ signal }) =>
    projectsGetWriteTokens(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsGetWriteTokens>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectsGetWriteTokensQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetWriteTokens>>>
export type ProjectsGetWriteTokensQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Write Tokens
 */
export const useProjectsGetWriteTokens = <
  TData = Awaited<ReturnType<typeof projectsGetWriteTokens>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetWriteTokens>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetWriteTokensQueryOptions(organization, project, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getProjectsGetWriteTokensSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetWriteTokens>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetWriteTokens>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetWriteTokensQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetWriteTokens>>> = ({ signal }) =>
    projectsGetWriteTokens(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof projectsGetWriteTokens>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectsGetWriteTokensSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetWriteTokens>>>
export type ProjectsGetWriteTokensSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Write Tokens
 */
export const useProjectsGetWriteTokensSuspense = <
  TData = Awaited<ReturnType<typeof projectsGetWriteTokens>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetWriteTokens>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetWriteTokensSuspenseQueryOptions(organization, project, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Projects Get Write Token
 */
export const projectsGetWriteToken = (
  organization: string,
  project: string,
  tokenId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<WriteTokenRead>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/write-tokens/${tokenId}`, options)
}

export const getProjectsGetWriteTokenQueryKey = (organization: string, project: string, tokenId: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/write-tokens/${tokenId}`] as const
}

export const getProjectsGetWriteTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetWriteToken>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  tokenId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetWriteToken>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetWriteTokenQueryKey(organization, project, tokenId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetWriteToken>>> = ({ signal }) =>
    projectsGetWriteToken(organization, project, tokenId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project && tokenId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsGetWriteToken>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectsGetWriteTokenQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetWriteToken>>>
export type ProjectsGetWriteTokenQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Write Token
 */
export const useProjectsGetWriteToken = <
  TData = Awaited<ReturnType<typeof projectsGetWriteToken>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  tokenId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetWriteToken>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetWriteTokenQueryOptions(organization, project, tokenId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getProjectsGetWriteTokenSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetWriteToken>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  tokenId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetWriteToken>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetWriteTokenQueryKey(organization, project, tokenId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetWriteToken>>> = ({ signal }) =>
    projectsGetWriteToken(organization, project, tokenId, { signal, ...axiosOptions })

  return {
    queryKey,
    queryFn,
    enabled: !!(organization && project && tokenId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetWriteToken>>, TError, TData> & {
    queryKey: QueryKey
  }
}

export type ProjectsGetWriteTokenSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetWriteToken>>>
export type ProjectsGetWriteTokenSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Write Token
 */
export const useProjectsGetWriteTokenSuspense = <
  TData = Awaited<ReturnType<typeof projectsGetWriteToken>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  tokenId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetWriteToken>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetWriteTokenSuspenseQueryOptions(organization, project, tokenId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Projects Update Write Token
 */
export const projectsUpdateWriteToken = (
  organization: string,
  project: string,
  tokenId: string,
  writeTokenUpdate: WriteTokenUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<WriteTokenRead>> => {
  return axios.default.put(
    `/dash/organizations/${organization}/projects/${project}/write-tokens/${tokenId}`,
    writeTokenUpdate,
    options,
  )
}

export const getProjectsUpdateWriteTokenMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsUpdateWriteToken>>,
    TError,
    { organization: string; project: string; tokenId: string; data: WriteTokenUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsUpdateWriteToken>>,
  TError,
  { organization: string; project: string; tokenId: string; data: WriteTokenUpdate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsUpdateWriteToken>>,
    { organization: string; project: string; tokenId: string; data: WriteTokenUpdate }
  > = (props) => {
    const { organization, project, tokenId, data } = props ?? {}

    return projectsUpdateWriteToken(organization, project, tokenId, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsUpdateWriteTokenMutationResult = NonNullable<Awaited<ReturnType<typeof projectsUpdateWriteToken>>>
export type ProjectsUpdateWriteTokenMutationBody = WriteTokenUpdate
export type ProjectsUpdateWriteTokenMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Update Write Token
 */
export const useProjectsUpdateWriteToken = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsUpdateWriteToken>>,
    TError,
    { organization: string; project: string; tokenId: string; data: WriteTokenUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsUpdateWriteToken>>,
  TError,
  { organization: string; project: string; tokenId: string; data: WriteTokenUpdate },
  TContext
> => {
  const mutationOptions = getProjectsUpdateWriteTokenMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Projects Delete Write Token
 */
export const projectsDeleteWriteToken = (
  organization: string,
  project: string,
  tokenId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.default.delete(
    `/dash/organizations/${organization}/projects/${project}/write-tokens/${tokenId}`,
    options,
  )
}

export const getProjectsDeleteWriteTokenMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsDeleteWriteToken>>,
    TError,
    { organization: string; project: string; tokenId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsDeleteWriteToken>>,
  TError,
  { organization: string; project: string; tokenId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsDeleteWriteToken>>,
    { organization: string; project: string; tokenId: string }
  > = (props) => {
    const { organization, project, tokenId } = props ?? {}

    return projectsDeleteWriteToken(organization, project, tokenId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsDeleteWriteTokenMutationResult = NonNullable<Awaited<ReturnType<typeof projectsDeleteWriteToken>>>

export type ProjectsDeleteWriteTokenMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Delete Write Token
 */
export const useProjectsDeleteWriteToken = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsDeleteWriteToken>>,
    TError,
    { organization: string; project: string; tokenId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsDeleteWriteToken>>,
  TError,
  { organization: string; project: string; tokenId: string },
  TContext
> => {
  const mutationOptions = getProjectsDeleteWriteTokenMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * This function creates a read token for a project. The read token allows you to read data from your project.
 * @summary Projects Create Read Token
 */
export const projectsCreateReadToken = (
  organization: string,
  project: string,
  readTokenCreate: ReadTokenCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<string>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/read-tokens`,
    readTokenCreate,
    options,
  )
}

export const getProjectsCreateReadTokenMutationOptions = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsCreateReadToken>>,
    TError,
    { organization: string; project: string; data: ReadTokenCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsCreateReadToken>>,
  TError,
  { organization: string; project: string; data: ReadTokenCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsCreateReadToken>>,
    { organization: string; project: string; data: ReadTokenCreate }
  > = (props) => {
    const { organization, project, data } = props ?? {}

    return projectsCreateReadToken(organization, project, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsCreateReadTokenMutationResult = NonNullable<Awaited<ReturnType<typeof projectsCreateReadToken>>>
export type ProjectsCreateReadTokenMutationBody = ReadTokenCreate
export type ProjectsCreateReadTokenMutationError = AxiosError<HTTPExceptionError | HTTPValidationError>

/**
 * @summary Projects Create Read Token
 */
export const useProjectsCreateReadToken = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsCreateReadToken>>,
    TError,
    { organization: string; project: string; data: ReadTokenCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsCreateReadToken>>,
  TError,
  { organization: string; project: string; data: ReadTokenCreate },
  TContext
> => {
  const mutationOptions = getProjectsCreateReadTokenMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Projects Get Read Tokens
 */
export const projectsGetReadTokens = (
  organization: string,
  project: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ReadTokenRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/read-tokens`, options)
}

export const getProjectsGetReadTokensQueryKey = (organization: string, project: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/read-tokens`] as const
}

export const getProjectsGetReadTokensQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetReadTokens>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetReadTokens>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetReadTokensQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetReadTokens>>> = ({ signal }) =>
    projectsGetReadTokens(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsGetReadTokens>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectsGetReadTokensQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetReadTokens>>>
export type ProjectsGetReadTokensQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Read Tokens
 */
export const useProjectsGetReadTokens = <
  TData = Awaited<ReturnType<typeof projectsGetReadTokens>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetReadTokens>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetReadTokensQueryOptions(organization, project, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getProjectsGetReadTokensSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetReadTokens>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetReadTokens>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetReadTokensQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetReadTokens>>> = ({ signal }) =>
    projectsGetReadTokens(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof projectsGetReadTokens>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectsGetReadTokensSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetReadTokens>>>
export type ProjectsGetReadTokensSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Read Tokens
 */
export const useProjectsGetReadTokensSuspense = <
  TData = Awaited<ReturnType<typeof projectsGetReadTokens>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetReadTokens>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetReadTokensSuspenseQueryOptions(organization, project, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Projects Get Read Token
 */
export const projectsGetReadToken = (
  organization: string,
  project: string,
  tokenId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ReadTokenRead>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/read-tokens/${tokenId}`, options)
}

export const getProjectsGetReadTokenQueryKey = (organization: string, project: string, tokenId: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/read-tokens/${tokenId}`] as const
}

export const getProjectsGetReadTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetReadToken>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  tokenId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetReadToken>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetReadTokenQueryKey(organization, project, tokenId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetReadToken>>> = ({ signal }) =>
    projectsGetReadToken(organization, project, tokenId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project && tokenId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof projectsGetReadToken>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ProjectsGetReadTokenQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetReadToken>>>
export type ProjectsGetReadTokenQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Read Token
 */
export const useProjectsGetReadToken = <
  TData = Awaited<ReturnType<typeof projectsGetReadToken>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  tokenId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetReadToken>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetReadTokenQueryOptions(organization, project, tokenId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getProjectsGetReadTokenSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetReadToken>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  tokenId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetReadToken>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetReadTokenQueryKey(organization, project, tokenId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetReadToken>>> = ({ signal }) =>
    projectsGetReadToken(organization, project, tokenId, { signal, ...axiosOptions })

  return {
    queryKey,
    queryFn,
    enabled: !!(organization && project && tokenId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetReadToken>>, TError, TData> & {
    queryKey: QueryKey
  }
}

export type ProjectsGetReadTokenSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetReadToken>>>
export type ProjectsGetReadTokenSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Read Token
 */
export const useProjectsGetReadTokenSuspense = <
  TData = Awaited<ReturnType<typeof projectsGetReadToken>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  tokenId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetReadToken>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetReadTokenSuspenseQueryOptions(organization, project, tokenId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Projects Update Read Token
 */
export const projectsUpdateReadToken = (
  organization: string,
  project: string,
  tokenId: string,
  readTokenUpdate: ReadTokenUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ReadTokenRead>> => {
  return axios.default.put(
    `/dash/organizations/${organization}/projects/${project}/read-tokens/${tokenId}`,
    readTokenUpdate,
    options,
  )
}

export const getProjectsUpdateReadTokenMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsUpdateReadToken>>,
    TError,
    { organization: string; project: string; tokenId: string; data: ReadTokenUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsUpdateReadToken>>,
  TError,
  { organization: string; project: string; tokenId: string; data: ReadTokenUpdate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsUpdateReadToken>>,
    { organization: string; project: string; tokenId: string; data: ReadTokenUpdate }
  > = (props) => {
    const { organization, project, tokenId, data } = props ?? {}

    return projectsUpdateReadToken(organization, project, tokenId, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsUpdateReadTokenMutationResult = NonNullable<Awaited<ReturnType<typeof projectsUpdateReadToken>>>
export type ProjectsUpdateReadTokenMutationBody = ReadTokenUpdate
export type ProjectsUpdateReadTokenMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Update Read Token
 */
export const useProjectsUpdateReadToken = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsUpdateReadToken>>,
    TError,
    { organization: string; project: string; tokenId: string; data: ReadTokenUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsUpdateReadToken>>,
  TError,
  { organization: string; project: string; tokenId: string; data: ReadTokenUpdate },
  TContext
> => {
  const mutationOptions = getProjectsUpdateReadTokenMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Projects Delete Read Token
 */
export const projectsDeleteReadToken = (
  organization: string,
  project: string,
  tokenId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.default.delete(
    `/dash/organizations/${organization}/projects/${project}/read-tokens/${tokenId}`,
    options,
  )
}

export const getProjectsDeleteReadTokenMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsDeleteReadToken>>,
    TError,
    { organization: string; project: string; tokenId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsDeleteReadToken>>,
  TError,
  { organization: string; project: string; tokenId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsDeleteReadToken>>,
    { organization: string; project: string; tokenId: string }
  > = (props) => {
    const { organization, project, tokenId } = props ?? {}

    return projectsDeleteReadToken(organization, project, tokenId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsDeleteReadTokenMutationResult = NonNullable<Awaited<ReturnType<typeof projectsDeleteReadToken>>>

export type ProjectsDeleteReadTokenMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Delete Read Token
 */
export const useProjectsDeleteReadToken = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsDeleteReadToken>>,
    TError,
    { organization: string; project: string; tokenId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsDeleteReadToken>>,
  TError,
  { organization: string; project: string; tokenId: string },
  TContext
> => {
  const mutationOptions = getProjectsDeleteReadTokenMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Projects Create Invitation
 */
export const projectsCreateInvitation = (
  organization: string,
  project: string,
  projectInvitationCreate: ProjectInvitationCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectInvitationRead>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/invitations`,
    projectInvitationCreate,
    options,
  )
}

export const getProjectsCreateInvitationMutationOptions = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsCreateInvitation>>,
    TError,
    { organization: string; project: string; data: ProjectInvitationCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsCreateInvitation>>,
  TError,
  { organization: string; project: string; data: ProjectInvitationCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsCreateInvitation>>,
    { organization: string; project: string; data: ProjectInvitationCreate }
  > = (props) => {
    const { organization, project, data } = props ?? {}

    return projectsCreateInvitation(organization, project, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsCreateInvitationMutationResult = NonNullable<Awaited<ReturnType<typeof projectsCreateInvitation>>>
export type ProjectsCreateInvitationMutationBody = ProjectInvitationCreate
export type ProjectsCreateInvitationMutationError = AxiosError<HTTPExceptionError | HTTPValidationError>

/**
 * @summary Projects Create Invitation
 */
export const useProjectsCreateInvitation = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsCreateInvitation>>,
    TError,
    { organization: string; project: string; data: ProjectInvitationCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsCreateInvitation>>,
  TError,
  { organization: string; project: string; data: ProjectInvitationCreate },
  TContext
> => {
  const mutationOptions = getProjectsCreateInvitationMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary List Project Invitations
 */
export const listProjectInvitations = (
  organization: string,
  project: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectInvitationRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/invitations`, options)
}

export const getListProjectInvitationsQueryKey = (organization: string, project: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/invitations`] as const
}

export const getListProjectInvitationsQueryOptions = <
  TData = Awaited<ReturnType<typeof listProjectInvitations>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectInvitations>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getListProjectInvitationsQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjectInvitations>>> = ({ signal }) =>
    listProjectInvitations(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listProjectInvitations>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListProjectInvitationsQueryResult = NonNullable<Awaited<ReturnType<typeof listProjectInvitations>>>
export type ListProjectInvitationsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary List Project Invitations
 */
export const useListProjectInvitations = <
  TData = Awaited<ReturnType<typeof listProjectInvitations>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectInvitations>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListProjectInvitationsQueryOptions(organization, project, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getListProjectInvitationsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof listProjectInvitations>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listProjectInvitations>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getListProjectInvitationsQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjectInvitations>>> = ({ signal }) =>
    listProjectInvitations(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof listProjectInvitations>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListProjectInvitationsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listProjectInvitations>>>
export type ListProjectInvitationsSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary List Project Invitations
 */
export const useListProjectInvitationsSuspense = <
  TData = Awaited<ReturnType<typeof listProjectInvitations>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listProjectInvitations>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListProjectInvitationsSuspenseQueryOptions(organization, project, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Projects Get Invitation
 */
export const projectsGetInvitation = (
  organization: string,
  project: string,
  invitationId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectInvitationRead>> => {
  return axios.default.get(
    `/dash/organizations/${organization}/projects/${project}/invitations/${invitationId}`,
    options,
  )
}

export const getProjectsGetInvitationQueryKey = (organization: string, project: string, invitationId: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/invitations/${invitationId}`] as const
}

export const getProjectsGetInvitationQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetInvitation>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  invitationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetInvitation>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetInvitationQueryKey(organization, project, invitationId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetInvitation>>> = ({ signal }) =>
    projectsGetInvitation(organization, project, invitationId, { signal, ...axiosOptions })

  return {
    queryKey,
    queryFn,
    enabled: !!(organization && project && invitationId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof projectsGetInvitation>>, TError, TData> & { queryKey: QueryKey }
}

export type ProjectsGetInvitationQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetInvitation>>>
export type ProjectsGetInvitationQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Invitation
 */
export const useProjectsGetInvitation = <
  TData = Awaited<ReturnType<typeof projectsGetInvitation>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  invitationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof projectsGetInvitation>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetInvitationQueryOptions(organization, project, invitationId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getProjectsGetInvitationSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof projectsGetInvitation>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  invitationId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetInvitation>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProjectsGetInvitationQueryKey(organization, project, invitationId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof projectsGetInvitation>>> = ({ signal }) =>
    projectsGetInvitation(organization, project, invitationId, { signal, ...axiosOptions })

  return {
    queryKey,
    queryFn,
    enabled: !!(organization && project && invitationId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetInvitation>>, TError, TData> & {
    queryKey: QueryKey
  }
}

export type ProjectsGetInvitationSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof projectsGetInvitation>>>
export type ProjectsGetInvitationSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Get Invitation
 */
export const useProjectsGetInvitationSuspense = <
  TData = Awaited<ReturnType<typeof projectsGetInvitation>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  invitationId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof projectsGetInvitation>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProjectsGetInvitationSuspenseQueryOptions(organization, project, invitationId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Projects Revoke Invitation
 */
export const projectsRevokeInvitation = (
  organization: string,
  project: string,
  invitationId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/invitations/${invitationId}/revoke`,
    undefined,
    options,
  )
}

export const getProjectsRevokeInvitationMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsRevokeInvitation>>,
    TError,
    { organization: string; project: string; invitationId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof projectsRevokeInvitation>>,
  TError,
  { organization: string; project: string; invitationId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof projectsRevokeInvitation>>,
    { organization: string; project: string; invitationId: string }
  > = (props) => {
    const { organization, project, invitationId } = props ?? {}

    return projectsRevokeInvitation(organization, project, invitationId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ProjectsRevokeInvitationMutationResult = NonNullable<Awaited<ReturnType<typeof projectsRevokeInvitation>>>

export type ProjectsRevokeInvitationMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Projects Revoke Invitation
 */
export const useProjectsRevokeInvitation = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof projectsRevokeInvitation>>,
    TError,
    { organization: string; project: string; invitationId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof projectsRevokeInvitation>>,
  TError,
  { organization: string; project: string; invitationId: string },
  TContext
> => {
  const mutationOptions = getProjectsRevokeInvitationMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Claim Project Invitation
 */
export const claimProjectInvitation = (
  organization: string,
  project: string,
  invitationId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/invitations/${invitationId}/claim`,
    undefined,
    options,
  )
}

export const getClaimProjectInvitationMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimProjectInvitation>>,
    TError,
    { organization: string; project: string; invitationId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof claimProjectInvitation>>,
  TError,
  { organization: string; project: string; invitationId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof claimProjectInvitation>>,
    { organization: string; project: string; invitationId: string }
  > = (props) => {
    const { organization, project, invitationId } = props ?? {}

    return claimProjectInvitation(organization, project, invitationId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ClaimProjectInvitationMutationResult = NonNullable<Awaited<ReturnType<typeof claimProjectInvitation>>>

export type ClaimProjectInvitationMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Claim Project Invitation
 */
export const useClaimProjectInvitation = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimProjectInvitation>>,
    TError,
    { organization: string; project: string; invitationId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof claimProjectInvitation>>,
  TError,
  { organization: string; project: string; invitationId: string },
  TContext
> => {
  const mutationOptions = getClaimProjectInvitationMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Logfire
 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import * as axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type { AutoComplete, AutocompleteGetParams, HTTPValidationError } from '.././model'

/**
 * @summary Autocomplete Get
 */
export const autocompleteGet = (
  organization: string,
  project: string,
  params?: AutocompleteGetParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<AutoComplete>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/autocomplete`, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getAutocompleteGetQueryKey = (organization: string, project: string, params?: AutocompleteGetParams) => {
  return [`/dash/organizations/${organization}/projects/${project}/autocomplete`, ...(params ? [params] : [])] as const
}

export const getAutocompleteGetQueryOptions = <
  TData = Awaited<ReturnType<typeof autocompleteGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  params?: AutocompleteGetParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAutocompleteGetQueryKey(organization, project, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof autocompleteGet>>> = ({ signal }) =>
    autocompleteGet(organization, project, params, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof autocompleteGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AutocompleteGetQueryResult = NonNullable<Awaited<ReturnType<typeof autocompleteGet>>>
export type AutocompleteGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Autocomplete Get
 */
export const useAutocompleteGet = <
  TData = Awaited<ReturnType<typeof autocompleteGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  params?: AutocompleteGetParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof autocompleteGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAutocompleteGetQueryOptions(organization, project, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getAutocompleteGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof autocompleteGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  params?: AutocompleteGetParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof autocompleteGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAutocompleteGetQueryKey(organization, project, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof autocompleteGet>>> = ({ signal }) =>
    autocompleteGet(organization, project, params, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof autocompleteGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AutocompleteGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof autocompleteGet>>>
export type AutocompleteGetSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Autocomplete Get
 */
export const useAutocompleteGetSuspense = <
  TData = Awaited<ReturnType<typeof autocompleteGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  params?: AutocompleteGetParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof autocompleteGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAutocompleteGetSuspenseQueryOptions(organization, project, params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

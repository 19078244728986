import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TimelineResponse, RecordSummary } from '@/packages/api/__generated__/model'
import { LogLevelLabel } from '@/store/TraceManagerV2/utils'
import { AppState } from '@/store/index'
import { SpanId, TraceId } from '@/packages/log'

import { ClientRecordSummary } from './TraceManagerV2/types'

export interface ScrollViewSettings {
  showTags: boolean
  showSpansOnly: boolean
  expandToMatches: boolean
  flatView: boolean
  recordDetailsPanelGroupDirection: 'vertical' | 'horizontal'
  timelinePosition: 'left' | 'top'
}

export interface SelectedRecord {
  kind: 'record'
  traceId: TraceId
  record: ClientRecordSummary
  worstDescendantLevel: RecordSummary['level']
  traceRecords: ClientRecordSummary[]
}

export interface SelectedGroup {
  kind: 'group'
  traceId: TraceId
  groupId: string
  records: ClientRecordSummary[]
  worstDescendantLevel: RecordSummary['level']
  traceRecords: ClientRecordSummary[]
}

export interface SelectedTracePlaceholder {
  kind: 'tracePlaceholder'
  traceId: TraceId
  roots: ClientRecordSummary[]
  worstDescendantLevel: RecordSummary['level']
  traceRecords: ClientRecordSummary[]
  placeHolderId: SpanId
}

export interface VisibleTimeRange {
  minVisibleTimestamp: number
  maxVisibleTimestamp: number
}

export interface VisibilityFilteringSettings {
  services: string[]
  scopeSlugs: string[]
  tags: string[]
  logLevels: LogLevelLabel[]
}

// State
export interface LogsState {
  // Settings
  settings: ScrollViewSettings

  // Timeline
  timeline: {
    data: TimelineResponse['data']
    isLoading: boolean
    isError: boolean
    errorMessage?: string
    brushTimeSelection: null | {
      since: number
      until: number
    }
  }

  // Connection state — used to display a status/error message in the UI
  connectionState: {
    liveConnected: boolean
    liveErrorMessage?: string | undefined
    historicalErrorMessage?: string | undefined
  }

  // The focus timestamp is set when clicking on the timeline to jump to a specific historical point in the logs
  focusTimestamp: string | null

  // These are the min/max timestamps visible on screen in the logs scroll, used for determining what to display
  // as the visible area in the timeline
  visibleTimeRange: VisibleTimeRange | null

  // The state of the live-streaming connection
  isLive: boolean

  maybeHasOlder: boolean
  maybeHasNewer: boolean

  // Selection
  selection: SelectedRecord | SelectedGroup | SelectedTracePlaceholder | undefined

  // Visibility filtering
  visibilityFiltering: VisibilityFilteringSettings
}

export const ALL_LOG_LEVELS: LogLevelLabel[] = ['trace', 'debug', 'info', 'notice', 'warn', 'error', 'fatal', 'null']
export const DEFAULT_LOG_LEVELS: LogLevelLabel[] = ['info', 'notice', 'warn', 'error', 'fatal', 'null']

const initialState = (): LogsState => {
  const searchParams = new URLSearchParams(window.location.search)

  const shouldNotBeLive = searchParams.has('since') && searchParams.has('until')

  return {
    settings: {
      showTags: true,
      showSpansOnly: false,
      expandToMatches: true,
      flatView: false,
      recordDetailsPanelGroupDirection: 'horizontal',
      timelinePosition: 'top',
    },
    timeline: { data: [], isLoading: false, isError: false, brushTimeSelection: null },
    connectionState: { liveConnected: false },
    isLive: !shouldNotBeLive,
    maybeHasOlder: true,
    maybeHasNewer: true,
    focusTimestamp: null,
    visibleTimeRange: null,
    selection: undefined,

    // visibility
    visibilityFiltering: {
      services: [],
      scopeSlugs: [],
      tags: [],
      logLevels: DEFAULT_LOG_LEVELS,
    },
  }
}

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setSettings(state, data: { payload: Partial<ScrollViewSettings> }) {
      state.settings = { ...state.settings, ...data.payload }
    },
    setBrushSelection: (state, action: PayloadAction<{ since: number; until: number } | null>) => {
      if (action.payload === null) {
        state.timeline.brushTimeSelection = null
      } else {
        state.timeline.brushTimeSelection = {
          since: action.payload.since,
          until: action.payload.until,
        }
      }
    },
    setTimeline: (
      state,
      data: {
        payload: { data?: TimelineResponse['data']; isLoading?: boolean; isError?: boolean; errorMessage?: string }
      },
    ) => {
      state.timeline = {
        ...state.timeline,
        ...data.payload,
        // TODO: Is the following line really necessary?
        data: data.payload.data ? data.payload.data : state.timeline.data,
      }
    },
    setConnectionState: (state, data: { payload: Partial<LogsState['connectionState']> }) => {
      state.connectionState = { ...state.connectionState, ...data.payload }
    },
    disconnectDueToUserError: (state, data: { payload: string }) => {
      state.isLive = false
      state.connectionState = {
        ...state.connectionState,
        liveConnected: false,
        liveErrorMessage: data.payload,
      }
    },
    setSelection: (state, data: { payload: LogsState['selection'] }) => {
      state.selection = data.payload
    },
    setFocusTimestamp: (state, data: { payload: string | null }) => {
      state.focusTimestamp = data.payload
    },
    setVisibleTimeRange(state, data: { payload: VisibleTimeRange }) {
      state.visibleTimeRange = data.payload
    },
    setVisibilityFiltering: (state, data: { payload: Partial<VisibilityFilteringSettings> }) => {
      state.visibilityFiltering = { ...state.visibilityFiltering, ...data.payload }
    },
    setIsLive: (state, data: { payload: boolean }) => {
      state.isLive = data.payload
    },
    setHasOlder: (state, data: { payload: boolean }) => {
      state.maybeHasOlder = data.payload
    },
    setHasNewer: (state, data: { payload: boolean }) => {
      state.maybeHasNewer = data.payload
    },
    resetOlderNewer: (state) => {
      state.maybeHasOlder = true
      state.maybeHasNewer = true
    },
  },
})

// Actions
// Unpacking and repacking the logsSlice.actions is a workaround for a bug in PyCharm that causes
// it to mis-resolve the actions as the reducer functions.
const {
  disconnectDueToUserError,
  resetOlderNewer,
  setBrushSelection,
  setConnectionState,
  setFocusTimestamp,
  setHasNewer,
  setHasOlder,
  setIsLive,
  setSelection,
  setSettings,
  setTimeline,
  setVisibilityFiltering,
  setVisibleTimeRange,
} = logsSlice.actions

export const logsActions = {
  disconnectDueToUserError,
  setSettings,
  setTimeline,
  setConnectionState,
  setSelection,
  setFocusTimestamp,
  setVisibleTimeRange,
  setIsLive,
  setVisibilityFiltering,
  setBrushSelection,
  setHasOlder,
  setHasNewer,
  resetOlderNewer,
}

const selectScrollHasError = (state: AppState) => {
  const connectionState = state.logs.connectionState
  return Boolean(connectionState.liveErrorMessage ?? connectionState.historicalErrorMessage)
}
const selectStateMessage = (state: AppState) => {
  const connectionState = state.logs.connectionState

  if (connectionState.liveErrorMessage) {
    return connectionState.liveErrorMessage
  }

  if (connectionState.historicalErrorMessage) {
    return `${connectionState.historicalErrorMessage}. Change your query (it should be just a where clause) and try again.`
  }

  if (connectionState.liveConnected) {
    return 'Connected'
  }

  return ''
}
const selectIsLive = (state: AppState) => state.logs.isLive
const selectBrushTimeSelection = (state: AppState) => state.logs.timeline.brushTimeSelection
export const logsSelectors = {
  selectScrollHasError,
  selectStateMessage,
  selectIsLive,
  selectBrushTimeSelection,
}

import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '@/context/auth'
import { ROUTES } from '@/packages/router/routes'
import { LoadingLogo } from '@/components/LoadingLogo'

export const AlreadyAuthed = () => {
  const { isReady, isAuthenticated, user } = useAuth()
  const { state } = useLocation()

  let redirectTo = state?.state?.redirectTo

  if (redirectTo === ROUTES.AUTH.path) {
    // This should mean that we are handling a log in from github with the default redirectUrl
    // so we want to redirect the user to their default organization's landing page
    const organizationName =
      state.user.default_organization?.organization_name ??
      state.organizations?.[0].organization_name ??
      state.user.name
    // This console.info line is important since this is the only place we explicitly ignore/modify the redirectTo
    // value, and the console.info provides a way to discover where in the code this modification to the redirectTo
    // is happening (intended to help with debugging). Please don't remove the log line unless you also remove the
    // modification to the redirectTo value.
    console.info('Redirecting to organization', organizationName)
    redirectTo = ROUTES.ORGANIZATION.buildPath({ organizationName })
  }

  if (!isReady) {
    return <LoadingLogo />
  }

  if (isAuthenticated && redirectTo) {
    return <Navigate to={redirectTo} replace />
  } else if (isAuthenticated && !redirectTo && user) {
    return (
      <>
        <LoadingLogo />
        <Navigate
          to={ROUTES.ORGANIZATION.buildPath({ organizationName: user.defaultOrganizationName ?? user.name })}
          replace
        />
      </>
    )
  }
  return <Outlet />
}

import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { add, format } from 'date-fns'
import { useState } from 'react'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import { getListProjectInvitationsQueryKey, queryClient, useProjectsCreateInvitation } from '@/packages/api'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@/components/shadcn/ui/dialog'
import { Form } from '@/components/shadcn/ui/form'
import { ROUTES } from '@/packages/router/routes'
import { Button } from '@/components/shadcn/ui/button'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { LoadingButton } from '@/components/Button'
import { Alert } from '@/components/shadcn/ui/alert'

const createProjectInvitationFormSchema = z.object({
  max_usage_count: z.string().refine(
    (value) => {
      return parseInt(value) > 0
    },
    { message: 'Max usage count must be greater than 0' },
  ),
  expiration: z.enum(['1', '3', '7', '30']),
})

const createExpirationTime = (expirationInDays: number) => {
  return format(add(new Date(), { days: expirationInDays }), 'yyyy-MM-dd HH:mm')
}

const expirationOptions = [
  { value: '1', label: '1 day', className: 'peer/' },
  { value: '3', label: '3 days' },
  { value: '7', label: '1 week' },
  { value: '30', label: '1 month' },
]

type CreateProjectInvitationForm = z.infer<typeof createProjectInvitationFormSchema>

export const CreateProjectInvitation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS.INVITATIONS)
  const mutation = useProjectsCreateInvitation()
  const { toast } = useToast()

  const form = useForm<CreateProjectInvitationForm>({
    resolver: zodResolver(createProjectInvitationFormSchema),
    defaultValues: {
      max_usage_count: '100',
      expiration: '7',
    },
  })

  const onSubmit = (formData: CreateProjectInvitationForm) => {
    mutation.mutate(
      {
        organization: organizationName,
        project: projectName,
        data: {
          max_usage_count: parseInt(formData.max_usage_count),
          expiration: createExpirationTime(Number(formData.expiration)),
        },
      },
      {
        onSuccess: () => {
          form.reset()
          const projectInvitationsQuery = getListProjectInvitationsQueryKey(organizationName, projectName)
          queryClient.invalidateQueries({ queryKey: projectInvitationsQuery }).then(() => {
            mutation.reset()
            setIsOpen(false)
          })
        },
        onError: (error) => {
          const errorMessage =
            typeof error.response?.data.detail === 'string'
              ? error.response.data.detail
              : 'An error occurred. Please try again.'
          toast({
            title: 'Error',
            description: errorMessage,
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button>New project invitation</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Invite to Project</DialogTitle>
        <DialogDescription>
          <p className="text-base">
            Generate a project invitation link to add people to this project. Use this link up to 100 times until it
            expires.
          </p>
          <p className="mt-4 text-sm italic">
            This invitate link does not add people to the organization. If they are not already a member they join as a
            guest.
          </p>
        </DialogDescription>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col space-y-4">
              <p className="text-sm font-semibold">Link expires in</p>
              <ul className="flex gap-x-2">
                {expirationOptions.map((option) => (
                  <li key={option.value} className="relative">
                    <input
                      {...form.register('expiration')}
                      type="radio"
                      className="peer sr-only"
                      value={option.value}
                      id={option.value}
                    />
                    <label
                      className="cursor-pointer rounded-md p-3 py-1 shadow-md outline outline-outline transition-all peer-checked:outline-primary"
                      htmlFor={option.value}
                    >
                      {option.label}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <DialogFooter className="mt-6 sm:justify-start">
              <LoadingButton
                type="submit"
                isLoading={mutation.isError}
                disabled={mutation.isPending || mutation.isSuccess || mutation.isError}
              >
                Create
              </LoadingButton>
              <Button variant="outline" type="reset" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            </DialogFooter>
          </form>
          {mutation.isError && (
            <section>
              <Alert variant="destructive">An error occurred while creating the invitation. Please try again.</Alert>
            </section>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  )
}

import { githubLightInit } from '@uiw/codemirror-theme-github'
import { draculaInit } from '@uiw/codemirror-theme-dracula'
import { tags as t } from '@lezer/highlight'

import { Theme, useTheme } from '@/packages/theme/useTheme'

const fontFamily = "'IBM Plex Mono', monospace "

const lightTheme = githubLightInit({
  settings: {
    background: 'transparent',
    fontFamily,
  },
})

const darkTheme = draculaInit({
  settings: {
    background: 'transparent',
    selection: '#0c4a6e',
    fontFamily,
  },
  styles: [
    { tag: t.keyword, color: '#E2A4E7' },
    { tag: t.string, color: '#D3FA63' },
  ],
})

export function useCodemirrorTheme() {
  const { theme } = useTheme()
  return theme === Theme.dark ? darkTheme : lightTheme
}

import { useTypedParams } from 'react-router-typesafe-routes/dom'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/shadcn/ui/alert-dialog'
import { ROUTES } from '@/packages/router/routes'
import { getListProjectInvitationsQueryKey, queryClient, useProjectsRevokeInvitation } from '@/packages/api'
import { usePageTitle } from '@/hooks/usePageTitle'

interface RevokeProjectInvitationProps {
  invitationId: string | null
  setInvitationId: React.Dispatch<React.SetStateAction<string | null>>
}

export const RevokeProjectInvitation = ({ invitationId, setInvitationId }: RevokeProjectInvitationProps) => {
  usePageTitle('Revoke Invitation')
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS.INVITATIONS)
  const mutation = useProjectsRevokeInvitation()
  const revokeInvitation = () => {
    if (!invitationId) return
    mutation.mutate(
      {
        organization: organizationName,
        project: projectName,
        invitationId,
      },
      {
        onSuccess: () => {
          const projectInvitationsQuery = getListProjectInvitationsQueryKey(organizationName, projectName)
          queryClient.invalidateQueries({ queryKey: projectInvitationsQuery }).then(() => {
            setInvitationId(null)
          })
        },
      },
    )
  }
  return (
    <AlertDialog
      open={Boolean(invitationId)}
      onOpenChange={(open) => {
        if (!open) {
          setInvitationId(null)
        }
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Revoke the invitation</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to revoke the invitation? This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={revokeInvitation} disabled={mutation.isPending}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

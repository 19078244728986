interface ProjectIconProps {
  className?: string
}

export const ProjectIcon = (props: ProjectIconProps) => {
  return (
    <svg viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
      <path
        d="M168 39.4668H24C19.6 39.4668 16 43.0668 16 47.4668V119.467H32V135.467H48V151.467H168C172.4 151.467 176 147.867 176 143.467V47.4668C176 43.0668 172.4 39.4668 168 39.4668ZM72 67.4668H144V75.4668H72V67.4668ZM44 83.4668L32 71.4668L44 59.4668L56 71.4668L44 83.4668ZM64 107.467L52 95.4668L64 83.4668L76 95.4668L64 107.467ZM160 99.4668H92V91.4668H160V99.4668Z"
        fill="currentColor"
      />
    </svg>
  )
}

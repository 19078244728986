/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Logfire
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import * as axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type {
  AlertCreate,
  AlertRead,
  AlertRunRead,
  AlertTimelineRecord,
  AlertUpdate,
  AlertWithLastRun,
  GetAlertHistoryParams,
  GetAlertTimelineParams,
  HTTPValidationError,
} from '.././model'

/**
 * List all alerts for a project.
 * @summary List Alerts
 */
export const listAlerts = (
  organization: string,
  project: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<AlertWithLastRun[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/alerts/`, options)
}

export const getListAlertsQueryKey = (organization: string, project: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/alerts/`] as const
}

export const getListAlertsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAlerts>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listAlerts>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getListAlertsQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAlerts>>> = ({ signal }) =>
    listAlerts(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAlerts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListAlertsQueryResult = NonNullable<Awaited<ReturnType<typeof listAlerts>>>
export type ListAlertsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary List Alerts
 */
export const useListAlerts = <
  TData = Awaited<ReturnType<typeof listAlerts>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listAlerts>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAlertsQueryOptions(organization, project, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getListAlertsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof listAlerts>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAlerts>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getListAlertsQueryKey(organization, project)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAlerts>>> = ({ signal }) =>
    listAlerts(organization, project, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof listAlerts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListAlertsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listAlerts>>>
export type ListAlertsSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary List Alerts
 */
export const useListAlertsSuspense = <
  TData = Awaited<ReturnType<typeof listAlerts>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAlerts>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAlertsSuspenseQueryOptions(organization, project, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a new alert.

The newly created alert will automatically be set to "active".
 * @summary Create Alert
 */
export const createAlert = (
  organization: string,
  project: string,
  alertCreate: AlertCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<AlertRead>> => {
  return axios.default.post(`/dash/organizations/${organization}/projects/${project}/alerts/`, alertCreate, options)
}

export const getCreateAlertMutationOptions = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAlert>>,
    TError,
    { organization: string; project: string; data: AlertCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAlert>>,
  TError,
  { organization: string; project: string; data: AlertCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAlert>>,
    { organization: string; project: string; data: AlertCreate }
  > = (props) => {
    const { organization, project, data } = props ?? {}

    return createAlert(organization, project, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateAlertMutationResult = NonNullable<Awaited<ReturnType<typeof createAlert>>>
export type CreateAlertMutationBody = AlertCreate
export type CreateAlertMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Create Alert
 */
export const useCreateAlert = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAlert>>,
    TError,
    { organization: string; project: string; data: AlertCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof createAlert>>,
  TError,
  { organization: string; project: string; data: AlertCreate },
  TContext
> => {
  const mutationOptions = getCreateAlertMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Update an alert.
 * @summary Update Alert
 */
export const updateAlert = (
  organization: string,
  project: string,
  alertId: string,
  alertUpdate: AlertUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<AlertRead>> => {
  return axios.default.put(
    `/dash/organizations/${organization}/projects/${project}/alerts/${alertId}`,
    alertUpdate,
    options,
  )
}

export const getUpdateAlertMutationOptions = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAlert>>,
    TError,
    { organization: string; project: string; alertId: string; data: AlertUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAlert>>,
  TError,
  { organization: string; project: string; alertId: string; data: AlertUpdate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAlert>>,
    { organization: string; project: string; alertId: string; data: AlertUpdate }
  > = (props) => {
    const { organization, project, alertId, data } = props ?? {}

    return updateAlert(organization, project, alertId, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAlertMutationResult = NonNullable<Awaited<ReturnType<typeof updateAlert>>>
export type UpdateAlertMutationBody = AlertUpdate
export type UpdateAlertMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Update Alert
 */
export const useUpdateAlert = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAlert>>,
    TError,
    { organization: string; project: string; alertId: string; data: AlertUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAlert>>,
  TError,
  { organization: string; project: string; alertId: string; data: AlertUpdate },
  TContext
> => {
  const mutationOptions = getUpdateAlertMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Delete an alert.
 * @summary Delete Alert
 */
export const deleteAlert = (
  organization: string,
  project: string,
  alertId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.default.delete(`/dash/organizations/${organization}/projects/${project}/alerts/${alertId}`, options)
}

export const getDeleteAlertMutationOptions = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAlert>>,
    TError,
    { organization: string; project: string; alertId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAlert>>,
  TError,
  { organization: string; project: string; alertId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAlert>>,
    { organization: string; project: string; alertId: string }
  > = (props) => {
    const { organization, project, alertId } = props ?? {}

    return deleteAlert(organization, project, alertId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteAlertMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAlert>>>

export type DeleteAlertMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Delete Alert
 */
export const useDeleteAlert = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAlert>>,
    TError,
    { organization: string; project: string; alertId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAlert>>,
  TError,
  { organization: string; project: string; alertId: string },
  TContext
> => {
  const mutationOptions = getDeleteAlertMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Get an alert.
 * @summary Get Alert
 */
export const getAlert = (
  organization: string,
  project: string,
  alertId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<AlertWithLastRun>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/alerts/${alertId}`, options)
}

export const getGetAlertQueryKey = (organization: string, project: string, alertId: string) => {
  return [`/dash/organizations/${organization}/projects/${project}/alerts/${alertId}`] as const
}

export const getGetAlertQueryOptions = <
  TData = Awaited<ReturnType<typeof getAlert>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAlert>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAlertQueryKey(organization, project, alertId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlert>>> = ({ signal }) =>
    getAlert(organization, project, alertId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project && alertId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAlert>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAlertQueryResult = NonNullable<Awaited<ReturnType<typeof getAlert>>>
export type GetAlertQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Alert
 */
export const useGetAlert = <TData = Awaited<ReturnType<typeof getAlert>>, TError = AxiosError<HTTPValidationError>>(
  organization: string,
  project: string,
  alertId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAlert>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAlertQueryOptions(organization, project, alertId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetAlertSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAlert>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAlert>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAlertQueryKey(organization, project, alertId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlert>>> = ({ signal }) =>
    getAlert(organization, project, alertId, { signal, ...axiosOptions })

  return {
    queryKey,
    queryFn,
    enabled: !!(organization && project && alertId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAlert>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAlertSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAlert>>>
export type GetAlertSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Alert
 */
export const useGetAlertSuspense = <
  TData = Awaited<ReturnType<typeof getAlert>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAlert>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAlertSuspenseQueryOptions(organization, project, alertId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get the history of an alert.
 * @summary Get Alert History
 */
export const getAlertHistory = (
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertHistoryParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<AlertRunRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/alerts/${alertId}/history`, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getGetAlertHistoryQueryKey = (
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertHistoryParams,
) => {
  return [
    `/dash/organizations/${organization}/projects/${project}/alerts/${alertId}/history`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetAlertHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getAlertHistory>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertHistoryParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAlertHistory>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAlertHistoryQueryKey(organization, project, alertId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertHistory>>> = ({ signal }) =>
    getAlertHistory(organization, project, alertId, params, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project && alertId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAlertHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAlertHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getAlertHistory>>>
export type GetAlertHistoryQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Alert History
 */
export const useGetAlertHistory = <
  TData = Awaited<ReturnType<typeof getAlertHistory>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertHistoryParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAlertHistory>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAlertHistoryQueryOptions(organization, project, alertId, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetAlertHistorySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAlertHistory>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertHistoryParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAlertHistory>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAlertHistoryQueryKey(organization, project, alertId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertHistory>>> = ({ signal }) =>
    getAlertHistory(organization, project, alertId, params, { signal, ...axiosOptions })

  return {
    queryKey,
    queryFn,
    enabled: !!(organization && project && alertId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAlertHistory>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAlertHistorySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAlertHistory>>>
export type GetAlertHistorySuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Alert History
 */
export const useGetAlertHistorySuspense = <
  TData = Awaited<ReturnType<typeof getAlertHistory>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertHistoryParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAlertHistory>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAlertHistorySuspenseQueryOptions(organization, project, alertId, params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get the timeline of an alert.
 * @summary Get Alert Timeline
 */
export const getAlertTimeline = (
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertTimelineParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<AlertTimelineRecord[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/projects/${project}/alerts/${alertId}/timeline`, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getGetAlertTimelineQueryKey = (
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertTimelineParams,
) => {
  return [
    `/dash/organizations/${organization}/projects/${project}/alerts/${alertId}/timeline`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetAlertTimelineQueryOptions = <
  TData = Awaited<ReturnType<typeof getAlertTimeline>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertTimelineParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAlertTimeline>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAlertTimelineQueryKey(organization, project, alertId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertTimeline>>> = ({ signal }) =>
    getAlertTimeline(organization, project, alertId, params, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && project && alertId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAlertTimeline>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAlertTimelineQueryResult = NonNullable<Awaited<ReturnType<typeof getAlertTimeline>>>
export type GetAlertTimelineQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Alert Timeline
 */
export const useGetAlertTimeline = <
  TData = Awaited<ReturnType<typeof getAlertTimeline>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertTimelineParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAlertTimeline>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAlertTimelineQueryOptions(organization, project, alertId, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetAlertTimelineSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAlertTimeline>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertTimelineParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAlertTimeline>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAlertTimelineQueryKey(organization, project, alertId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertTimeline>>> = ({ signal }) =>
    getAlertTimeline(organization, project, alertId, params, { signal, ...axiosOptions })

  return {
    queryKey,
    queryFn,
    enabled: !!(organization && project && alertId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAlertTimeline>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAlertTimelineSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAlertTimeline>>>
export type GetAlertTimelineSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Alert Timeline
 */
export const useGetAlertTimelineSuspense = <
  TData = Awaited<ReturnType<typeof getAlertTimeline>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  project: string,
  alertId: string,
  params: GetAlertTimelineParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAlertTimeline>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAlertTimelineSuspenseQueryOptions(organization, project, alertId, params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

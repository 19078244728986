import { Link, Outlet } from 'react-router-dom'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { Tabs } from '@radix-ui/react-tabs'
import { useState } from 'react'
import { CircleXIcon, MegaphoneOffIcon } from 'lucide-react'

import { ROUTES } from '@/packages/router/routes'
import { cn } from '@/packages/style'
import { Button, buttonVariants } from '@/components/shadcn/ui/button'
import { TabsList, TabsTrigger } from '@/components/shadcn/ui/tabs'
import { useListChannels } from '@/packages/api'
import List, { ListItem } from '@/components/List'
import { AgeWithTimestamp } from '@/components/TimestampWithAge'
import { EditChannel } from '@/app/alerts/channels/EditChannel'
import { ChannelRead } from '@/packages/api/__generated__/model'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { DeleteChannelModal } from '@/app/alerts/channels/components/DeleteChannelModal'

const LoadingChannelsJSX = (
  <section className="flex flex-col items-center justify-center gap-y-1 rounded-lg border border-outline bg-surface-container-low px-3 py-10">
    <div className="text-foreground text-opacity-50">
      <LoadingSpinner />
    </div>
    <p className="text-foreground text-opacity-50">Loading notification channels...</p>
  </section>
)

const NoChannelsJSX = (
  <section className="mt-6 flex flex-col items-center justify-center gap-y-1 rounded-lg border border-outline bg-surface-container-low px-3 py-10">
    <MegaphoneOffIcon className="opacity-50" />
    <p className="text-foreground text-opacity-50">
      You don't have any notification channels. Create one to view it in this list.
    </p>
  </section>
)

const ChannelsError = ({ message }: { message?: string }) => {
  return (
    <section className="flex flex-col items-center justify-center gap-y-1 rounded-lg border border-outline bg-surface-container-low px-3 py-10">
      <div className="text-states-error opacity-80">
        <CircleXIcon />
      </div>
      <p className="text-foreground text-opacity-50">
        {message ?? 'An error occurred while retrieving notification channels.'}
      </p>
    </section>
  )
}

export const ChannelsList = () => {
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS)
  const [editChannel, setEditChannel] = useState<ChannelRead | null>(null)

  const { data: channelsListData, error, isLoading } = useListChannels(organizationName, projectName)
  const channels = channelsListData?.data ?? []

  return (
    <>
      <section className="mb-8 mt-12 flex items-center justify-between border-outline">
        <section className="flex space-x-2">
          <Tabs className="m-0 p-0" value="channels">
            <TabsList className="h-8">
              <TabsTrigger asChild value="alerts" className="h-6 text-xs">
                <Link to={ROUTES.ORGANIZATION.PROJECT.ALERTS.buildPath({ organizationName, projectName })}>
                  {' '}
                  Alerts{' '}
                </Link>
              </TabsTrigger>
              <TabsTrigger value="channels" className="h-6 text-xs">
                <Link
                  to={ROUTES.ORGANIZATION.PROJECT.ALERTS.CHANNELS.buildPath({
                    organizationName,
                    projectName,
                  })}
                >
                  {' '}
                  Channels{' '}
                </Link>
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </section>
        <section>
          <Link
            to={ROUTES.ORGANIZATION.PROJECT.ALERTS.CHANNELS.NEW.buildPath({ organizationName, projectName })}
            className={cn(buttonVariants({ size: 'sm', variant: 'alternative' }))}
          >
            New Channel
          </Link>
        </section>
      </section>
      {isLoading ? (
        LoadingChannelsJSX
      ) : error ? (
        <ChannelsError message={error.message} />
      ) : channels.length === 0 && !isLoading ? (
        NoChannelsJSX
      ) : (
        <section className="mt-6 flex flex-col gap-y-10">
          <List>
            {channels.map((channel) => (
              <ListItem key={channel.id} className="flex flex-col space-y-3">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-semibold">{channel.label}</h3>
                    <p className="text-sm text-on-surface-variant">
                      {channel.updated_by_name && channel.updated_at ? (
                        <>
                          Updated by {channel.updated_by_name} at{' '}
                          <AgeWithTimestamp
                            hideDate
                            timestamp={channel.updated_at}
                            formatDistanceOptions={{ addSuffix: true }}
                            labelFormatOverride={`yyyy-MM-dd 'at' HH:mm`}
                          />
                        </>
                      ) : (
                        <>
                          Created by {channel.created_by_name} at{' '}
                          <AgeWithTimestamp
                            hideDate
                            timestamp={channel.created_at}
                            formatDistanceOptions={{ addSuffix: true }}
                            labelFormatOverride={`yyyy-MM-dd 'at' HH:mm`}
                          />
                        </>
                      )}
                    </p>
                  </div>
                  <div className="space-x-2">
                    <Button variant="secondary" size="sm" onClick={() => setEditChannel(channel)}>
                      Edit
                    </Button>
                    <DeleteChannelModal channel={channel} />
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        </section>
      )}
      {editChannel && (
        <EditChannel
          channel={editChannel}
          onDismiss={() => setEditChannel(null)}
          onSaved={() => setEditChannel(null)}
        />
      )}
      <Outlet />
    </>
  )
}

import { useState } from 'react'
import { useTypedParams, useTypedSearchParams } from 'react-router-typesafe-routes/dom'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/shadcn/ui/button'
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '@/components/shadcn/ui/dialog'
import { getListAlertsQueryKey, queryClient, useDeleteAlert } from '@/packages/api'
import { AlertRead, AlertWithLastRun } from '@/packages/api/__generated__/model'
import { ROUTES } from '@/packages/router/routes'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { LoadingButton } from '@/components/Button'

interface DeleteAlertProps {
  alert: AlertWithLastRun | AlertRead
}

export const DeleteAlert = ({ alert }: DeleteAlertProps) => {
  const [_, setSearchParams] = useTypedSearchParams(ROUTES.ORGANIZATION.PROJECT.ALERTS)
  const [isOpen, setIsOpen] = useState(false)
  const navigation = useNavigate()
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS)
  const { toast } = useToast()
  const mutation = useDeleteAlert({
    mutation: {
      onError: () => {
        toast({ title: 'Error', description: 'An error occurred while deleting the alert', variant: 'destructive' })
      },
      onSuccess: () => {
        const keys = getListAlertsQueryKey(organizationName, projectName)
        queryClient.invalidateQueries({ queryKey: keys }).then(() => {
          setIsOpen(false)
          setSearchParams((prev) => ({ ...prev, alertId: undefined }))
          toast({ title: 'Success', description: 'Alert deleted successfully', variant: 'default' })
          navigation(ROUTES.ORGANIZATION.PROJECT.ALERTS.buildPath({ organizationName, projectName }), {
            replace: true,
          })
        })
      },
    },
  })

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive">Delete</Button>
      </DialogTrigger>
      <DialogContent>
        <section>
          <p className="text-2xl font-bold">Delete alert</p>

          <p className="mt-2 text-on-surface">
            You are about to delete alert <span className="font-semibold">{alert.name}</span>. This action can&apos;t
            be undone. Do you want to proceed?
          </p>
        </section>
        <section className="flex items-center gap-x-2">
          <LoadingButton
            isLoading={mutation.isPending}
            onClick={() =>
              mutation.mutate({ organization: organizationName, project: projectName, alertId: alert.id })
            }
            variant="destructive"
          >
            Delete
          </LoadingButton>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
        </section>
      </DialogContent>
    </Dialog>
  )
}

import { useEffect } from 'react'

import useLocalStorage from '@/hooks/useLocalStorage'

export enum FeatureFlag {
  PAYMENT = 'payment',
  FF = 'ff',
  READ_TOKENS = 'readTokens',
}

export const LOCAL_STORAGE_KEY = 'featureFlags'
export const ENABLE_FLAGS_URL_HASH_KEY = 'enableFlags'
export const DISABLE_FLAGS_URL_HASH_KEY = 'disableFlags'

/**
 * Fetches the feature flags from the local storage and url
 *
 * This hook is used to fetch the feature flags from the url hash and store it in local storage or remove it from local storage
 * It also reads the feature flags from the local storage and returns a boolean value if the feature flag is enabled or not
 * the url sample: https://url#enableFlags=payment,shipping&disableFlags=payment-beta
 */

export const useFeatureFlag = (flag: FeatureFlag) => {
  useEffect(() => {
    initFeatureFlags()
  }, [])

  const { value: flagsArray } = useLocalStorage<FeatureFlag[]>(LOCAL_STORAGE_KEY, [], (x): x is FeatureFlag[] =>
    Array.isArray(x),
  )

  return flagsArray.includes(flag)
}

export const initFeatureFlags = () => {
  const url = new URL(window.location.href)
  const parsedHash = new URLSearchParams(url.hash.substring(1))
  const enableFlagsHash = parsedHash.get(ENABLE_FLAGS_URL_HASH_KEY)
  const disableFlagHash = parsedHash.get(DISABLE_FLAGS_URL_HASH_KEY)

  const enableFlags = enableFlagsHash?.split(',') ?? []
  const disableFlags = disableFlagHash?.split(',') ?? []

  try {
    const flags = new Set(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '[]'))

    // remove the disable flags from the flags
    disableFlags.forEach((flag) => flags.delete(flag))

    // add the enable flags to the flags
    enableFlags.forEach((flag) => flags.add(flag))

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(Array.from(flags)))
  } catch (e) {
    console.error('Error while setting the feature flags', e)
  }
}

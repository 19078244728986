import { PlusIcon } from 'lucide-react'
import { CheckIcon, DotsVerticalIcon } from '@radix-ui/react-icons'
import { Link, useParams } from 'react-router-dom'
import { useMemo } from 'react'

import { useAccountGet, useAccountUpdate, useOrganizationsList } from '@/packages/api'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/shadcn/ui/dropdown-menu'
import { ROUTES } from '@/packages/router/routes'
import type { OrganizationRead } from '@/packages/api/__generated__/model'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/shadcn/ui/avatar'
import { cn } from '@/packages/style'
import { Badge } from '@/components/shadcn/ui/badge'

export const OrganizationsList = () => {
  const { data: res, isLoading } = useOrganizationsList()
  const { data: account } = useAccountGet()
  const makeDefaultMutation = useAccountUpdate()
  const markAsDefault = (newDefaultOrgId: string) => {
    makeDefaultMutation.mutate({
      data: {
        default_organization_id: newDefaultOrgId,
      },
    })
  }

  const defaultOrg =
    makeDefaultMutation?.data?.data?.default_organization?.organization_name ??
    account?.data?.default_organization?.organization_name

  return (
    <div className="mt-6 grid">
      {isLoading && (
        <div className="flex h-36 items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
      {res?.data.map((org) => (
        <OrganizationsListItem
          key={org.organization_name}
          organization={org}
          onMakeDefault={() => markAsDefault(org.id)}
          isDefault={defaultOrg === org.organization_name}
        />
      ))}
      <Link to={ROUTES.ORGANIZATIONS.NEW.path}>
        <div className="flex justify-between px-4 py-2 hover:bg-surface-container-high/40">
          <div className="flex items-center space-x-2">
            <div className="flex h-14 w-14 items-center justify-center rounded-full bg-surface-container-high">
              <PlusIcon className="h-4 w-4 text-foreground" />
            </div>
            <div className="flex flex-col">
              <span className="text-sm text-foreground">Create a new organization</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

interface OrganizationsListItemProps {
  organization: OrganizationRead
  onMakeDefault: (organization: OrganizationRead) => void
  isDefault?: boolean
}

const OrganizationsListItem = ({ organization, onMakeDefault, isDefault }: OrganizationsListItemProps) => {
  // Should not use useTypedParams here, as this will be called in /organizations route that does not have organizationName param
  const { organizationName } = useParams()
  const title = organization.organization_display_name ?? organization.organization_name

  const avatarFallback =
    title
      ?.split(' ')
      .slice(0, 2)
      .map((w) => w[0])
      .join('') ?? '-'

  const markAsDefault = useMemo(
    () => () => {
      onMakeDefault(organization)
    },
    [onMakeDefault, organization],
  )

  return (
    <Link to={ROUTES.ORGANIZATION.buildPath({ organizationName: organization.organization_name })}>
      <div
        className={cn(
          'flex justify-between px-4 py-2',
          organizationName === organization.organization_name
            ? 'bg-states-info/20'
            : 'hover:bg-surface-container-high/40',
        )}
      >
        <div className="flex items-center space-x-2">
          <Avatar className="h-14 w-14 rounded-full">
            <AvatarImage
              src={organization.avatar!}
              alt={`${organization.organization_display_name} profile picture`}
            />
            <AvatarFallback className="bg-inverse-primary uppercase">{avatarFallback}</AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <span className="text-sm text-foreground">
              {organization.organization_display_name ?? organization.organization_name}
              {isDefault && (
                <Badge
                  className="ml-2 border-states-info-variant px-1 py-0.5 text-xs text-states-info-variant"
                  variant="outline"
                >
                  Default
                </Badge>
              )}
            </span>
            <span className="text-xs font-medium text-link">/{organization.organization_name}</span>
          </div>
        </div>
        <div
          className="flex items-center"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <DropdownMenu>
            <DropdownMenuTrigger className="group flex items-center gap-x-1">
              <DotsVerticalIcon className="h-4 w-4" />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" sideOffset={4}>
              <DropdownMenuItem disabled={isDefault} onClick={markAsDefault} className="flex items-center gap-x-1">
                <CheckIcon className="h-4 w-4" />
                <span>Mark as Default</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </Link>
  )
}

import { useMatches, useParams } from 'react-router-dom'
import { UIMatch } from '@remix-run/router/utils'
import { ChevronRightIcon } from 'lucide-react'

import { CrumbProps } from '@/packages/router/browserRouter'
import { useOrganizationGet } from '@/packages/api'

interface CrumbMatch extends UIMatch<unknown, unknown> {
  handle: { crumbs: (props: CrumbProps) => React.ReactNode[] }
}

export const Breadcrumbs = () => {
  const matches: UIMatch<unknown, unknown>[] = useMatches()

  // both organizationName and projectName can be undefined based on the url, so web cannot use the typed version
  const { organizationName, projectName } = useParams()

  const { data: orgInfo } = useOrganizationGet(organizationName!, {
    query: {
      enabled: !!organizationName,
    },
  })

  if (!organizationName) {
    return null
  }

  const crumbs = matches
    .filter((match) => Boolean((match as CrumbMatch)?.handle?.crumbs))
    .map((match) =>
      (match as CrumbMatch).handle.crumbs({
        organizationName,
        organizationDisplayName: orgInfo?.data?.organization_display_name ?? organizationName,
        projectName,
      }),
    )

  return crumbs
    .flat()
    .flatMap((value, index, arr) =>
      index < arr.length - 1 ? [value, <ChevronRightIcon key={index + '->'} className="-mx-1 h-4 w-4" />] : [value],
    )
}

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useNavigate } from 'react-router-dom'
import { useTypedState } from 'react-router-typesafe-routes/dom'
import { jwtDecode } from 'jwt-decode'

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/ui/form'
import { useCreateGithubUser, useGetPlatformConfig } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { Checkbox } from '@/components/shadcn/ui/checkbox'
import { Input } from '@/components/shadcn/ui/input'
import { BlockRegisterMessage } from '@/components/BlockRegisterMessage'
import { LoadingButton } from '@/components/Button'
import { extractError } from '@/utils/extract-error'
import { LogfireLogoWithBetaBadge } from '@/components/LogfireLogoWithBetaBadge'
import { usePageTitle } from '@/hooks/usePageTitle'

const formSchema = z.object({
  username: z.string().min(1, 'Please accept Terms & Conditions'),
  allowMarketing: z.boolean(),
  tosAccepted: z.boolean().refine((val) => val === true, { message: 'Please accept Terms & Conditions' }),
})

type FormValues = z.infer<typeof formSchema>

export const AcceptTOS = () => {
  usePageTitle('Terms of Service')

  const navigate = useNavigate()

  const { userInfoJWT, state } = useTypedState(ROUTES.AUTH.ACCEPT_TOS)
  const decoded: { username: string; email?: string } = jwtDecode(userInfoJWT)

  const { data: platformConfig } = useGetPlatformConfig()

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: { username: decoded.username, allowMarketing: true, tosAccepted: false },
  })
  const mutation = useCreateGithubUser()
  const isMutationPending = mutation.isPending

  const onSubmit = (data: FormValues) => {
    if (!data.tosAccepted) {
      return form.setError('tosAccepted', { message: 'Please accept Terms & Conditions' })
    }
    mutation.mutate(
      { data: { username: data.username, user_info_jwt: userInfoJWT, allow_marketing: data.allowMarketing } },
      {
        onSuccess: ({ data }) => {
          navigate(ROUTES.AUTH.AUTHENTICATE.path, { state: ROUTES.AUTH.AUTHENTICATE.buildState({ ...data, state }) })
        },
        onError: (error) => {
          const errorMessage = extractError(error.response?.data.detail, 'An error occurred, please try again.')
          return form.setError('username', { message: errorMessage })
        },
      },
    )
  }

  if (platformConfig?.data.signup === 'disallow') {
    return (
      <section className="no-footer fixed-height grid h-full w-full place-content-center">
        <BlockRegisterMessage />
      </section>
    )
  }

  return (
    <section className="no-footer fixed-height grid h-full w-full place-content-center">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex max-w-xl flex-col items-start rounded-lg border border-outline bg-surface-container px-14 py-10"
        >
          <section className="flex w-full flex-col justify-center">
            <LogfireLogoWithBetaBadge className="justify-center" />
            <p className="mx-auto text-xl font-semibold">Create an account</p>
          </section>
          <section className="my-8 flex flex-col gap-y-3">
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl className="x-full">
                <Input value={decoded?.email} disabled={true} />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Pick your username</FormLabel>
                    <FormControl className="x-full">
                      <Input value={field.value} onChange={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            />
            <FormField
              control={form.control}
              name="allowMarketing"
              render={({ field }) => {
                return (
                  <FormItem className="flex items-center gap-x-2 space-y-0 pl-0.5">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <div className="relative flex flex-col gap-y-2">
                      <FormLabel className="leading-5">Receive occasional updates and announcements</FormLabel>
                    </div>
                    <FormMessage className="absolute bottom-0 left-0 translate-y-full transform" />
                  </FormItem>
                )
              }}
            />
            <FormField
              control={form.control}
              name="tosAccepted"
              render={({ field }) => {
                return (
                  <FormItem className="mb-4 pl-0.5">
                    <div className="flex items-center gap-x-2 space-y-0">
                      <FormControl>
                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <div className="relative flex flex-col gap-y-2">
                        <FormLabel className="leading-5">
                          I agree to{' '}
                          <a
                            className="underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://docs.pydantic.dev/logfire/legal/terms_of_service/"
                          >
                            Terms of Service
                          </a>{' '}
                          and the{' '}
                          <a
                            href="https://docs.pydantic.dev/logfire/legal/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline"
                          >
                            Privacy Policy
                          </a>
                        </FormLabel>
                      </div>
                    </div>
                    <FormMessage className="" />
                  </FormItem>
                )
              }}
            />
          </section>
          <LoadingButton isLoading={isMutationPending} type="submit" className="w-full gap-x-2">
            Continue
          </LoadingButton>
        </form>
      </Form>
    </section>
  )
}

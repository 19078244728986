import { route, string, boolean } from 'react-router-typesafe-routes/dom'
import { zod } from 'react-router-typesafe-routes/zod'
import { z } from 'zod'

import { organizationSettingsRoutes } from '@/app/organization/routes'

export const stateSchema = z.object({
  redirectTo: z.string().optional(),
})

export const REDIRECT_KEY = 'redirectTo'
export const LONGEST_TIME_FILTER = '30d'

// organizationSchema should match `OrganizationReadBase` from the API
const organizationSchema = z.object({ id: z.string(), organization_name: z.string() })
const organizationsSchema = z.array(organizationSchema)
// userSchema should match `UserRead` from the API
const userSchema = z.object({
  default_organization: organizationSchema.nullable(),
  email: z.string(),
  github_user_id: z.number().nullable(),
  github_username: z.string().nullable(),
  id: z.string(),
  name: z.string(),
})

// Individual Routes

const authRoutes = route(
  'auth',
  {},
  {
    GITHUB_LOGIN: route('github-login', { searchParams: { [REDIRECT_KEY]: string() } }),
    GITHUB_CODE: route('github-code'),
    ACCEPT_TOS: route('accept-tos', { state: { userInfoJWT: string().defined(), state: zod(stateSchema) } }),
    AUTHENTICATE: route('authenticate', {
      state: {
        access_token: string().defined(),
        expiration: string().defined(),
        organizations: zod(organizationsSchema),
        user: zod(userSchema).defined(),
        state: zod(stateSchema).defined(),
      },
    }),
    DEVICE_AUTH: route('device/:deviceCode', { params: { deviceCode: string().defined() } }),
  },
)

const projectSettingsRoutes = route('settings', undefined, {
  SETUP: route('setup'),
  WRITE_TOKENS: route('write-tokens', undefined, { NEW: route('new') }),
  READ_TOKENS: route('read-tokens', undefined, { NEW: route('new') }),
  DATABASE_CREDENTIALS: route('database-credentials', undefined, { NEW: route('new') }),
  INVITATIONS: route('invitations'),
  MEMBERS: route('members'),
  CUSTOM_PANELS: route('custom-panels', undefined, { NEW: route('new') }),
})

// Main Router

export const ROUTES = {
  USER_PAGE: route('user/:username', { params: { username: string().defined() } }),
  LOGIN: route('login', { state: { from: string() } }),
  AUTH: authRoutes,
  JOIN: route('join', undefined, {
    PROJECT: route(':organizationName/-/project-invitation/:projectName/:invitationId', {
      params: {
        organizationName: string().defined(),
        projectName: string().defined(),
        invitationId: string().defined(),
      },
    }),
  }),
  ORGANIZATION: route(
    ':organizationName',
    { params: { organizationName: string().defined() } },
    {
      INTERNAL: route('-', undefined, {
        SETTINGS: organizationSettingsRoutes,
        PROJECTS: route('projects', undefined, {
          NEW: route('new'),
        }),
        JOIN_ORGANIZATION: route('join-organization/:invitationId', { params: { invitationId: string().defined() } }),
      }),
      PROJECT: route(
        ':projectName',
        {
          params: { projectName: string().defined() },
          searchParams: {
            traceId: string(),
            spanId: string(),
            q: string(),
            last: string(),
            since: string(),
            until: string(),
            gettingStartedTab: string(),
            timeFilter: string(), // this is for backwards compatibility; TODO: Remove this after a little while
          },
        },
        {
          JOIN: route('join-project/:invitationId', { params: { invitationId: string().defined() } }),
          EXPLORE: route('explore', {
            searchParams: {
              q: string(),
              query: string(),
              tab: string(),
            },
          }),
          GETTING_STARTED: route('getting-started', {
            searchParams: { tab: string() },
            state: { wasRedirected: boolean().default(false) },
          }),
          EXPLORE_V3: route('explore-v3'),
          DASHBOARDS: route(
            'dashboards',
            {},
            {
              DASHBOARD: route(':dashboardId', { params: { dashboardId: string().defined() } }),
            },
          ),
          ALERTS: route(
            'alerts',
            {},
            {
              NEW: route('new'),
              CHANNELS: route(
                'channels',
                {},
                {
                  NEW: route('new'),
                },
              ),
              HISTORY: route(':alertId', { params: { alertId: string().defined() } }),
              EDIT: route(':alertId/edit', { params: { alertId: string().defined() } }),
            },
          ),
          SETTINGS: projectSettingsRoutes,
        },
      ),
    },
  ),
  ORGANIZATIONS: route('organizations', {}, { NEW: route('new') }),
}

import { UseFormReturn } from 'react-hook-form'
import { useState } from 'react'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { Cross2Icon } from '@radix-ui/react-icons'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/shadcn/ui/select'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/shadcn/ui/form'
import { Input } from '@/components/shadcn/ui/input'
import { cn } from '@/packages/style'
import { AlertRead, AlertWithLastRun } from '@/packages/api/__generated__/model'
import { Switch } from '@/components/shadcn/ui/switch'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/ui/tooltip'
import { AlertQuery } from '@/app/alerts/components/AlertQuery'
import { LoadingButton } from '@/components/Button'
import { Alert, AlertDescription, AlertTitle } from '@/components/shadcn/ui/alert'
import { ROUTES } from '@/packages/router/routes'
import { AssignNotificationChannelToAlert } from '@/app/alerts/components/AssignNotificationChannelToAlert'
import { useListChannels } from '@/packages/api'
import { Badge } from '@/components/shadcn/ui/badge'
import { Button } from '@/components/shadcn/ui/button'
import { getSimpleDurationLabel, simpleDurationSchema } from '@/packages/time/simpleDuration'

import { AlertUpdateFormSchema, notifyWhenLabelsByValue, notifyWhenOptions } from '../zod-schemas'
import { ONE_MINUTE_DURATION, timeWindowOptions } from '../utils'

import { FormInfoIcon } from './FormInfoIcon'
import { DeleteAlert } from './DeleteAlert'

interface AlertUnifiedFormProps {
  form: UseFormReturn<AlertUpdateFormSchema>
  onSubmit: (data: AlertUpdateFormSchema) => void
  // If updating, provide the alert:
  alert?: AlertWithLastRun | AlertRead
  isSubmitting: boolean
  mutationErrorMessage?: string
}

const formLabelStyles = 'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'

export const AlertForm = ({ form, onSubmit, alert, isSubmitting, mutationErrorMessage }: AlertUnifiedFormProps) => {
  const [isQueryValid, setIsQueryValid] = useState<boolean>()

  const isFormDirty = form.formState.isDirty
  const isQueryDirty = form.formState.dirtyFields.query

  const disableUpdate = isSubmitting || (isQueryDirty ? !isQueryValid : !isFormDirty)

  const isNewAlert = !alert
  const legacyWebhookURLs = form.watch('webhookURLs') ?? ''

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-4 text-on-surface-variant">
        <section className="grid grid-cols-1 items-start gap-x-4 md:grid-cols-2">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="grow">
                <div className="flex w-full items-center justify-between">
                  <FormLabel>Alert name</FormLabel>
                </div>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {isNewAlert ? (
            <input type="hidden" {...form.register('active')} />
          ) : (
            <FormField
              control={form.control}
              name="active"
              render={({ field }) => (
                <FormItem className="flex items-center justify-end gap-x-1 space-y-0 pt-0">
                  <FormLabel>Active</FormLabel>
                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </section>
        <AlertQuery form={form} isQueryValid={isQueryValid} setIsQueryValid={setIsQueryValid} />
        <AlertCondition form={form} />
        <ChannelsSelect form={form} />
        {legacyWebhookURLs.length > 0 && (
          <section className="space-y-2">
            <Alert variant="warning">
              <AlertTitle>Note:</AlertTitle>
              <AlertDescription>
                The Webhook URLs field will be removed soon. Please use the new &quot;Channels&quot; feature above to
                configure your desired notification destinations.
              </AlertDescription>
            </Alert>
            <section>
              <FormField
                control={form.control}
                name="webhookURLs"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center gap-x-1">
                      Webhook URLs (Legacy)
                      <FormInfoIcon>
                        <p>You can add multiple webhook URLs as a comma-separated list.</p>
                      </FormInfoIcon>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      Your webhook will receive a payload following{' '}
                      <a
                        href="https://api.slack.com/reference/messaging/payload"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="underline"
                      >
                        Slack&apos;s format.
                      </a>
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </section>
          </section>
        )}
        <section className="flex gap-x-2">
          <Tooltip>
            {!isQueryValid && <TooltipContent>Preview your query results to enable this button</TooltipContent>}
            <TooltipTrigger asChild>
              <span className="w-max">
                <LoadingButton
                  variant="alternative"
                  isLoading={isSubmitting}
                  disabled={disableUpdate}
                  className={cn('disabled:cursor-not-allowed')}
                  type="submit"
                >
                  {alert ? 'Update' : 'Create alert'}
                </LoadingButton>
              </span>
            </TooltipTrigger>
          </Tooltip>
          {alert && <DeleteAlert alert={alert} />}
        </section>
        {mutationErrorMessage && <Alert variant="destructive">{mutationErrorMessage}</Alert>}
      </form>
    </Form>
  )
}

const ChannelsSelect = ({ form }: { form: UseFormReturn<AlertUpdateFormSchema> }) => {
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS)
  const { data: channelsListData } = useListChannels(organizationName, projectName)

  const selectedChannels = channelsListData?.data.filter((channel) => form.watch('channels')?.includes(channel.id))

  return (
    <section className="space-y-2">
      <section>
        <FormField
          control={form.control}
          name="channels"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-x-1">
                Channels{' '}
                <FormInfoIcon>
                  <p>You can enable multiple notification channels to send alerts to multiple destinations.</p>
                </FormInfoIcon>
              </FormLabel>
              <FormControl>
                <div className="flex flex-row space-x-2">
                  {selectedChannels?.map((channel) => (
                    <Badge key={channel.id} variant="outline" className="h-8">
                      <Button
                        size="sm"
                        variant="link"
                        className="m-0 p-0.5"
                        type="button"
                        onClick={() => {
                          field.onChange(field.value.filter((id) => id !== channel.id))
                        }}
                      >
                        <Cross2Icon />
                      </Button>
                      {channel.label}
                    </Badge>
                  ))}
                  <AssignNotificationChannelToAlert
                    channelIds={field.value}
                    onSave={(selectedChannels) => {
                      field.onChange(selectedChannels.map((channel) => channel.id))
                    }}
                  />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </section>
    </section>
  )
}

const AlertCondition = ({ form }: { form: UseFormReturn<AlertUpdateFormSchema> }) => {
  const notifyWhen = form.watch('notifyWhen')
  const rawTimeWindow = form.watch('timeWindow')
  const timeWindow = simpleDurationSchema.safeParse(rawTimeWindow).data

  return (
    <section className="flex flex-col justify-between">
      <p className={cn(formLabelStyles, 'mb-4 mt-2')}>Alert Parameters</p>
      <section className="flex gap-x-10">
        <FormField
          control={form.control}
          name="timeWindow"
          render={() => (
            <FormItem>
              <FormLabel className="flex items-center gap-x-1">
                Frequency{' '}
                <FormInfoIcon>
                  <p>Your query will be executed once per minute.</p>
                  <p>Currently, this cannot be changed.</p>
                </FormInfoIcon>
              </FormLabel>
              <Select disabled defaultValue={ONE_MINUTE_DURATION}>
                <FormControl>
                  <SelectTrigger className="h-8 bg-surface-bright text-xs text-on-surface placeholder:text-on-surface">
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem key={ONE_MINUTE_DURATION} value={ONE_MINUTE_DURATION}>
                    {getSimpleDurationLabel(ONE_MINUTE_DURATION, true)}
                  </SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="timeWindow"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-x-1">
                Time window{' '}
                <FormInfoIcon>
                  <p>
                    To keep matching rows from triggering alerts forever, and to make it easier to do aggregations over
                    a rolling time window, we replace references to the <code>records</code> and <code>metrics</code>{' '}
                    tables in your alert query with views that include only recent rows.
                  </p>
                  <p>
                    The maximum age of rows included in these views is determined by the &quot;Time window&quot; you
                    select here.
                  </p>
                </FormInfoIcon>
              </FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger className="h-8 bg-surface-bright text-xs text-on-surface placeholder:text-on-surface">
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {timeWindowOptions.map((option) => (
                    <SelectItem key={option} value={option}>
                      {getSimpleDurationLabel(option, true)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="notifyWhen"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-x-1">
                Notify when{' '}
                <FormInfoIcon>
                  <p>The &quot;Notify when&quot; setting determines when a notification is sent to your webhooks.</p>
                  <p>See the description below the setting for more information.</p>
                </FormInfoIcon>
              </FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger className="h-8 bg-surface-bright text-xs text-on-surface placeholder:text-on-surface">
                    <SelectValue>
                      {field.value ? notifyWhenLabelsByValue[field.value as keyof typeof notifyWhenLabelsByValue] : ''}
                    </SelectValue>
                  </SelectTrigger>
                </FormControl>
                <SelectContent className="overflow-visible">
                  {notifyWhenOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      <span className="flex items-center gap-x-1">{option.label}</span>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
      </section>
      <section className="mt-2 flex flex-col gap-y-1">
        <p className="text-sm text-foreground/60">
          Your query will be executed every <span className="text-foreground">1 minute</span>.
        </p>
        <p className="text-sm text-foreground/60">
          When executed, it will have access to the last{' '}
          <span className="text-foreground">
            {timeWindow ? getSimpleDurationLabel(timeWindow, true) : rawTimeWindow}
          </span>{' '}
          worth of rows in the <code>records</code> and <code>metrics</code> tables.
        </p>
        <p className="text-sm text-foreground/60">
          A notification will be sent to your webhooks when{' '}
          <span className="text-foreground">
            {notifyWhen === 'has_matches' && 'the query returns one or more rows.'}
            {notifyWhen === 'matches_changed' && 'the query returns a different set of rows than its previous run.'}
            {notifyWhen === 'has_matches_changed' &&
              'the number of rows returned by the query changes between zero and non-zero.'}
          </span>
        </p>
      </section>
    </section>
  )
}

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Logfire
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import * as axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type {
  BodyOrganizationsCheckNameDashOrganizationsCheckNamePost,
  DashboardCreate,
  DashboardPatch,
  DashboardProjectCreate,
  DashboardRead,
  DashboardUpdate,
  HTTPValidationError,
  OrganizationCheckNameResponse,
  OrganizationCreate,
  OrganizationDashboardsListParams,
  OrganizationInvitationCreate,
  OrganizationInvitationRead,
  OrganizationMemberCreate,
  OrganizationMemberUpdate,
  OrganizationMemberUserRead,
  OrganizationRead,
  OrganizationStats,
  OrganizationUpdate,
  OrganizationUsage,
  ProjectRead,
  UpdateOrganizationSubscriptionPlanParams,
} from '.././model'

/**
 * @summary Organizations Check Name
 */
export const organizationsCheckName = (
  bodyOrganizationsCheckNameDashOrganizationsCheckNamePost: BodyOrganizationsCheckNameDashOrganizationsCheckNamePost,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationCheckNameResponse>> => {
  return axios.default.post(
    `/dash/organizations/check-name`,
    bodyOrganizationsCheckNameDashOrganizationsCheckNamePost,
    options,
  )
}

export const getOrganizationsCheckNameMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationsCheckName>>,
    TError,
    { data: BodyOrganizationsCheckNameDashOrganizationsCheckNamePost },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationsCheckName>>,
  TError,
  { data: BodyOrganizationsCheckNameDashOrganizationsCheckNamePost },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationsCheckName>>,
    { data: BodyOrganizationsCheckNameDashOrganizationsCheckNamePost }
  > = (props) => {
    const { data } = props ?? {}

    return organizationsCheckName(data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationsCheckNameMutationResult = NonNullable<Awaited<ReturnType<typeof organizationsCheckName>>>
export type OrganizationsCheckNameMutationBody = BodyOrganizationsCheckNameDashOrganizationsCheckNamePost
export type OrganizationsCheckNameMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organizations Check Name
 */
export const useOrganizationsCheckName = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationsCheckName>>,
    TError,
    { data: BodyOrganizationsCheckNameDashOrganizationsCheckNamePost },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationsCheckName>>,
  TError,
  { data: BodyOrganizationsCheckNameDashOrganizationsCheckNamePost },
  TContext
> => {
  const mutationOptions = getOrganizationsCheckNameMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organizations List
 */
export const organizationsList = (options?: AxiosRequestConfig): Promise<AxiosResponse<OrganizationRead[]>> => {
  return axios.default.get(`/dash/organizations`, options)
}

export const getOrganizationsListQueryKey = () => {
  return [`/dash/organizations`] as const
}

export const getOrganizationsListQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationsList>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationsList>>, TError, TData>>
  axios?: AxiosRequestConfig
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationsList>>> = ({ signal }) =>
    organizationsList({ signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationsListQueryResult = NonNullable<Awaited<ReturnType<typeof organizationsList>>>
export type OrganizationsListQueryError = AxiosError<unknown>

/**
 * @summary Organizations List
 */
export const useOrganizationsList = <
  TData = Awaited<ReturnType<typeof organizationsList>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationsList>>, TError, TData>>
  axios?: AxiosRequestConfig
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrganizationsListSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationsList>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationsList>>, TError, TData>>
  axios?: AxiosRequestConfig
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationsList>>> = ({ signal }) =>
    organizationsList({ signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof organizationsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationsListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof organizationsList>>>
export type OrganizationsListSuspenseQueryError = AxiosError<unknown>

/**
 * @summary Organizations List
 */
export const useOrganizationsListSuspense = <
  TData = Awaited<ReturnType<typeof organizationsList>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationsList>>, TError, TData>>
  axios?: AxiosRequestConfig
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationsListSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Organization Create
 */
export const organizationCreate = (
  organizationCreate: OrganizationCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationRead>> => {
  return axios.default.post(`/dash/organizations`, organizationCreate, options)
}

export const getOrganizationCreateMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreate>>,
    TError,
    { data: OrganizationCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationCreate>>,
  TError,
  { data: OrganizationCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationCreate>>, { data: OrganizationCreate }> = (
    props,
  ) => {
    const { data } = props ?? {}

    return organizationCreate(data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationCreateMutationResult = NonNullable<Awaited<ReturnType<typeof organizationCreate>>>
export type OrganizationCreateMutationBody = OrganizationCreate
export type OrganizationCreateMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Create
 */
export const useOrganizationCreate = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreate>>,
    TError,
    { data: OrganizationCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationCreate>>,
  TError,
  { data: OrganizationCreate },
  TContext
> => {
  const mutationOptions = getOrganizationCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Get
 */
export const organizationGet = (
  organization: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationRead>> => {
  return axios.default.get(`/dash/organizations/${organization}`, options)
}

export const getOrganizationGetQueryKey = (organization: string) => {
  return [`/dash/organizations/${organization}`] as const
}

export const getOrganizationGetQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationGetQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationGet>>> = ({ signal }) =>
    organizationGet(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationGetQueryResult = NonNullable<Awaited<ReturnType<typeof organizationGet>>>
export type OrganizationGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Get
 */
export const useOrganizationGet = <
  TData = Awaited<ReturnType<typeof organizationGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetQueryOptions(organization, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrganizationGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationGetQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationGet>>> = ({ signal }) =>
    organizationGet(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof organizationGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof organizationGet>>>
export type OrganizationGetSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Get
 */
export const useOrganizationGetSuspense = <
  TData = Awaited<ReturnType<typeof organizationGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetSuspenseQueryOptions(organization, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Organizations Update
 */
export const organizationsUpdate = (
  organization: string,
  organizationUpdate: OrganizationUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationRead>> => {
  return axios.default.put(`/dash/organizations/${organization}`, organizationUpdate, options)
}

export const getOrganizationsUpdateMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationsUpdate>>,
    TError,
    { organization: string; data: OrganizationUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationsUpdate>>,
  TError,
  { organization: string; data: OrganizationUpdate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationsUpdate>>,
    { organization: string; data: OrganizationUpdate }
  > = (props) => {
    const { organization, data } = props ?? {}

    return organizationsUpdate(organization, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof organizationsUpdate>>>
export type OrganizationsUpdateMutationBody = OrganizationUpdate
export type OrganizationsUpdateMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organizations Update
 */
export const useOrganizationsUpdate = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationsUpdate>>,
    TError,
    { organization: string; data: OrganizationUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationsUpdate>>,
  TError,
  { organization: string; data: OrganizationUpdate },
  TContext
> => {
  const mutationOptions = getOrganizationsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Get Stats
 */
export const organizationGetStats = (
  organization: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationStats>> => {
  return axios.default.get(`/dash/organizations/${organization}/stats`, options)
}

export const getOrganizationGetStatsQueryKey = (organization: string) => {
  return [`/dash/organizations/${organization}/stats`] as const
}

export const getOrganizationGetStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetStats>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationGetStats>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationGetStatsQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationGetStats>>> = ({ signal }) =>
    organizationGetStats(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetStats>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationGetStatsQueryResult = NonNullable<Awaited<ReturnType<typeof organizationGetStats>>>
export type OrganizationGetStatsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Get Stats
 */
export const useOrganizationGetStats = <
  TData = Awaited<ReturnType<typeof organizationGetStats>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationGetStats>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetStatsQueryOptions(organization, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrganizationGetStatsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetStats>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationGetStats>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationGetStatsQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationGetStats>>> = ({ signal }) =>
    organizationGetStats(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof organizationGetStats>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationGetStatsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof organizationGetStats>>>
export type OrganizationGetStatsSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Get Stats
 */
export const useOrganizationGetStatsSuspense = <
  TData = Awaited<ReturnType<typeof organizationGetStats>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationGetStats>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetStatsSuspenseQueryOptions(organization, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Organization Get Members
 */
export const organizationGetMembers = (
  organization: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationMemberUserRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/members`, options)
}

export const getOrganizationGetMembersQueryKey = (organization: string) => {
  return [`/dash/organizations/${organization}/members`] as const
}

export const getOrganizationGetMembersQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetMembers>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationGetMembers>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationGetMembersQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationGetMembers>>> = ({ signal }) =>
    organizationGetMembers(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationGetMembersQueryResult = NonNullable<Awaited<ReturnType<typeof organizationGetMembers>>>
export type OrganizationGetMembersQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Get Members
 */
export const useOrganizationGetMembers = <
  TData = Awaited<ReturnType<typeof organizationGetMembers>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationGetMembers>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetMembersQueryOptions(organization, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrganizationGetMembersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetMembers>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationGetMembers>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationGetMembersQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationGetMembers>>> = ({ signal }) =>
    organizationGetMembers(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof organizationGetMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationGetMembersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof organizationGetMembers>>>
export type OrganizationGetMembersSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Get Members
 */
export const useOrganizationGetMembersSuspense = <
  TData = Awaited<ReturnType<typeof organizationGetMembers>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationGetMembers>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetMembersSuspenseQueryOptions(organization, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Organization Add Member
 */
export const organizationAddMember = (
  organization: string,
  organizationMemberCreate: OrganizationMemberCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.post(`/dash/organizations/${organization}/members`, organizationMemberCreate, options)
}

export const getOrganizationAddMemberMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationAddMember>>,
    TError,
    { organization: string; data: OrganizationMemberCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationAddMember>>,
  TError,
  { organization: string; data: OrganizationMemberCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationAddMember>>,
    { organization: string; data: OrganizationMemberCreate }
  > = (props) => {
    const { organization, data } = props ?? {}

    return organizationAddMember(organization, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationAddMemberMutationResult = NonNullable<Awaited<ReturnType<typeof organizationAddMember>>>
export type OrganizationAddMemberMutationBody = OrganizationMemberCreate
export type OrganizationAddMemberMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Add Member
 */
export const useOrganizationAddMember = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationAddMember>>,
    TError,
    { organization: string; data: OrganizationMemberCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationAddMember>>,
  TError,
  { organization: string; data: OrganizationMemberCreate },
  TContext
> => {
  const mutationOptions = getOrganizationAddMemberMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Get Member
 */
export const organizationGetMember = (
  organization: string,
  userId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationMemberUserRead>> => {
  return axios.default.get(`/dash/organizations/${organization}/members/${userId}`, options)
}

export const getOrganizationGetMemberQueryKey = (organization: string, userId: string) => {
  return [`/dash/organizations/${organization}/members/${userId}`] as const
}

export const getOrganizationGetMemberQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetMember>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  userId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationGetMember>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationGetMemberQueryKey(organization, userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationGetMember>>> = ({ signal }) =>
    organizationGetMember(organization, userId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && userId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetMember>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationGetMemberQueryResult = NonNullable<Awaited<ReturnType<typeof organizationGetMember>>>
export type OrganizationGetMemberQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Get Member
 */
export const useOrganizationGetMember = <
  TData = Awaited<ReturnType<typeof organizationGetMember>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  userId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationGetMember>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetMemberQueryOptions(organization, userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrganizationGetMemberSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetMember>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  userId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationGetMember>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationGetMemberQueryKey(organization, userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationGetMember>>> = ({ signal }) =>
    organizationGetMember(organization, userId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && userId), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof organizationGetMember>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationGetMemberSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof organizationGetMember>>>
export type OrganizationGetMemberSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Get Member
 */
export const useOrganizationGetMemberSuspense = <
  TData = Awaited<ReturnType<typeof organizationGetMember>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  userId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationGetMember>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetMemberSuspenseQueryOptions(organization, userId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Organization Update Member
 */
export const organizationUpdateMember = (
  organization: string,
  userId: string,
  organizationMemberUpdate: OrganizationMemberUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.put(`/dash/organizations/${organization}/members/${userId}`, organizationMemberUpdate, options)
}

export const getOrganizationUpdateMemberMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationUpdateMember>>,
    TError,
    { organization: string; userId: string; data: OrganizationMemberUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationUpdateMember>>,
  TError,
  { organization: string; userId: string; data: OrganizationMemberUpdate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationUpdateMember>>,
    { organization: string; userId: string; data: OrganizationMemberUpdate }
  > = (props) => {
    const { organization, userId, data } = props ?? {}

    return organizationUpdateMember(organization, userId, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationUpdateMemberMutationResult = NonNullable<Awaited<ReturnType<typeof organizationUpdateMember>>>
export type OrganizationUpdateMemberMutationBody = OrganizationMemberUpdate
export type OrganizationUpdateMemberMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Update Member
 */
export const useOrganizationUpdateMember = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationUpdateMember>>,
    TError,
    { organization: string; userId: string; data: OrganizationMemberUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationUpdateMember>>,
  TError,
  { organization: string; userId: string; data: OrganizationMemberUpdate },
  TContext
> => {
  const mutationOptions = getOrganizationUpdateMemberMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Remove Member
 */
export const organizationRemoveMember = (
  organization: string,
  userId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.default.delete(`/dash/organizations/${organization}/members/${userId}`, options)
}

export const getOrganizationRemoveMemberMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationRemoveMember>>,
    TError,
    { organization: string; userId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationRemoveMember>>,
  TError,
  { organization: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationRemoveMember>>,
    { organization: string; userId: string }
  > = (props) => {
    const { organization, userId } = props ?? {}

    return organizationRemoveMember(organization, userId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationRemoveMemberMutationResult = NonNullable<Awaited<ReturnType<typeof organizationRemoveMember>>>

export type OrganizationRemoveMemberMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Remove Member
 */
export const useOrganizationRemoveMember = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationRemoveMember>>,
    TError,
    { organization: string; userId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationRemoveMember>>,
  TError,
  { organization: string; userId: string },
  TContext
> => {
  const mutationOptions = getOrganizationRemoveMemberMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Dashboards List
 */
export const organizationDashboardsList = (
  organization: string,
  params?: OrganizationDashboardsListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DashboardRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/dashboards/`, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getOrganizationDashboardsListQueryKey = (
  organization: string,
  params?: OrganizationDashboardsListParams,
) => {
  return [`/dash/organizations/${organization}/dashboards/`, ...(params ? [params] : [])] as const
}

export const getOrganizationDashboardsListQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationDashboardsList>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  params?: OrganizationDashboardsListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationDashboardsList>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationDashboardsListQueryKey(organization, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationDashboardsList>>> = ({ signal }) =>
    organizationDashboardsList(organization, params, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationDashboardsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationDashboardsListQueryResult = NonNullable<Awaited<ReturnType<typeof organizationDashboardsList>>>
export type OrganizationDashboardsListQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboards List
 */
export const useOrganizationDashboardsList = <
  TData = Awaited<ReturnType<typeof organizationDashboardsList>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  params?: OrganizationDashboardsListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationDashboardsList>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationDashboardsListQueryOptions(organization, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrganizationDashboardsListSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationDashboardsList>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  params?: OrganizationDashboardsListParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationDashboardsList>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationDashboardsListQueryKey(organization, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationDashboardsList>>> = ({ signal }) =>
    organizationDashboardsList(organization, params, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof organizationDashboardsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationDashboardsListSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardsList>>
>
export type OrganizationDashboardsListSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboards List
 */
export const useOrganizationDashboardsListSuspense = <
  TData = Awaited<ReturnType<typeof organizationDashboardsList>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  params?: OrganizationDashboardsListParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationDashboardsList>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationDashboardsListSuspenseQueryOptions(organization, params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Organization Dashboard Create
 */
export const organizationDashboardCreate = (
  organization: string,
  dashboardCreate: DashboardCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DashboardRead>> => {
  return axios.default.post(`/dash/organizations/${organization}/dashboards/`, dashboardCreate, options)
}

export const getOrganizationDashboardCreateMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardCreate>>,
    TError,
    { organization: string; data: DashboardCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationDashboardCreate>>,
  TError,
  { organization: string; data: DashboardCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationDashboardCreate>>,
    { organization: string; data: DashboardCreate }
  > = (props) => {
    const { organization, data } = props ?? {}

    return organizationDashboardCreate(organization, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationDashboardCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardCreate>>
>
export type OrganizationDashboardCreateMutationBody = DashboardCreate
export type OrganizationDashboardCreateMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Create
 */
export const useOrganizationDashboardCreate = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardCreate>>,
    TError,
    { organization: string; data: DashboardCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationDashboardCreate>>,
  TError,
  { organization: string; data: DashboardCreate },
  TContext
> => {
  const mutationOptions = getOrganizationDashboardCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Dashboard Get
 */
export const organizationDashboardGet = (
  organization: string,
  dashboardId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DashboardRead>> => {
  return axios.default.get(`/dash/organizations/${organization}/dashboards/${dashboardId}`, options)
}

export const getOrganizationDashboardGetQueryKey = (organization: string, dashboardId: string) => {
  return [`/dash/organizations/${organization}/dashboards/${dashboardId}`] as const
}

export const getOrganizationDashboardGetQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationDashboardGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  dashboardId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationDashboardGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationDashboardGetQueryKey(organization, dashboardId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationDashboardGet>>> = ({ signal }) =>
    organizationDashboardGet(organization, dashboardId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && dashboardId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationDashboardGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationDashboardGetQueryResult = NonNullable<Awaited<ReturnType<typeof organizationDashboardGet>>>
export type OrganizationDashboardGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Get
 */
export const useOrganizationDashboardGet = <
  TData = Awaited<ReturnType<typeof organizationDashboardGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  dashboardId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationDashboardGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationDashboardGetQueryOptions(organization, dashboardId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrganizationDashboardGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationDashboardGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  dashboardId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationDashboardGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationDashboardGetQueryKey(organization, dashboardId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationDashboardGet>>> = ({ signal }) =>
    organizationDashboardGet(organization, dashboardId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && dashboardId), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof organizationDashboardGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationDashboardGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardGet>>
>
export type OrganizationDashboardGetSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Get
 */
export const useOrganizationDashboardGetSuspense = <
  TData = Awaited<ReturnType<typeof organizationDashboardGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  dashboardId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationDashboardGet>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationDashboardGetSuspenseQueryOptions(organization, dashboardId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Organization Dashboard Patch
 */
export const organizationDashboardPatch = (
  organization: string,
  dashboardId: string,
  dashboardPatch: DashboardPatch,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DashboardRead>> => {
  return axios.default.patch(`/dash/organizations/${organization}/dashboards/${dashboardId}`, dashboardPatch, options)
}

export const getOrganizationDashboardPatchMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardPatch>>,
    TError,
    { organization: string; dashboardId: string; data: DashboardPatch },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationDashboardPatch>>,
  TError,
  { organization: string; dashboardId: string; data: DashboardPatch },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationDashboardPatch>>,
    { organization: string; dashboardId: string; data: DashboardPatch }
  > = (props) => {
    const { organization, dashboardId, data } = props ?? {}

    return organizationDashboardPatch(organization, dashboardId, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationDashboardPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardPatch>>
>
export type OrganizationDashboardPatchMutationBody = DashboardPatch
export type OrganizationDashboardPatchMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Patch
 */
export const useOrganizationDashboardPatch = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardPatch>>,
    TError,
    { organization: string; dashboardId: string; data: DashboardPatch },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationDashboardPatch>>,
  TError,
  { organization: string; dashboardId: string; data: DashboardPatch },
  TContext
> => {
  const mutationOptions = getOrganizationDashboardPatchMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Dashboard Update
 */
export const organizationDashboardUpdate = (
  organization: string,
  dashboardId: string,
  dashboardUpdate: DashboardUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DashboardRead>> => {
  return axios.default.put(`/dash/organizations/${organization}/dashboards/${dashboardId}`, dashboardUpdate, options)
}

export const getOrganizationDashboardUpdateMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardUpdate>>,
    TError,
    { organization: string; dashboardId: string; data: DashboardUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationDashboardUpdate>>,
  TError,
  { organization: string; dashboardId: string; data: DashboardUpdate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationDashboardUpdate>>,
    { organization: string; dashboardId: string; data: DashboardUpdate }
  > = (props) => {
    const { organization, dashboardId, data } = props ?? {}

    return organizationDashboardUpdate(organization, dashboardId, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationDashboardUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardUpdate>>
>
export type OrganizationDashboardUpdateMutationBody = DashboardUpdate
export type OrganizationDashboardUpdateMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Update
 */
export const useOrganizationDashboardUpdate = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardUpdate>>,
    TError,
    { organization: string; dashboardId: string; data: DashboardUpdate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationDashboardUpdate>>,
  TError,
  { organization: string; dashboardId: string; data: DashboardUpdate },
  TContext
> => {
  const mutationOptions = getOrganizationDashboardUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Dashboard Delete
 */
export const organizationDashboardDelete = (
  organization: string,
  dashboardId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.default.delete(`/dash/organizations/${organization}/dashboards/${dashboardId}`, options)
}

export const getOrganizationDashboardDeleteMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardDelete>>,
    TError,
    { organization: string; dashboardId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationDashboardDelete>>,
  TError,
  { organization: string; dashboardId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationDashboardDelete>>,
    { organization: string; dashboardId: string }
  > = (props) => {
    const { organization, dashboardId } = props ?? {}

    return organizationDashboardDelete(organization, dashboardId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationDashboardDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardDelete>>
>

export type OrganizationDashboardDeleteMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Delete
 */
export const useOrganizationDashboardDelete = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardDelete>>,
    TError,
    { organization: string; dashboardId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationDashboardDelete>>,
  TError,
  { organization: string; dashboardId: string },
  TContext
> => {
  const mutationOptions = getOrganizationDashboardDeleteMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Dashboard Get Project
 */
export const organizationDashboardGetProject = (
  organization: string,
  dashboardId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProjectRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/dashboards/${dashboardId}/projects`, options)
}

export const getOrganizationDashboardGetProjectQueryKey = (organization: string, dashboardId: string) => {
  return [`/dash/organizations/${organization}/dashboards/${dashboardId}/projects`] as const
}

export const getOrganizationDashboardGetProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationDashboardGetProject>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  dashboardId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationDashboardGetProject>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationDashboardGetProjectQueryKey(organization, dashboardId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationDashboardGetProject>>> = ({ signal }) =>
    organizationDashboardGetProject(organization, dashboardId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && dashboardId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationDashboardGetProject>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationDashboardGetProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardGetProject>>
>
export type OrganizationDashboardGetProjectQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Get Project
 */
export const useOrganizationDashboardGetProject = <
  TData = Awaited<ReturnType<typeof organizationDashboardGetProject>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  dashboardId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationDashboardGetProject>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationDashboardGetProjectQueryOptions(organization, dashboardId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrganizationDashboardGetProjectSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationDashboardGetProject>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  dashboardId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationDashboardGetProject>>, TError, TData>
    >
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationDashboardGetProjectQueryKey(organization, dashboardId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationDashboardGetProject>>> = ({ signal }) =>
    organizationDashboardGetProject(organization, dashboardId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && dashboardId), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof organizationDashboardGetProject>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type OrganizationDashboardGetProjectSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardGetProject>>
>
export type OrganizationDashboardGetProjectSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Get Project
 */
export const useOrganizationDashboardGetProjectSuspense = <
  TData = Awaited<ReturnType<typeof organizationDashboardGetProject>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  dashboardId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof organizationDashboardGetProject>>, TError, TData>
    >
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationDashboardGetProjectSuspenseQueryOptions(organization, dashboardId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Organization Dashboard Add Project
 */
export const organizationDashboardAddProject = (
  organization: string,
  dashboardId: string,
  dashboardProjectCreate: DashboardProjectCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/dashboards/${dashboardId}/projects`,
    dashboardProjectCreate,
    options,
  )
}

export const getOrganizationDashboardAddProjectMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardAddProject>>,
    TError,
    { organization: string; dashboardId: string; data: DashboardProjectCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationDashboardAddProject>>,
  TError,
  { organization: string; dashboardId: string; data: DashboardProjectCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationDashboardAddProject>>,
    { organization: string; dashboardId: string; data: DashboardProjectCreate }
  > = (props) => {
    const { organization, dashboardId, data } = props ?? {}

    return organizationDashboardAddProject(organization, dashboardId, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationDashboardAddProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardAddProject>>
>
export type OrganizationDashboardAddProjectMutationBody = DashboardProjectCreate
export type OrganizationDashboardAddProjectMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Add Project
 */
export const useOrganizationDashboardAddProject = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardAddProject>>,
    TError,
    { organization: string; dashboardId: string; data: DashboardProjectCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationDashboardAddProject>>,
  TError,
  { organization: string; dashboardId: string; data: DashboardProjectCreate },
  TContext
> => {
  const mutationOptions = getOrganizationDashboardAddProjectMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Organization Dashboard Remove Project
 */
export const organizationDashboardRemoveProject = (
  organization: string,
  dashboardId: string,
  projectId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.default.delete(
    `/dash/organizations/${organization}/dashboards/${dashboardId}/projects/${projectId}`,
    options,
  )
}

export const getOrganizationDashboardRemoveProjectMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardRemoveProject>>,
    TError,
    { organization: string; dashboardId: string; projectId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationDashboardRemoveProject>>,
  TError,
  { organization: string; dashboardId: string; projectId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationDashboardRemoveProject>>,
    { organization: string; dashboardId: string; projectId: string }
  > = (props) => {
    const { organization, dashboardId, projectId } = props ?? {}

    return organizationDashboardRemoveProject(organization, dashboardId, projectId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrganizationDashboardRemoveProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationDashboardRemoveProject>>
>

export type OrganizationDashboardRemoveProjectMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Organization Dashboard Remove Project
 */
export const useOrganizationDashboardRemoveProject = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDashboardRemoveProject>>,
    TError,
    { organization: string; dashboardId: string; projectId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationDashboardRemoveProject>>,
  TError,
  { organization: string; dashboardId: string; projectId: string },
  TContext
> => {
  const mutationOptions = getOrganizationDashboardRemoveProjectMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Create Organization Invitation
 */
export const createOrganizationInvitation = (
  organization: string,
  organizationInvitationCreate: OrganizationInvitationCreate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationInvitationRead>> => {
  return axios.default.post(`/dash/organizations/${organization}/invitations`, organizationInvitationCreate, options)
}

export const getCreateOrganizationInvitationMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrganizationInvitation>>,
    TError,
    { organization: string; data: OrganizationInvitationCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOrganizationInvitation>>,
  TError,
  { organization: string; data: OrganizationInvitationCreate },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrganizationInvitation>>,
    { organization: string; data: OrganizationInvitationCreate }
  > = (props) => {
    const { organization, data } = props ?? {}

    return createOrganizationInvitation(organization, data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateOrganizationInvitationMutationResult = NonNullable<
  Awaited<ReturnType<typeof createOrganizationInvitation>>
>
export type CreateOrganizationInvitationMutationBody = OrganizationInvitationCreate
export type CreateOrganizationInvitationMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Create Organization Invitation
 */
export const useCreateOrganizationInvitation = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrganizationInvitation>>,
    TError,
    { organization: string; data: OrganizationInvitationCreate },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof createOrganizationInvitation>>,
  TError,
  { organization: string; data: OrganizationInvitationCreate },
  TContext
> => {
  const mutationOptions = getCreateOrganizationInvitationMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get Organization Invitations
 */
export const getOrganizationInvitations = (
  organization: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationInvitationRead[]>> => {
  return axios.default.get(`/dash/organizations/${organization}/invitations`, options)
}

export const getGetOrganizationInvitationsQueryKey = (organization: string) => {
  return [`/dash/organizations/${organization}/invitations`] as const
}

export const getGetOrganizationInvitationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganizationInvitations>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationInvitations>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationInvitationsQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationInvitations>>> = ({ signal }) =>
    getOrganizationInvitations(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrganizationInvitations>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetOrganizationInvitationsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationInvitations>>>
export type GetOrganizationInvitationsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Organization Invitations
 */
export const useGetOrganizationInvitations = <
  TData = Awaited<ReturnType<typeof getOrganizationInvitations>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationInvitations>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrganizationInvitationsQueryOptions(organization, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetOrganizationInvitationsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganizationInvitations>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOrganizationInvitations>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationInvitationsQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationInvitations>>> = ({ signal }) =>
    getOrganizationInvitations(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getOrganizationInvitations>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetOrganizationInvitationsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrganizationInvitations>>
>
export type GetOrganizationInvitationsSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Organization Invitations
 */
export const useGetOrganizationInvitationsSuspense = <
  TData = Awaited<ReturnType<typeof getOrganizationInvitations>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOrganizationInvitations>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrganizationInvitationsSuspenseQueryOptions(organization, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get Organization Invitation
 */
export const getOrganizationInvitation = (
  organization: string,
  invitationId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationInvitationRead>> => {
  return axios.default.get(`/dash/organizations/${organization}/invitations/${invitationId}`, options)
}

export const getGetOrganizationInvitationQueryKey = (organization: string, invitationId: string) => {
  return [`/dash/organizations/${organization}/invitations/${invitationId}`] as const
}

export const getGetOrganizationInvitationQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganizationInvitation>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  invitationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationInvitation>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationInvitationQueryKey(organization, invitationId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationInvitation>>> = ({ signal }) =>
    getOrganizationInvitation(organization, invitationId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && invitationId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrganizationInvitation>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetOrganizationInvitationQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationInvitation>>>
export type GetOrganizationInvitationQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Organization Invitation
 */
export const useGetOrganizationInvitation = <
  TData = Awaited<ReturnType<typeof getOrganizationInvitation>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  invitationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationInvitation>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrganizationInvitationQueryOptions(organization, invitationId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetOrganizationInvitationSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganizationInvitation>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  invitationId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOrganizationInvitation>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationInvitationQueryKey(organization, invitationId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationInvitation>>> = ({ signal }) =>
    getOrganizationInvitation(organization, invitationId, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!(organization && invitationId), ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getOrganizationInvitation>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetOrganizationInvitationSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrganizationInvitation>>
>
export type GetOrganizationInvitationSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Organization Invitation
 */
export const useGetOrganizationInvitationSuspense = <
  TData = Awaited<ReturnType<typeof getOrganizationInvitation>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  invitationId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOrganizationInvitation>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrganizationInvitationSuspenseQueryOptions(organization, invitationId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Revoke Organization Invitation
 */
export const revokeOrganizationInvitation = (
  organization: string,
  invitationId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/invitations/${invitationId}/revoke`,
    undefined,
    options,
  )
}

export const getRevokeOrganizationInvitationMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeOrganizationInvitation>>,
    TError,
    { organization: string; invitationId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof revokeOrganizationInvitation>>,
  TError,
  { organization: string; invitationId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof revokeOrganizationInvitation>>,
    { organization: string; invitationId: string }
  > = (props) => {
    const { organization, invitationId } = props ?? {}

    return revokeOrganizationInvitation(organization, invitationId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RevokeOrganizationInvitationMutationResult = NonNullable<
  Awaited<ReturnType<typeof revokeOrganizationInvitation>>
>

export type RevokeOrganizationInvitationMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Revoke Organization Invitation
 */
export const useRevokeOrganizationInvitation = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeOrganizationInvitation>>,
    TError,
    { organization: string; invitationId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof revokeOrganizationInvitation>>,
  TError,
  { organization: string; invitationId: string },
  TContext
> => {
  const mutationOptions = getRevokeOrganizationInvitationMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Claim Organization Invitation
 */
export const claimOrganizationInvitation = (
  organization: string,
  invitationId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/invitations/${invitationId}/claim`,
    undefined,
    options,
  )
}

export const getClaimOrganizationInvitationMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimOrganizationInvitation>>,
    TError,
    { organization: string; invitationId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof claimOrganizationInvitation>>,
  TError,
  { organization: string; invitationId: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof claimOrganizationInvitation>>,
    { organization: string; invitationId: string }
  > = (props) => {
    const { organization, invitationId } = props ?? {}

    return claimOrganizationInvitation(organization, invitationId, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ClaimOrganizationInvitationMutationResult = NonNullable<
  Awaited<ReturnType<typeof claimOrganizationInvitation>>
>

export type ClaimOrganizationInvitationMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Claim Organization Invitation
 */
export const useClaimOrganizationInvitation = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimOrganizationInvitation>>,
    TError,
    { organization: string; invitationId: string },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof claimOrganizationInvitation>>,
  TError,
  { organization: string; invitationId: string },
  TContext
> => {
  const mutationOptions = getClaimOrganizationInvitationMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Change the subscription plan of an organization.
 * @summary Update Organization Subscription Plan
 */
export const updateOrganizationSubscriptionPlan = (
  organization: string,
  params: UpdateOrganizationSubscriptionPlanParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<unknown>> => {
  return axios.default.put(`/dash/organizations/${organization}/subscriptions`, undefined, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getUpdateOrganizationSubscriptionPlanMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateOrganizationSubscriptionPlan>>,
    TError,
    { organization: string; params: UpdateOrganizationSubscriptionPlanParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateOrganizationSubscriptionPlan>>,
  TError,
  { organization: string; params: UpdateOrganizationSubscriptionPlanParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateOrganizationSubscriptionPlan>>,
    { organization: string; params: UpdateOrganizationSubscriptionPlanParams }
  > = (props) => {
    const { organization, params } = props ?? {}

    return updateOrganizationSubscriptionPlan(organization, params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateOrganizationSubscriptionPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateOrganizationSubscriptionPlan>>
>

export type UpdateOrganizationSubscriptionPlanMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Update Organization Subscription Plan
 */
export const useUpdateOrganizationSubscriptionPlan = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateOrganizationSubscriptionPlan>>,
    TError,
    { organization: string; params: UpdateOrganizationSubscriptionPlanParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof updateOrganizationSubscriptionPlan>>,
  TError,
  { organization: string; params: UpdateOrganizationSubscriptionPlanParams },
  TContext
> => {
  const mutationOptions = getUpdateOrganizationSubscriptionPlanMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Get the organization's usage for the current month.
 * @summary Get Organization Usage
 */
export const getOrganizationUsage = (
  organization: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrganizationUsage>> => {
  return axios.default.get(`/dash/organizations/${organization}/usage`, options)
}

export const getGetOrganizationUsageQueryKey = (organization: string) => {
  return [`/dash/organizations/${organization}/usage`] as const
}

export const getGetOrganizationUsageQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganizationUsage>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationUsage>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationUsageQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationUsage>>> = ({ signal }) =>
    getOrganizationUsage(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrganizationUsage>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetOrganizationUsageQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationUsage>>>
export type GetOrganizationUsageQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Organization Usage
 */
export const useGetOrganizationUsage = <
  TData = Awaited<ReturnType<typeof getOrganizationUsage>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationUsage>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrganizationUsageQueryOptions(organization, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetOrganizationUsageSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganizationUsage>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOrganizationUsage>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationUsageQueryKey(organization)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationUsage>>> = ({ signal }) =>
    getOrganizationUsage(organization, { signal, ...axiosOptions })

  return { queryKey, queryFn, enabled: !!organization, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getOrganizationUsage>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetOrganizationUsageSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationUsage>>>
export type GetOrganizationUsageSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Organization Usage
 */
export const useGetOrganizationUsageSuspense = <
  TData = Awaited<ReturnType<typeof getOrganizationUsage>>,
  TError = AxiosError<HTTPValidationError>,
>(
  organization: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOrganizationUsage>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrganizationUsageSuspenseQueryOptions(organization, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

import { boolean, route, string } from 'react-router-typesafe-routes/dom'

export const organizationSettingsRoutes = route(
  'settings',
  {},
  {
    GENERAL: route('general'),
    MEMBERS: route('members', undefined, {
      LIST: route('list'),
      INVITATIONS: route('invitations'),
    }),
    PAYMENT: route('payment', undefined, {
      PAYMENT_INFORMATION: route('payment-information', {
        searchParams: {
          setup_intent: string(),
          setup_intent_client_secret: string(),
          redirect_status: string(),
          upgrade_plan: boolean(),
        },
      }),
      INVOICES_HISTORY: route('invoices-history'),
      USAGE: route('usage'),
    }),
  },
)

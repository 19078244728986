import { CustomCell, CustomRenderer, GridCellKind, getMiddleCenterBias } from '@glideapps/glide-data-grid'

import { JsonObjectView } from '@/components/ObjectView/JsonObjectView'

interface AttributesCellProps {
  readonly kind: 'attributes-cell'
  readonly json: object | undefined
}

type AttributesCell = CustomCell<AttributesCellProps>

const renderer: CustomRenderer<AttributesCell> = {
  kind: GridCellKind.Custom,
  isMatch: (c): c is AttributesCell => (c.data as any).kind === 'attributes-cell',
  draw: (args, cell) => {
    const { ctx, theme, rect } = args
    const { json } = cell.data

    ctx.fillStyle = theme.textDark
    ctx.fillText(
      JSON.stringify(json),
      rect.x + theme.cellHorizontalPadding,
      rect.y + rect.height / 2 + getMiddleCenterBias(ctx, theme),
    )

    return true
  },
  provideEditor: () => ({
    editor: (p) => {
      return <JsonObjectView value={p.value.data.json} />
    },
    disablePadding: true,
  }),
}

export default renderer

// TODO: Rework this to use indexdb, have a TTL, etc.

import { useCallback, useEffect, useState } from 'react'

import { TraceId, SpanId } from '@/packages/log'
import { fetchRecordDetails } from '@/packages/api'

import { RecordDetails, type RecordSummaryKind } from '../api/__generated__/model'
import { convertMillisWithMicrosecondsToIsoString } from '../datetime/formattedDuration'

const BATCH_SIZE = 20 // we expect to never/rarely need to batch, but just in case

type SpanLocatorString = `${TraceId}:${SpanId}`
interface DetailsFetchingState {
  loading: boolean
  failed: boolean
  details: RecordDetails | undefined
}

interface RecordDetailsRequest {
  traceId: string
  spanId: string
  startTimestamp: number
  kind: RecordSummaryKind
  force?: boolean
}

// TODO(DavidM): If we persist the record details, we'll need to keep track of project ID
class RecordDetailsLoader {
  private data: Record<SpanLocatorString, DetailsFetchingState | undefined> = {}
  private listeners: Record<SpanLocatorString, ((details: DetailsFetchingState) => void)[] | undefined> = {}
  private requestQueue: RecordDetailsRequest[] = []

  private isRequesting = false

  private retrieveDetailsFunc?: (locators: RecordDetailsRequest[]) => Promise<RecordDetails[]>

  setRetrieveDetailsFunc(retrieveDetailsFunc: (locators: RecordDetailsRequest[]) => Promise<RecordDetails[]>) {
    this.retrieveDetailsFunc = retrieveDetailsFunc
    this.processRequestQueue()
  }

  upsertState(traceId: string, spanId: string, state: DetailsFetchingState) {
    const key = buildKey(traceId, spanId)
    this.data[key] = state
    this.notifyListeners(traceId, spanId, state)
  }

  clear() {
    this.data = {}
    this.listeners = {}
    this.requestQueue = []
  }

  getDetails({ traceId, spanId, startTimestamp, kind, force }: RecordDetailsRequest): DetailsFetchingState {
    const key = buildKey(traceId, spanId)

    if (!force) {
      const result = this.data[key]
      // Return the result early if:
      // * it is loading, because we don't want to trigger a new request
      // * there are no details, because this means something went wrong with the existing one
      // * the kind matches the requested kind, because it means we already have the appropriate result
      if (result && (result.loading || !result.details || result.details.kind === kind)) return result
    }

    this.retrieveDetails([{ traceId, spanId, startTimestamp, kind, force }])
    // We need to check this.data[key] again because the previous call to this.retrieveDetails may have updated it
    return this.data[key] ?? { loading: true, failed: false, details: undefined }
  }

  retrieveDetails(requests: RecordDetailsRequest[]) {
    this.requestQueue.push(...requests)
    this.processRequestQueue()
  }

  dedupeRequestQueue() {
    const uniqueRequests = new Map<string, RecordDetailsRequest>()
    this.requestQueue.forEach((request) => {
      const key = buildKey(request.traceId, request.spanId)
      const existingValue = uniqueRequests.get(key)
      if (!existingValue?.force) uniqueRequests.set(key, request)
    })

    // Convert the Map values back to an array and update the queue
    this.requestQueue = Array.from(uniqueRequests.values())
  }

  processRequestQueue() {
    if (this.isRequesting) return
    if (!this.retrieveDetailsFunc) return

    // Remove anything from the queue that may have been retrieved since we last checked
    this.requestQueue = this.requestQueue.filter(({ traceId, spanId, kind, force }) => {
      if (force) return true

      const existingData = this.data[buildKey(traceId, spanId)]
      if (!existingData) return true // haven't retrieved any data for this span yet
      return existingData.details?.kind === 'pending_span' && kind === 'span' // in this case, there may be new data
    })

    if (this.requestQueue.length === 0) return

    // Remove duplicates from the queue
    this.dedupeRequestQueue()

    this.isRequesting = true
    // Grab only one batch of locators worth of details at a time:
    const locators = this.requestQueue.splice(0, BATCH_SIZE)
    locators.forEach(({ traceId, spanId }) => {
      const existingDetails = this.data[buildKey(traceId, spanId)]?.details
      this.upsertState(traceId, spanId, { loading: true, failed: false, details: existingDetails })
    })
    const unhandledLocatorStrings = new Set(locators.map(({ traceId, spanId }) => buildKey(traceId, spanId)))
    this.retrieveDetailsFunc(locators)
      .then((data) => {
        data.forEach((record) => {
          this.upsertState(record.trace_id, record.span_id, {
            loading: false,
            failed: false,
            details: record,
          })
          unhandledLocatorStrings.delete(buildKey(record.trace_id, record.span_id))
        })
        unhandledLocatorStrings.forEach((locatorString) => {
          const [traceId, spanId] = locatorString.split(':')
          this.upsertState(traceId, spanId, {
            loading: false,
            failed: true,
            details: undefined,
          })
        })
      })
      .finally(() => {
        this.isRequesting = false
        this.processRequestQueue() // call again in case there were more than BATCH_SIZE items in the queue
      })
  }

  addListener(traceId: string, spanId: string, listener: (state: DetailsFetchingState) => void) {
    const key = buildKey(traceId, spanId)
    let listeners = this.listeners[key]
    if (!listeners) {
      listeners = []
      this.listeners[key] = listeners
    }
    listeners.push(listener)

    const removeRecordListener = this.removeListener.bind(this)
    // Return the unsubscribe function to clean up hook implementations
    return () => removeRecordListener(traceId, spanId, listener)
  }

  private notifyListeners(traceId: TraceId, spanId: SpanId, state: DetailsFetchingState) {
    this.listeners[buildKey(traceId, spanId)]?.forEach((listener) => listener(state))
  }

  private removeListener(traceId: TraceId, spanId: SpanId, listener: (state: DetailsFetchingState) => void) {
    const key = buildKey(traceId, spanId)
    const listeners = this.listeners[key]
    if (!listeners) return

    const index = listeners.indexOf(listener)
    if (index !== -1) {
      listeners.splice(index, 1)
    }
    if (listeners.length === 0) {
      delete this.listeners[key]
    }
  }
}

const buildKey = (traceId: string, spanId: string): SpanLocatorString => {
  return `${traceId}:${spanId}`
}

const recordDetailsLoader = new RecordDetailsLoader()

export const initializeRecordDetailsLoader = (organization: string, project: string, queryEngine: 'ts' | 'ff') => {
  recordDetailsLoader.setRetrieveDetailsFunc(
    async (locators: { spanId: string; traceId: string; startTimestamp: number }[]): Promise<RecordDetails[]> => {
      if (locators.length === 0) return []
      const startTimestamps = locators.map(({ startTimestamp }) => startTimestamp)
      const spanLocators = locators.map(({ spanId, traceId }) => ({ span_id: spanId, trace_id: traceId }))
      const res = await fetchRecordDetails(organization, project, spanLocators, {
        query_engine: queryEngine,
        min_start_timestamp: convertMillisWithMicrosecondsToIsoString(Math.min(...startTimestamps)),
        max_start_timestamp: convertMillisWithMicrosecondsToIsoString(Math.max(...startTimestamps)),
      })
      return res.data
    },
  )
}

export const prefetchRecordDetails = recordDetailsLoader.retrieveDetails.bind(recordDetailsLoader)

export const useRecordDetails = ({ traceId, spanId, startTimestamp, kind }: Partial<RecordDetailsRequest>) => {
  const [data, setData] = useState<DetailsFetchingState>(() => {
    if (traceId && spanId && startTimestamp && kind) {
      return recordDetailsLoader.getDetails({ traceId, spanId, startTimestamp, kind })
    }
    return { loading: false, failed: false, details: undefined }
  })

  const refresh = useCallback(() => {
    if (!traceId || !spanId || !startTimestamp || !kind) return
    recordDetailsLoader.getDetails({ traceId, spanId, startTimestamp, kind, force: true })
  }, [traceId, spanId, startTimestamp, kind])

  useEffect(() => {
    if (!traceId || !spanId || !startTimestamp || !kind) {
      setData({ loading: false, failed: false, details: undefined })
    } else {
      const unsubscribeFunction = recordDetailsLoader.addListener(traceId, spanId, setData)
      const detailState = recordDetailsLoader.getDetails({ traceId, spanId, startTimestamp, kind })
      setData(detailState)
      return unsubscribeFunction
    }
  }, [traceId, spanId, startTimestamp, kind])

  return { data, refresh }
}

import { ProjectSettingsPage } from '@/app/project/project-settings-page'
import { ProjectSettingsGeneral } from '@/app/project/settings/project-settings-general'
import { ProjectSettingsWriteTokens } from '@/app/project/settings/project-settings-write-tokens'
import { ProjectSettingsWriteTokensNew } from '@/app/project/settings/project-settings-write-tokens-new'
import { ProjectSettingsInvitations } from '@/app/project/settings/project-settings-invitations/project-settings-invitations'
import { ProjectSettingsCustomPanels } from '@/app/project/settings/project-settings-custom-panels/project-settings-custom-panels'
import { GettingStarted } from '@/app/project/settings/getting-started/getting-started'
import { ProjectSettingsMembers } from '@/app/project/settings/project-settings-members-list'
import { ProjectSettingsReadTokens } from '@/app/project/settings/project-settings-read-tokens'
import { ProjectSettingsReadTokensNew } from '@/app/project/settings/project-settings-read-tokens-new'

import { ROUTES } from '../routes'

export const projectSettingsRoutes = {
  path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.path,
  element: <ProjectSettingsPage />,
  children: [
    { index: true, element: <ProjectSettingsGeneral /> },
    { path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.SETUP.path, element: <GettingStarted /> },
    { path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.WRITE_TOKENS.path, element: <ProjectSettingsWriteTokens /> },
    { path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.WRITE_TOKENS.NEW.path, element: <ProjectSettingsWriteTokensNew /> },
    { path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.READ_TOKENS.path, element: <ProjectSettingsReadTokens /> },
    { path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.READ_TOKENS.NEW.path, element: <ProjectSettingsReadTokensNew /> },
    { path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.INVITATIONS.path, element: <ProjectSettingsInvitations /> },
    { path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.MEMBERS.path, element: <ProjectSettingsMembers /> },
    { path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.CUSTOM_PANELS.path, element: <ProjectSettingsCustomPanels /> },
    {
      path: ROUTES.ORGANIZATION.PROJECT.SETTINGS.CUSTOM_PANELS.NEW.path,
      async lazy() {
        const ProjectSettingsCustomPanelsNew = await import(
          '@/app/project/settings/project-settings-custom-panels-new'
        )
        return { Component: ProjectSettingsCustomPanelsNew.default }
      },
    },
  ],
}

import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { cva } from 'class-variance-authority'

import { cn } from '@/packages/style'
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/shadcn/ui/pagination'

interface ListItemProps {
  children: React.ReactNode
  linkTo?: string
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void
  className?: string
  size?: 'sm' | 'md' | 'lg'
}

const listItemVariants = cva('border-t border-outline transition-colors first:border-0 ', {
  variants: {
    size: {
      sm: 'px-4 py-2',
      md: 'px-6 py-4',
      lg: 'px-8 py-6',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

export const ListItem: React.FC<ListItemProps> = ({ children, className = '', linkTo, size, onClick }) => {
  const ListItemComponent = (
    <li onClick={onClick} className={cn(linkTo ? '' : listItemVariants({ size }), className)}>
      {children}
    </li>
  )
  if (linkTo) {
    return (
      <Link to={linkTo} className={cn(listItemVariants({ size }), className)}>
        {ListItemComponent}
      </Link>
    )
  } else {
    return ListItemComponent
  }
}

interface ListProps {
  children: React.ReactNode
  className?: string
  localPagination?: boolean
  perPage?: number
  remotePagination?: boolean
  onPreviousPage?: () => void
  onNextPage?: () => void
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  nextPageTitle?: string
  previousPageTitle?: string
}

export const List: React.FC<ListProps> = ({
  children,
  className = '',
  localPagination,
  perPage = 20,
  remotePagination,
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
  nextPageTitle,
  previousPageTitle,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const rendererItems = useMemo(() => {
    if (localPagination) {
      return React.Children.toArray(children).slice((currentPage - 1) * perPage, currentPage * perPage)
    }
    return children
  }, [children, currentPage, localPagination, perPage])

  const pages = Math.ceil(React.Children.count(children) / perPage)

  // limit the pagination to show just 5 numbers and the first and last
  // like this: 1 2 3 4 5 ... 10
  const paginationLimitedPages = useMemo(() => {
    const totalNumbers = 5 // The number of page numbers to show
    let start = currentPage - Math.floor(totalNumbers / 2)
    start = Math.max(start, 1)
    let end = start + totalNumbers - 1
    end = Math.min(end, pages)
    start = Math.max(end - totalNumbers + 1, 1) // Recalculate start in case end is less than totalNumbers

    const array = []
    for (let i = start; i <= end; i++) {
      array.push(i)
    }

    // Add the first and last page if they're not already included
    if (array[0] !== 1) {
      array.unshift(null)
      array.unshift(1)
    }
    if (array[array.length - 1] !== pages) {
      array.push(null)
      array.push(pages)
    }

    return array
  }, [currentPage, pages])

  return (
    <ul
      className={cn(
        'relative overflow-hidden rounded-lg border border-outline animate-in fade-in dark:bg-none',
        className,
      )}
    >
      {rendererItems}
      {localPagination && (
        <li className="flex justify-center px-4 py-2">
          <section className="grid w-full grid-cols-3 items-center gap-2">
            <span className="text-sm text-on-surface-variant">
              Showing {currentPage === pages ? React.Children.count(children) : currentPage * perPage} of{' '}
              {React.Children.count(children)}
            </span>
            <Pagination className="grow">
              <PaginationItem
                onClick={() => setCurrentPage(currentPage - 1)}
                className={cn(
                  'text-on-surface-variant hover:cursor-pointer hover:text-inverse-surface',
                  currentPage === 1 && 'pointer-events-none opacity-20',
                )}
              >
                <PaginationPrevious />
              </PaginationItem>
              {paginationLimitedPages.map((page, index) => (
                <PaginationItem key={index}>
                  <PaginationLink
                    className={cn(
                      currentPage === page
                        ? 'bg-surface-container-highest text-inverse-surface'
                        : 'text-on-surface-variant',
                      page === null
                        ? 'hover:bg-inherit hover:text-inherit'
                        : 'hover:cursor-pointer hover:bg-on-surface-variant hover:text-inverse-surface',
                    )}
                    onClick={() => {
                      if (page === null) return
                      setCurrentPage(page)
                    }}
                  >
                    {page ?? '...'}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                onClick={() => setCurrentPage(currentPage + 1)}
                className={cn(
                  'text-on-surface-variant hover:cursor-pointer hover:text-inverse-surface',
                  currentPage === pages && 'pointer-events-none opacity-20',
                )}
              >
                <PaginationNext />
              </PaginationItem>
            </Pagination>
          </section>
        </li>
      )}
      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {remotePagination && (hasPreviousPage || hasNextPage) && (
        <li className="flex justify-center px-4 py-2">
          <section className="flex w-full items-center gap-2">
            <Pagination className="grow">
              {hasPreviousPage && (
                <PaginationItem
                  onClick={onPreviousPage}
                  className="text-on-surface-variant hover:cursor-pointer hover:text-inverse-surface"
                >
                  <PaginationPrevious title={previousPageTitle} />
                </PaginationItem>
              )}
              {hasNextPage && (
                <PaginationItem
                  onClick={onNextPage}
                  className="text-on-surface-variant hover:cursor-pointer hover:text-inverse-surface"
                >
                  <PaginationNext title={nextPageTitle} />
                </PaginationItem>
              )}
            </Pagination>
          </section>
        </li>
      )}
    </ul>
  )
}

export default List

import axios from 'axios'

export const getBaseUrl = (): string => {
  return `${window.location.protocol}//${window.appConfig.apiHost}`
}

axios.interceptors.request.use(
  (config) => {
    config.baseURL = getBaseUrl()
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { useMemo } from 'react'
import { SquareArrowOutUpRight } from 'lucide-react'
import { Link } from 'react-router-dom'

import { ROUTES } from '@/packages/router/routes'
import { buttonVariants } from '@/components/shadcn/ui/button'
import { cn } from '@/packages/style'
import { TipsCard } from '@/components/TipsContainer'

import { Highlighter } from './Highlighter'

export const DevelopmentInstructions = () => {
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT)

  const baseUrlOverride = useMemo(() => {
    // hide the base_url on prod, assuming it is hosted on a pydantic.dev domain:
    if (window.location.host.includes('pydantic.dev')) return ''
    return `base_url='${window.location.protocol}//${window.appConfig.apiHost}'`
  }, [])

  const basicExample = `import logfire

logfire.configure(${baseUrlOverride})

logfire.info('Hello, {name}!', name='world')`

  const logfireUse = `logfire projects use ${projectName}`

  return (
    <>
      <TipsCard
        description={
          <>
            During development, we recommend using the CLI to configure Logfire. You can also use a{' '}
            <Link
              to={ROUTES.ORGANIZATION.PROJECT.SETTINGS.WRITE_TOKENS.buildPath({ organizationName, projectName })}
              className="underline"
            >
              write token
            </Link>
            .
          </>
        }
      />
      <section className="flex flex-col gap-y-2 p-2">
        <h4 className="text-lg font-semibold">1. Install SDK</h4>
        <p className="pl-4">If you haven't installed the Logfire SDK, do this first. </p>
        <p className="pl-4">
          <a
            href="https://docs.pydantic.dev/logfire/guides/first_steps/#install"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Install SDK
            <SquareArrowOutUpRight className="ml-1 inline-block h-3 w-3" />
          </a>
        </p>

        <h4 className="mt-4 text-lg font-semibold">2. Configure your project</h4>
        <div className="space-y-2 pl-4">
          <p>A. Make sure you are authenticated with the CLI:</p>
          <section className="rounded-sm bg-surface-container p-2 py-0.5">
            <Highlighter language="bash" text="logfire auth" />
          </section>
          <p>
            B. From the working directory where you will run your application, use the CLI to set the Logfire project:
          </p>
          <section className="rounded-sm bg-surface-container p-2 py-0.5">
            <Highlighter language="bash" text={logfireUse} />
          </section>
        </div>
        <h4 className="mt-4 text-lg font-semibold">3. Send data to Logfire</h4>
        <div className="space-y-2 pl-4">
          <p>Once you've configured your project with the CLI, you should be ready to send data to Logfire.</p>
          <p>Here is a basic example of how to send data to Logfire using the SDK:</p>
          <section className="rounded-sm bg-surface-container p-2 py-0.5">
            <Highlighter language="python" text={basicExample} />
          </section>
        </div>
        <div className="pl-4">
          <Link
            className={cn(buttonVariants({ variant: 'default' }), 'my-2')}
            to={ROUTES.ORGANIZATION.PROJECT.buildPath({ organizationName, projectName })}
          >
            Go to Live View
          </Link>
        </div>
      </section>
    </>
  )
}

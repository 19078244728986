interface LogfireIconProps {
  className?: string
}

export const LogfireIcon = (props: LogfireIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 17"
      className={props.className}
      aria-hidden="true"
    >
      <path fill="#E520E9" d="m8.485 0 8.485 8.485-8.485 8.485L0 8.485z"></path>
    </svg>
  )
}

/**
 * Manages functions executed before a route change occurs within an application.
 */

type BeforeRouteChangeType = () => void
export class BeforeRouteChange {
  // eslint-disable-next-line no-use-before-define
  private static instance: BeforeRouteChange

  public static getInstance(): BeforeRouteChange {
    if (!BeforeRouteChange.instance) {
      BeforeRouteChange.instance = new BeforeRouteChange()
    }
    return BeforeRouteChange.instance
  }

  private beforeRouteChange?: BeforeRouteChangeType
  public setBeforeRouteChange(fn?: BeforeRouteChangeType) {
    this.beforeRouteChange = fn
  }

  public callBeforeRouteChange() {
    this.beforeRouteChange?.()
    // remove the function after calling it
    this.setBeforeRouteChange(undefined)
  }
}

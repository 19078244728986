import { Moon, Sun, SunMoon } from 'lucide-react'

import { ButtonToolTip } from '@/components/ButtonToolTip'
import { ThemeChoiceLightDarkSystem, useTheme } from '@/packages/theme/useTheme'

export function DarkModeToggle() {
  const { themeChoiceLightDarkSystem: theme, setThemeChoiceLightDarkSystem: setTheme } = useTheme()

  const onClick = () => {
    if (theme === ThemeChoiceLightDarkSystem.light) {
      setTheme(ThemeChoiceLightDarkSystem.dark)
    } else if (theme === ThemeChoiceLightDarkSystem.dark) {
      setTheme(ThemeChoiceLightDarkSystem.system)
    } else if (theme === ThemeChoiceLightDarkSystem.system) {
      setTheme(ThemeChoiceLightDarkSystem.light)
    }
  }

  const getClassName = (mode: ThemeChoiceLightDarkSystem) => {
    const activePart = mode === theme ? 'rotate-0 scale-100' : 'rotate-90 scale-0'
    return `absolute h-6 w-6 transition-all ${activePart} text-on-surface`
  }

  return (
    <ButtonToolTip tooltip={`Toggle theme (${theme})`} variant="ghost" size="icon" onClick={onClick}>
      <Sun className={getClassName(ThemeChoiceLightDarkSystem.light)} />
      <Moon className={getClassName(ThemeChoiceLightDarkSystem.dark)} />
      <SunMoon className={getClassName(ThemeChoiceLightDarkSystem.system)} />
      <span className="sr-only">Toggle theme ({theme})</span>
    </ButtonToolTip>
  )
}

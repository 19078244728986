import { isRouteErrorResponse, useLocation, useNavigate, useRouteError } from 'react-router-dom'

import { ErrorDisplay } from '@/components/ErrorDisplay'

import { Button } from './shadcn/ui/button'
import { Navbar } from './Navbar'

const RootBoundary = () => {
  const error = useRouteError()
  const location = useLocation()
  const navigate = useNavigate()

  const hasPreviousPage = location.key !== 'default'

  const goToPreviousPage = () => {
    if (hasPreviousPage) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <>
          <Navbar />
          <div className="container flex h-screen flex-col items-center justify-center gap-y-4 py-10">
            <h1 className="text-5xl font-bold">404</h1>
            <p>Page not found :(</p>
            <Button className="w-max" onClick={goToPreviousPage}>
              {hasPreviousPage ? 'Go back' : 'Go back home'}
            </Button>
          </div>
        </>
      )
    }
  }

  console.error('Error: ', error)

  function reloadPage() {
    const lastReloadTimestamp = localStorage.getItem('lastReloadTimestamp')
    const currentTimestamp = Date.now()

    if (!lastReloadTimestamp || currentTimestamp - Number(lastReloadTimestamp) > 10000) {
      localStorage.setItem('lastReloadTimestamp', String(currentTimestamp))
      window.location.reload()
    } else {
      return <ErrorDisplay />
    }
  }

  if ((error as any).message.includes('Failed to fetch dynamically imported module')) {
    return reloadPage()
  }

  return <ErrorDisplay />
}

export default RootBoundary

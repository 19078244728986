import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { QueryClientProvider } from '@tanstack/react-query'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from '@/store'
import { Toaster } from '@/components/shadcn/ui/toaster'
import { Router } from '@/packages/router'
import { queryClient } from '@/packages/api'
import { TooltipProvider } from '@/components/shadcn/ui/tooltip'
import { AppUpdatePopup } from '@/components/AppUpdatePopup'
import { ThemeChoiceLightDarkSystem, ThemeProvider } from '@/packages/theme/useTheme'
import '@fontsource/ibm-plex-mono/latin-400.css'
import '@fontsource/ibm-plex-mono/latin-400-italic.css'
import '@fontsource/ibm-plex-sans/latin-400.css'
import './index.css'
import { initFeatureFlags } from '@/packages/feature-flag/useFeatureFlag'

// initialize the feature flags
initFeatureFlags()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppUpdatePopup />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider defaultThemeChoice={ThemeChoiceLightDarkSystem.system} storageKey="vite-ui-theme">
            <TooltipProvider disableHoverableContent delayDuration={0}>
              <Router />
              <Toaster />
            </TooltipProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
    {/* We have this portal here to make Glide Data Grid overlay  */}
    {/* https://docs.grid.glideapps.com/api/dataeditor#html-css-prerequisites */}
    <div
      id="portal"
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 9999,
      }}
    />
  </React.StrictMode>,
)

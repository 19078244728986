/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Logfire
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import * as axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type { AccountGet200, HTTPValidationError, UserRead, UserUpdate } from '.././model'

/**
 * @summary Account Get
 */
export const accountGet = (options?: AxiosRequestConfig): Promise<AxiosResponse<AccountGet200>> => {
  return axios.default.get(`/dash/account/me`, options)
}

export const getAccountGetQueryKey = () => {
  return [`/dash/account/me`] as const
}

export const getAccountGetQueryOptions = <
  TData = Awaited<ReturnType<typeof accountGet>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountGet>>, TError, TData>>
  axios?: AxiosRequestConfig
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountGetQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountGet>>> = ({ signal }) =>
    accountGet({ signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountGetQueryResult = NonNullable<Awaited<ReturnType<typeof accountGet>>>
export type AccountGetQueryError = AxiosError<unknown>

/**
 * @summary Account Get
 */
export const useAccountGet = <TData = Awaited<ReturnType<typeof accountGet>>, TError = AxiosError<unknown>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountGet>>, TError, TData>>
  axios?: AxiosRequestConfig
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountGetQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getAccountGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof accountGet>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountGet>>, TError, TData>>
  axios?: AxiosRequestConfig
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountGetQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountGet>>> = ({ signal }) =>
    accountGet({ signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof accountGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof accountGet>>>
export type AccountGetSuspenseQueryError = AxiosError<unknown>

/**
 * @summary Account Get
 */
export const useAccountGetSuspense = <
  TData = Awaited<ReturnType<typeof accountGet>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountGet>>, TError, TData>>
  axios?: AxiosRequestConfig
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Account Update
 */
export const accountUpdate = (
  userUpdate: UserUpdate,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserRead>> => {
  return axios.default.put(`/dash/account/me`, userUpdate, options)
}

export const getAccountUpdateMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof accountUpdate>>, TError, { data: UserUpdate }, TContext>
  axios?: AxiosRequestConfig
}): UseMutationOptions<Awaited<ReturnType<typeof accountUpdate>>, TError, { data: UserUpdate }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountUpdate>>, { data: UserUpdate }> = (props) => {
    const { data } = props ?? {}

    return accountUpdate(data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type AccountUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof accountUpdate>>>
export type AccountUpdateMutationBody = UserUpdate
export type AccountUpdateMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Account Update
 */
export const useAccountUpdate = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof accountUpdate>>, TError, { data: UserUpdate }, TContext>
  axios?: AxiosRequestConfig
}): UseMutationResult<Awaited<ReturnType<typeof accountUpdate>>, TError, { data: UserUpdate }, TContext> => {
  const mutationOptions = getAccountUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

import { LogfireLogo } from '@/components/LogfireLogo'
import { cn } from '@/packages/style'

interface LogfireLogoWithBetaBadgeProps {
  className?: string
  badgeClassName?: string
}

export const LogfireLogoWithBetaBadge = ({ className, badgeClassName }: LogfireLogoWithBetaBadgeProps) => {
  return (
    <div className={cn('flex items-center gap-x-2', className)}>
      <LogfireLogo className="h-18 w-32" />
      <span
        className={cn(
          'rounded bg-black px-1.5 text-xs font-semibold text-white dark:bg-white dark:text-black',
          badgeClassName,
        )}
      >
        Beta
      </span>
    </div>
  )
}

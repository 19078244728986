import { Outlet, createBrowserRouter, redirect, useNavigation, Link } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { BeforeRouteChange } from '@/packages/router/utils/beforeRouteChange'
import { githubCodeLoader, githubLoginLoader } from '@/app/user/githubAuth'
import RootBoundary from '@/components/RootBoundary'
import ProtectedRoute from '@/components/ProtectedRoute'
import AuthenticatedLayout from '@/layouts/authenticated'
import { AuthProvider } from '@/context/auth'
import { ProjectLayout } from '@/components/Layout/ProjectLayout'
import { AuthenticateUser } from '@/app/auth/AuthenticateUser'
import { AcceptTOS } from '@/app/auth/AcceptTOS'
import { AlreadyAuthed } from '@/app/auth/AlreadyAuthed'
import { organizationSettingsBrowserRouter } from '@/app/organization/browserRouter'
import { AlertsList } from '@/app/alerts/components/AlertsList'
import { CreateAlert } from '@/app/alerts/components/CreateAlert'
import { AlertHistoryPage } from '@/app/alerts/alerts-history-page'
import { EditAlert } from '@/app/alerts/components/EditAlert'
import { ChannelsList } from '@/app/alerts/channels/ChannelsList'
import { useLoadingLogo, LoadingLogo } from '@/components/LoadingLogo'

import { ROUTES } from './routes'
import { projectSettingsRoutes } from './app-routes/project-settings'

export interface CrumbProps {
  organizationDisplayName: string
  organizationName: string
  projectName?: string
}

TopBarProgress.config({
  barColors: {
    '0': '#E72464',
    '1.0': '#E72464',
  },
  shadowBlur: 0,
})

const SetAuthProjectId = () => {
  const { state } = useNavigation()

  useLoadingLogo(state === 'loading')

  return (
    <AuthProvider>
      {state === 'loading' && <TopBarProgress />}
      <Outlet />
    </AuthProvider>
  )
}

export const browserRouter = createBrowserRouter([
  {
    element: <SetAuthProjectId />,
    children: [
      {
        errorElement: <RootBoundary />,
        children: [
          {
            path: '/',
            async lazy() {
              const Home = await import('@/app/page')
              return { Component: Home.default }
            },
          },
          // Redirect from /anon/ to /anonymous/ to reflect organization name
          {
            path: 'logs/anon/:projectName',
            loader: ({ params: { projectName } }) => {
              const organizationName = 'anonymous'
              return redirect(
                ROUTES.ORGANIZATION.PROJECT.buildPath({
                  organizationName,
                  projectName: projectName ?? '',
                }),
              )
            },
          },
          {
            path: ROUTES.LOGIN.path,
            async lazy() {
              const LoginPage = await import('@/app/user/LoginPage')
              return { Component: LoginPage.default }
            },
          },

          {
            path: ROUTES.AUTH.GITHUB_LOGIN.path,
            loader: githubLoginLoader,
            element: <LoadingLogo />,
          },
          {
            path: ROUTES.AUTH.GITHUB_CODE.path,
            loader: githubCodeLoader,
            async lazy() {
              const GitHubCodePage = await import('@/app/auth/GitHubCode')
              return { Component: GitHubCodePage.default }
            },
          },
          {
            element: <AlreadyAuthed />,
            children: [
              { path: ROUTES.AUTH.ACCEPT_TOS.path, element: <AcceptTOS /> },
              { path: ROUTES.AUTH.AUTHENTICATE.path, element: <AuthenticateUser /> },
            ],
          },

          {
            element: <ProtectedRoute />,
            handle: {
              crumbs: (props: CrumbProps) => [
                <Link key="org-crumb" to={ROUTES.ORGANIZATION.buildPath({ organizationName: props.organizationName })}>
                  {props.organizationDisplayName}
                </Link>,
              ],
            },
            children: [
              {
                element: <AuthenticatedLayout />,
                children: [
                  // device auth is here since it requires the user to be authenticated
                  {
                    path: ROUTES.AUTH.DEVICE_AUTH.path,
                    async lazy() {
                      const { DeviceAuth } = await import('@/app/user/deviceAuth')
                      return { Component: DeviceAuth }
                    },
                  },
                  // Join project here since user needs to be authenticated
                  {
                    path: ROUTES.JOIN.PROJECT.path,
                    lazy: async () => {
                      const ProjectJoin = await import('@/app/project/project-join')
                      return { Component: ProjectJoin.ProjectJoin }
                    },
                  },
                  {
                    path: '/custom-panel-dev',
                    async lazy() {
                      const CustomPanelDev = await import('@/app/custom-panel-dev/custom-panel-dev-page')
                      return { Component: CustomPanelDev.default }
                    },
                  },
                  {
                    path: ROUTES.ORGANIZATIONS.NEW.path,
                    handle: {
                      crumbs: () => [
                        <Link key="org-crumb" to={ROUTES.ORGANIZATIONS.NEW.buildPath({})}>
                          New Organization
                        </Link>,
                      ],
                    },
                    async lazy() {
                      const { CreateOrganizationPage } = await import('@/app/organization/create-organization-page')
                      return { Component: CreateOrganizationPage }
                    },
                  },
                  ...organizationSettingsBrowserRouter,
                  {
                    path: ROUTES.ORGANIZATION.INTERNAL.JOIN_ORGANIZATION.path,
                    async lazy() {
                      const JoinOrganizationPage = await import('@/app/organization/organization-join')
                      return { Component: JoinOrganizationPage.JoinOrganizationPage }
                    },
                  },
                  {
                    path: ROUTES.ORGANIZATION.INTERNAL.PROJECTS.NEW.path,
                    lazy: async () => {
                      const ProjectNew = await import('@/app/project/project-new')
                      return { Component: ProjectNew.ProjectNew }
                    },
                  },
                  {
                    path: ROUTES.ORGANIZATION.path,
                    async lazy() {
                      const OrganizationPage = await import(
                        '@/app/organization/organization-page/organization-page-loader'
                      )
                      return { Component: OrganizationPage.OrganizationPageLoader }
                    },
                    children: [
                      {
                        index: true,
                        async lazy() {
                          const OverviewPage = await import('@/app/organization/overview-page')
                          return { Component: OverviewPage.OverviewPage }
                        },
                      },

                      {
                        path: ROUTES.ORGANIZATION.INTERNAL.PROJECTS.path,
                        handle: {
                          crumbs: (props: CrumbProps) => [
                            <Link
                              key="org-crumb"
                              to={ROUTES.ORGANIZATION.INTERNAL.PROJECTS.buildPath({
                                organizationName: props.organizationDisplayName,
                              })}
                            >
                              Projects
                            </Link>,
                          ],
                        },
                        async lazy() {
                          const ProjectsPage = await import('@/app/projects/projects-page')
                          return { Component: ProjectsPage.ProjectsPage }
                        },
                      },
                      { path: 'members' },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ROUTES.ORGANIZATION.PROJECT.path,
            element: <ProjectLayout />,
            handle: {
              crumbs: ({ organizationName, projectName, organizationDisplayName }: CrumbProps) => [
                <Link key="org-crumb" to={ROUTES.ORGANIZATION.buildPath({ organizationName })}>
                  {organizationDisplayName}
                </Link>,
                <Link key="proj-crumb" to={ROUTES.ORGANIZATION.INTERNAL.PROJECTS.buildPath({ organizationName })}>
                  Projects
                </Link>,
                <Link
                  key="proj-name-crumb"
                  to={ROUTES.ORGANIZATION.PROJECT.buildPath({ organizationName, projectName: projectName! })}
                >
                  {projectName}
                </Link>,
              ],
            },
            children: [
              {
                // TODO: This route is deprecated and should be removed
                path: ROUTES.ORGANIZATION.PROJECT.GETTING_STARTED.path,
                lazy: async () => {
                  const GettingStartedPage = await import('@/app/project/settings/getting-started/getting-started')
                  return { Component: GettingStartedPage.GettingStarted }
                },
              },
              {
                path: ROUTES.ORGANIZATION.PROJECT.EXPLORE_V3.path,
                lazy: async () => {
                  const ExplorePage = await import('@/app/explore-v3/explore-v3-page').then(
                    (mod) => mod.ContextWrapper,
                  )
                  return { Component: ExplorePage }
                },
              },
              {
                path: ROUTES.ORGANIZATION.PROJECT.EXPLORE.path,
                lazy: async () => {
                  const ExplorePage = await import('@/app/explore-v3/explore-v3-page').then(
                    (mod) => mod.ContextWrapper,
                  )
                  return { Component: ExplorePage }
                },
              },
              {
                path: ROUTES.ORGANIZATION.PROJECT.DASHBOARDS.path,
                lazy: async () => {
                  const monitoringDashboards = await import('@/app/monitoring/monitoring-page')
                  return { Component: monitoringDashboards.default }
                },
              },
              {
                path: ROUTES.ORGANIZATION.PROJECT.DASHBOARDS.DASHBOARD.path,
                lazy: async () => {
                  const monitoringDashboard = await import('@/app/monitoring-dashboard/monitoring-dashboard-page')
                  return { Component: monitoringDashboard.default }
                },
              },
              {
                index: true,
                lazy: async () => {
                  const LiveView = await import('@/app/logs/logs-page')
                  return { Component: LiveView.default }
                },
              },
              {
                path: ROUTES.ORGANIZATION.PROJECT.ALERTS.path,
                lazy: async () => {
                  const alerts = await import('@/app/alerts/alert-page')
                  return { Component: alerts.AlertPage }
                },
                children: [
                  { index: true, element: <AlertsList /> },
                  { path: ROUTES.ORGANIZATION.PROJECT.ALERTS.NEW.path, element: <CreateAlert /> },
                  {
                    path: ROUTES.ORGANIZATION.PROJECT.ALERTS.path,
                    children: [
                      {
                        index: true,
                        path: ROUTES.ORGANIZATION.PROJECT.ALERTS.HISTORY.path,
                        element: <AlertHistoryPage />,
                      },
                      {
                        path: ROUTES.ORGANIZATION.PROJECT.ALERTS.EDIT.path,
                        element: <EditAlert />,
                      },
                    ],
                  },
                  {
                    path: ROUTES.ORGANIZATION.PROJECT.ALERTS.CHANNELS.path,
                    element: <ChannelsList />,
                    children: [
                      {
                        path: ROUTES.ORGANIZATION.PROJECT.ALERTS.CHANNELS.NEW.path,
                        async lazy() {
                          const NewChannel = await import('@/app/alerts/channels/NewChannel')
                          return { Component: NewChannel.NewChannel }
                        },
                      },
                    ],
                  },
                ],
              },
              projectSettingsRoutes,
            ],
          },
        ],
      },
    ],
  },
])

browserRouter.subscribe(() => {
  BeforeRouteChange.getInstance().callBeforeRouteChange()
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Dashboard, MonitoringBlockItem, MonitoringStoreType } from '@/store/monitoring/monitoringType'
import { getSimpleDurationMs, simpleDurationSchema } from '@/packages/time/simpleDuration'
import { adjustResolutions } from '@/packages/time'

const defaultTimeRange = {
  from: new Date(Date.now() - 1000 * 60 * 60 * 24).toISOString(),
  to: new Date().toISOString(),
}

const loadInitialData = () => {
  const preset = simpleDurationSchema.safeParse(localStorage.getItem('monitoring-dashboard-preset')).data
  const resolution = simpleDurationSchema.safeParse(localStorage.getItem('monitoring-dashboard-resolution')).data

  if (preset && resolution) {
    const duration = getSimpleDurationMs(preset)
    return {
      timeRange: {
        range: {
          from: new Date(Date.now() - duration).toISOString(),
          to: new Date().toISOString(),
        },
        preset,
      },
      resolution,
    }
  } else {
    return {
      timeRange: {
        range: defaultTimeRange,
        preset: '3d',
      } as const,
      resolution: '1d' as const,
    }
  }
}

export const initialState: MonitoringStoreType = {
  dashboards: [
    {
      name: 'Dashboard',
      id: 'dashboard',
      slug: 'dashboard',
      templateName: 'custom',
      items: [],
    },
  ],
  timeRange: loadInitialData().timeRange,
  resolution: loadInitialData().resolution,
}

const getInitialState = (): MonitoringStoreType => {
  const state = localStorage.getItem('monitoring')
  if (state) {
    return JSON.parse(state)
  }
  return initialState
}

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState: getInitialState(),
  reducers: {
    setDashboards: (state, action: PayloadAction<Dashboard[]>) => {
      state.dashboards = action.payload
    },

    addDashboard: (state, action: PayloadAction<Dashboard>) => {
      state.dashboards.push(action.payload)
    },

    removeDashboard: (state, action: PayloadAction<string>) => {
      state.dashboards = state.dashboards.filter((item) => item.id !== action.payload)
    },

    updateDashboard: (state, action: PayloadAction<Dashboard>) => {
      const index = state.dashboards.findIndex((item) => item.id === action.payload.id)
      if (index !== -1) {
        state.dashboards[index] = action.payload
      }
    },

    updateDashboardItems: (state, action: PayloadAction<{ dashboardId: string; items: MonitoringBlockItem[] }>) => {
      const dashboardIndex = state.dashboards.findIndex((item) => item.id === action.payload.dashboardId)
      if (dashboardIndex !== -1) {
        state.dashboards[dashboardIndex].items = action.payload.items
      }
    },

    addBlock: (state, action: PayloadAction<{ dashboardId: string; block: MonitoringBlockItem }>) => {
      const dashboard = state.dashboards.find((item) => item.id === action.payload.dashboardId)
      if (dashboard) {
        dashboard.items.push(action.payload.block)
      }
    },

    setTimeRange: (state, action: PayloadAction<MonitoringStoreType['timeRange']>) => {
      state.timeRange = action.payload
      localStorage.setItem('monitoring-dashboard-preset', action.payload.preset ?? '3d')

      state.resolution = adjustResolutions(action.payload.preset ?? '3d', state.resolution)
    },

    setResolution: (state, action: PayloadAction<MonitoringStoreType['resolution']>) => {
      state.resolution = action.payload
      localStorage.setItem('monitoring-dashboard-resolution', action.payload)
    },
  },
})

const {
  setDashboards,
  addDashboard,
  removeDashboard,
  updateDashboard,
  updateDashboardItems,
  addBlock,
  setTimeRange,
  setResolution,
} = monitoringSlice.actions

export const monitoringActions = {
  setDashboards,
  addDashboard,
  removeDashboard,
  updateDashboard,
  updateDashboardItems,
  addBlock,
  setTimeRange,
  setResolution,
}

import { ReactNode } from 'react'

import { cn } from '@/packages/style'

interface TabsAndSidebarProps {
  children: ReactNode
}

export const Tabs = ({ children }: { children: ReactNode }) => {
  return <ul className="flex border-b border-outline text-sm font-medium">{children}</ul>
}

export const Main = ({ className, children }: { className?: string; children: ReactNode }) => {
  return <section className={cn('mt-6 flex gap-x-20', className)}>{children}</section>
}

export const Sidebar = ({ children }: { children: ReactNode }) => {
  return (
    <section>
      <nav className="sticky top-2 w-72">
        <ul>{children}</ul>
      </nav>
    </section>
  )
}

export const Content = ({ className, children }: { className?: string; children: ReactNode }) => {
  return <section className={cn('grow pb-10', className)}>{children}</section>
}

export const TabsAndSidebar = ({ children }: TabsAndSidebarProps) => {
  return (
    <div className="flex flex-col items-center overflow-y-auto">
      <div className="w-[min(1344px,_90vw)] pt-5">{children}</div>
    </div>
  )
}

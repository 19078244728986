import { Link } from 'react-router-dom'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import { buttonVariants } from '@/components/shadcn/ui/button'
import { usePageTitle } from '@/hooks/usePageTitle'
import { ROUTES } from '@/packages/router/routes'
import { cn } from '@/packages/style'
import { useDeletePanel, useGetPanels, useUpdatePanel } from '@/packages/api'
import { useToast } from '@/components/shadcn/ui/use-toast'

import { CustomPanelItem } from './components/CustomPanelItem'

export const ProjectSettingsCustomPanels = () => {
  usePageTitle('Custom Panels')
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS.CUSTOM_PANELS)
  const customPanelsQuery = useGetPanels(organizationName, projectName)
  const customPanels = customPanelsQuery.data?.data
  const deletePanelMutation = useDeletePanel()
  const updatePanelMutation = useUpdatePanel()
  const { toast } = useToast()

  return (
    <div>
      <section className="flex items-center justify-between border-b border-outline pb-2">
        <p className="text-2xl font-semibold">Custom Panels</p>
        <Link
          to={ROUTES.ORGANIZATION.PROJECT.SETTINGS.CUSTOM_PANELS.NEW.buildPath({
            organizationName,
            projectName,
          })}
          className={cn(buttonVariants({ variant: 'default' }))}
        >
          New custom panel
        </Link>
      </section>
      <section className="mt-5">
        <ul className="flex flex-col gap-y-4">
          {customPanels?.map((customPanel) => {
            const { id } = customPanel
            return (
              <li key={id}>
                <CustomPanelItem
                  organizationName={organizationName}
                  projectName={projectName}
                  deletePanelMutation={deletePanelMutation}
                  updatePanelMutation={updatePanelMutation}
                  toast={toast}
                  {...customPanel}
                />
              </li>
            )
          })}
        </ul>
      </section>
    </div>
  )
}

import { format, formatDistance } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'

import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/shadcn/ui/tooltip'

const FULL_TIMESTAMP_FORMAT = `yyyy-MM-dd HH:mm:ss`
const TIME_FORMAT = 'HH:mm:ss'
const DATE_FORMAT = 'yyyy-MM-dd'

export const TimestampWithAge = ({
  className,
  timestamp,
  hideDate,
  formatOptions,
  formatDistanceOptions,
  labelFormatOverride,
}: {
  className?: string
  timestamp: string
  hideDate?: boolean
  formatOptions?: Parameters<typeof format>[2]
  formatDistanceOptions?: Parameters<typeof formatDistance>[2]
  labelFormatOverride?: string
}) => {
  const labelFormat = labelFormatOverride ?? (hideDate ? TIME_FORMAT : FULL_TIMESTAMP_FORMAT)
  const labelTimestamp = useMemo(
    () => format(new Date(timestamp), labelFormat, formatOptions),
    [timestamp, labelFormat, formatOptions],
  )
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span className={className}>{labelTimestamp}</span>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>
          <TimestampTooltip
            timestamp={timestamp}
            dateWasHidden={hideDate}
            formatDistanceOptions={formatDistanceOptions}
          />
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

export const AgeWithTimestamp = ({
  className,
  timestamp,
  hideDate,
  formatOptions,
  formatDistanceOptions,
  labelFormatOverride,
  updateIntervalMs = 60_000,
}: {
  className?: string
  timestamp: string
  hideDate?: boolean
  formatOptions?: Parameters<typeof format>[2]
  formatDistanceOptions?: Parameters<typeof formatDistance>[2]
  labelFormatOverride?: string
  updateIntervalMs?: number
}) => {
  const labelFormat = labelFormatOverride ?? (hideDate ? TIME_FORMAT : FULL_TIMESTAMP_FORMAT)
  const labelTimestamp = useMemo(
    () => format(new Date(timestamp), labelFormat, formatOptions),
    [timestamp, labelFormat, formatOptions],
  )
  const timestampDate = useMemo(() => new Date(timestamp), [timestamp])
  const [now, setNow] = useState(() => new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date())
    }, updateIntervalMs)
    return () => clearInterval(interval)
  }, [setNow, updateIntervalMs])

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span className={className}>{formatDistance(timestampDate, now, formatDistanceOptions)}</span>
      </TooltipTrigger>
      <TooltipContent>
        <span>{labelTimestamp}</span>
      </TooltipContent>
    </Tooltip>
  )
}

const TimestampTooltip = ({
  timestamp,
  formatDistanceOptions,
  // If the date was hidden, we show the full timestamp in the tooltip if the date is different from the current date
  dateWasHidden,
}: {
  timestamp: string
  formatDistanceOptions: Parameters<typeof formatDistance>[2]
  dateWasHidden?: boolean
}) => {
  // This component is only rendered when the tooltip is open, so we don't need a useMemo and don't need to worry
  // about performance impact. It's important to put the current-timestamp-dependent logic in a separate component like
  // this so that 1, it doesn't affect the performance of the much-more-commonly rendered TimestampWithAge component,
  // and 2, (and likely more importantly) so that the tooltip content updates when the current time changes.

  const now = new Date()
  const timestampDate = new Date(timestamp)
  let tooltipTimestamp: string | undefined
  if (dateWasHidden) {
    const formattedTimestampDate = format(timestampDate, DATE_FORMAT)
    const formattedNowDate = format(now, DATE_FORMAT)
    if (formattedNowDate !== formattedTimestampDate) {
      tooltipTimestamp = format(timestampDate, FULL_TIMESTAMP_FORMAT)
    }
  }

  return (
    <div className="flex flex-col items-center">
      {tooltipTimestamp && <div>{tooltipTimestamp}</div>}
      <div>{formatDistance(timestampDate, now, formatDistanceOptions)}</div>
    </div>
  )
}

import { useMemo } from 'react'
import { defaultKeymap } from '@codemirror/commands'
import CodeMirror from '@uiw/react-codemirror'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { keymap } from '@codemirror/view'
import { UseFormReturn } from 'react-hook-form'
import { CheckIcon, XIcon } from 'lucide-react'

import { buildSqlAutocompleteColumns, pgSql, useCodemirrorTheme } from '@/packages/codeMirror'
import { useAutocompleteGet } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { useProjectDashboardQuery } from '@/packages/api/__generated__/projects/projects'
import { cn } from '@/packages/style'
import { GlideDataGrid } from '@/components/GlideDataGrid'
import { LoadingButton } from '@/components/Button'
import { useQueryEngine } from '@/hooks/useQueryEngine'
import { getSimpleDurationMs, simpleDurationSchema } from '@/packages/time/simpleDuration'

import { AlertUpdateFormSchema } from '../zod-schemas'

const formLabelStyles = 'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
const formErrorStyles = 'text-[0.8rem] font-medium text-destructive'

const basicSetup = {
  highlightActiveLine: false,
  lineNumbers: true,
  foldGutter: false,
  searchKeymap: false,
  defaultKeymap: false,
  tabSize: 4,
}

interface AlertQueryProps {
  form: UseFormReturn<AlertUpdateFormSchema>
  isQueryValid: boolean | undefined
  setIsQueryValid: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

export const AlertQuery = ({ form, setIsQueryValid, isQueryValid }: AlertQueryProps) => {
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS.NEW)
  const codeMirrorTheme = useCodemirrorTheme()
  const timeWindow = form.watch('timeWindow')
  const queryHasError = form.formState.errors.query
  const query = form.watch('query')
  const { data: sqlAutocompleteColumns } = useAutocompleteGet(organizationName, projectName)
  const [queryEngine] = useQueryEngine()
  const mutation = useProjectDashboardQuery()
  const queryData = mutation.data?.data

  const validateQuery = (query: string) => {
    if (!query.trim()) {
      form.setError('query', { message: 'Query is required' })
      return console.error('Query cannot be empty')
    }
    form.clearErrors()
    const timeWindowMilliseconds = getSimpleDurationMs(simpleDurationSchema.safeParse(timeWindow).data ?? '5m')
    const minTimestamp = new Date(Date.now() - timeWindowMilliseconds).toISOString()

    mutation.mutate(
      {
        organization: organizationName,
        project: projectName,
        data: { query },
        params: {
          min_timestamp: minTimestamp,
          engine: queryEngine,
        },
      },
      {
        onSuccess: () => {
          setIsQueryValid(true)
        },
        onError: (error) => {
          const errorMessage =
            typeof error.response?.data.detail === 'string'
              ? error.response.data.detail
              : 'An error occurred. Please try again.'

          form.setError('query', { message: errorMessage })
          setIsQueryValid(false)
        },
      },
    )
  }

  const modifiedEnterDefaultKeymap = keymap.of([
    ...defaultKeymap.filter((x) => !x.key?.includes('-Enter')),
    {
      key: 'Mod-Enter',
      preventDefault: true,
      run: () => {
        validateQuery(query)
        return true
      },
    },
  ])

  const sqlExtension = useMemo(() => {
    if (sqlAutocompleteColumns?.data) {
      return pgSql(buildSqlAutocompleteColumns(sqlAutocompleteColumns.data), true)
    }
    return pgSql([], true)
  }, [sqlAutocompleteColumns])

  return (
    <section className="flex max-w-full flex-col gap-y-2">
      <p className={cn(formLabelStyles, queryHasError && 'text-destructive')}>Query</p>
      <p className="text-sm">You will be alerted when the following query returns one or more rows:</p>
      <div className="min-h-[150px]">
        <CodeMirror
          className="rounded border border-outline bg-surface-container-low font-ibm-plex-mono text-xs [&_.cm-gutters]:rounded-l"
          theme={codeMirrorTheme}
          placeholder="SELECT * FROM records WHERE is_exception"
          extensions={[sqlExtension, modifiedEnterDefaultKeymap]}
          basicSetup={basicSetup}
          value={query}
          onChange={(text) => {
            if (isQueryValid !== undefined) setIsQueryValid(undefined)
            form.setValue('query', text, { shouldDirty: true })
            form.clearErrors('query')
          }}
          height="150px"
        />
      </div>
      <div className="flex items-center gap-x-2">
        <LoadingButton
          isLoading={mutation.isPending}
          loadingText="Executing query"
          onClick={() => validateQuery(query)}
          type="button"
          className="w-max"
        >
          Preview query results
        </LoadingButton>
        {isQueryValid && <CheckIcon className="text-states-notice" />}
        {isQueryValid === false && <XIcon className="text-states-error" />}
      </div>
      {form.formState.errors.query && <p className={formErrorStyles}>{form.formState.errors.query.message}</p>}
      {mutation.isPending && <p className="text-sm text-foreground/60">Loading...</p>}
      {queryData && (
        <>
          <div className="w-full overflow-scroll rounded border border-outline">
            <GlideDataGrid data={queryData} />
          </div>
        </>
      )}
    </section>
  )
}

import { FC } from 'react'

import { Button, ButtonProps } from '@/components/shadcn/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/ui/tooltip'

export interface Props extends ButtonProps {
  tooltip: string
}

export const ButtonToolTip: FC<Props> = ({ tooltip, children, ...buttonProps }) => {
  return (
    <Tooltip delayDuration={1000}>
      <TooltipTrigger asChild>
        {/* extra span here means tooltip appears even when button is disabled */}
        <span className="flex">
          <Button {...buttonProps}>
            {children}
            <span className="sr-only">{tooltip}</span>
          </Button>
        </span>
      </TooltipTrigger>
      <TooltipContent>
        <p>{tooltip}</p>
      </TooltipContent>
    </Tooltip>
  )
}

import { ReactNode } from 'react'
import { ChevronRight } from 'lucide-react'

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../shadcn/ui/collapsible'

interface DetailsPanelProps {
  title: ReactNode
  children: ReactNode
  collapsible?: boolean
  open?: boolean
  setOpen?: (open: boolean) => void
}

export const DetailsPanel = ({ title, children, collapsible, open, setOpen }: DetailsPanelProps) => {
  return (
    <Collapsible
      disabled={!collapsible}
      open={collapsible ? open : true}
      onOpenChange={setOpen && ((newOpen) => setOpen(newOpen))}
      className="grow overflow-y-auto"
    >
      {collapsible ? (
        <CollapsibleTrigger className="group mb-2 flex w-full items-center border-b pb-1">
          <span className="transform transition-transform group-data-[state=open]:rotate-90">
            <ChevronRight />
          </span>
          <span className="flex grow items-baseline text-lg font-bold">{title}</span>
        </CollapsibleTrigger>
      ) : (
        <span className="flex grow items-baseline text-lg font-bold">{title}</span>
      )}
      <CollapsibleContent className="text-sm">{children}</CollapsibleContent>
    </Collapsible>
  )
}

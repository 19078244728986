import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { useEffect } from 'react'

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/ui/form'
import { Input } from '@/components/shadcn/ui/input'
import { LoadingButton } from '@/components/Button'
import { ROUTES } from '@/packages/router/routes'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { getProjectInfoQueryKey, queryClient, useProjectInfo, useProjectsUpdate } from '@/packages/api'

const newProjectSchema = z.object({
  description: z.string().max(500, { message: 'Description cannot be more than 500 characters long' }).optional(),
})

export const ProjectInfoForm = () => {
  const { toast } = useToast()
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS)
  const { data: projectInfoRes } = useProjectInfo(organizationName, projectName)
  const { mutate, isPending } = useProjectsUpdate({
    mutation: {
      onSuccess: () => {
        const projectInfoKey = getProjectInfoQueryKey(organizationName, projectName)
        queryClient.invalidateQueries({ queryKey: projectInfoKey })

        toast({
          title: 'Project updated',
        })
      },
      onError: (error) => {
        toast({
          title: 'Error updating project',
          description: error.message,
          variant: 'destructive',
        })
      },
    },
  })

  const projectInfo = projectInfoRes?.data

  const form = useForm<z.infer<typeof newProjectSchema>>({
    resolver: zodResolver(newProjectSchema),
    defaultValues: {
      description: '',
    },
  })

  useEffect(() => {
    if (projectInfo) {
      form.reset({ description: projectInfo.description ?? '' })
    }
  }, [form, projectInfo])

  const onSubmit = (values: z.infer<typeof newProjectSchema>) => {
    mutate({
      organization: organizationName,
      project: projectName,
      data: {
        description: values.description ?? '',
      },
    })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-8">
        <FormItem className="relative">
          <FormLabel>Project Name </FormLabel>
          <FormControl>
            <Input placeholder="my-project" value={projectName} disabled />
          </FormControl>
          <FormMessage className="pt-0.5" />
        </FormItem>

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => {
            return (
              <FormItem className="relative">
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input placeholder="My project description" {...field} />
                </FormControl>
                <FormMessage className="pt-0.5" />
              </FormItem>
            )
          }}
        />
        <LoadingButton type="submit" isLoading={isPending}>
          Save Changes
        </LoadingButton>
      </form>
    </Form>
  )
}

import { z } from 'zod'

const logFireUserTokenSchema = z.object({
  token: z.string(),
  expiration: z.string(),
})

type LogfireUserToken = z.infer<typeof logFireUserTokenSchema>

const userSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  githubUsername: z.string().nullable(),
  defaultOrganizationName: z.string().optional(),
})

export type User = z.infer<typeof userSchema>

const tokenElementSchema = z.object({
  token: z.string().optional(),
  organizationName: z.string(),
  projectName: z.string(),
})

const tokenElementArraySchema = z.array(tokenElementSchema)

export type TokenElement = z.infer<typeof tokenElementSchema>

const TOKEN_STORAGE_KEY = 'tokens'
const USER_STORAGE_KEY = 'user'
const LOG_FIRE_USER_TOKEN_STORAGE_KEY = 'logFireUser'

export class AuthManager {
  private tokens: z.infer<typeof tokenElementArraySchema> = []
  private user: User | null = null
  private logFireUserToken: LogfireUserToken | null = null

  // eslint-disable-next-line no-use-before-define
  private static instance: AuthManager

  private constructor() {
    console.debug(`AuthManager:: Initializing AuthManager`)

    // if loaded the local data is not match with the Schemas,
    // clear all data to force user to login again.
    const loadAuthLocalStorageError = this.loadLocalStorage()
    if (loadAuthLocalStorageError instanceof Error) {
      this.cleanAll()
    }
  }

  public static getInstance() {
    if (!AuthManager.instance) {
      AuthManager.instance = new AuthManager()
    }
    return AuthManager.instance
  }

  public setUser(user: User) {
    console.debug(`AuthManager:: Setting user`, user)

    const parsedUser = userSchema.safeParse(user)
    if (parsedUser.success) {
      this.user = user
      this.syncLocalStorage()
      return user
    } else {
      console.error(`AuthManager:: Error parsing user`, parsedUser.error)
      return new Error(`Error parsing user`)
    }
  }

  public getUser() {
    return this.user
  }

  public setLogfireUserToken(logFireUserToken: LogfireUserToken) {
    console.debug(`AuthManager:: Setting logFireUserToken`, logFireUserToken)
    const parsedLogfireUserToken = logFireUserTokenSchema.safeParse(logFireUserToken)
    if (parsedLogfireUserToken.success) {
      this.logFireUserToken = logFireUserToken
      this.syncLocalStorage()
      return logFireUserToken
    } else {
      console.error(`AuthManager:: Error parsing logFireUserToken`, parsedLogfireUserToken.error)
      return new Error(`Error parsing logFireUserToken`)
    }
  }

  public getLogfireUserToken() {
    return this.logFireUserToken
  }

  public addProject(tokenElement: TokenElement) {
    if (this.isOrganizationProjectTokenExist(tokenElement)) {
      console.debug(
        `AuthManager:: Organization: ${tokenElement.organizationName} and project: ${tokenElement.projectName} already exists`,
        tokenElement,
      )
      this.updateProject(tokenElement)
    } else if (this.isTokenElementExist(tokenElement)) {
      console.debug(`AuthManager:: Organization and project already exists`, tokenElement)
      this.updateProject(tokenElement)
    } else {
      console.debug(`AuthManager:: Adding token`, tokenElement)
      this.tokens.push(tokenElement)
    }
    this.syncLocalStorage()
  }

  public getToken(organization: string, project: string) {
    return this.tokens.find((element) => {
      return element.organizationName === organization && element.projectName === project
    })
  }

  public cleanAll() {
    console.debug(`AuthManager:: Clearing all tokens and user`)
    this.user = null
    this.logFireUserToken = null
    this.tokens = []
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    localStorage.removeItem(USER_STORAGE_KEY)
    localStorage.removeItem(LOG_FIRE_USER_TOKEN_STORAGE_KEY)
  }

  public removeProject(tokenElement: TokenElement) {
    console.debug(`AuthManager:: Removing project`, tokenElement)
    this.tokens = this.tokens.filter(
      (element) =>
        element.projectName !== tokenElement.projectName && element.organizationName !== tokenElement.organizationName,
    )
  }

  public updateProject(tokenElement: TokenElement) {
    this.tokens = this.tokens.map((element) => {
      if (
        element.projectName === tokenElement.projectName &&
        element.organizationName === tokenElement.organizationName
      ) {
        return {
          ...element,
          ...tokenElement,
        }
      }
      return element
    })
  }

  public isLogfireUserTokenExpired(logFireUserToken: LogfireUserToken) {
    return new Date(logFireUserToken.expiration) < new Date()
  }

  private isTokenElementExist(tokenElement: TokenElement) {
    return this.tokens.some((element) => {
      return element.token === tokenElement.token
    })
  }

  private isOrganizationProjectTokenExist(tokenElement: TokenElement) {
    return this.tokens.some((element) => {
      return (
        element.organizationName === tokenElement.organizationName && element.projectName === tokenElement.projectName
      )
    })
  }

  private syncLocalStorage() {
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(this.tokens))
    if (this.user) {
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(this.user))
    }
    if (this.logFireUserToken) {
      localStorage.setItem(LOG_FIRE_USER_TOKEN_STORAGE_KEY, JSON.stringify(this.logFireUserToken))
    }
  }

  public loadLocalStorage() {
    const loadTokenError = this.loadTokens()
    const loadUserError = this.loadUser()
    const loadLogFireUserTokenError = this.loadLogfireUserToken()
    if (
      loadTokenError instanceof Error ||
      loadUserError instanceof Error ||
      loadLogFireUserTokenError instanceof Error
    ) {
      console.error(
        `AuthManager:: Error loading local storage`,
        loadTokenError,
        loadUserError,
        loadLogFireUserTokenError,
      )
      return new Error(`Error loading local storage`)
    }
  }

  private loadTokens() {
    const tokens = localStorage.getItem(TOKEN_STORAGE_KEY)
    if (tokens) {
      try {
        const localTokens = JSON.parse(tokens)
        const parsedTokens = tokenElementArraySchema.safeParse(localTokens)
        if (parsedTokens.success) {
          this.tokens = parsedTokens.data
        } else {
          console.error(`AuthManager:: Error parsing tokens`, parsedTokens.error)
          return new Error(`Error parsing tokens`)
        }
      } catch (error) {
        console.error(`AuthManager:: Error parsing tokens`, error)
        return new Error(`Error parsing tokens`)
      }
    }
  }

  private loadUser() {
    const user = localStorage.getItem(USER_STORAGE_KEY)
    if (user) {
      try {
        const parsedUser = userSchema.safeParse(JSON.parse(user))
        if (parsedUser.success) {
          this.user = parsedUser.data
        } else {
          console.error(`AuthManager:: Error parsing user`, parsedUser.error)
          return new Error(`Error parsing user`)
        }
      } catch (error) {
        console.error(`AuthManager:: Error parsing user`, error)
        return new Error(`Error parsing user`)
      }
    }
  }

  private loadLogfireUserToken() {
    const logFireUserToken = localStorage.getItem(LOG_FIRE_USER_TOKEN_STORAGE_KEY)
    if (logFireUserToken) {
      try {
        const parsedLogfireUserToken = logFireUserTokenSchema.safeParse(JSON.parse(logFireUserToken))
        if (parsedLogfireUserToken.success) {
          this.logFireUserToken = parsedLogfireUserToken.data
        } else {
          console.error(`AuthManager:: Error parsing logFireUserToken`, parsedLogfireUserToken.error)
          return new Error(`Error parsing logFireUserToken`)
        }
      } catch (error) {
        console.error(`AuthManager:: Error parsing logFireUserToken`, error)
        return new Error(`Error parsing logFireUserToken`)
      }
    }
  }
}

import { Outlet } from 'react-router-dom'

import { Navbar } from '@/components/Navbar'
import { Footer } from '@/components/Footer'

const AuthenticatedLayout = () => {
  return (
    <div className="flex h-full flex-1 flex-col">
      <Navbar />
      <main className="flex flex-1 flex-col items-stretch">
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

export default AuthenticatedLayout

import { z } from 'zod'

export const channelFormSchema = z.object({
  label: z.string().min(1, { message: 'Label is required' }),
  config: z.object({
    type: z.literal('webhook'),
    url: z.string().url({ message: 'Invalid URL' }),
    format: z.union([
      z.literal('auto'),
      z.literal('slack-blockkit'),
      z.literal('slack-legacy'),
      z.literal('raw-data'),
    ]),
  }),
})

export type ChannelFormSchema = z.infer<typeof channelFormSchema>

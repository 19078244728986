export const humanReadableNumber = (value: number | string, decimals = 2) => {
  const parsedValue = typeof value !== 'number' ? Number(value) : value

  if (isNaN(parsedValue)) {
    return value
  }

  if (parsedValue < 1000) {
    return parsedValue.toLocaleString()
  }

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['', 'K', 'M', 'B', 'T', 'Q']
  const i = Math.floor(Math.log(parsedValue) / Math.log(k))
  return parseFloat((parsedValue / Math.pow(k, i)).toFixed(dm)) + sizes[i]
}

export const numberFormatter = new Intl.NumberFormat('en-US')

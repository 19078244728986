import { Terminal } from 'lucide-react'

import { Button } from '@/components/shadcn/ui/button'

import { Alert, AlertDescription, AlertTitle } from './shadcn/ui/alert'

export function BlockRegisterMessage() {
  return (
    <Alert className="max-w-xl">
      <Terminal className="h-4 w-4" />
      <AlertTitle>Temporary Pause: Sign-Up Reopening Soon!</AlertTitle>
      <AlertDescription className="mt-2">
        <div>Due to high demand, we currently have a significant number of requests for registration.</div>
        <Button className="mt-2" variant="secondary">
          <a href="http://eepurl.com/iO7iSI" rel="noreferrer">
            Join the Waitlist
          </a>
        </Button>
      </AlertDescription>
    </Alert>
  )
}

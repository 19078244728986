import JsonView from '@uiw/react-json-view'
import { vscodeTheme } from '@uiw/react-json-view/vscode'

import { Theme, useTheme } from '@/packages/theme/useTheme'

import { JsonViewJsonOverrides } from './JsonViewJsonOverrides'
import { omitKey } from './util'

import './json-view.css'

export interface JsonObjectViewProps {
  value: object | undefined
  style?: React.CSSProperties | undefined
  collapsed?: boolean
  includeFilter?: boolean
  children?: React.ReactNode
}

export const useJsonViewTheme = () => {
  const { theme } = useTheme()

  // Omitting the background color makes it use the same value as the page
  // @ts-ignore // this key is specific to the library
  const darkTheme = omitKey(vscodeTheme, '--w-rjv-background-color')

  // The default theme works well enough for light theme
  return theme === Theme.dark ? darkTheme : undefined
}

export const JsonObjectView = ({ value, style, includeFilter, collapsed, children }: JsonObjectViewProps) => {
  const theme = useJsonViewTheme()

  return (
    <div className="relative">
      <JsonView
        collapsed={collapsed}
        displayDataTypes={false}
        value={value}
        shortenTextAfterLength={100}
        style={{ ...theme, ...style, wordBreak: 'break-all' }}
      >
        {children ?? <JsonViewJsonOverrides includeFilter={includeFilter} />}
      </JsonView>
    </div>
  )
}

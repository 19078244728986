import { useEffect, useState } from 'react'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { PlusIcon } from 'lucide-react'

import { Dialog, DialogContent, DialogFooter, DialogTrigger } from '@/components/shadcn/ui/dialog'
import { ROUTES } from '@/packages/router/routes'
import { useUpdateAlert, useListChannels, queryClient, getGetAlertQueryKey } from '@/packages/api'
import { type ChannelRead } from '@/packages/api/__generated__/model'
import { Checkbox } from '@/components/shadcn/ui/checkbox'
import List, { ListItem } from '@/components/List'
import { LoadingButton } from '@/components/Button'
import { cn } from '@/packages/style'
import { Button } from '@/components/shadcn/ui/button'
import { NewChannel } from '@/app/alerts/channels/NewChannel'

interface AssignNotificationChannelToAlertProps {
  alertId?: string
  channelIds?: string[]
  onSave?: (selectedChannels: ChannelRead[]) => void
}

const NoAlertsJSX = ({ onCreateChannelClick }: { onCreateChannelClick: () => void }) => (
  <section className="mt-6 flex flex-col items-center justify-center gap-y-1 space-y-4 rounded-lg border border-outline bg-surface-container-low px-3 py-10">
    <p className="text-center text-foreground text-opacity-50">
      You haven't configured any notification channels. Create one to receive alert notifications.
    </p>
    <Button onClick={onCreateChannelClick} variant="secondary" size="sm">
      New Channel
    </Button>
  </section>
)

export const AssignNotificationChannelToAlert = ({
  alertId,
  channelIds,
  onSave,
}: AssignNotificationChannelToAlertProps) => {
  const [isOpened, setIsOpened] = useState(false)
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.ALERTS)
  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>(channelIds ?? [])
  const [isCreateChannelOpened, setIsCreateChannelOpened] = useState(false)

  const { data: channelsListData } = useListChannels(organizationName, projectName)
  const mutation = useUpdateAlert({
    mutation: {
      onSuccess: async () => {
        if (alertId) {
          const key = getGetAlertQueryKey(organizationName, projectName, alertId)
          await queryClient.invalidateQueries({ queryKey: key })
        }
        onSave?.(channelsListData?.data.filter((channel) => selectedChannelIds.includes(channel.id)) ?? [])
        setIsOpened(false)
      },
    },
  })

  const channels = channelsListData?.data ?? []

  const onSubmit = () => {
    if (alertId) {
      mutation.mutateAsync({
        organization: organizationName,
        project: projectName,
        alertId,
        data: {
          channel_ids: selectedChannelIds,
        },
      })
    } else {
      setIsOpened(false)
      onSave?.(channelsListData?.data.filter((channel) => selectedChannelIds.includes(channel.id)) ?? [])
    }
  }

  useEffect(() => {
    setSelectedChannelIds(channelIds ?? [])
  }, [channelIds])

  if (isCreateChannelOpened) {
    return (
      <NewChannel
        onSaved={(channel) => {
          setSelectedChannelIds([...selectedChannelIds, channel.id])
          setIsCreateChannelOpened(false)
        }}
        onDismissed={() => setIsCreateChannelOpened(false)}
      />
    )
  }

  return (
    <Dialog open={isOpened} onOpenChange={setIsOpened}>
      <DialogTrigger asChild>
        <Button size="sm" variant="secondary" type="button">
          Add Channels
        </Button>
      </DialogTrigger>
      <DialogContent>
        <section>
          <p className="text-2xl font-bold">Select Notification Channels</p>
        </section>
        <section>
          {channels.length === 0 && !mutation.isPending && (
            <NoAlertsJSX
              onCreateChannelClick={() => {
                setIsCreateChannelOpened(true)
              }}
            />
          )}
          {channels.length > 0 && (
            <List className="max-h-96 overflow-y-auto">
              {channels.map((channel) => (
                <ListItem
                  size="sm"
                  key={channel.id}
                  onClick={() => {
                    setSelectedChannelIds(
                      selectedChannelIds.includes(channel.id)
                        ? selectedChannelIds.filter((id) => id !== channel.id)
                        : [...selectedChannelIds, channel.id],
                    )
                  }}
                  className="flex cursor-pointer items-center border-surface-container-highest bg-surface-container-high"
                >
                  <Checkbox
                    checked={selectedChannelIds.includes(channel.id)}
                    onCheckedChange={(checked) => {
                      setSelectedChannelIds(
                        checked
                          ? [...selectedChannelIds, channel.id]
                          : selectedChannelIds.filter((id) => id !== channel.id),
                      )
                    }}
                  />
                  <span className="ml-2 text-sm text-on-surface">{channel.label}</span>
                </ListItem>
              ))}
              <ListItem
                size="sm"
                className="flex cursor-pointer items-center border-surface-container-highest bg-surface-container-high"
                onClick={() => setIsCreateChannelOpened(true)}
              >
                <PlusIcon className="h-4 w-4" />
                <span className="ml-2 text-sm text-on-surface">New Channel</span>
              </ListItem>
            </List>
          )}
        </section>
        {channels.length > 0 && (
          <DialogFooter>
            <section className="flex gap-x-2">
              <span className="w-max">
                <LoadingButton
                  variant="alternative"
                  isLoading={mutation.isPending}
                  className={cn('disabled:cursor-not-allowed')}
                  onClick={onSubmit}
                >
                  Save
                </LoadingButton>
              </span>
            </section>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  )
}

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CogIcon, LogOutIcon } from 'lucide-react'

import { useAuth } from '@/context/auth'
import { AdvancedSettingsPopover } from '@/app/explore/components/AdvancedSettingsPanel'
import { useAccountGet, useOrganizationGet } from '@/packages/api'

import { UserAvatar } from '../UserAvatar'
import { Separator } from '../shadcn/ui/separator'
import { Sheet, SheetContent, SheetFooter, SheetTrigger } from '../shadcn/ui/sheet'

import { OrganizationsList } from './OrganizationsList'
import { UserSheetLink } from './UserSheetLink'

export const UserSheet = () => {
  const { data: account } = useAccountGet()
  const { user, logout } = useAuth()
  const location = useLocation()
  const personalOrg = account?.data?.name ?? ''
  const organizationQuery = useOrganizationGet(personalOrg)
  const organizationData = organizationQuery?.data?.data
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  useEffect(() => {
    setIsSheetOpen(false)
  }, [location.pathname])

  if (!user) {
    return (
      <AdvancedSettingsPopover isOpen={isAdvancedSettingsOpen} setIsOpen={setIsAdvancedSettingsOpen} allowLeftClick>
        <CogIcon />
      </AdvancedSettingsPopover>
    )
  }

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <AdvancedSettingsPopover isOpen={isAdvancedSettingsOpen} setIsOpen={setIsAdvancedSettingsOpen}>
        <SheetTrigger className="flex h-8 w-8 items-center">
          <UserAvatar
            displayName={organizationData?.organization_display_name}
            avatar={organizationData?.avatar ?? undefined}
            username={organizationData?.organization_name}
            className={organizationData?.github_handle ? 'h-8 w-8' : 'h-6 w-6'}
          />
        </SheetTrigger>
      </AdvancedSettingsPopover>
      <SheetContent className="flex h-[100vh] flex-1 flex-col gap-0 p-0">
        <section className="flex flex-1 flex-col overflow-hidden">
          <Separator />
          <div className="flex-1 overflow-y-auto pt-4">
            <OrganizationsList />
          </div>
        </section>
        <SheetFooter className="mt-0 flex flex-row pt-0">
          <div className="w-full">
            <Separator />
            <UserSheetLink external to="https://docs.pydantic.dev/logfire/">
              Documentation
            </UserSheetLink>
            <UserSheetLink external to="https://docs.pydantic.dev/logfire/help/">
              Support
            </UserSheetLink>
            <UserSheetLink external to="https://github.com/pydantic/logfire/issues/new/choose">
              Report an issue
            </UserSheetLink>
            <UserSheetLink external to="https://logfirestatus.pydantic.dev/">
              System Status
            </UserSheetLink>
            <UserSheetLink external to="https://docs.pydantic.dev/logfire/legal/terms_of_service/">
              Terms and Conditions
            </UserSheetLink>
            <div className="mt-4">
              <button className="inline-block w-full" onClick={logout}>
                <div className="flex items-center gap-x-2 px-4 py-4 hover:bg-surface-container-highest">
                  <LogOutIcon className="h-4 w-4 text-on-surface-variant" />
                  <span className="text-sm text-on-surface">Log out</span>
                </div>
              </button>
            </div>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

import React, { useMemo } from 'react'
import { Group } from '@visx/group'
import { Bar } from '@visx/shape'
import { AxisBottom } from '@visx/axis'
import { scaleLinear, scaleTime } from '@visx/scale'
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { localPoint } from '@visx/event'
import { PatternLines } from '@visx/pattern'
import { TriangleDownIcon } from '@radix-ui/react-icons'
import { formatDuration, intervalToDuration } from 'date-fns'

import { formatTimestamp } from '@/packages/time'
import { numberFormatter } from '@/packages/utils/humanReadableNumber'
import type { AlertTimelineRecord } from '@/packages/api/__generated__/model'

let tooltipTimeout: number

interface TooltipData {
  event: AlertTimelineRecord
  dataIndex?: number
}

interface BarChartProps {
  data: AlertTimelineRecord[]
  startTimestamp: Date
  endTimestamp: Date
  width: number
  yMax: number
  margin: { top: number; right: number; bottom: number; left: number }
  hideBottomAxis?: boolean
  hideLeftAxis?: boolean
  top?: number
  left?: number
  children?: React.ReactNode
}

const MIN_BAR_WIDTH = 5
const MIN_BAR_HEIGHT = 5

export default function BarChart(props: BarChartProps) {
  const { data, width, yMax, margin, top, left, children, startTimestamp, endTimestamp } = props

  const hasMatchesCounts = data.filter((a) => a.name === 'MATCHES_CHANGE').map((a) => a.new_matches ?? 0)

  const x = scaleTime({
    range: [0, width],
    domain: [startTimestamp, endTimestamp],
  })

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...hasMatchesCounts)],
      }),
    [hasMatchesCounts, yMax],
  )

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>()
  const { TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  })

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: undefined,
    color: undefined,
  }

  const getBarWidth = (record: AlertTimelineRecord) => {
    if (record.created_at && record.prev_created_at) {
      return x(new Date(record.created_at)) - x(new Date(record.prev_created_at))
    }
    if (record.prev_created_at) {
      return x(new Date(record.prev_created_at))
    } else if (record.created_at) {
      return x(new Date(record.created_at))
    }
    return 0
  }

  const onMouseMove = (alertEvent: AlertTimelineRecord, e: unknown, dataIndex?: number) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout)
    const eventSvgCoords = localPoint(document.body, e as MouseEvent)
    showTooltip({
      tooltipData: {
        event: alertEvent,
        dataIndex,
      },
      tooltipTop: eventSvgCoords?.y,
      tooltipLeft: eventSvgCoords?.x,
    })
  }

  return (
    <>
      <AxisBottom
        top={yMax}
        tickLength={yMax}
        tickLineProps={{
          className: 'stroke-on-surface-variant/20',
        }}
        labelClassName="text-on-surface"
        tickTransform={`translate(0, ${-yMax})`}
        scale={x}
        numTicks={x.ticks().length}
        labelOffset={-10}
        tickLabelProps={{
          offset: 12,
          transform: 'translate(0, 6)',
          textAnchor: 'middle',
          className: 'fill-on-surface-variant',
        }}
        tickFormat={(value) => {
          if (value instanceof Date) return formatTimestamp(value)
        }}
      />
      <Group left={left ?? margin.left} top={top ?? margin.top}>
        {children}
        {data
          .filter((record) => record.name === 'MATCHES_CHANGE')
          .map((record) => {
            if (
              record.name !== 'MATCHES_CHANGE' ||
              (record.name === 'MATCHES_CHANGE' && record.new_matches === 0) ||
              !record.created_at
            ) {
              return null
            }

            const barWidth = Math.max(getBarWidth(record), MIN_BAR_WIDTH)
            const barHeight = Math.max(yMax - yScale(record.new_matches ?? 0), MIN_BAR_HEIGHT)
            const barX = record.prev_created_at ? x(new Date(record.prev_created_at)) : x(new Date(record.created_at))
            const barY = yMax - barHeight
            const id = `bar-${record.created_at}`
            return (
              <React.Fragment key={id}>
                <PatternLines
                  id={id}
                  height={3}
                  width={3}
                  className="stroke-states-warning"
                  strokeWidth={1}
                  orientation={['diagonal']}
                />
                <Bar
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={`url(#${id})`}
                  onMouseLeave={() => {
                    tooltipTimeout = window.setTimeout(() => {
                      hideTooltip()
                    }, 300)
                  }}
                  onMouseMove={(e) => onMouseMove(record, e)}
                />
              </React.Fragment>
            )
          })}
        {data
          .filter((record) => record.name === 'CONFIG_CHANGE')
          .map((record) => {
            if (!record.created_at) {
              return null
            }

            const barX = x(new Date(record.created_at))
            return (
              <TriangleDownIcon
                height={24}
                width={24}
                className="stroke-on-surface-variant/20 stroke-1 text-[#FBC4FF] hover:stroke-on-surface-variant/10"
                onMouseLeave={() => {
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip()
                  }, 300)
                }}
                onMouseMove={(e) => onMouseMove(record, e)}
                key={record.created_at + '_config'}
                x={barX - 12}
                y={yMax - 18}
              />
            )
          })}
        {/* {data */}
        {/*   .filter((record) => record.name === 'NOTIFICATION_ERROR') */}
        {/*   .map((alertEvent, index) => { */}
        {/*     const barX = x(new Date(alertEvent.timestamp)) */}
        {/*     return ( */}
        {/*       <FlagTriangleRight */}
        {/*         height={20} */}
        {/*         className="fill-states-error stroke-states-error-variant stroke-1 hover:stroke-states-error" */}
        {/*         onMouseLeave={() => { */}
        {/*           tooltipTimeout = window.setTimeout(() => { */}
        {/*             hideTooltip() */}
        {/*           }, 300) */}
        {/*         }} */}
        {/*         onMouseMove={(e) => onMouseMove(alertEvent, e)} */}
        {/*         key={alertEvent.timestamp} */}
        {/*         x={barX} */}
        {/*         y={yMax - 19} */}
        {/*       /> */}
        {/*     ) */}
        {/*   })} */}
        {/* {data */}
        {/*   .filter((a) => a.name === 'QUERY_ERROR') */}
        {/*   .map((alertEvent, index) => { */}
        {/*     const barX = x(new Date(alertEvent.timestamp)) */}
        {/*     return ( */}
        {/*       <Bar */}
        {/*         height={6} */}
        {/*         width={6} */}
        {/*         className="fill-states-error hover:stroke-states-error" */}
        {/*         onMouseLeave={() => { */}
        {/*           tooltipTimeout = window.setTimeout(() => { */}
        {/*             hideTooltip() */}
        {/*           }, 300) */}
        {/*         }} */}
        {/*         onMouseMove={(e) => onMouseMove(alertEvent, e)} */}
        {/*         key={alertEvent.timestamp} */}
        {/*         x={barX} */}
        {/*         y={yMax - 6} */}
        {/*       /> */}
        {/*     ) */}
        {/*   })} */}
      </Group>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
          className="z-10 w-[215px] rounded-lg bg-surface-container p-2 shadow-lg backdrop-blur"
        >
          <article className="flex flex-col space-y-2 text-xs">{getTooltipData(tooltipData).body}</article>
        </TooltipInPortal>
      )}
    </>
  )
}

const getTooltipData = (tooltipData: TooltipData): { body: React.ReactNode } => {
  const event = tooltipData.event
  switch (event.name) {
    case 'CONFIG_CHANGE':
      return {
        body: (
          <>
            <div className="border-l-2 border-[#FBC4FF] pl-2 text-[0.65rem]">
              <p>
                <span className="font-semibold">Configuration Change</span>
              </p>
            </div>
            <div className="bg-surface-container-highest">
              <p>{event.created_at ? formatTimestamp(new Date(event.created_at)) : null}</p>
            </div>
          </>
        ),
      }
    // case 'NOTIFICATION_ERROR':
    //   return {
    //     body: (
    //       <>
    //         <div className="border-l-2 border-states-error pl-2 text-[0.65rem]">
    //           <p>
    //             <span className="font-semibold">Sending Notification Failed</span>
    //           </p>
    //         </div>
    //         <div className="bg-surface-container-highest">
    //           <p>{formatTimestamp(new Date(event.timestamp))}</p>
    //         </div>
    //       </>
    //     ),
    //   }
    // case 'QUERY_ERROR':
    //   return {
    //     body: (
    //       <>
    //         <div className="border-l-2 border-states-error pl-2 text-[0.65rem]">
    //           <p>
    //             <span className="font-semibold">Query Error</span>
    //           </p>
    //         </div>
    //         <div className="bg-surface-container-highest">
    //           <p>{formatTimestamp(new Date(event.timestamp))}</p>
    //         </div>
    //       </>
    //     ),
    //   }
    case 'MATCHES_CHANGE': {
      return {
        body: (
          <>
            <div className="border-l-2 border-states-warning pl-2 text-[0.65rem]">
              <p>
                <span className="font-semibold">Has Matches</span>
              </p>
            </div>
            <div className="bg-surface-container-highest">
              <p>{numberFormatter.format(event.new_matches ?? 0)} matches</p>
            </div>
            {event.prev_created_at && event.created_at && (
              <div className="bg-surface-container-highest">
                <p>
                  Duration:{' '}
                  {formatDuration(
                    intervalToDuration({ start: new Date(event.prev_created_at), end: new Date(event.created_at) }),
                  )}
                </p>
                <p>
                  {formatTimestamp(new Date(event.prev_created_at))} - {formatTimestamp(new Date(event.created_at))}
                </p>
              </div>
            )}
          </>
        ),
      }
    }
  }
  return {
    body: null,
  }
}

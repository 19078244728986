import { UseFormReturn } from 'react-hook-form'

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/ui/form'
import { Input } from '@/components/shadcn/ui/input'
import { ChannelRead } from '@/packages/api/__generated__/model'
import { Alert } from '@/components/shadcn/ui/alert'
import { ChannelFormSchema } from '@/app/alerts/channels/channel-zod-schema'
import { LoadingButton } from '@/components/Button'
import { cn } from '@/packages/style'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/shadcn/ui/select'

interface ChannelFormProps {
  form: UseFormReturn<ChannelFormSchema>
  onSubmit: (values: Record<string, any>) => void
  channel?: ChannelRead
  isSubmitting: boolean
  mutationErrorMessage?: string
}

export const ChannelForm = ({ form, channel, isSubmitting, mutationErrorMessage }: ChannelFormProps) => {
  return (
    <>
      <>
        <section className="grid grid-cols-1 items-start gap-y-4">
          <FormField
            control={form.control}
            name="label"
            render={({ field }) => (
              <FormItem className="grow">
                <div className="flex w-full items-center justify-between">
                  <FormLabel>Channel Name</FormLabel>
                </div>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="config.type"
            render={({ field }) => (
              <FormItem className="grow">
                <div className="flex w-full items-center justify-between">
                  <FormLabel>Type</FormLabel>
                </div>
                <FormControl>
                  <Input {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="config.format"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Format</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className="h-8 bg-surface-bright text-xs text-on-surface placeholder:text-on-surface">
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {/* TODO: Add some in-line documentation about what the different formats are */}
                    {/* TODO: Add ability to trigger a "test" notification in each format, and/or view the payload */}
                    <SelectItem value="auto">Auto</SelectItem>
                    <SelectItem value="slack-blockkit">Slack</SelectItem>
                    <SelectItem value="slack-legacy">Slack Legacy (for Discord, etc.)</SelectItem>
                    <SelectItem value="raw-data">Raw Data</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="config.url"
            render={({ field }) => (
              <FormItem className="grow">
                <div className="flex w-full items-center justify-between">
                  <FormLabel>Webhook URL</FormLabel>
                </div>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </section>
        <section className="flex gap-x-2">
          <span className="w-max">
            <LoadingButton
              variant="alternative"
              isLoading={isSubmitting}
              className={cn('disabled:cursor-not-allowed')}
              type="submit"
            >
              {channel ? 'Update Channel' : 'Create Channel'}
            </LoadingButton>
          </span>
        </section>
      </>
      {mutationErrorMessage && <Alert variant="destructive">{mutationErrorMessage}</Alert>}
    </>
  )
}

import * as React from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { Loader2Icon, LucideIcon } from 'lucide-react'
import { cva } from 'class-variance-authority'

import { cn } from '@/packages/style'

import { buttonVariants, ButtonProps as ShadcnButtonProps } from './shadcn/ui/button'

const LoadingVariants = cva('', {
  variants: {
    size: {
      default: 'h-4 w-4 ml-2 mr-2',
      sm: 'h-4 w-4 mr-1 ml-1',
      lg: 'h-6 w-6 mr-4 ml-8',
      icon: 'h-6 w-6 mr-2 ml-2',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

export interface ButtonProps extends ShadcnButtonProps {
  isLoading?: boolean
  loadingText?: string
  icon?: LucideIcon
}

const LoadingButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, loadingText, icon, isLoading, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    const spinnerClass = isLoading ? 'animate-spin' : ''
    const Icon = isLoading ? Loader2Icon : icon
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), (isLoading === true || !!icon) && 'pl-0')}
        ref={ref}
        {...props}
        // It's safe to use the OR operator (||) here as a nullish coalescing operator because if the
        // disabled prop is explicitly set to false, it will remain as false. However, we should
        // always disable the button when loading, regardless of the value of the disabled prop.
        // This ensures that the button is non-interactive while the loading state is active.
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        disabled={props.disabled || isLoading}
      >
        {Icon && <Icon className={cn(LoadingVariants({ size }), spinnerClass)} />}
        {size !== 'icon' && <Slottable>{isLoading && loadingText ? loadingText : props.children}</Slottable>}
      </Comp>
    )
  },
)
LoadingButton.displayName = 'LoadingButton'

export { LoadingButton, buttonVariants }

import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { format, formatDistance } from 'date-fns'
import { useState } from 'react'
import { CopyIcon, CopyXIcon, MoreVertical } from 'lucide-react'

import { Button } from '@/components/shadcn/ui/button'
import { useListProjectInvitations } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { TableBody, TableCell, TableHead, TableHeader, TableRow, Table } from '@/components/shadcn/ui/table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/shadcn/ui/dropdown-menu'
import { ProjectInvitationRead } from '@/packages/api/__generated__/model'
import { useToastCopyText } from '@/hooks/clipboard'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/ui/tooltip'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { usePageTitle } from '@/hooks/usePageTitle'
import { EmptyStateCard } from '@/components/EmptyStateContainer'

import { CreateProjectInvitation } from './components/create-project-invitation'
import { RevokeProjectInvitation } from './revoke-project-invitation'

const getInvitationStatus = (
  invitation: Pick<ProjectInvitationRead, 'expiration' | 'max_usage_count' | 'usage_count'>,
) => {
  if (invitation.max_usage_count === invitation.usage_count) {
    return <span className="bg-yellow-100 text-yellow-800">Used</span>
  }
  if (new Date(invitation.expiration).getTime() < new Date().getTime()) {
    return <span className="rounded bg-red-100 p-1 text-red-800">Expired</span>
  }
  return <span className="rounded bg-green-100 p-1 text-green-800">Active</span>
}

const isActive = (invitation: Pick<ProjectInvitationRead, 'expiration' | 'max_usage_count' | 'usage_count'>) => {
  return (
    invitation.max_usage_count !== invitation.usage_count &&
    new Date(invitation.expiration).getTime() > new Date().getTime()
  )
}

const formatWithSeconds = (date: Date) => format(date, 'yyyy-MM-dd HH:mm:ss')

export const ProjectSettingsInvitations = () => {
  usePageTitle('Invitations')
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS.INVITATIONS)
  const projectInvitationsQuery = useListProjectInvitations(organizationName, projectName)
  const projectInvitations = projectInvitationsQuery.data?.data ?? []
  const { toastCopyText } = useToastCopyText()
  const [invitationId, setInvitationId] = useState<string | null>(null)
  return (
    <div className="space-y-4">
      <section className="flex items-center justify-between">
        <CreateProjectInvitation />
      </section>
      {!projectInvitationsQuery.isLoading && projectInvitations.length === 0 && (
        <EmptyStateCard description="You don't have any invitations. Create some to view them in this list." />
      )}
      {!projectInvitationsQuery.isLoading && projectInvitations.length > 0 && (
        <div>
          <Table>
            <TableHeader>
              <TableRow className="uppercase hover:bg-inherit">
                <TableHead className="pl-2">Status</TableHead>
                <TableHead>Created</TableHead>
                <TableHead>Expiration</TableHead>
                <TableHead>Last Used</TableHead>
                <TableHead>Usage/Limit</TableHead>
                <TableHead>Invite</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {projectInvitations?.map(
                ({
                  id,
                  created_at: createdAt,
                  expiration,
                  last_used_at: lastUsedAt,
                  max_usage_count: maxUsageCount,
                  usage_count: usageCount,
                }) => (
                  <TableRow key={id}>
                    <TableCell>
                      {getInvitationStatus({ usage_count: usageCount, expiration, max_usage_count: maxUsageCount })}
                    </TableCell>
                    <TableCell>
                      <Tooltip>
                        <TooltipTrigger>
                          {formatDistance(new Date(createdAt), new Date(), { addSuffix: true })}
                        </TooltipTrigger>
                        <TooltipContent>{formatWithSeconds(new Date(createdAt))}</TooltipContent>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip>
                        <TooltipTrigger>
                          {formatDistance(new Date(expiration), new Date(), { addSuffix: true })}
                        </TooltipTrigger>
                        <TooltipContent>{formatWithSeconds(new Date(expiration))}</TooltipContent>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {lastUsedAt ? (
                        <Tooltip>
                          <TooltipTrigger>
                            {formatDistance(new Date(lastUsedAt), new Date(), { addSuffix: true })}
                          </TooltipTrigger>
                          <TooltipContent>{formatWithSeconds(new Date(lastUsedAt))}</TooltipContent>
                        </Tooltip>
                      ) : (
                        'Never'
                      )}
                    </TableCell>
                    <TableCell>
                      {usageCount}/{maxUsageCount}
                    </TableCell>
                    <TableCell className="flex justify-between">
                      <Button
                        disabled={!isActive({ usage_count: usageCount, expiration, max_usage_count: maxUsageCount })}
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          const host = window.location.origin
                          const copyValue =
                            host +
                            ROUTES.JOIN.PROJECT.buildPath({
                              organizationName,
                              projectName,
                              invitationId: id,
                            })
                          if (copyValue !== null) {
                            toastCopyText(copyValue?.toString() ?? '', {
                              title: 'Copied',
                              duration: 3000,
                            })
                          } else {
                            toastCopyText('null', {
                              title: 'Copied',
                              duration: 3000,
                            })
                          }
                        }}
                      >
                        <CopyIcon className="mr-2 h-4 w-4" />
                        Copy Invitation Link
                      </Button>
                      <DropdownMenu modal={false}>
                        <DropdownMenuTrigger
                          asChild
                          disabled={!isActive({ usage_count: usageCount, expiration, max_usage_count: maxUsageCount })}
                        >
                          <Button className="px-1" variant="ghost" size="sm">
                            <MoreVertical size={20} />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem
                            onSelect={() => {
                              setInvitationId(id)
                            }}
                          >
                            <CopyXIcon className="mr-2 h-4 w-4" />
                            Revoke Invitation
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ),
              )}
              {projectInvitationsQuery.isLoading && (
                <TableRow>
                  <TableCell colSpan={6} className="text-center">
                    <div className="flex flex-col items-center justify-center gap-y-3">
                      <p>Loading Invitations</p>
                      <LoadingSpinner />
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {!projectInvitations?.length && !projectInvitationsQuery.isLoading && (
                <TableRow>
                  <TableCell colSpan={6} className="text-center">
                    No invitations
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
      <RevokeProjectInvitation invitationId={invitationId} setInvitationId={setInvitationId} />
    </div>
  )
}

/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Logfire
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import * as axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type {
  DeviceAuthParams,
  DeviceCompleteResponse,
  GitHubAuthURL,
  GithubAuthUrlParams,
  GithubUserInfo200,
  GithubUserInfoParams,
  HTTPExceptionError,
  HTTPValidationError,
  LoggedInUser,
  UserInfo,
} from '.././model'

/**
 * @summary Github Auth Url
 */
export const githubAuthUrl = (
  params?: GithubAuthUrlParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GitHubAuthURL>> => {
  return axios.default.get(`/auth/github-auth-url`, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getGithubAuthUrlQueryKey = (params?: GithubAuthUrlParams) => {
  return [`/auth/github-auth-url`, ...(params ? [params] : [])] as const
}

export const getGithubAuthUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof githubAuthUrl>>,
  TError = AxiosError<HTTPValidationError>,
>(
  params?: GithubAuthUrlParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof githubAuthUrl>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGithubAuthUrlQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof githubAuthUrl>>> = ({ signal }) =>
    githubAuthUrl(params, { signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof githubAuthUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GithubAuthUrlQueryResult = NonNullable<Awaited<ReturnType<typeof githubAuthUrl>>>
export type GithubAuthUrlQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Github Auth Url
 */
export const useGithubAuthUrl = <
  TData = Awaited<ReturnType<typeof githubAuthUrl>>,
  TError = AxiosError<HTTPValidationError>,
>(
  params?: GithubAuthUrlParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof githubAuthUrl>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGithubAuthUrlQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGithubAuthUrlSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof githubAuthUrl>>,
  TError = AxiosError<HTTPValidationError>,
>(
  params?: GithubAuthUrlParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof githubAuthUrl>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGithubAuthUrlQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof githubAuthUrl>>> = ({ signal }) =>
    githubAuthUrl(params, { signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof githubAuthUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GithubAuthUrlSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof githubAuthUrl>>>
export type GithubAuthUrlSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Github Auth Url
 */
export const useGithubAuthUrlSuspense = <
  TData = Awaited<ReturnType<typeof githubAuthUrl>>,
  TError = AxiosError<HTTPValidationError>,
>(
  params?: GithubAuthUrlParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof githubAuthUrl>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGithubAuthUrlSuspenseQueryOptions(params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * This endpoint is hit by the browser using the result of the GitHub OAuth flow.

If there is already a user account tied to the authenticated GitHub user,we return a payload to the browser with an
access token for that user which is used to make authenticated requests to our API.

If the user does not already have an account, we return a (string) JWT to the browser, which the browser
will include in a separate request to the create-github-user endpoint after they have explicitly accepted the
terms of service and privacy policy. The JWT includes all details necessary to create a user account, and is signed
to prevent tampering.
 * @summary Github User Info
 */
export const githubUserInfo = (
  params: GithubUserInfoParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GithubUserInfo200>> => {
  return axios.default.get(`/auth/github-callback`, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getGithubUserInfoQueryKey = (params: GithubUserInfoParams) => {
  return [`/auth/github-callback`, ...(params ? [params] : [])] as const
}

export const getGithubUserInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof githubUserInfo>>,
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
>(
  params: GithubUserInfoParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof githubUserInfo>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGithubUserInfoQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof githubUserInfo>>> = ({ signal }) =>
    githubUserInfo(params, { signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof githubUserInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GithubUserInfoQueryResult = NonNullable<Awaited<ReturnType<typeof githubUserInfo>>>
export type GithubUserInfoQueryError = AxiosError<HTTPExceptionError | HTTPValidationError>

/**
 * @summary Github User Info
 */
export const useGithubUserInfo = <
  TData = Awaited<ReturnType<typeof githubUserInfo>>,
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
>(
  params: GithubUserInfoParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof githubUserInfo>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGithubUserInfoQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGithubUserInfoSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof githubUserInfo>>,
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
>(
  params: GithubUserInfoParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof githubUserInfo>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGithubUserInfoQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof githubUserInfo>>> = ({ signal }) =>
    githubUserInfo(params, { signal, ...axiosOptions })

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof githubUserInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GithubUserInfoSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof githubUserInfo>>>
export type GithubUserInfoSuspenseQueryError = AxiosError<HTTPExceptionError | HTTPValidationError>

/**
 * @summary Github User Info
 */
export const useGithubUserInfoSuspense = <
  TData = Awaited<ReturnType<typeof githubUserInfo>>,
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
>(
  params: GithubUserInfoParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof githubUserInfo>>, TError, TData>>
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGithubUserInfoSuspenseQueryOptions(params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * This endpoint is hit when a user is creating an account after getting a response from the GitHub OAuth flow.

We need a separate endpoint for this because we want to make sure the user has explicitly accepted the terms of
service and privacy policy before we create an account for them.
 * @summary Create Github User
 */
export const createGithubUser = (
  userInfo: UserInfo,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<LoggedInUser>> => {
  return axios.default.post(`/auth/create-github-user`, userInfo, options)
}

export const getCreateGithubUserMutationOptions = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGithubUser>>, TError, { data: UserInfo }, TContext>
  axios?: AxiosRequestConfig
}): UseMutationOptions<Awaited<ReturnType<typeof createGithubUser>>, TError, { data: UserInfo }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGithubUser>>, { data: UserInfo }> = (props) => {
    const { data } = props ?? {}

    return createGithubUser(data, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateGithubUserMutationResult = NonNullable<Awaited<ReturnType<typeof createGithubUser>>>
export type CreateGithubUserMutationBody = UserInfo
export type CreateGithubUserMutationError = AxiosError<HTTPExceptionError | HTTPValidationError>

/**
 * @summary Create Github User
 */
export const useCreateGithubUser = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGithubUser>>, TError, { data: UserInfo }, TContext>
  axios?: AxiosRequestConfig
}): UseMutationResult<Awaited<ReturnType<typeof createGithubUser>>, TError, { data: UserInfo }, TContext> => {
  const mutationOptions = getCreateGithubUserMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Called by the frontend once a user has logged in.

This endpoint set's a value in a redis list which the SDK should be waiting upon.
 * @summary Device Auth
 */
export const deviceAuth = (
  params: DeviceAuthParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DeviceCompleteResponse>> => {
  return axios.default.post(`/auth/device-auth`, undefined, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getDeviceAuthMutationOptions = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deviceAuth>>, TError, { params: DeviceAuthParams }, TContext>
  axios?: AxiosRequestConfig
}): UseMutationOptions<Awaited<ReturnType<typeof deviceAuth>>, TError, { params: DeviceAuthParams }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deviceAuth>>, { params: DeviceAuthParams }> = (
    props,
  ) => {
    const { params } = props ?? {}

    return deviceAuth(params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeviceAuthMutationResult = NonNullable<Awaited<ReturnType<typeof deviceAuth>>>

export type DeviceAuthMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Device Auth
 */
export const useDeviceAuth = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deviceAuth>>, TError, { params: DeviceAuthParams }, TContext>
  axios?: AxiosRequestConfig
}): UseMutationResult<Awaited<ReturnType<typeof deviceAuth>>, TError, { params: DeviceAuthParams }, TContext> => {
  const mutationOptions = getDeviceAuthMutationOptions(options)

  return useMutation(mutationOptions)
}

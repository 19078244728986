import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { PropsWithChildren } from 'react'

import { Dialog, DialogClose, DialogContent, DialogTrigger } from '@/components/shadcn/ui/dialog'
import { Button } from '@/components/shadcn/ui/button'
import { getProjectsListByOrgQueryKey, queryClient, useProjectsDelete } from '@/packages/api'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/ui/form'
import { Input } from '@/components/shadcn/ui/input'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { LoadingButton } from '@/components/Button'
import { Alert } from '@/components/shadcn/ui/alert'

interface DeleteProjectProps {
  organizationName: string
  projectName: string
  afterDelete?: () => void
}

export const DeleteProject = ({
  organizationName,
  projectName,
  afterDelete,
  children,
}: PropsWithChildren<DeleteProjectProps>) => {
  const { toast } = useToast()
  const formSchema = z.object({
    projectName: z
      .string()
      .min(1, { message: 'Project name is required' })
      .refine((val) => val === projectName, { message: 'Project name does not match' }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      projectName: '',
    },
  })

  const deleteProjectMutation = useProjectsDelete({
    mutation: {
      onSuccess: () => {
        const projectKey = getProjectsListByOrgQueryKey(organizationName)
        queryClient.invalidateQueries({ queryKey: projectKey }).then(() => {
          afterDelete?.()
        })
      },
      onError: (error) => {
        const errorMessage =
          typeof error.response?.data.detail === 'string'
            ? error.response.data.detail
            : 'An error occurred. Please try again.'
        toast({
          title: 'Error',
          description: errorMessage,
          variant: 'destructive',
        })
      },
    },
  })

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    deleteProjectMutation.mutate({
      organization: organizationName,
      project: values.projectName,
    })
  }
  const isMutationPending = deleteProjectMutation.isPending
  const hasError = deleteProjectMutation.isError

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <div className="flex flex-col gap-y-5">
          <section>
            <h1 className="text-2xl font-bold">Delete project</h1>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-5">
                <p className="mt-2 text-on-surface">
                  This will permanently delete <span className="font-extrabold">{projectName}</span>, to proceed type
                  the project name.
                </p>
                <FormField
                  control={form.control}
                  name="projectName"
                  render={({ field }) => {
                    return (
                      <FormItem className="relative">
                        <FormLabel>Project name</FormLabel>
                        <FormControl>
                          <Input placeholder={projectName} {...field} />
                        </FormControl>
                        <FormMessage className="pt-0.5" />
                      </FormItem>
                    )
                  }}
                />
                <section className="flex items-center gap-x-2">
                  <LoadingButton
                    variant="destructive"
                    isLoading={isMutationPending}
                    type="submit"
                    loadingText="Deleting"
                  >
                    Delete
                  </LoadingButton>
                  <DialogClose asChild>
                    <Button disabled={isMutationPending} variant="secondary" type="button">
                      Cancel
                    </Button>
                  </DialogClose>
                </section>
                {hasError && (
                  <section>
                    <Alert variant="destructive">
                      There is an error while deleting the project. Please try again.
                    </Alert>
                  </section>
                )}
              </form>
            </Form>
          </section>
        </div>
      </DialogContent>
    </Dialog>
  )
}

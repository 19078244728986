import { useRef, useMemo } from 'react'
import { scaleTime, scaleLinear } from '@visx/scale'
import { Bounds } from '@visx/brush/lib/types'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import { max } from '@visx/vendor/d3-array'
import { Brush } from '@visx/brush'

import { BrushHandle } from '@/app/alerts/components/timeline/BrushHandle'
import { type AlertTimelineRecord } from '@/packages/api/__generated__/model'

import BarChart from './BarChart'

// accessors
const getDate = (d: AlertTimelineRecord) => (d.created_at ? new Date(d.created_at) : new Date())

export interface BrushProps {
  width: number
  height: number
  margin?: { top: number; right: number; bottom: number; left: number }
  compact?: boolean
  startTimestamp: Date
  endTimestamp: Date
  alertsRun?: AlertTimelineRecord[]
  onRangeChange?: (range: { startTimestamp: string; endTimestamp: string } | null) => void
}

export const AlertTimeline = ({
  width,
  height,
  margin = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  alertsRun = [],
  onRangeChange,
  startTimestamp,
  endTimestamp,
}: BrushProps) => {
  const brushRef = useRef<BaseBrush | null>(null)

  const ChartBottomMargin = 30
  const ChartHeight = height - ChartBottomMargin

  // bounds
  const xMax = Math.max(width, 0)
  const yMax = Math.max(ChartHeight, 0)

  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xMax],
        domain: [startTimestamp, endTimestamp],
      }),
    [endTimestamp, startTimestamp, xMax],
  )
  const brushStockScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [0, max(alertsRun, getDate) ?? 0],
        nice: true,
      }),
    [alertsRun, yMax],
  )

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) {
      return
    }
    const start = new Date(domain?.x0 ?? 0)
    const end = new Date(domain?.x1 ?? 0)
    if (onRangeChange) {
      onRangeChange({
        startTimestamp: start.toISOString(),
        endTimestamp: end.toISOString(),
      })
    }
  }

  // event handlers
  const handleClearClick = () => {
    if (brushRef?.current) {
      // setFilteredStock(stock)
      brushRef.current.reset()
    }
    if (onRangeChange) {
      onRangeChange(null)
    }
  }

  return (
    <div className="relative select-none">
      <svg width={width} height={height} onDoubleClick={handleClearClick}>
        <BarChart
          hideLeftAxis
          data={alertsRun}
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
          width={width}
          yMax={yMax}
          margin={{ ...margin, bottom: ChartBottomMargin }}
        >
          {alertsRun.length > 0 && (
            <Brush
              xScale={brushDateScale}
              yScale={brushStockScale}
              width={xMax}
              height={yMax - 1}
              handleSize={8}
              innerRef={brushRef}
              resizeTriggerAreas={['left', 'right']}
              brushDirection="horizontal"
              onChange={onBrushChange}
              onClick={handleClearClick}
              selectedBoxStyle={{
                className: 'fill-on-surface/10 stroke-on-surface/20 z-10',
                radius: 30,
              }}
              useWindowMoveEvents
              renderBrushHandle={(props) => <BrushHandle {...props} />}
            />
          )}
        </BarChart>
      </svg>
    </div>
  )
}

import { Fragment, ReactNode } from 'react'
import { ChevronDownIcon } from 'lucide-react'

import { cn } from '@/packages/style'
import { MarkdownComp } from '@/components/Panels/Custom/Markdown'
import { Separator } from '@/components/shadcn/ui/separator'
import { DynamicIcon, DynamicIconName } from '@/components/Logs/LogDetails/components/DynamicIcon'
import { JsonObjectView } from '@/components/ObjectView/JsonObjectView'
import { DetailsPanel } from '@/components/Panels/DetailsPanel'
import { Badge } from '@/components/shadcn/ui/badge'

export type Json = string | number | boolean | null | { [property: string]: Json } | Json[]

interface SectionProps {
  title?: string
  subtitle?: string
  border?: boolean
  children?: React.ReactNode
}
export const Section = (props: SectionProps) => {
  const { title, border, children, subtitle } = props
  return (
    <div className={cn('p-2', border && 'rounded-lg border')}>
      <section className="flex items-center gap-x-2">
        {title && <h2 className="text-md mb-2 font-bold">{title}</h2>}
        {subtitle && <h3 className="text-sm font-normal text-on-surface">{subtitle}</h3>}
      </section>
      <div className="space-y-2">
        <ComponentSelector props={children} />
      </div>
    </div>
  )
}

interface AccordionProps {
  title?: string
  subtitle?: string
  border?: boolean
  collapsibleContent?: React.ReactNode
  children?: React.ReactNode
}

export const Accordion = (props: AccordionProps) => {
  const { title, border, children, subtitle, collapsibleContent } = props
  return (
    <div className={cn('p-2', border && 'rounded-lg border')}>
      <details className={cn('group')}>
        <summary className="flex flex-col">
          <section className="mb-2 flex cursor-pointer items-center gap-x-1">
            <span>
              <ChevronDownIcon className="h-4 w-4 transition-transform group-open:-scale-y-100" />
            </span>
            <section className="flex w-full items-center gap-x-1">
              {title && <h2 className="text-md font-bold">{title}</h2>}
              {subtitle && <h3 className="text-sm font-normal text-on-surface">{subtitle}</h3>}
            </section>
          </section>
        </summary>
        <div className="overflow-x-auto pb-2">
          <ComponentSelector props={collapsibleContent} />
        </div>
      </details>
      <div className="space-y-2">
        <ComponentSelector props={children} />
      </div>
    </div>
  )
}

interface LinkProps {
  url: string
  children?: React.ReactNode
}

export const Link = ({ url, children }: LinkProps) => {
  return <a href={url}>{typeof children === 'string' ? children : <ComponentSelector props={children} />}</a>
}

interface ImageProps {
  src: string
  alt: string
  className?: string
}

export const Image = ({ src, alt, className }: ImageProps) => {
  return <img src={src} alt={alt} className={className} />
}

interface IconProps {
  name: string
  color?: string
}

export const Icon = ({ name, color = 'currentColor' }: IconProps) => {
  return <DynamicIcon name={name as DynamicIconName} style={{ color }} />
}

interface PillProps {
  label: string
  value: ReactNode
}

export const Pill = ({ label, value }: PillProps) => {
  let children: ReactNode
  if (value === null) {
    children = <span className="italic text-muted-foreground">{'<null>'}</span>
  } else if (value === undefined) {
    children = <span className="italic text-muted-foreground">{'<undefined>'}</span>
  } else if (typeof value === 'string') {
    children = <span>{value}</span>
  } else {
    children = <span>{JSON.stringify(value)}</span>
  }
  return (
    <div className="inline-block">
      <Badge
        variant="outline"
        className="flex cursor-default items-center overflow-hidden bg-muted p-0 font-normal text-on-surface"
      >
        <span className="bg-slate-300 p-0.5 px-1 italic dark:bg-slate-700">{label}</span>
        <span className="p-0.5 px-1">{children}</span>
      </Badge>
    </div>
  )
}

interface MarkdownTextProps {
  content: string
  muted?: boolean
}

export const MarkdownText = ({ content, muted = false }: MarkdownTextProps) => {
  return (
    <MarkdownComp className={cn('space-y-2 rounded text-sm', muted ? 'text-on-surface' : undefined)} text={content} />
  )
}

interface PlainTextProps {
  // This component is intended for use when you want to escape Markdown syntax for any reason.
  text: string
  bold?: boolean
  italic?: boolean
  muted?: boolean
  code?: boolean // For monospaced font representation
}
export const PlainText = ({ text, bold, italic, muted, code }: PlainTextProps) => {
  return (
    <span
      className={cn(
        'text-sm',
        bold && 'font-bold',
        italic && 'italic',
        muted && 'text-on-surface',
        code && 'rounded bg-gray-100 p-1 font-ibm-plex-mono',
      )}
    >
      {text}
    </span>
  )
}

interface JsonObjectProps {
  content: Json
  raw?: boolean
}
export const JsonObject = ({ content, raw }: JsonObjectProps) => {
  return content && typeof content === 'object' ? (
    <JsonObjectView value={content} />
  ) : (
    <pre className="rounded bg-gray-100 p-1 font-ibm-plex-mono">{raw ? content : JSON.stringify(content)}</pre>
  )
}

interface DividerProps {
  orientation?: 'horizontal' | 'vertical'
}
export const Divider = ({ orientation }: DividerProps) => {
  return <Separator orientation={orientation} />
}

interface GridProps {
  className?: string
  columns: number // Number of columns in the grid.
  children?: React.ReactNode
}
export const Grid = ({ className, columns, children }: GridProps) => {
  return (
    <div className={cn(`grid grid-cols-${columns} gap-4`, className)}>
      <ComponentSelector props={children} />
    </div>
  )
}

interface FlexProps {
  className?: string
  direction: 'row' | 'column'
  children?: React.ReactNode
}
export const Flex = ({ className, direction, children }: FlexProps) => {
  return (
    <div className={cn('flex', direction === 'row' ? 'flex-row' : 'flex-col', className)}>
      <ComponentSelector props={children} />
    </div>
  )
}
export interface CustomPanelProps {
  title: string
  subtitle?: string
  children?: React.ReactNode
}
export const CustomPanel = ({ title, subtitle, children }: CustomPanelProps) => {
  return (
    <DetailsPanel
      title={
        <div className="flex w-full items-baseline justify-between">
          <div>{title}</div>
          {subtitle && <div className="text-sm font-normal text-on-surface">{subtitle}</div>}
        </div>
      }
    >
      <div className="space-y-2">
        <ComponentSelector props={children} />
      </div>
    </DetailsPanel>
  )
}

// Note: this component should generally be wrapped in an ErrorBoundary due to the dynamic nature of the
// components it renders and the weak guarantees on what it will receive.
const ComponentSelector = ({ props }: { props: any }) => {
  if (Array.isArray(props)) {
    return props.map((child, index) => <ComponentSelector key={index} props={child} />)
  }

  if (!props) return props

  if (typeof props === 'object') {
    if (props.type === undefined) {
      return <div>Invalid props for ComponentSelector: &apos;{JSON.stringify(props)}&apos;</div>
    }

    // the props.type should be set by the jsonJsxFactory function based on the component name
    switch (props.type) {
      case 'Section':
        return <Section {...props} />
      case 'Link':
        return <Link {...props} />
      case 'Image':
        return <Image {...props} />
      case 'Icon':
        return <Icon {...props} />
      case 'Pill':
        return <Pill {...props} />
      case 'MarkdownText':
        return <MarkdownText {...props} />
      case 'PlainText':
        return <PlainText {...props} />
      case 'JsonObject':
        return <JsonObject {...props} />
      case 'Divider':
        return <Divider {...props} />
      case 'Grid':
        return <Grid {...props} />
      case 'Flex':
        return <Flex {...props} />
      case 'CustomPanel':
        return <CustomPanel {...props} />
      case 'Fragment':
        return <Fragment {...props} />
      case 'Accordion':
        return <Accordion {...props} />
      default: {
        console.error('Unsupported component:', props.type, 'All props:', props)
        return <div>Unsupported component: &apos;{props.type}&apos;</div>
      }
    }
  }

  return props
}

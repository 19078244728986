import { CircleDashedIcon } from 'lucide-react'

import { cn } from '@/packages/style'

interface EmptyStateCardProps {
  description: string
  className?: string
}

export const EmptyStateCard = ({ description, className }: EmptyStateCardProps) => {
  return (
    <div
      className={cn(
        'flex h-full flex-col items-center justify-center gap-4 rounded-xl border p-12 text-sm',
        className,
      )}
    >
      <CircleDashedIcon className="opacity-50" />
      <p className="text-sm text-foreground text-opacity-50">{description}</p>
    </div>
  )
}

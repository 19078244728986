import { Link, RouteObject } from 'react-router-dom'

import { ROUTES } from '@/packages/router/routes'
import { CrumbProps } from '@/packages/router/browserRouter'

export const organizationSettingsBrowserRouter: RouteObject[] = [
  {
    path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.path,
    async lazy() {
      const OrganizationPageLoader = await import('@/app/organization/organization-page/organization-page-loader')
      return { Component: OrganizationPageLoader.OrganizationPageLoader }
    },
    children: [
      {
        path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.GENERAL.path,
        handle: {
          crumbs: (props: CrumbProps) => [
            <Link
              key="org-crumb"
              to={ROUTES.ORGANIZATION.INTERNAL.PROJECTS.buildPath({ organizationName: props.organizationName })}
            >
              Settings
            </Link>,
          ],
        },
        async lazy() {
          const OrganizationGeneralSettingPage = await import('@/app/organization/settings/general')
          return { Component: OrganizationGeneralSettingPage.OrganizationGeneralSettingPage }
        },
      },
      {
        path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.PAYMENT.path,
        children: [
          {
            path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.PAYMENT.PAYMENT_INFORMATION.path,
            async lazy() {
              const OrganizationPaymentMethodsPage = await import(
                '@/app/organization/settings/payment/payment-information'
              )
              return {
                Component: OrganizationPaymentMethodsPage.OrganizationPaymentMethodsPage,
              }
            },
          },
          {
            path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.PAYMENT.INVOICES_HISTORY.path,
            async lazy() {
              const OrganizationInvoicesHistory = await import('@/app/organization/settings/payment/invoices-history')
              return { Component: OrganizationInvoicesHistory.OrganizationInvoicesHistory }
            },
          },
          {
            path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.PAYMENT.USAGE.path,
            async lazy() {
              const OrganizationUsage = await import('@/app/organization/settings/payment/usage')
              return { Component: OrganizationUsage.OrganizationUsage }
            },
          },
        ],
      },
      {
        path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.MEMBERS.path,
        handle: {
          crumbs: (props: CrumbProps) => [
            <Link
              key="org-crumb"
              to={ROUTES.ORGANIZATION.INTERNAL.PROJECTS.buildPath({ organizationName: props.organizationName })}
            >
              Team
            </Link>,
          ],
        },
        children: [
          {
            path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.MEMBERS.LIST.path,
            async lazy() {
              const OrganizationGeneralSettingMemberListPage = await import('@/app/organization/settings/members-list')
              return { Component: OrganizationGeneralSettingMemberListPage.OrganizationGeneralSettingMemberListPage }
            },
          },
          {
            path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.MEMBERS.INVITATIONS.path,
            async lazy() {
              const OrganizationGeneralSettingInvitationsListPage = await import(
                '@/app/organization/settings/members-invitation'
              )
              return {
                Component: OrganizationGeneralSettingInvitationsListPage.OrganizationGeneralSettingInvitationsListPage,
              }
            },
          },
          {
            path: ROUTES.ORGANIZATION.INTERNAL.SETTINGS.MEMBERS.INVITATIONS.path,
            async lazy() {
              const OrganizationGeneralSettingInvitationsListPage = await import(
                '@/app/organization/settings/members-invitation'
              )
              return {
                Component: OrganizationGeneralSettingInvitationsListPage.OrganizationGeneralSettingInvitationsListPage,
              }
            },
          },
        ],
      },
    ],
  },
]

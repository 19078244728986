import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { useNavigate } from 'react-router-dom'

import { usePageTitle } from '@/hooks/usePageTitle'
import { NewReadTokenForm } from '@/components/NewReadTokenForm'
import { ROUTES } from '@/packages/router/routes'

export const ProjectSettingsReadTokensNew = () => {
  usePageTitle('New Read Token')

  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS.READ_TOKENS.NEW)
  const navigate = useNavigate()

  return (
    <>
      <section className="flex items-center justify-between border-b border-outline pb-2">
        <p className="text-xl font-semibold">Create Read Token</p>
      </section>
      <section className="mt-6">
        <NewReadTokenForm
          onDone={() =>
            navigate(ROUTES.ORGANIZATION.PROJECT.SETTINGS.READ_TOKENS.buildPath({ organizationName, projectName }))
          }
          organizationName={organizationName}
          projectName={projectName}
        />
      </section>
    </>
  )
}

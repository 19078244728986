import { useEffect } from 'react'
import { useTypedState } from 'react-router-typesafe-routes/dom'

import { useAuth } from '@/context/auth'
import { ROUTES } from '@/packages/router/routes'
import { LoadingLogo } from '@/components/LoadingLogo'

export const AuthenticateUser = () => {
  const { access_token: accessToken, expiration, user } = useTypedState(ROUTES.AUTH.AUTHENTICATE)
  const { login, isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      return
    }

    login({
      user: {
        id: user.id,
        name: user.name,
        email: user.email,
        githubUsername: user.github_username,
      },
      userToken: accessToken,
      expiration,
    })
  }, [expiration, accessToken, login, user, isAuthenticated])

  return <LoadingLogo />
}

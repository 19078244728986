import { useCallback, useMemo, useState } from 'react'
import {
  DataEditor,
  GridCell,
  GridCellKind,
  type GridColumn,
  type Item,
  Theme as GlideTheme,
} from '@glideapps/glide-data-grid'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import { getIdForColumn } from '@/app/monitoring-dashboard/libs/blockRender/TableRender'
import { themeTokens } from '@/utils/colors'
import { LONGEST_TIME_FILTER, ROUTES } from '@/packages/router/routes'
import { Theme, useTheme } from '@/packages/theme/useTheme'
import { handleJson } from '@/hooks/useJsonHandling'
import { ExploreQueryResponse } from '@/packages/api/__generated__/model'

import AttributesCellRenderer from './custom-renderers/attributes'

import '@glideapps/glide-data-grid/dist/index.css'

export type GlideDataGridData = Pick<ExploreQueryResponse, 'columns' | 'data'>

interface GlideDataGridProps {
  data: GlideDataGridData
  paginationInfo?: {
    rowsPerPage: number
    enabled: boolean
  }

  rowIndexStart?: number
  fullHeight?: boolean
}

const HEADER_HEIGHT = 36
const ROW_HEIGHT = 34

export const GlideDataGrid = ({ data, fullHeight, rowIndexStart, paginationInfo }: GlideDataGridProps) => {
  const height = fullHeight
    ? undefined
    : Math.min(
        HEADER_HEIGHT + ROW_HEIGHT * (paginationInfo?.enabled ? paginationInfo.rowsPerPage : data.data.length),
        400,
      )
  const theme = useDataGridTheme()

  // Note: the following line assumes that this component is used in the context of a project.
  // If we want to change that, we should probably make a less logfire-project-specific version of this component,
  // and wrap it in a project-specific component that provides the necessary context.
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT)

  const [columns, setColumns] = useState<GridColumn[]>(() =>
    data.columns.map((col, index) => ({
      title: col.name,
      id: getIdForColumn(index),
    })),
  )
  // This is used to render span_id as a link where possible:
  const traceIdColumnIndex = useMemo(() => columns.findIndex((col) => col.title === 'trace_id'), [columns])

  const getCellContent = useCallback(
    ([col, row]: Item): GridCell => {
      const value = data.data[row][col]

      const { json, value: handledValue } = handleJson(value)

      const valueAsString = json ? JSON.stringify(handledValue) : String(handledValue)

      if (json) {
        // Render JSON specially:
        return {
          kind: GridCellKind.Custom,
          data: { kind: 'attributes-cell', json: handledValue },
          allowOverlay: true,
          copyData: valueAsString,
        }
      }

      if (columns[col].title === 'trace_id' && valueAsString.match(/^[0-9a-f]{32}$/)) {
        // ***** Render trace_id as a link: *****
        const url =
          window.location.origin +
          ROUTES.ORGANIZATION.PROJECT.buildPath(
            { organizationName, projectName },
            { q: `trace_id='${valueAsString}'`, traceId: valueAsString, last: LONGEST_TIME_FILTER },
          )
        return {
          kind: GridCellKind.Uri,
          data: url,
          displayData: valueAsString,
          copyData: valueAsString,
          allowOverlay: false,
          hoverEffect: true,
          onClickUri: () => {
            window.open(url, '_blank')
          },
        }
      } else if (columns[col].title === 'span_id' && valueAsString.match(/^[0-9a-f]{16}$/)) {
        // ***** Render span_id as a link: *****
        if (traceIdColumnIndex >= 0) {
          const traceId = String(data.data[row][traceIdColumnIndex])
          if (traceId.match(/^[0-9a-f]{32}$/)) {
            const url =
              window.location.origin +
              ROUTES.ORGANIZATION.PROJECT.buildPath(
                { organizationName, projectName },
                {
                  q: `trace_id='${traceId}' AND span_id='${valueAsString}'`,
                  spanId: valueAsString,
                  traceId,
                  last: LONGEST_TIME_FILTER,
                },
              )
            return {
              kind: GridCellKind.Uri,
              data: url,
              displayData: valueAsString,
              copyData: valueAsString,
              allowOverlay: false,
              hoverEffect: true,
              onClickUri: () => {
                window.open(url, '_blank')
              },
            }
          }
        }
      }

      // Everything else:
      return {
        kind: GridCellKind.Text,
        data: valueAsString,
        displayData: valueAsString,
        allowOverlay: true,
        readonly: true,
        cursor: 'pointer',
      }
    },
    [columns, traceIdColumnIndex, data.data, organizationName, projectName],
  )

  const onColumnResize = useCallback(
    (column: GridColumn, newSize: number, colIndex: number, newSizeWithGrow: number) => {
      setColumns((prevCols) => {
        const index = prevCols.findIndex((col) => col.id === column.id)
        const newArray = [...prevCols]
        const isLastColumn = index === prevCols.length - 1
        newArray.splice(index, 1, { ...column, width: isLastColumn ? newSizeWithGrow : newSize })
        return newArray
      })
    },
    [],
  )

  const getRowThemeOverride = useCallback(
    (row: number): Partial<GlideTheme> | undefined => (row % 2 === 1 ? { bgCell: theme.bgCellMedium } : undefined),
    [theme],
  )

  return (
    <DataEditor
      rowMarkers={{ kind: 'number', startIndex: rowIndexStart ?? 1 }}
      width="100%"
      customRenderers={[AttributesCellRenderer]}
      maxColumnWidth={2_000}
      maxColumnAutoWidth={800}
      onColumnResize={onColumnResize}
      columns={columns}
      getCellContent={getCellContent}
      rows={data.data.length}
      getCellsForSelection
      height={height}
      theme={theme}
      getRowThemeOverride={getRowThemeOverride}
    />
  )
}

const useDataGridTheme = (): Partial<GlideTheme> => {
  const { theme } = useTheme()
  return useMemo(() => {
    const tokens = theme === Theme.dark ? themeTokens.dark : themeTokens.light
    return {
      accentColor: tokens['inverse-primary'],
      accentFg: tokens.surface,
      accentLight: `${tokens['inverse-surface']}1A`, // 10% opacity
      textDark: tokens['on-surface'],
      textMedium: tokens['on-surface-variant'],
      textLight: tokens['on-surface-variant'],
      textBubble: tokens['on-surface'],
      bgIconHeader: tokens['on-surface-variant'],
      fgIconHeader: tokens.surface,
      textHeader: tokens['on-surface'],
      textGroupHeader: `${tokens['on-surface']}BB`, // 70% opacity
      textHeaderSelected: tokens.surface,
      bgCell: tokens.surface,
      bgCellMedium: tokens['surface-container-low'],
      bgHeader: tokens['surface-container'],
      bgHeaderHasFocus: tokens['surface-container-high'],
      bgHeaderHovered: tokens['surface-container-highest'],
      bgBubble: tokens.outline,
      bgBubbleSelected: tokens.surface,
      bgSearchResult: tokens['states-warning-container-alpha'],
      borderColor: `${tokens['inverse-surface']}22`,
      horizontalBorderColor: `${tokens['inverse-surface']}22`,
      drilldownBorder: '#00000000',
      linkColor: tokens.link,
      cellHorizontalPadding: 8,
      cellVerticalPadding: 3,
      headerFontStyle: '600 13px',
      baseFontStyle: '13px',
      editorFontSize: '13px',
    }
  }, [theme])
}

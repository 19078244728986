import { useCallback } from 'react'

import { useToast } from '@/components/shadcn/ui/use-toast'

type ToastInputs = Parameters<ReturnType<typeof useToast>['toast']>[0]

export const useToastCopyText = (): { toastCopyText: (text: string, props: ToastInputs) => void } => {
  const { toast } = useToast()

  const toastCopyText = useCallback(
    (text: string, props: Parameters<typeof toast>[0]): void => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast(props)
        })
        .catch((err) => {
          console.error(err)
          toast({
            variant: 'destructive',
            title: 'Failed to copy',
            description: `See console for more details`,
          })
        })
    },
    [toast],
  )
  return { toastCopyText }
}

import { useEffect, useMemo, useState } from 'react'

export const useMatchMediaQuery = (query: string): boolean => {
  const queryList = useMemo(() => window.matchMedia(query), [query])
  const [matches, setMatches] = useState(queryList.matches)
  useEffect(() => {
    const listener = (ev: MediaQueryListEvent) => {
      setMatches(ev.matches)
    }
    queryList.addEventListener('change', listener)
    return () => queryList.removeEventListener('change', listener)
  }, [queryList])
  return matches
}

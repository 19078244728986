import { ReactNode } from 'react'
import { InfoIcon } from 'lucide-react'

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/ui/tooltip'

export const FormInfoIcon = ({ children }: { children: ReactNode }) => {
  return (
    <Tooltip>
      <TooltipTrigger type="button">
        <InfoIcon className="h-4 w-4" />
      </TooltipTrigger>
      <TooltipContent className="flex max-w-lg flex-col gap-y-2">{children}</TooltipContent>
    </Tooltip>
  )
}

import { z } from 'zod'

import { AlertCreateNotifyWhen } from '@/packages/api/__generated__/model'

export const notifyWhenOptions: { label: string; value: AlertCreateNotifyWhen }[] = [
  {
    label: 'Query has matches',
    value: 'has_matches',
  },
  { label: 'Query matches change', value: 'matches_changed' },
  { label: 'Query-has-matches changes', value: 'has_matches_changed' },
]

export const notifyWhenLabelsByValue = notifyWhenOptions.reduce<{ [key in AlertCreateNotifyWhen]?: string }>(
  (initialObj, option) => {
    initialObj[option.value] = option.label
    return initialObj
  },
  {},
)

// Step 1: Define the base schema without the refine method
const baseAlertFormSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  query: z.string().min(1, { message: 'Query is required' }),
  timeWindow: z.string().min(1, { message: 'Time window is required' }),
  webhookURLs: z.string().optional(),
  channels: z.array(z.string()),
  notifyWhen: z.union([z.literal('has_matches'), z.literal('has_matches_changed'), z.literal('matches_changed')]),
})

const extendedAlertFormSchema = baseAlertFormSchema.extend({
  active: z.boolean(),
})

export const updateAlertFormSchema = extendedAlertFormSchema.refine(
  (data) => data.channels.length > 0 || (data.webhookURLs && data.webhookURLs.trim() !== ''),
  {
    message: 'Choose at least one channel or add a new channel.',
    path: ['channels'],
  },
)

// Export the original schema if needed
export const alertFormSchema = baseAlertFormSchema.refine(
  (data) => data.channels.length > 0 || (data.webhookURLs && data.webhookURLs.trim() !== ''),
  {
    message: 'Choose at least one channel or add a new channel.',
    path: ['channels'],
  },
)

export type AlertFormSchema = z.infer<typeof alertFormSchema>

export type AlertUpdateFormSchema = z.infer<typeof updateAlertFormSchema>

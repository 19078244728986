export function formattedDuration(start: Date, end: Date): string {
  const duration = end.getTime() - start.getTime()
  return formattedDurationMsLong(duration)
}

const decimalRegex = /\.([0-9]+)/
export function convertTimestampToMillisWithMicroseconds(timestamp: string) {
  if (!timestamp.match(decimalRegex)) {
    // If there is no decimal with trailing digits, treat it as a normal timestamp
    // This "mishandles" the case where you have a timezone and a trailing decimal but no decimal places,
    // e.g., '2023-12-28T22:44:53.Z', but I don't think we need to worry about that.
    return new Date(timestamp).getTime()
  }

  // Split the timestamp into main part and microseconds
  let [mainPart, microsPart, timezone] = timestamp.split(decimalRegex)
  if (timezone !== undefined) {
    mainPart += timezone
  }
  microsPart = microsPart.padEnd(6, '0')

  // Create a Date object using the main part
  const date = new Date(mainPart)

  // Get the time in milliseconds from the Date object
  const timeMillis = date.getTime()

  // If there is a microseconds part, convert it to milliseconds and add to the time
  if (microsPart) {
    const microsAsMillis = parseInt(microsPart, 10) / 1000
    return timeMillis + microsAsMillis
  }

  // If there is no microseconds part, return the time in milliseconds
  return timeMillis
}

export function convertMillisWithMicrosecondsToIsoString(millisWithMicros: number) {
  const date = new Date(millisWithMicros)
  const micros = Math.round((millisWithMicros - date.getTime()) * 1000)
  const microsString = micros.toString().padStart(3, '0')

  // Format the date to an ISO string without milliseconds
  return `${date.toISOString().slice(0, -1)}${microsString}Z`
}

export function formattedDurationMsShort(
  durationMs: number,
  options?: { includeSpace: boolean; includeFullUnit: boolean },
): string {
  let suffix: string
  if (durationMs < 1000) {
    if (options?.includeFullUnit) suffix = 'milliseconds'
    else suffix = 'ms'
  } else {
    if (options?.includeFullUnit) suffix = 'seconds'
    else suffix = 's'
  }
  if (options?.includeSpace) suffix = ` ${suffix}`

  let number: string
  if (durationMs < 1) {
    number = `${durationMs.toFixed(1)}`
  } else if (durationMs < 10) {
    number = `${durationMs.toFixed(0)}`
  } else if (durationMs < 1_000) {
    number = `${durationMs.toFixed(0)}`
  } else if (durationMs < 100_000) {
    number = `${(durationMs / 1000).toFixed(1)}`
  } else {
    number = `${(durationMs / 1000).toFixed(0)}`
  }

  return `${number}${suffix}`
}

export function formattedDurationMsLong(durationMs: number): string {
  if (durationMs < 1) {
    return `${durationMs.toFixed(1)}ms`
  } else if (durationMs < 10) {
    return `${durationMs.toFixed(0)}ms`
  } else if (durationMs < 1_000) {
    return `${durationMs.toFixed(0)}ms`
  } else if (durationMs < 60_000) {
    return `${(durationMs / 1000).toFixed(1)}s`
  } else if (durationMs < 3_600_000) {
    // show minutes and seconds
    const minutes = Math.floor(durationMs / 60_000)
    const seconds = Math.floor(durationMs - minutes * 60_000) / 1000
    return `${padZeros(minutes)}:${padZeros(seconds, 2)}s`
  } else {
    // show hours, minutes, and seconds
    const hours = Math.floor(durationMs / 3_600_000)
    const minutes = Math.floor((durationMs - hours * 3_600_000) / 60_000)
    const seconds = Math.floor((durationMs - hours * 3_600_000 - minutes * 60_000) / 1000)
    return `${padZeros(hours)}:${padZeros(minutes)}:${padZeros(seconds, 2)}s`
  }
}

function padZeros(num: number, digits = 0): string {
  const padding = digits === 0 ? 2 : digits + 3
  return num.toFixed(digits).padStart(padding, '0')
}

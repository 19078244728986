import { useEffect } from 'react'
import { useTypedParams, useTypedSearchParams } from 'react-router-typesafe-routes/dom'
import { useMatch, useNavigate } from 'react-router-dom'

import { ROUTES } from '@/packages/router/routes'
import { Tabs, TabsContent, TabsTrigger, TabsList } from '@/components/shadcn/ui/tabs'
import { usePageTitle } from '@/hooks/usePageTitle'

import { ProductionInstructions } from './components/ProductionInstructions'
import { DevelopmentInstructions } from './components/DevelopmentInstructions'

export const GettingStarted = () => {
  usePageTitle('Get Started')

  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT)
  const route = ROUTES.ORGANIZATION.PROJECT.GETTING_STARTED
  const [searchParams, setSearchParams] = useTypedSearchParams(route)
  const tab = searchParams.gettingStartedTab ?? 'production'
  const getStartedPath = useMatch(ROUTES.ORGANIZATION.PROJECT.GETTING_STARTED.path)
  const navigate = useNavigate()

  useEffect(() => {
    // If the user is coming from ROUTES.ORGANIZATION.PROJECT.GETTING_STARTED.path
    // we want to redirect them to ROUTES.ORGANIZATION.PROJECT.SETTINGS.SETUP.path

    if (getStartedPath) {
      navigate(ROUTES.ORGANIZATION.PROJECT.SETTINGS.SETUP.buildPath({ organizationName, projectName }))
    }
  }, [getStartedPath, navigate, organizationName, projectName])

  return (
    <div className="overflow-y-auto">
      <div className="">
        <h1 className="text-lg font-semibold">Send data to your Logfire project</h1>
        <section className="mt-4">
          <Tabs value={tab} onValueChange={(val) => setSearchParams({ gettingStartedTab: val })}>
            <TabsList>
              <TabsTrigger value="production">Production</TabsTrigger>
              <TabsTrigger value="development">Development</TabsTrigger>
            </TabsList>
            <TabsContent className="text-sm" value="development">
              <section className="mt-4 flex flex-col gap-y-2">
                <DevelopmentInstructions />
              </section>
            </TabsContent>
            <TabsContent className="text-sm" value="production">
              <section className="mt-4 flex flex-col gap-y-2">
                <ProductionInstructions />
              </section>
            </TabsContent>
          </Tabs>
        </section>
      </div>
    </div>
  )
}

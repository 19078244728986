import { lazy, Suspense } from 'react'
import { LucideProps } from 'lucide-react'
import dynamicIconImports from 'lucide-react/dynamicIconImports'

export type DynamicIconName = keyof typeof dynamicIconImports
interface IconProps extends Omit<LucideProps, 'ref'> {
  name: DynamicIconName
  fallbackHeight?: number
  fallbackWidth?: number
}

export const DynamicIcon = ({ name, fallbackHeight = 24, fallbackWidth = 24, ...props }: IconProps) => {
  let dynamicIcon = dynamicIconImports[name]
  if (dynamicIcon === undefined) {
    dynamicIcon = dynamicIconImports['circle-help']
  }
  const LucideIcon = lazy(dynamicIcon)

  return (
    <Suspense fallback={<div style={{ background: '#ddd', width: fallbackWidth, height: fallbackHeight }} />}>
      <LucideIcon {...props} />
    </Suspense>
  )
}
